// import css & scss
import "../../../../shared/scss/comman.scss";
import "../../../../shared/scss/comman.css";

// import img
import Path2481 from '../../../../assets/Icons/Path-2481.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import _, { map } from 'underscore';
import { ArrowForward } from "@material-ui/icons";
import CustomFunction from "../../../../shared/library/CustomFunction";



const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const float2int = (value) => {
    return value | 0;
}

const getNoOfDays = (d1,d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    
    // To calculate the time difference of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();
    
    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}

const DirectFlight = (prop) => {
    //console.log(prop);
    if(prop.data.stop == 0){
        return <p className="pt-3" style={{fontSize: '11px'}}>Non Stop </p>
    }
    else {
        //let via = '';
        let via = prop.data.segments[0].Destination_City.split('(')[0];
        for (let index = 1; index < prop.data.segments.length-1; index++) {
            via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
            
        }
        return <p className="pt-3" style={{fontSize: '11px'}}>{prop.data.stop} Stop, via {via} </p>
    }
}

const DirectFlightForMObile = (prop) => {
    //console.log(prop);
    if(prop.data.stop == 0){
        return <p className="pt-0" style={{fontSize: '11px'}}>Non Stop </p>
    }
    else {
        //let via = '';
        let via = prop.data.segments[0].Destination_City.split('(')[0];
        for (let index = 1; index < prop.data.segments.length-1; index++) {
            via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
            
        }
        // , via {via}
        return <p className="pt-0" style={{fontSize: '11px'}}>{prop.data.stop} Stop </p>
    }
}

const DayHoursMins = (prop) =>{
    //console.log(prop);
    if(prop.data?.DurD == 0){
        return(<>{prop.data?.DurH}Hrs {prop.data?.DurM}Mins </>);
    }
    else{
        return(<>{prop.data?.DurD}Day {prop.data?.DurH}Hrs {prop.data?.DurM}Mins </>);
    }
}




class FlightDetailsForTickets extends Component {

    constructor(props){
        super(props);
        // this.fetchToken();
        //this.state = {isToggleOn: true};
        
    }

    state = {
        hide: true
    }

    
    className = "search-least xs-mb-1 "+this.props.classes;


    componentDidMount = () =>{
        console.log("=== componentDidMount ===");
    }

    componentWillUnmount = () =>{
        console.log("=== componentWillUnmount ===");
        this.setState({
            token: null
        });
    }

    // componentDidUpdate = () =>{
    //     console.log("=== componentDidUpdate ===");
    //     this.fetchToken();
    // }
    

    render() {
        
        //console.log("=== token ===", this.state.token);
        let dep = this.props.data.dtime;
        let ariv = this.props.data.artime;

        let next = getNoOfDays(dep[0], ariv[0]);
        let nextText = '';
        if(next == 1){
            nextText = "NEXT DAY";
        }
        if(next > 1){
            //console.log(ariv[0]);
            let dd = new Date(ariv[0]);
            let dd1 = dd.getDay();
            //console.log(dd);
            //console.log(dd1);
            nextText = "On "+dayName[dd1];
        }

        
        // const images = require('../../assets/AL1/'+this.props.data.Airline_Code+'.gif');
        //images.default
        //console.log(images.default);
        // let urlPath =`/review-flight?SData=${this.state.token}`

        const images = new CustomFunction().getImage(this.props.data.A_code+'.gif');

        return(
        <div className={this.className}>
            <Card className="search-card my-card">
                <CardContent className="sm-device md-device lg-device xl-device p-0">
                    
                    <Grid container className="mt-2 py-0">
                        <Grid item xs={4} sm md lg xl className="py-1 px-3">
                            <div className="w-100">
                                <ul className="mb-1" style={{display: 'inline-flex', paddingLeft: 0}}>
                                    <li style={{listStyle: 'none'}}><img src={images.default} width="50px" height="35px" /></li>
                                    <li style={{listStyle: 'none'}}><p className="gray-color f-w-600 pt-2">{this.props.data?.A_code}-{this.props.data?.flNo}</p></li>
                                </ul>
                            </div>
                            {/* <p className="m-0 w-100 gray-color f-w-600 pt-1" style={{fontSize: '12px'}}>Class - 0ONHJTY</p> */}
                        </Grid>
                        <Grid item xs={4} sm md lg xl className="py-1 position-relative text-center">
                            <h2 className="h5 p-0 m-0">{this.props.data.dep}</h2>
                            <p className="m-0"><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.dtime}</b></p>
                            <p className="pt-1" style={{fontSize: '11px'}}>Terminal {this.props.data.dTerninal} </p>
                        </Grid>
                        <Grid item xs={4} sm md lg xl className="px-0 text-center">
                            <p className="pb-2" style={{fontSize: '11px'}}> 
                                {this.props.data.duration}
                                {/* <DayHoursMins data={this.props.data} />  */}
                            </p>
                            <div style={{borderTop:'2px dashed gray',width:'100px',margin:'auto'}} className="position-relative">
                                <FiberManualRecordIcon className="position-absolute gradient2-color" style={{top:'-12px',left:'20px'}}></FiberManualRecordIcon>
                                <img src={Path2481} width="25px" className="float-left position-absolute" style={{right:0,top:'-13px'}} />
                            </div>
                            {/* <DirectFlight data={{segments:this.props.data.Segments,stop:this.props.data.stops}} /> */}
                        </Grid>
                        <Grid item xs={4} sm md lg xl className="py-1 text-center">
                            <h2 className="h5 p-0 m-0" >{this.props.data.arr}</h2>
                            <p className="m-0"><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.artime}</b></p>
                            <p className="pt-1" style={{fontSize: '11px'}}>Terminal {this.props.data.aTerninal} </p>
                        </Grid>
                        {/* <Grid item xs={4} sm md lg xl className="px-4 py-2 text-center">
                            <img src={Group1206} width="30px" />
                            <p className="text-danger pt-2" style={{fontSize:'9px'}}><b>{this.props.data.Fares.Seats_Available} LEFT</b></p>
                        </Grid> */}
                        
                        {/* <Grid item xs={4} sm md lg xl className="px-3 py-3">
                            <h1 className="h3" style={{fontSize:'20px'}}><img src={rupeeIcon} className="rupee-icon" width="23" /> {this.props.data.Fares.FareDetails.Total_Amount}</h1>
                        </Grid> */}
                    </Grid>
                    
                </CardContent>
                <CardContent className="xs-device p-0" data-fid={this.props.data.Flight_Id} onClick={this.setFlightData}>
                    
                    <Grid container className="mt-0 py-1 pb-1">
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className="py-1 px-2">
                            <div className="text-center float-start">
                                <img src={images.default} width="30px" height="25px" className="mb-1" />
                                <p className="gray-color f-w-600 pt-1 ps-1">{this.props.data?.A_code}-{this.props.data.flNo}</p>
                                {/* <img src={images.default} width="35px" height="25px" className="" /> */}
                            </div>
                        </Grid>
                        {/* <Grid item xs={1} sm={2} md={2} lg={2} xl={2} className="py-1">
                            <div className="py-0 m-0">
                                <p className="gray-color f-w-600 pt-1">{this.props.data?.A_code}-{this.props.data.flNo}</p>
                                <p className="p-0 m-0 mt-1" style={{fontSize:'9px'}}>Class - 0OAJSH</p>
                            </div>
                        </Grid> */}
                        {/* <Grid item xs={4} sm md lg xl className="px-4 py-2 text-center">
                            <img src={Group1206} width="30px" />
                            <p className="text-danger pt-2" style={{fontSize:'9px'}}><b>{this.props.data.Fares.Seats_Available} LEFT</b></p>
                        </Grid> */}
                        
                        <Grid item xs={11} sm md lg xl className="py-1 px-3 position-relative text-center">
                            <div className="float-start py-0 pt-2 text-start">
                                <h2 className="h3 p-0 m-0">{this.props.data.dep}</h2>
                                <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.dtime}</b></p>
                            </div>
                            <div className="text-center float-start" style={{width: '50px'}}>
                                <ArrowForward />
                                <p className="py-0 m-0">
                                    {this.props.data.duration}
                                    {/* <DayHoursMins data={this.props.data} /> */}
                                </p>
                            </div>
                            <div className="float-end py-0 pt-2 text-end">
                                <h2 className="h3 p-0 m-0">{this.props.data.arr}</h2>
                                <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.artime}</b></p>
                                {/* <p className="text-danger p-0 m-0" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p> */}
                                {/* <DirectFlightForMObile data={{segments:this.props.data.Segments,stop:this.props.data.stops}} /> */}
                            </div>
                        </Grid>
                        
                        {/* <Grid item xs={3} sm md lg xl className="px-1 py-2 text-end pe-2">
                            <h1 className="h3" style={{fontSize:'20px'}}><img src={rupeeIcon} className="rupee-icon" width="15" /> {this.props.data.Fares.FareDetails.Total_Amount}</h1>
                        </Grid> */}
                    </Grid>
                    
                </CardContent>
            </Card>
        </div>
        );
    }
}

export default FlightDetailsForTickets;