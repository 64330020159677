// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';
import Path2481 from '../../assets/Icons/Path-2481.png';
import Group1206 from '../../assets/Icons/Group_1206.png';
import Path2482 from '../../assets/Icons/Path2482.png';
import Indigo from '../../assets/Icons/indigo.png';
import uk from "../../assets/AL1/UK.gif";
import rupeeIcon from '../../assets/Icons/icons8-rupee-24.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { NavLink } from 'react-router-dom';
import FlightDetails from "./FlightDetails";
import JWT from "../library/jwt";
import _, { map } from 'underscore';
import { ArrowForward } from "@material-ui/icons";
import CustomFunction from "../library/CustomFunction";



const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const float2int = (value) => {
    return value | 0;
}

const getNoOfDays = (d1,d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    
    // To calculate the time difference of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();
    
    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}

const DirectFlight = (prop) => {
    try{
        //console.log(prop);
        if(prop.data.stop == 0){
            return <p className="pt-3" style={{fontSize: '11px'}}>Non Stop </p>
        }
        else {
            //let via = '';
            let via = prop.data.segments[0].Destination_City.split('(')[0];
            for (let index = 1; index < prop.data.segments.length-1; index++) {
                via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
                
            }
            return <p className="pt-3" style={{fontSize: '11px'}}>{prop.data.stop} Stop, via {via} </p>
        }
    } catch (error) {
        throw new Error(error);
    }
}

const DirectFlightForMObile = (prop) => {
    try{
        //console.log(prop);
        if(prop.data.stop == 0){
            return <p className="pt-0" style={{fontSize: '11px'}}>Non Stop </p>
        }
        else {
            //let via = '';
            let via = prop.data.segments[0].Destination_City.split('(')[0];
            for (let index = 1; index < prop.data.segments.length-1; index++) {
                via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
                
            }
            // , via {via}
            return <p className="pt-0" style={{fontSize: '11px'}}>{prop.data.stop} Stop </p>
        }
    } catch (error) {
        throw new Error(error);
    }
}

const DayHoursMins = (prop) =>{
    //console.log(prop);
    if(prop.data.DurD == 0){
        return(<>{prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
    }
    else{
        return(<>{prop.data.DurD}Day {prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
    }
}

class SearchList extends Component {

    constructor(props){
        super(props);
        // this.fetchToken();
        //this.state = {isToggleOn: true};
        
    }

    fetchToken = async () => {
        try{
            const token = await new JWT().generateToken({searchKey: this.props.searchKey, flightsData: [this.props.data]}, {searchData: this.props.searchData}, 'session');
            //const { email } = await response.json();
            this.setState({
            token:token
            });
            // console.log("=== this token ===", token);
        } catch (error) {
            throw new Error(error);
        }
    };
    
    
    
    state = {
        hide: true
    }

    toggleVisibility = () => this.setState({ hide: !this.state.hide })

    
    className = "search-least xs-mb-1 "+this.props.classes;


    componentDidMount = () =>{
        console.log("=== componentDidMount ===");
        this.fetchToken();
    }

    componentWillUnmount = () =>{
        console.log("=== componentWillUnmount ===");
        this.setState({
            token: null
        });
    }

    // componentDidUpdate = () =>{
    //     console.log("=== componentDidUpdate ===");
    //     this.fetchToken();
    // }

    setFlightData = (event) =>{
        try{
            const fid = event.currentTarget.dataset.fid;

            //const fid = this.state.token.substring(1, 10);

            const f = async() =>{
                let searchId = _.uniqueId(fid);
                console.log("== searchId ==", searchId);
                const token = await new JWT().generateToken({key:'searchId'},{searchId: searchId});
                const urlPath =`/review-flight?SData=${token}`;
                //console.log("=== token ===", token);
                sessionStorage.setItem(searchId, this.state.token);
                window.open(urlPath, "_blank");
            };
            f();
        } catch (error) {
            throw new Error(error);
        }
        
    }
    

    render() {
        try{
            //console.log("=== token ===", this.state.token);
            console.log("tokan ====");
            if(this.state.token){
                let dep = this.props.data.Segments[0].Departure_DateTime.split(' ');
                let ariv = this.props.data.Segments[this.props.data.Segments.length - 1].Arrival_DateTime.split(' ');

                let next = getNoOfDays(dep[0], ariv[0]);
                let nextText = '';
                if(next == 1){
                    nextText = "NEXT DAY";
                }
                if(next > 1){
                    //console.log(ariv[0]);
                    let dd = new Date(ariv[0]);
                    let dd1 = dd.getDay();
                    //console.log(dd);
                    //console.log(dd1);
                    nextText = "On "+dayName[dd1];
                }

                
                const images = new CustomFunction().getImage(`${this.props.data.Airline_Code}.gif`);
                //images.default
                //console.log(images.default);
                let urlPath =`/review-flight?SData=${this.state.token}`
                return(
                <div className={this.className}>
                    <Card className="search-card my-card">
                        <CardContent className="sm-device md-device lg-device xl-device">
                            
                            <Grid container className="mt-2 py-1 pb-2">
                                <Grid item xs={4} sm={2} md={2} lg={2} xl={2} className="py-1 px-3">
                                    <img src={images.default} width="50px" height="40px" className="float-left" />
                                    <div className="float-left ps-2 py-0 m-0">
                                        <p className="p-0 m-0"><b>{this.props.data.AlName.toUpperCase()}</b></p>
                                        <p className="gray-color f-w-600 pt-2">{this.props.data.Segments[0].Airline_Code}-{this.props.data.Segments[0].Flight_Number}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="py-1 position-relative text-center">
                                    <h2 className="h3 p-0 m-0">{dep[1]}</h2>
                                    <p><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.OrgName.split('(')[0]}</b></p>
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="px-0 text-center">
                                    <p className="pb-2" style={{fontSize: '11px'}}> <DayHoursMins data={this.props.data} /> </p>
                                    <div style={{borderTop:'2px dashed gray',width:'100px',margin:'auto'}} className="position-relative">
                                        <FiberManualRecordIcon className="position-absolute gradient2-color" style={{top:'-12px',left:'20px'}}></FiberManualRecordIcon>
                                        <img src={Path2481} width="25px" className="float-left position-absolute" style={{right:0,top:'-13px'}} />
                                    </div>
                                    <DirectFlight data={{segments:this.props.data.Segments,stop:this.props.data.stops}} />
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="py-0 text-center">
                                    <h2 className="h3 p-0 m-0" >{ariv[1]}</h2>
                                    <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.DestName.split('(')[0]}</b></p>
                                    <p className="text-danger p-0 m-0" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p>
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="px-4 py-2 text-center">
                                    <img src={Group1206} width="30px" />
                                    <p className="text-danger pt-2" style={{fontSize:'9px'}}><b>{this.props.data.Fares.Seats_Available} LEFT</b></p>
                                </Grid>
                                
                                <Grid item xs={4} sm md lg xl className="px-3 py-3">
                                    <h1 className="h3" style={{fontSize:'20px'}}><img src={rupeeIcon} className="rupee-icon" width="23" /> {this.props.data.Fares.FareDetails.Total_Amount}</h1>
                                </Grid>
                                <Grid item xs={12} sm md lg xl className="px-3 text-center">
                                    {/* <NavLink to={urlPath} target="_blank"> */}
                                        <Button className="bg-gradient p-2" style={{width:'135px',color:'white',border:'0px solid black'}} data-fid={this.props.data.Flight_Id} onClick={this.setFlightData}>
                                            Book Now
                                        </Button>
                                    {/* </NavLink> */}
                                    <p className="mt-2" style={{cursor:'pointer',fontSize:'15px'}} onClick={this.toggleVisibility}>+ Flight Details</p>
                                </Grid>
                            </Grid>
                            
                        </CardContent>
                        <CardContent className="xs-device p-0" data-fid={this.props.data.Flight_Id} onClick={this.setFlightData}>
                            
                            <Grid container className="mt-0 py-1 pb-1">
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className="py-1 px-2">
                                    <div className="text-center float-start">
                                        <img src={images.default} width="35px" height="25px" className="" />
                                    </div>
                                </Grid>
                                <Grid item xs sm={2} md={2} lg={2} xl={2} className="py-1">
                                    <div className="py-0 m-0">
                                        <p className="p-0 m-0 mt-1" style={{fontSize:'9px'}}><b>{this.props.data.AlName.toUpperCase()}</b></p>
                                        <p className="gray-color f-w-600 pt-1">{this.props.data.Segments[0].Airline_Code}-{this.props.data.Segments[0].Flight_Number}</p>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={4} sm md lg xl className="px-4 py-2 text-center">
                                    <img src={Group1206} width="30px" />
                                    <p className="text-danger pt-2" style={{fontSize:'9px'}}><b>{this.props.data.Fares.Seats_Available} LEFT</b></p>
                                </Grid> */}
                                
                                <Grid item xs={5} sm md lg xl className="py-1 position-relative text-center">
                                    <div className="float-start py-0 text-start">
                                        <h2 className="h3 p-0 m-0">{dep[1]}</h2>
                                        <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.OrgName.split('(')[0]}</b></p>
                                        <p className="py-0 m-0"><DayHoursMins data={this.props.data} /></p>
                                    </div>
                                    <ArrowForward />
                                    <div className="float-end py-0 text-end">
                                        <h2 className="h3 p-0 m-0" >{ariv[1]}</h2>
                                        <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.DestName.split('(')[0]}</b></p>
                                        {/* <p className="text-danger p-0 m-0" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p> */}
                                        <DirectFlightForMObile data={{segments:this.props.data.Segments,stop:this.props.data.stops}} />
                                    </div>
                                </Grid>
                                
                                <Grid item xs={3} sm md lg xl className="px-1 py-2 text-end pe-2">
                                    <h1 className="h3" style={{fontSize:'20px'}}><img src={rupeeIcon} className="rupee-icon" width="15" /> {this.props.data.Fares.FareDetails.Total_Amount}</h1>
                                </Grid>
                            </Grid>
                            
                        </CardContent>
                        <CardActions className="p-0 position-relative sm-device-block md-device-block lg-device-block xl-device-block" style={{marginTop: -23}}>
                            <FlightDetails data={this.props.data} hidden={this.state.hide} travelClass={this.props.searchData.travellersData.seatClassesData.name} />
                        </CardActions>
                        {this.props.data?.Fares?.Warning && this.props.data?.Fares?.Warning != "" ? (<><div className="p-2 m-0 text-left" style={{borderTop:'2px dashed #adacac69', color: '#2196f3', fontSize: 11}} dangerouslySetInnerHTML={{__html:this.props.data?.Fares?.Warning}} /></>) : (<></>) }
                    </Card>
                </div>
                );
            }
            else{
                return <></>;
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default SearchList;