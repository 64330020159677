// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import Indigo from '../../assets/Icons/indigo.png';

// import library
import React, {Component} from "react";
import Table from 'react-bootstrap/Table';
import { Row, Form, Button, Modal, Spinner } from 'react-bootstrap';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FlightMetaDataForConfirmation from "./FlightMetaDataForConfirmation";

class ConfirmationPopup extends Component {
    
    componentDidMount(){
       console.log("=== datas for popup ===",this.props.data);
    };

    render(){
        try{
            let flightsData = [];
            let total = 0;
            this.props.data.flightsData.map((element, index) =>{
                
                let subTotal = 0;
                let tr = [];
                let j = 1;
                if(this.props.data.airRepricedData[index]?.Flight?.Fares[0]?.FareDetails[0]?.Total_Amount){
                    subTotal = subTotal + this.props.data.airRepricedData[index]?.Flight?.Fares[0]?.FareDetails[0]?.Total_Amount;
                    console.log("=== subtotal 1 ===", subTotal);
                }
                else{
                    subTotal = subTotal + element.Fares.FareDetails.Total_Amount ? element.Fares.FareDetails.Total_Amount : 0;
                    console.log("=== subtotal 2 ===", subTotal);
                }
                
                if(index === 0){
                    tr.push(<tr><th colSpan={3}><b>{this.props.data.searchData.from.ooro_AirportCode} <ArrowForwardIcon></ArrowForwardIcon> {this.props.data.searchData.to.ooro_AirportCode}</b></th></tr>); 
                }
                else{
                    tr.push(<tr><th colSpan={3}><b>{this.props.data.searchData.to.ooro_AirportCode} <ArrowForwardIcon></ArrowForwardIcon> {this.props.data.searchData.from.ooro_AirportCode}</b></th></tr>); 
                }
                
                tr.push(<tr><td colSpan={3} className="p-0"><FlightMetaDataForConfirmation classes="box-shadow" data={element} searchData={this.props.data.searchData} searchKey={this.props.data.searchKey} /></td></tr>);
                tr.push(<tr style={{fontSize: '15px'}}><th colSpan={3}>Passenger Details</th></tr>);
                
                this.props.data.travelersDetails.map((e, i)=>{
                    tr.push(
                        <tr style={{fontSize: '12px'}}>
                            <th colSpan={2}>{e.label}</th>
                            <th>SSR Details</th>
                        </tr>
                    );

                    e.data.map((ee, ii) =>{
                        let ssrDetails = [];

                        let meals = ee.meals.filter(r => r.flightId === element.Flight_Id)[0]?.meals;
                        let baggage = ee.baggage.filter(r => r.flightId === element.Flight_Id)[0]?.baggage;
                        let seats = this.props.data.seatMapData.filter(r => r.flightId === element.Flight_Id)[0]?.seats[0];

                        console.log("meals===", meals);
                        console.log("baggage===", baggage);
                        if(meals?.SSR_TypeDesc){
                            subTotal = subTotal + Number(baggage.Total_Amount?meals.Total_Amount: 0);
                            ssrDetails.push(meals.SSR_TypeDesc);
                            console.log("=== subtotal 3 ===", subTotal);
                        }
                        if(baggage?.SSR_TypeDesc){
                            subTotal = subTotal + Number(baggage.Total_Amount?baggage.Total_Amount: 0);
                            ssrDetails.push(baggage.SSR_TypeDesc);
                            console.log("=== subtotal 4 ===", subTotal);
                        }

                        if(seats?.SSR_TypeDesc){
                            subTotal = subTotal + Number(seats.Total_Amount?seats.Total_Amount: 0);
                            ssrDetails.push(seats.SSR_TypeDesc);
                            console.log("=== subtotal 5 ===", subTotal);
                        }

                        tr.push(
                            <tr>
                                <td>{j++}</td>
                                <td>{ee?.fName} {ee?.lName} &nbsp; <b><i>({ee?.gender.toUpperCase()}) &nbsp; { ee?.dob?.d > 0 ? (<>({ee?.dob.d}-{ee?.dob.m}-{ee?.dob.y})</>) : (<></>)}</i></b></td>
                                <td>{ssrDetails.length > 0 ? ssrDetails.join(', ') : (<p style={{color: 'blue'}}>Not Selected</p>)}</td>
                            </tr>
                        ); 
                    });
                    
                });

                // tr.push(<tr><th colSpan={3} className="text-end">Sub Total : ₹{subTotal}</th></tr>);

                flightsData.push(
                    <>
                    <Table bordered className="mb-2">
                        <tbody>
                            {tr}
                        </tbody>
                    </Table>
                    {/* <div className="w-100 mb-3">
                        <h5 className="text-end">Sub Total : ₹{subTotal}</h5>
                    </div> */}
                    </>
                );

                total = total + subTotal;

            });


            total = total + Number(this.props.data.ccf);

            if(this.props.data?.appliedCoupon){
                total = total - Number(this.props.data.appliedCoupon.amount);
            }

            return(
                <div className="fare-rules p-3">
                    {flightsData}
                    <div className="w-100">
                        <Button variant="contained" type="button" className="w-100 color-w bg-gradient border-0 pd-10" onClick={() =>this.props.onConfirmClick(true)}>
                            <b>Confirm & Continue To Payment <i>(₹{total})</i></b>
                        </Button>
                    </div>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default ConfirmationPopup;