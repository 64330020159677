import React from 'react';

class SofiaLogo extends React.Component {

  render() {
    return (
      <svg  className={this.props.className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M15.3146 6.24536C15.3146 5.55731 14.7463 5 14.0463 5H14.0446L11.3296 5.00163C7.67797 5.0049 4.7063 7.92219 4.7063 11.5046V14.6033C4.7063 15.2898 5.27463 15.8487 5.97463 15.8487C6.67463 15.8487 7.24463 15.2898 7.24463 14.6033V11.5046C7.24463 9.2934 9.07796 7.49399 11.3313 7.49073L14.0463 7.48909C14.748 7.48909 15.3146 6.93178 15.3146 6.24536ZM28.6561 5.00049H26.0011C25.3011 5.00049 24.7328 5.5578 24.7328 6.24585C24.7328 6.93227 25.3011 7.48795 26.0011 7.48795H28.6561C30.9178 7.48795 32.7578 9.29225 32.7578 11.51V14.6038C32.7578 15.2903 33.3261 15.8492 34.0261 15.8492C34.7278 15.8492 35.2945 15.2903 35.2945 14.6038V11.51C35.2945 7.91941 32.3178 5.00049 28.6561 5.00049ZM15.3976 11.2378H24.6043C25.6126 11.2378 26.581 11.6349 27.286 12.3426C27.996 13.0568 28.3893 14.0161 28.3876 15.0147V17.0903C28.3793 17.3338 28.181 17.5316 27.9326 17.5381H12.066C11.8193 17.5299 11.621 17.3322 11.616 17.0903V15.0147C11.5976 12.9473 13.291 11.259 15.3976 11.2378Z" fill="#4D53E0"/>
        <path d="M37.0651 20.0687H2.93675C2.23675 20.0687 1.66675 20.626 1.66675 21.3141C1.66675 22.0005 2.23675 22.5562 2.93675 22.5562H4.70508V28.4954C4.70508 32.0778 7.67841 34.9951 11.3301 34.9984L14.0451 35C14.7467 35 15.3134 34.4427 15.3151 33.7546C15.3151 33.0682 14.7467 32.5109 14.0467 32.5109L11.3334 32.5093C9.07841 32.506 7.24341 30.7066 7.24341 28.4954V22.5562H11.6151V24.2085C11.5984 26.2759 13.2901 27.9658 15.3967 27.9854H24.6034C26.7117 27.9658 28.4034 26.2759 28.3867 24.2085V22.5562H32.7584V28.4888C32.7584 30.7082 30.9184 32.5109 28.6567 32.5109H26.0017C25.3001 32.5109 24.7317 33.0682 24.7317 33.7546C24.7317 34.4427 25.3001 35 26.0017 35H28.6567C32.3167 35 35.2951 32.0811 35.2951 28.4888V22.5562H37.0651C37.7651 22.5562 38.3334 22.0005 38.3334 21.3141C38.3334 20.626 37.7651 20.0687 37.0651 20.0687Z" fill="#4D53E0"/>
      </svg>
    );
  }
}

export default SofiaLogo;
