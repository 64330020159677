// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import Indigo from '../../assets/Icons/indigo.png';

// import library
import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import FlightMetaData from "./FlightMetaData";
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

class FareRulesList extends Component {
    

    componentDidMount(){
        
    };

    render(){
        try{
            return(
                <div className="fare-rules">
                    <Table bordered className="box-shadow">
                        <thead>
                            <tr>
                                <th><b>BOM <ArrowForwardIcon></ArrowForwardIcon> AMD</b></th>
                                <th><b>Adult</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{backgroundColor: '#F1F1F1'}}>
                                <td colSpan="2" className="fee">Cancellation Fee</td>
                            </tr>
                            <tr>
                                <td>0 HRS - 4 HRS To Departure</td>
                                <td className="sm-p">Non Refundable</td>
                            </tr>
                            <tr>
                                <td>4 HRS - 3 HRS To Departure</td>
                                <td>₹3500</td>
                            </tr>
                            <tr>
                                <td>4 Days - 999 Days To Departure</td>
                                <td>₹30000</td>
                            </tr>
                            <tr style={{backgroundColor: '#F1F1F1'}}>
                                <td colSpan="2" className="fee">Change Fee</td>
                            </tr>
                            <tr>
                                <td>0 HRS - 4 HRS To Departure</td>
                                <td className="sm-p">Non Refundable</td>
                            </tr>
                            <tr>
                                <td>4 HRS - 3 HRS To Departure</td>
                                <td>₹3500</td>
                            </tr>
                            <tr>
                                <td>4 Days - 999 Days To Departure</td>
                                <td>₹30000</td>
                            </tr>
                            <tr style={{backgroundColor: '#F1F1F1'}}>
                                <td colSpan="2" className="fee">ATO Service Fee</td>
                            </tr>
                            <tr>
                                <td>Re Schedule</td>
                                <td className="sm-p">Non Refundable</td>
                            </tr>
                            <tr>
                                <td>Cancellation</td>
                                <td>₹3500</td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ul>
                                        <li style={{fontSize:'8px !important'}}>
                                            What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type.
                                        </li>
                                        <li style={{fontSize:'8px !important'}}>
                                            What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing.
                                        </li>
                                        <li style={{fontSize:'8px !important'}}>
                                            What is Lorem Ipsum Lorem Ipsum is simply dummy text.
                                        </li>
                                        <li style={{fontSize:'8px !important'}}>
                                            What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting
                                        </li>
                                        <li style={{fontSize:'8px !important'}}>
                                            What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default FareRulesList;