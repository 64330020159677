import React, { Component } from 'react';


class Shimmer extends Component {
    

  render(){
    return(
            <div className="shimmer-container pt-5 mt-5 xs-mt-0 xs-pt-0">
                <div className="shimmer" id="TxnShimmer">
                    <div className="wrapper">
                    <div className="image-card animate"></div>
                    <div className="stroke animate title"></div>
                    <div className="stroke2 animate link"></div>
                    <div className="stroke animate description"></div>
                    </div>
                </div>
                <div className="shimmer" id="TxnShimmer">
                    <div className="wrapper">
                    <div className="image-card animate"></div>
                    <div className="stroke animate title"></div>
                    <div className="stroke2 animate link"></div>
                    <div className="stroke animate description"></div>
                    </div>
                </div>
                <div className="shimmer" id="TxnShimmer">
                    <div className="wrapper">
                    <div className="image-card animate"></div>
                    <div className="stroke animate title"></div>
                    <div className="stroke2 animate link"></div>
                    <div className="stroke animate description"></div>
                    </div>
                </div>
                <div className="shimmer" id="TxnShimmer">
                    <div className="wrapper">
                    <div className="image-card animate"></div>
                    <div className="stroke animate title"></div>
                    <div className="stroke2 animate link"></div>
                    <div className="stroke animate description"></div>
                    </div>
                </div>
                <div className="shimmer" id="TxnShimmer">
                    <div className="wrapper">
                    <div className="image-card animate"></div>
                    <div className="stroke animate title"></div>
                    <div className="stroke2 animate link"></div>
                    <div className="stroke animate description"></div>
                    </div>
                </div>
                <div className="shimmer" id="TxnShimmer">
                    <div className="wrapper">
                    <div className="image-card animate"></div>
                    <div className="stroke animate title"></div>
                    <div className="stroke2 animate link"></div>
                    <div className="stroke animate description"></div>
                    </div>
                </div>
                {/* <div className="shimmer" id="TxnShimmer">
                    <div className="wrapper">
                    <div className="image-card animate"></div>
                    <div className="stroke animate title"></div>
                    <div className="stroke2 animate link"></div>
                    <div className="stroke animate description"></div>
                    </div>
                </div>   */}
            </div>
    )
  }
}

export default Shimmer