import DisplayMessages from "../displayMessages/displayMessages";

const baseUrl = "https://ooro.in/live_bke";
// const baseUrl = "https://ooro.in/live_bke";
const baseUrl2 = 'https://ooro.in/live_bke';
const baseUrl3 = "https://ooro.in/blog";
export const paymentUrl = "https://ooro.in/live_bke/pg/ICICIupi/index.php";
export const exceptionLogsUrl = "https://ooro.in/live_bke/flights/error_catch.php";


const isJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

export default class Service {
    
    getAllAirports = (data) => {
        let url = `${baseUrl}/sys/airport.php`;
        return this.fetch(url, 'post', 'fd', 'getAllAirports', data);
    }

    getPopularDomesticFlights = () => {
        let url = `${baseUrl}/sys/pdfr.php`;
        return this.fetch(url, 'get', 'fd', 'getPopularDomesticFlights');
    }

    searchFlight = (data) => {
        let url = `${baseUrl}/flights/AirSeach_new.php`;
        return this.fetch(url, 'post', 'json', 'searchFlight', data);
    }

    getAllAirlines = (data) => {
        let url = `${baseUrl}/sys/airlines.php`;
        return this.fetch(url, 'post', 'fd', 'getAllAirlines', data);
    }

    getAirSeatMaps = (data) =>{
        let url = `${baseUrl}/flights/SeatMap.php`;
        return this.fetch(url, 'post', 'json', 'getAirSeatMaps', data);
    }

    getAirSSR = (data) =>{
        let url = `${baseUrl}/flights/Air_SSR.php`;
        return this.fetch(url, 'post', 'fd', 'getAirSSR', data);
    }

    login = (usersName, password) =>{
        let url = `${baseUrl}/users/login.php`;
        return this.fetch(url, 'post', 'fd', 'login', [{name: "username", value: `${usersName}`}, {name: "password", value: `${password}`}]);
    }

    forget = (usersName) =>{
        let url = `${baseUrl}/users/resetPassword.php`;
        return this.fetch(url, 'post', 'fd', 'forget', [{name: "input", value: `${usersName}`}]);
    }

    signup = (name, email, mobile, password) =>{
        let data = [
            {name: "name", value: `${name}`},
            {name: "email", value: `${email}`},
            {name: "mobile", value: `${mobile}`},
            {name: "password", value: `${password}`},
        ];
        let url = `${baseUrl}/users/registration.php`;
        return this.fetch(url, 'post', 'fd', 'signup', data);
    }

    getPageDerails = () =>{
        let url = `${baseUrl}/sys/ooro_ext.php`;
        return this.fetch(url, 'get', 'fd', 'getPageDerails');
    }

    getTestimonials = () =>{
        let url = `${baseUrl3}/ourApi/travel_Blog.php`;
        return this.fetch(url, 'get', 'fd', 'getTestimonials');
    }

    airRepricing = (data) =>{
        let url = `${baseUrl}/flights/AirReprising.php`;
        return this.fetch(url, 'post', 'json', 'airRepricing', data);
    }

    fareRules = (searchKey, fareId, flightKey) =>{
        let data = [
            {name: "SearchKey", value: `${searchKey}`},
            {name: "FareId", value: `${fareId}`},
            {name: "FlightKey", value: `${flightKey}`}
        ];
        let url = `${baseUrl}/flights/Fare_Rules.php`;
        return this.fetch(url, 'post', 'fd', 'fareRules', data);
    }

    profile = (token) =>{
        let data = [
            {name: "token", value: `${token}`}
        ];
        let url = `${baseUrl}/users/profile.php`;
        return this.fetch(url, 'post', 'fd', 'fareRules', data);
    }

    tempBooking = (data) => {
        let url = `${baseUrl}/flights/Temp_booking_new.php`;
        return this.fetch(url, 'post', 'json', 'tempBooking', data);
    }

    urlShortner = (temp_url) =>{
        console.log("== urlShortner ==", temp_url);

        let url = `https://yfl.ae/short?url=${temp_url}`;
        try {

            let f = fetch(url);
            
            let error = null;
            f?.catch(err =>{
                error = {message: `${err?.message} at ${url}`, status: 500, type: 'INTERNAL_SERVER_ERROR'}
                new DisplayMessages().errorMsg({error:error});
            });

            f?.then(response => {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    if (!response.ok) {
                        response.json().then(err =>{
                            error = {message: `API request failed with status ${response?.status} and Error is "${err?.message}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                            new DisplayMessages().errorMsg({error:error});
                        }).catch(err =>{
                            error = {message: `API request failed with status ${response?.status} and Error is "${err}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                            new DisplayMessages().errorMsg({error:error});
                        });
                    }
                    else {
                        response.json().then(err =>{
                            if(!isJSON(err)){
                                error = {message: `API request failed with status ${response?.status} and Error is "${err}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                                new DisplayMessages().errorMsg({error:error});
                            }
                        }).catch(err =>{
                            error = {message: `API request failed with status ${response?.status} and Error is "${err}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                            new DisplayMessages().errorMsg({error:error});
                        });
                    }
                    
                } else {
                    response.text().then(data =>{
                        error = {message: `API request failed with status ${response?.status} and Error is "${data}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                        new DisplayMessages().errorMsg({error:error});
                    }).catch(err =>{
                        error = {message: `API request failed with status ${response?.status} and Error is "${err}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                        new DisplayMessages().errorMsg({error:error});
                    });
                }
            });
            
            if(error){
                return;
            }
            else{
                return f;
            }
        } catch (error) {
            console.log("==== fff error ===", error);
            let err = {message: `failed to access service urlShortner() at ${url}`, status: 500, type: 'INTERNAL_SERVER_ERROR'}
            new DisplayMessages().errorMsg({error: err});
        }
    }

    fareCalender = (data) =>{
        let url = `${baseUrl}/flights/fare_calender.php`;
        return this.fetch(url, 'post', 'json', 'fareCalender', data);
    }

    getPaymentStatus = (userSession) => {
        let url = `${baseUrl}/sys/air_status_new.php?userSession=${userSession}&token=${new Date().getTime()}`;
        return this.fetch(url, 'get', 'fd', 'getPaymentStatus');
    }

    getBookingList = (data) =>{
        let url = `${baseUrl}/sys/bookingList.php`;
        return this.fetch(url, 'post', 'json', 'getBookingList', data);
    }

    getAccountLogs = (token) =>{
        let data = [
            {name: "token", value: `${token}`}
        ];
        let url = `${baseUrl}/users/ledger.php`;
        return this.fetch(url, 'post', 'fd', 'getAccountLogs', data);
    }

    chnagePassword = (token, cp, np) =>{
        let data = [
            {name: "token", value: `${token}`},
            {name: "cpassword", value: `${cp}`},
            {name: "npassword", value: `${np}`}
        ];
        let url = `${baseUrl}/users/change_password.php`;
        return this.fetch(url, 'post', 'fd', 'chnagePassword', data);
    }


    sendUrlContent = (data) =>{
        let url = `${baseUrl2}/sys/urlCapture.php`;
        return this.fetch(url, 'post', 'json', 'sendUrlContent', data);
    }


    fetch = (url, method, type, functionName, data = null) =>{
        try {
            console.log(`== ${functionName} ==`, data);
            let config = {method: 'get'};
            if(data){
                if(type == 'fd'){
                    var fd = new FormData();
                    data.forEach(element => {
                        fd.append(element.name,element.value)
                    });

                    config = {
                        method,
                        body:fd
                    }
                }
                else if(type == 'json'){
                    config = {
                        method:'post',
                        headers: { 'Content-Type': 'application/json' },
                        body:JSON.stringify(data)
                    }
                }
            }

            let f = fetch(url , config);
            
            let error = null;
            f?.catch(err =>{
                error = {message: `${err.message} at ${url}`, data, status: 500, type: 'INTERNAL_SERVER_ERROR'}
                new DisplayMessages().errorMsg({error: error});
            });

            f?.then(response => {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    if (!response.ok) {
                        response.json().then(err =>{
                            error = {message: `API request failed with status ${response?.status} and Error is "${err?.message}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                            new DisplayMessages().errorMsg({error:error});
                        }).catch(err =>{
                            error = {message: `API request failed with status ${response?.status} and Error is "${err}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                            new DisplayMessages().errorMsg({error:error});
                        });
                    }
                    
                } else {
                    response.text().then(data =>{
                        error = {message: `API request failed with status ${response?.status} and Error is "${data}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                        new DisplayMessages().errorMsg({error:error});
                    }).catch(err =>{
                        error = {message: `API request failed with status ${response?.status} and Error is "${err}" at ${url}`, status: response?.status, type: 'INTERNAL_SERVER_ERROR'}
                        new DisplayMessages().errorMsg({error:error});
                    });
                }
            });
            
            if(error){
                return;
            }
            else{
                return f;
            }
        } catch (error) {
            console.log("==== fff error ===", error);
            let err = {message: `failed to access service ${functionName}() at ${url}`, data, status: 500, type: 'INTERNAL_SERVER_ERROR'}
            new DisplayMessages().errorMsg({error: err});
        }
    }

}