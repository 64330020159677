import React from "react";
import SearchList from './SearchList.js';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';



export default class OneWayTrip extends React.Component {
    render(){
        try{
            console.log("=== OneWayTrip ===", this.props);
            if(this.props.data?.length == 0){
                return(
                    <Grid container>
                        <Grid item xs={12} className="py-5 text-center">
                            <p className="text-danger">No data found</p>
                        </Grid>
                    </Grid>
                );
            }
            else if(this.props.data?.length > 0){
                //this.props.setLoader(true);
                // let airlines = this.props.allAirlines.filter(r => r.)
                //this.props.setLoader(true);
                let item = [];
                this.props.data.forEach(element => {
                    item.push(<SearchList classes="box-shadow mb-3" data={element} searchData={this.props.searchData} searchKey={this.props.searchKey}  />)
                });
                //this.props.setLoader(false);
                //this.props.setLoader(false);
                return(
                    <>
                        <div className="sm-device md-device lg-device xl-device">
                            <Grid container className="mt-2 py-0 pt-3">
                                <Grid item xs={2} className="py-3 text-center">
                                    <h3 className="h6">AIRLINES</h3>
                                </Grid>
                                <Grid item xs className="py-3 text-center">
                                    <h3 className="h6">DEPARTURE</h3>
                                </Grid>
                                <Grid item xs className="py-3">
                                    <h3 className="h6">DURATION</h3>
                                </Grid>
                                <Grid item xs className="py-3">
                                    <h3 className="h6">ARRIVAL</h3>
                                </Grid>
                                <Grid item xs className="py-3 text-center">
                                    {/* <h3 className="h6">SEATS</h3> */}
                                </Grid>
                                
                                <Grid item xs className="px-3 py-3">
                                    <h3 className="h6">PRICE</h3>
                                </Grid>
                                <Grid item xs className="px-3">
                                    
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container>
                            <Grid item xs={12} className="mt-3">
                                {item}
                                {/* <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" /> */}
                            </Grid>
                        </Grid>
                    </>
                );

            }else{
                return(
                    <>
                    </>
                );
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}