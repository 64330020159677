import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import TaskContainer from "../../pages/tables/components/TaskContainer/TaskContainer";

// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import MUIDataTable from "mui-datatables";

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";

import s from "./AccountLog.module.scss";
import mock from "../tables/mock";
import Services from "../../../../shared/services/services.js";
import DisplayMessages from "../../../../shared/displayMessages/displayMessages.js";
import { Grid } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const AccountLog = function () {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [firstTable] = useState(mock.firstTable);
  const [secondTable, setSecondTable] = useState([]);
  const [transactions, setTransactions] = useState(mock.transactionsWidget);
  const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const pageSize = 10;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  }

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  }

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const transactionMenuOpen = (id) => {
    setTransactions(
      transactions.map( transaction => {
        if (transaction.id === id) {
          transaction.dropdownOpen = !transaction.dropdownOpen;
        }
        return transaction;
      })
    )
  }

  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  }

  const toggleTask = (id) => {
    setTasks(
      tasks.map( task => {
      if (task.id === id) {
        task.completed = !task.completed;
      }
      return task;
      })
    )
  }

  React.useEffect(() => {
    // document.title = "Ooro Flights";
    let token = localStorage.getItem('OoroToken');
    if(token){
      new Services().getAccountLogs(token).then(res => res.json()).then(
        (result) => {
            console.log(result);
            setSecondTable(result.message);
            setLoading(false);
            setError(false);
            
        },
        (error) => {
          console.log("== error ==", error);
          setLoading(true);
          setError(true);
          // new DisplayMessages().errorMsg({error:error});
        }
      );
    }
      else{
        window.location.reload();
    }
  }, []);

  return (
    <div>
        <Row>
            <Col>
                <Row className="mb-4">
                    <Col xs={12} xl={6} className="pr-grid-col">
                        <Widget>
                        <div className={s.tableTitle}>
                            <div className="headline-2">Account Logs</div>
                        </div>
                        <>{ !loading ? (
                            <>
                            {Array.isArray(secondTable) ? (<>
                                <div className={s.widgetContentBlock}>
                                    {secondTable.slice(
                                      secondTableCurrentPage * pageSize,
                                      (secondTableCurrentPage + 1) * pageSize
                                      ).map(item => (
                                        <div key={uuidv4()} className={s.content}>
                                            <p className="m-0 body-2">{item.ptype == 'DR' ? (<span className={item.ptype }> - ₹{item.cs_bal}</span>) : (<span className={item.ptype }> + ₹{item.cs_bal}</span>)} [<span className="ml-3">{item.reason}</span>]</p>
                                            {/* <img src={item.icon} alt="Item" /> */}
                                            
                                            <div className="body-3 muted d-md-block">{item.dt}</div>
                                            {/* <div className="body-3 muted d-none d-lg-block">{item.reason}</div> */}
                                        </div>
                                    ))}
                                </div>
                                {Array.isArray(secondTable) ? (<>
                                        <Pagination className="pagination-with-border">
                                        <PaginationItem disabled={secondTableCurrentPage <= 0}>
                                            <PaginationLink
                                            onClick={e => setSecondTablePage(e, secondTableCurrentPage - 1)}
                                            previous
                                            href="#top"
                                            />
                                        </PaginationItem>
                                        {[...Array(secondTablePagesCount)].map((page, i) =>
                                            <PaginationItem active={i === secondTableCurrentPage} key={i}>
                                            <PaginationLink onClick={e => setSecondTablePage(e, i)} href="#top">
                                                {i + 1}
                                            </PaginationLink>
                                            </PaginationItem>
                                        )}
                                        <PaginationItem disabled={secondTableCurrentPage >= secondTablePagesCount - 1}>
                                            <PaginationLink
                                            onClick={e => setSecondTablePage(e, secondTableCurrentPage + 1)}
                                            next
                                            href="#top"
                                            />
                                        </PaginationItem>
                                        </Pagination>
                                    </>) : (<></>)}
                                </>) : (<><p className="text-center py-3">{secondTable}</p></>)}
                            </>
                    ) : (<>
                        <Grid container>
                            <Grid item xs={12} className="py-5 text-center">
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </>)}</>
                        </Widget>
                    </Col>
                </Row>
    
            </Col>
        </Row>
    </div>
    
    )
}

export default AccountLog;
