// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import Indigo from '../../assets/Icons/indigo.png';

// import library
import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import FlightMetaData from "./FlightMetaData";
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

class FreeBuggage extends Component {
    

    componentDidMount(){
        
    };

    render(){
        try{
            return(
                <div className="free-buggage p-1 py-3">
                    <h3>Fare Detail</h3>
                    <Table bordered className="box-shadow">
                        <thead style={{backgroundColor: '#F5F6F8'}}>
                            <tr>
                                <th>Sector/Flight</th>
                                <th>Check In Baggage</th>
                                <th>Cabin Baggage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.props.data.OrgName} - {this.props.data.DestName}</td>
                                <td>{this.props.data.Fares.FareDetails.Free_Baggage.Check_In_Baggage}</td>
                                <td>{this.props.data.Fares.FareDetails.Free_Baggage.Hand_Baggage}</td>
                            </tr>
                            {/* <tr>
                                <td>BOM - DELL</td>
                                <td>15 Kg (Adult)</td>
                                <td>7 Kg (Adult)</td>
                            </tr> */}
                            {/* <tr>
                                <td colSpan="3" className="px-3">
                                    <ul className="py-2 px-3">
                                        <li>
                                            <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </li>
                                    </ul>
                                    <Alert variant="danger" className="p-2">
                                        <ReportProblemIcon className="r-color" style={{fontSize:'17px'}}></ReportProblemIcon> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </Alert>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default FreeBuggage;