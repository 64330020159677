import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Progress,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import ApexActivityChart from "./components/ActivityChart.js";

import meal1 from "../../assets/dashboard/meal-1.svg";
import meal2 from "../../assets/dashboard/meal-2.svg";
import meal3 from "../../assets/dashboard/meal-3.svg";
import upgradeImage from "../../assets/dashboard/upgradeImage.svg";
import heartRed from "../../assets/dashboard/heartRed.svg";
import heartTeal from "../../assets/dashboard/heartTeal.svg";
import heartViolet from "../../assets/dashboard/heartViolet.svg";
import heartYellow from "../../assets/dashboard/heartYellow.svg";
import gymIcon from "../../assets/dashboard/gymIcon.svg";
import therapyIcon from "../../assets/dashboard/therapyIcon.svg";
import user from "../../assets/user.svg";
import statsPie from "../../assets/dashboard/statsPie.svg";

import s from "./Dashboard.module.scss";
import BookingList from "../../components/BookingList/BookingList.js";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.toggleOne = this.toggleOne.bind(this);
    this.toggleTwo = this.toggleTwo.bind(this);
    this.toggleThree = this.toggleThree.bind(this);

    this.state = {
      dropdownOpenOne: false,
      dropdownOpenTwo: false,
      dropdownOpenThree: false,
      checkboxes: [false, true],
    };
  }

  meals = [meal1, meal2, meal3];

  toggleOne() {
    this.setState({
      dropdownOpenOne: !this.state.dropdownOpenOne,
    });
  }

  toggleTwo() {
    this.setState({
      dropdownOpenTwo: !this.state.dropdownOpenTwo,
    });
  }

  toggleThree() {
    this.setState({
      dropdownOpenThree: !this.state.dropdownOpenThree,
    });
  }

  changeCheck(event, checkbox, id) {
    this.state[checkbox][id] = event.target.checked;

    if (!event.target.checked) {
      this.state[checkbox][id] = false;
    }
    this.setState({
      [checkbox]: this.state[checkbox],
    });
  }

  render() {
    return (
      <div>
        <Row>
          {/* <Col className="mt-4 mt-lg-0 pl-grid-col" xs={12} lg={4}>
            <Widget className="widget-p-lg p-3">
              <div className="d-flex">
                <img className={s.image} src={user} alt="..." />
                <div className={s.userInfo}>
                  <p className="headline-3">Christina Karey</p>
                  <p className="body-3 muted">Brasil</p>
                </div>
              </div>
              <div className={s.userParams}>
                <div className="d-flex flex-column">
                  <p className="headline-3">63 kg</p>
                  <p className="body-3 muted">Weight</p>
                </div>
                <div className="d-flex flex-column">
                  <p className="headline-3">175 sm</p>
                  <p className="body-3 muted">Height</p>
                </div>
                <div className="d-flex flex-column">
                  <p className="headline-3">28 y.</p>
                  <p className="body-3 muted">Age</p>
                </div>
              </div>
            </Widget>
          </Col> */}
          <Col className="pr-grid-col" xs={12} lg={12}>
            <BookingList />
          </Col>
        </Row>
      </div>
    )
  }
}

export default Dashboard;
