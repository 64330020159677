import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

export default class BtnGroup extends React.Component {

    state = {
        value:this.props.value
    }

    setBtnValue = (event) =>{
        let value = event.currentTarget.dataset.value;
        console.log("=== value ===",value);
        this.setState({value:value});
        this.props.onHandel(value);
    }

    render() {
        try{
            if(this.props.value){
                if(Number(this.props?.value) == 0){
                    return(
                        <ButtonGroup style={{width:'100%'}} color="primary" aria-label="outlined primary button group">
                            <Button onClick={this.setBtnValue} className="color-w bg-gradient active" data-value="0"  style={{width:'33%'}}>0</Button>
                            <Button onClick={this.setBtnValue} data-value="1" style={{width:'33%'}}>1</Button>
                            <Button onClick={this.setBtnValue} data-value="2" style={{width:'33%'}}>1+</Button>
                        </ButtonGroup>
                    );
                }
                else if(Number(this.props?.value) == 1){
                    return(
                        <ButtonGroup style={{width:'100%'}} color="primary" aria-label="outlined primary button group">
                            <Button onClick={this.setBtnValue} data-value="0"  style={{width:'33%'}}>0</Button>
                            <Button onClick={this.setBtnValue} className="color-w bg-gradient active" data-value="1" style={{width:'33%'}}>1</Button>
                            <Button onClick={this.setBtnValue} data-value="2" style={{width:'33%'}}>1+</Button>
                        </ButtonGroup>
                    );
                }
                else{
                    return(
                        <ButtonGroup style={{width:'100%'}} color="primary" aria-label="outlined primary button group">
                            <Button onClick={this.setBtnValue} data-value="0"  style={{width:'33%'}}>0</Button>
                            <Button onClick={this.setBtnValue} data-value="1" style={{width:'33%'}}>1</Button>
                            <Button onClick={this.setBtnValue} className="color-w bg-gradient active" data-value="2" style={{width:'33%'}}>1+</Button>
                        </ButtonGroup>
                    );
                }
            } 
            else{
                return(
                    <ButtonGroup style={{width:'100%'}} color="primary" aria-label="outlined primary button group">
                        <Button onClick={this.setBtnValue} className="" data-value="0"  style={{width:'33%'}}>0</Button>
                        <Button onClick={this.setBtnValue} data-value="1" style={{width:'33%'}}>1</Button>
                        <Button onClick={this.setBtnValue} data-value="2" style={{width:'33%'}}>1+</Button>
                    </ButtonGroup>
                );
            }
        } catch (error) {
            throw new Error(error);
        }  
    }
}