import React from "react";

import "./SearchResult.scss";
import "./SearchResult.css";
import { Row, Form, Button, Modal } from 'react-bootstrap';
import Header from '../../../shared/component/Header.js';
import VarticalSearchCard from '../../../shared/component/VarticalSearchCard.js';
import SearchFilter from '../../../shared/component/SearchFilter.js';
import VarticalSearchDisplayCard from '../../../shared/component/VarticalSearchDisplayCard.js';
import LiBlock from '../../../shared/component/LiBlock.js';
import Forms from '../../../shared/component/Form.js';
import banner from '../../../assets/Icons/june1.jpg';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Footer from '../../../shared/component/Footer.js';
import { useHistory, useParams } from "react-router-dom";
import RoundSearchList from '../../../shared/component/RoundSearchList.js';
import RoundTrip from '../../../shared/component/RoundTrip.js';
import OneWayTrip from '../../../shared/component/OneWayTrip.js';
import SearchList from '../../../shared/component/SearchList.js';
import VerticalCalendar from '../../../shared/component/VerticalCalendar.js';
import OurFamily from '../../../shared/component/OurFamily.js';
import { NavLink, Redirect } from 'react-router-dom';
import Services from "../../../shared/services/services";
import displayMessages from "../../../shared/displayMessages/displayMessages";
import FooterSearchList from "../../../shared/component/FooterSearchList";
import FlightDetails from "../../../shared/component/FlightDetails";
import JWT from "../../../shared/library/jwt";
import * as QueryString from "query-string";
// import { allAirports } from '../../../assets/allAirports';
import { allClasses } from '../../../assets/allClasses';
import { allFlightsData } from '../../../assets/flights';
import Loading from "../../../shared/component/Loading";
import Shimmer from "../../../shared/ui/Shimmer";
import NotFound from "../../../shared/component/NotFound";
import SomeThingWentWrong from "../../../shared/component/SomeThingWentWrong";
//import Input from '../../../shared/component/Input.js';
import TreeViews from "../../../shared/ui/TreeViews";
import _, { map } from 'underscore';
import { Tab } from 'semantic-ui-react'
import VerticalSearchDisplayCardForMobile from "../../../shared/component/VerticalSearchDisplayCardForMobile";
import VerticalCalendarForMobile from "../../../shared/component/VerticalCalendarForMobile";
import multiply from "../../../assets/Icons/multiply.svg";
import SearchFilterForMobile from "../../../shared/component/SearchFilterForMobile";
import moment from "moment";
import CustomSpinnner from "../../../shared/component/CustomSpinner";
import CustomFunction from "../../../shared/library/CustomFunction";
import { AllAirportsContext } from '../../../core/provider/AllAirportsProvider';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];

const monthIndex = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];


function Headers(prop) {
    try{
        return(
            <div className="bg-white" style={{fontSize:'16px'}}>
                <span className="float-left">
                    <b>{prop.LValue}</b>
                </span>
                <span className="float-right" style={{fontSize:'16px'}}>
                    <b>{prop.RValue}</b>
                </span>
            </div>
        );
    } catch (error) {
        throw new Error(error);
    }
}

const OneWayRoundTrip = (prop) => {
    console.log("=== OneWayRoundTrip ===", prop);
    try{
        if(prop.loader){
            return(
                <Grid container>
                    <Grid item xs={12} className="py-5 text-center">
                        <CircularProgress />
                    </Grid>
                </Grid>
            );
        }
        else if(prop.searchData.trip == 0){
            return(
                <OneWayTrip data={prop.data} searchData={prop.searchData} searchKey={prop.searchKey} allAirlines={prop.allAirlines} onHandel={(data) =>{ prop.onHandel(data) }} />
            );
        }
        else if(prop.searchData.trip == 1){
            return(
                <Grid container>
                    <Grid item xs={6} className="py-1 px-1 xs-pt-0 text-center xs-mt-0 xs-border-end">
                        <RoundTrip type="0" onSpinner={(status)=> prop.onSpinner(status)} searchKey={prop.searchKey} data={prop.data} selectedFlight={prop.selectedFlight} searchData={prop.searchData} allAirlines={prop.allAirlines} onHandel={(data) =>{ prop.onHandel(data) }} />
                    </Grid>
                    <Grid item xs={6} className="py-1 px-1 xs-pt-0 text-center xs-mt-0">
                        <RoundTrip type="1" onSpinner={(status)=> prop.onSpinner(status)} searchKey={prop.searchKey} data={prop.data2} selectedFlight={prop.selectedFlight2} searchData={prop.searchData} allAirlines={prop.returnAllFlights} onHandel={(data) =>{ prop.onHandel(data) }} />
                    </Grid>
                </Grid>
            );
        }
    } catch (error) {
        throw new Error(error);
    }
}


const TopCallender = (prop) => {
    try{
        if(prop.visible){
            return(
                <div className={prop.className}>
                    <Grid container>
                        <Grid item xs={11} className="py-2">
                            <span className="float-left" style={{cursor:'pointer'}}> Fare Trends <ArrowDropDownIcon style={{fontSize:'25px',marginTop:'-2px'}}></ArrowDropDownIcon></span>
                            <span className="float-left ms-3" style={{cursor:'pointer'}}> Best Value <ArrowRightIcon style={{fontSize:'25px',marginTop:'-2px'}}></ArrowRightIcon></span>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <VerticalCalendar data={prop.data} classes="box-shadow" onHandel={(url)=>prop.onHandel(url)} />
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else{
            return (<></>);
        }
    } catch (error) {
        throw new Error(error);
    }
}

const TopCallenderForMobile = (prop) => {
    try{
        if(prop.visible){
            return(
                <div className={prop.className}>
                    <Grid container>
                        <Grid item xs={12}>
                            <VerticalCalendarForMobile data={prop.data} classes="box-shadow" onHandel={(url)=>prop.onHandel(url)} />
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else{
            return (<></>);
        }
    } catch (error) {
        throw new Error(error);
    }
}

const FooterFlightsDetails = (prop) => {
    const [token, setToken] = React.useState('');
    try{
        if(prop.data.length == 2){
            
            console.log("== FooterFlightsDetails == ",prop);
            const openModel = () =>{
                prop.toggle(true);
            }
            const fetchToken = async () => {
                const token = await new JWT().generateToken({searchKey: prop.searchKey, flightsData: [prop.data[0], prop.data[1]]},{searchData: prop.searchData}, 'session');
                //const { email } = await response.json();
                setToken(token);
            };
            fetchToken();

            const setFlightData = (event) =>{
                // const fid = event.currentTarget.dataset.fid;

                const fid = token.substring(1, 10);
        
                const f = async() =>{
                    let searchId = _.uniqueId(fid);
                    console.log("== searchId ==", searchId);
                    const newToken = await new JWT().generateToken({key:'searchId'},{searchId: searchId});
                    const urlPath =`/review-flight?SData=${newToken}`;
                    //console.log("=== token ===", token);
                    sessionStorage.setItem(searchId, token);
                    window.open(urlPath, "_blank");
                };
                f();
                
            }

            let url = "/review-flight?SData="+token;
            return (
                <div className={prop.className}>
                    <Card className="footer-card position-fixed" style={{bottom:0, left:0, width:'100vw', zIndex:10, backgroundColor: '#062127', color:'white'}}>
                        <Grid container>
                            <Grid item xs>
                                <FooterSearchList classes="box-shadow" data={prop.data[0]} searchData={prop.searchData}  />
                            </Grid>
                            <Grid item xs className="border-left">
                                <FooterSearchList classes="box-shadow" data={prop.data[1]} searchData={prop.searchData}  />
                            </Grid>
                            <Grid item xs={3} sm={2} className="px-3 text-center md-text-center sm-pt-1 md-pt-1 border-left">
                                <p className="gray-color m-0 footer-price-some lg-device xl-device" style={{fontSize:'30px', fontWeight:'bolder'}}>₹ {prop.data[0].Fares.FareDetails.Total_Amount + prop.data[1].Fares.FareDetails.Total_Amount}</p>
                                <p className="gray-color m-0 p-0 footer-price-some sm-device md-device" style={{fontSize:'30px', fontWeight:'bolder'}}>₹ {prop.data[0].Fares.FareDetails.Total_Amount + prop.data[1].Fares.FareDetails.Total_Amount}</p>
                                <br />
                                {/* <NavLink to={url} target="_blank" className="float-end ms-3"> */}
                                    <Button className="bg-gradient px-2 mt-2 footer-book-now-btn md-mb-2" style={{width:'135px',color:'white',border:'0px solid black'}} data-fid={prop.data[0].Flight_Id} onClick={setFlightData}>
                                        Book Now
                                    </Button>
                                {/* </NavLink> */}
                                
                                <Grid container>
                                    <Grid item xs={12} className="px-3 text-right md-text-center sm-px-1 md-px-1">
                                        <p className="my-1 footer-flight-details text-center" style={{cursor:'pointer',fontSize:'15px'}} onClick={openModel}>+ Flight Details</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            );
        }
        else{
            return <></>;
        }
    } catch (error) {
        throw new Error(error);
    }
}

const FooterFlightsDetailsForMobile = (prop) => {
    const [token, setToken] = React.useState('');
    try{
        if(prop.data.length == 2){
            
            console.log("== FooterFlightsDetails == ",prop);
            const openModel = () =>{
                prop.toggle(true);
            }
            const fetchToken = async () => {
                const token = await new JWT().generateToken({searchKey: prop.searchKey, flightsData: [prop.data[0], prop.data[1]]},{searchData: prop.searchData}, 'session');
                //const { email } = await response.json();
                setToken(token);
            };
            fetchToken();

            const setFlightData = (event) =>{
                // const fid = event.currentTarget.dataset.fid;

                const fid = token.substring(1, 10);
        
                const f = async() =>{
                    let searchId = _.uniqueId(fid);
                    console.log("== searchId ==", searchId);
                    const newToken = await new JWT().generateToken({key:'searchId'},{searchId: searchId});
                    const urlPath =`/review-flight?SData=${newToken}`;
                    //console.log("=== token ===", token);
                    sessionStorage.setItem(searchId, token);
                    window.open(urlPath, "_blank");
                };
                f();
                
            }

            let url = "/review-flight?SData="+token;
            return (
                <div className={prop.className}>
                    <Card className="footer-card" style={{width:'100vw', zIndex:10, backgroundColor: '#f8f8f8', color:'white'}}>
                        <Grid container>
                            <Grid item xs={12} className="p-2 border-left">
                                {/* <NavLink to={url} target="_blank" className="float-end ms-3"> */}
                                    <Button className="float-end bg-gradient px-2" style={{width:'110px',color:'white',border:'0px solid black'}} data-fid={prop.data[0].Flight_Id} onClick={setFlightData}>
                                        Book Now
                                    </Button>
                                {/* </NavLink> */}
                                <span className="float-start gradient2-color mt-2" style={{fontSize:'20px', fontWeight:'bolder'}}>₹ {prop.data[0].Fares.FareDetails.Total_Amount + prop.data[1].Fares.FareDetails.Total_Amount}</span>
                                {/* <Grid container>
                                    <Grid item xs={12} className="px-3 text-right">
                                    <p className="mt-2" style={{cursor:'pointer',fontSize:'15px'}} onClick={openModel}>+ Flight Details</p>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            );
        }
        else{
            return <></>;
        }
    } catch (error) {
        throw new Error(error);
    }
}

const OneWayRoundSearchFilter = (prop) => {
    try{
        if(prop.trip == 1){
            return (
                <>
                    <SearchFilter to={prop.SearchData.to.ooro_AirportName} from={prop.SearchData.from.ooro_AirportName} data={prop.OneWayFilterData} filterFlights={prop.filterFlights[0]} heading="Onward Journey" searchData={prop.SearchData} min={prop.amountRange[0].min} max={prop.amountRange[0].max} onHandel={(data) =>{prop.onHandelOneWay(data)}} />
                    <SearchFilter to={prop.SearchData.from.ooro_AirportName} from={prop.SearchData.to.ooro_AirportName} data={prop.RoundFilterData} filterFlights={prop.filterFlights[1]} heading="Return Journey" searchData={prop.SearchData} min={prop.amountRange[1].min} max={prop.amountRange[1].max} onHandel={(data) =>{prop.onHandelRound(data)}} />
                </>
            );
        }
        else{
            return <SearchFilter to={prop.SearchData.to.ooro_AirportName} from={prop.SearchData.from.ooro_AirportName} data={prop.OneWayFilterData} filterFlights={prop.filterFlights[0]} heading="Onward Journey" searchData={prop.SearchData} min={prop.amountRange[0].min} max={prop.amountRange[0].max} onHandel={(data) =>{prop.onHandelOneWay(data)}} />;
        }
    } catch (error) {
        throw new Error(error);
    }
}

const OneWayRoundSearchFilterForMobile = (prop) => {
    try{
        const handleTabChange = (e, { activeIndex }) => prop.handleTabChange(activeIndex);

        let panes = [];

        if(prop.trip == 1){
            panes = [
                {
                menuItem: 'Onward Journey',
                render: () => <Tab.Pane attached={false}><SearchFilterForMobile to={prop.SearchData.to.ooro_AirportName} from={prop.SearchData.from.ooro_AirportName} data={prop.OneWayFilterData} filterFlights={prop.filterFlights[0]} heading="Onward Journey" searchData={prop.SearchData} min={prop.amountRange[0].min} max={prop.amountRange[0].max} onHandel={(data) =>{prop.onHandelOneWay(data)}} onFilter={(data) => prop.onFilter()} /></Tab.Pane>,
                },
                {
                menuItem: 'Return Journey',
                render: () => <Tab.Pane attached={false}><SearchFilterForMobile to={prop.SearchData.from.ooro_AirportName} from={prop.SearchData.to.ooro_AirportName} data={prop.RoundFilterData} filterFlights={prop.filterFlights[1]} heading="Return Journey" searchData={prop.SearchData} min={prop.amountRange[1].min} max={prop.amountRange[1].max} onHandel={(data) =>{prop.onHandelRound(data)}} onFilter={(data) => prop.onFilter()} /></Tab.Pane>,
                }
            ];
        }
        else{
            panes = [
                {
                menuItem: 'Onward Journey',
                render: () => <Tab.Pane attached={false}><SearchFilterForMobile to={prop.SearchData.to.ooro_AirportName} from={prop.SearchData.from.ooro_AirportName} data={prop.OneWayFilterData} filterFlights={prop.filterFlights[0]} heading="Onward Journey" searchData={prop.SearchData} min={prop.amountRange[0].min} max={prop.amountRange[0].max} onHandel={(data) =>{prop.onHandelOneWay(data)}} onFilter={(data) => prop.onFilter()} /></Tab.Pane>,
                }
            ];
        }

        const TabExamplePointing = () => <Tab menu={{ pointing: true }} activeIndex={prop.activeFilterIndex}
        onTabChange={handleTabChange} className="login-signup-tab" panes={panes} />
        return (
            <>
                <TabExamplePointing />  
            </>
        );
    } catch (error) {
        throw new Error(error);
    }
}

class SearchResult extends React.Component {
    static contextType = AllAirportsContext;

    constructor(props){
        super(props)
        this.state = {
            loading:true,
            searchKey:null,
            allFlights:null,
            allAirports: [],
            error:null,
            activeFilterIndex: 0,
            filter: false,
            openSearchModel: false,
            price: [],
            spinner: false
        }
    }

    //history = useHistory();
    

    componentDidMount(){
        try{
            const { allAirports } = this.context;
            this.setState({allAirports: allAirports});
            console.log(this.props);
            const { from, to, fromDate, toDate, adult, children, infants, trip, directFlight, seatClass } = this.props.match.params;
            console.log({from:from, to:to, fromDate:fromDate, toDate:toDate, adult:adult, children:children, infants:infants, trip:trip, directFlight:directFlight, seatClass:seatClass});
            
            
            let fromNew = allAirports?.find(r => r.ooro_AirportCode == from);
            let toNew = allAirports?.find(r => r.ooro_AirportCode == to);
            let classTypeNew = allClasses.filter(r => r.id == seatClass)[0];
            let date = Number(fromDate.split('-')[2]);
            let month = Number(fromDate.split('-')[1]);

            document.title = `${fromNew.ooro_AirportName} - ${toNew.ooro_AirportName}, ${classTypeNew.name} Flights, ${date} ${monthNames[(month -1)]}` ;
            
            
            setTimeout(() => {
                this.setSearchData();
            }, 500);
            console.log("== allAirports ==", allAirports);
            console.log("== allClasses ==", allClasses);
            // let data = [
                
            //     {name:'origin', value:this.state.searchData.from.ooro_AirportCode},
            //     {name:'destination', value:this.state.searchData.to.ooro_AirportCode},
            //     {name:'travelDate', value:fromDate},
            //     {name:'tripId', value:'0'},
            //     {name:'adultsCount', value:this.state.searchData.travellersData.adult.toString()},
            //     {name:'childCount', value:this.state.searchData.travellersData.children.toString()},
            //     {name:'infantCount', value:this.state.searchData.travellersData.infants.toString()},
            //     {name:'classOfTraval', value:'0'},
            //     {name:'inventoryType', value:'0'}
            // ]
            // if(props?.location?.data?.trip == 1){
            //     mx1 = props?.location?.amountRange[0]?.max;
            //     mx2 = props?.location?.amountRange[1]?.max;
            // }else{
            //     mx1 = props?.location?.amountRange[0]?.max;
            // }
            // //console.log("=== props.location.amountRange ===",props.location.amountRange);
            // this.state = {
            //     hide:true,
            //     searchData: props.location.data,
            //     redirect:false,
            //     search:false,
            //     allFlights:props.location.searchFlight,
            //     isLoaded:false,
            //     departureSelectFlight : 0,
            //     returnSelectFlight : 0,
            //     allAirlines:props.location.allAirlines,
            //     returnAllFlights:props.location.returnAllFlights,
            //     amountRange:props.location.amountRange,
            //     filterFlights:props.location.filterFlights,
            //     filterData:{
            //         stop:null,
            //         fareType:false,
            //         departureTime:null,
            //         arrivalTime:null,
            //         airLines:null,
            //         priceRange:mx1,
            //         airlines:[],

            //     },
            //     roundFilterData:{
            //         stop:null,
            //         fareType:false,
            //         departureTime:null,
            //         arrivalTime:null,
            //         airLines:null,
            //         priceRange:mx2,
            //         airlines:[],

            //     }

            // }
            // console.log('--- props.allAirlines ---',props.location.allAirlines);
            // console.log('--- props.searchFlight ---',props.location.searchFlight);
            // if(params){
            //     let fromDate = this.state.searchData.departDate.month+'/'+this.state.searchData.departDate.date+'/'+this.state.searchData.departDate.year;

            //     let data = [
            //         {name:'travalType', value:'0'},
            //         {name:'bookingType', value:'0'},
            //         {name:'origin', value:this.state.searchData.from.ooro_AirportCode},
            //         {name:'destination', value:this.state.searchData.to.ooro_AirportCode},
            //         {name:'travelDate', value:fromDate},
            //         {name:'tripId', value:'0'},
            //         {name:'adultsCount', value:this.state.searchData.travellersData.adult.toString()},
            //         {name:'childCount', value:this.state.searchData.travellersData.children.toString()},
            //         {name:'infantCount', value:this.state.searchData.travellersData.infants.toString()},
            //         {name:'classOfTraval', value:'0'},
            //         {name:'inventoryType', value:'0'}
            //     ]

            //     //console.log('--- body ----',data);
            //     //this.getFlightsData(data);
            // }

            this.setFareCalenderData(from, to);
        } catch (error) {
            throw new Error(error);
        }

    }

    setFareCalenderData = (from, to) =>{
        try{
            let ddd = new Date();
            let today = new Date();
            for (let index = 1; index < 6; index++) {
                let mmm = monthIndex[ddd.getMonth()];
                // if(mmm < 10){
                // 	mmm = `0${mmm}`;
                // }else{
                // 	mmm = `${mmm}`;
                // }
                let data = {
                    "Destination": to,
                    "Month": mmm,
                    "Origin": from,
                    "Year": `${ddd.getFullYear()}`
                }
                new Services().fareCalender(data).then(res => res.json()).then(
                    (result) => {
                        console.log("fareCalender Data ", data, result);
                        // let data = [
                        // 	{
                        // 		month:'06',
                        // 		data:[
                        // 			{day:'01', price: this.c--},
                        // 		]
                        // 	}
                        // ];

                        let priceData = []; 

                        // if(result?.LowFares){
                        //     priceData = result.LowFares;
                        // }
                        if(result.LowFares?.length > 0){
                            result.LowFares.map(r =>{
                                let d = r.TravelDate.split('/')[1];
                                let amount = r.Amount;
                                priceData.push({day: d, price: amount});
                            });
                        }

                        let price = this.state.price;
                        let priceYear = price.filter(r => r.year == ddd.getFullYear());

                        if(priceYear.length > 0){
                            console.log("ddd.getFullYear()", ddd.getFullYear());
                            console.log("ddd.getFullYear()", ddd.getFullYear());
                            price.map(e =>{
                                if(e.year == ddd.getFullYear()){
                                    e.data.push({month: mmm, data: priceData});
                                }
                            });
                        }
                        else{
                            console.log("ddd.getFullYear()", ddd.getFullYear());
                            price.push({year: ddd.getFullYear(), data: [{month: mmm, data: priceData}]});
                        }

                        console.log("=== price ===", price);
                        this.setState({price: price});
                    },
                    (error) => {
                        console.log("== error ==", error);
                        // new displayMessages().errorMsg({error:error});
                    }
                );
                ddd.setMonth(ddd.getMonth() + 1);
            }
        } catch (error) {
            throw new Error(error);
        }
	}

    setSearchData(){
        try{
            const { from, to, fromDate, toDate, adult, children, infants, trip, directFlight, seatClass } = this.props.match.params;
            // let params = QueryString.parse(this.props.location.search);
            // console.log("== params ==", params);
            let fDateArray = fromDate.split('-');
            let tDateArray = toDate.split('-');
            let sum = Number(adult) + Number(children) + Number(infants)
            if(fDateArray.length == 3 && tDateArray.length == 3){
                if(sum <= 9 && Number(infants) <= Number(adult)){
                    let isUser = 0;

                    let token = localStorage.getItem('OoroToken');

                    if(token){
                        isUser  = 1;
                    }

                    let Booking_Type = Number(trip);

                    let fDate = moment(new Date(fromDate)).format('MM/DD/YYYY');
                    let tDate = moment(new Date(toDate)).format('MM/DD/YYYY');

                    let TripInfo = [
                        {
                            "Origin": from,
                            "Destination": to,
                            "TravelDate": fDate,
                            "Trip_Id": 0
                        }
                    ];

                    if(Number(trip) == 1){
                        TripInfo.push({
                            "Origin": to,
                            "Destination": from,
                            "TravelDate": tDate,
                            "Trip_Id": 1
                        });
                    }

                    let data = {
                        "Auth_Header": {
                        "UserToken": token,
                        "is_user": isUser
                        },
                        "Travel_Type": 0,
                        "Booking_Type": Booking_Type,
                        "TripInfo": TripInfo,
                        "Adult_Count": Number(adult),
                        "Child_Count": Number(children),
                        "Infant_Count": Number(infants),
                        "Class_Of_Travel": Number(seatClass),
                        "InventoryType": 0
                    }
                    // let data = [
                    //     {name:'inventoryType', value:'0'}, 
                    //     {name:'travalType', value:'0'},
                    //     {name:'bookingType', value:trip.toString()},
                    //     {name:'tripId', value:trip.toString()},
                    //     {name:'origin', value:from},
                    //     {name:'destination', value:to},
                    //     {name:'travelDate', value:fromDate},
                    //     {name:'adultsCount', value:adult.toString()},
                    //     {name:'childCount', value:children.toString()},
                    //     {name:'infantCount', value:infants.toString()},
                    //     {name:'classOfTraval', value:seatClass.toString()}
                        
                    // ];
                    // if(Number(trip) == 1){
                    //     data.push({name:'returnDate', value:toDate});
                    // }
                    console.log("=== search data ===",data);
                    this.getFlightsData(data);
                    //this.getFlightsDataDummy(data);
                }
                else{
                    let error = "Invalid Travellers Count";
                    this.setState({
                        loading: false,
                        error: error
                    });
                    new displayMessages().errorMsg({error:error});
                }
            }
            else{
                let error = "Invalid Date String";
                this.setState({
                    loading: false,
                    error: error
                });
                new displayMessages().errorMsg({error:error});
            }
        } catch (error) {
            throw new Error(error);
        }
        
    }

    renderRedirect = () => {
		if (this.state.redirect) {
		  return <Redirect to={{pathname:'/home',data:''}} />
		}
	}

    searchView = (boolean) => {
        this.setState({
            search:boolean
        })
    }

    setFinalData = (data) => {
        console.log("=== data ===", data);
        //this.useHistory.push(data);
        
        this.props.history.push(data);
        
        this.setState({
            search:false,
            loading:true,
            openSearchModel:false,
        });

        setTimeout(() => {
            this.setSearchData();
        }, 50);

        //this.props.router.push(data)
        // let finalData = data.finalData;
        // let allFlights = data.allFlights;
        // let returnAllFlights = data.returnAllFlights;
        // let amountRange = data.amountRange
        // let filterFlights = data.filterFlights
        // let mx1 = 0;
        // let mx2 = 0;
        // if(finalData.trip == 1){
        //     mx1 = amountRange[0].max;
        //     mx2 = amountRange[1].max;
        // }else{
        //     mx1 = amountRange[0].max;
        // }
        // // console.log('--- data data ---',data);
        // // console.log('--- finalData data ---',finalData);
        // console.log('--- returnAllFlights data ---',returnAllFlights);
        // this.setState({
        //     search:false,
        //     loading:false,
        //     searchData:finalData,
        //     allFlights: allFlights,
        //     returnAllFlights:returnAllFlights,
        //     amountRange:amountRange,
        //     filterFlights:filterFlights,
        //     filterData:{
        //         stop:null,
        //         fareType:false,
        //         departureTime:null,
        //         arrivalTime:null,
        //         airLines:null,
        //         priceRange:mx1,
        //         airlines:[],
        //     },
        //     roundFilterData:{
        //         stop:null,
        //         fareType:false,
        //         departureTime:null,
        //         arrivalTime:null,
        //         airLines:null,
        //         priceRange:mx2,
        //         airlines:[],

        //     }
        // })

    }

    getFlightsDataDummy(data){
        this.setAllFlightsData(allFlightsData);
    }

    getFlightsData = (data) => {
        //let res = null;
        console.log("== d ==", data);
        try{
            new Services().searchFlight(data).then(res => res.json()).then(
                (result) => {
                    console.log(result);
                    this.setAllFlightsData(result);
                    
                },
                (error) => {
                    console.log("== error ==", error);
                    this.setState({
                        loading: false,
                        error: error
                    });
                    // new displayMessages().errorMsg({error:error});
                }
            );
        } catch (error) {
            throw new Error(error);
        }

        //return res;
    }

    setAllFlightsData(results){
        try{
            const { from, to, fromDate, toDate, adult, children, infants, trip, directFlight, seatClass } = this.props.match.params;
            
            let fromNew = this.state?.allAirports?.find(r => r.ooro_AirportCode == from);
            let toNew = this.state?.allAirports?.find(r => r.ooro_AirportCode == to);
            let classTypeNew = allClasses.filter(r => r.id == seatClass)[0];
            let directFlightNew = Boolean(Number(directFlight));
            let tripNew = Number(trip);

            let searchData = {
                departDate: {
                    date: Number(fromDate.split('-')[2]),
                    month: Number((fromDate.split('-')[1] - 1)),
                    year: Number(fromDate.split('-')[0])
                },
                departDateStr: fromDate,
                directFlight: directFlightNew,
                from: fromNew,
                returnDate: {
                    date: Number(toDate.split('-')[2]),
                    month: Number((toDate.split('-')[1] -1)),
                    year: Number(toDate.split('-')[0])
                },
                returnDateStr: toDate,
                to: toNew,
                travellersData: {
                    adult: Number(adult),
                    children: Number(children),
                    infants: Number(infants),
                    seatClassesData: classTypeNew
                },
                traveller: false,
                trip: tripNew

            };
            
            console.log("=== searchData ===", searchData);
            
            let mx1 = 0;
            let mx2 = 0;
            let tripDetails = results?.TripDetails?.Flights;
            let searchKey = results?.Search_Key;
            
            console.log("=== searchKey ===", searchKey, typeof(searchKey));
            console.log("=== tripDetails ===", tripDetails, typeof(tripDetails));
            
            let allFlights = null;
            let returnAllFlights = null;
            let amountRange = null;
            let filterFlights = null;
            
            if(searchKey && tripDetails){
                
                allFlights = tripDetails?.Flights[0];
                returnAllFlights = tripDetails?.Flights[1];
                amountRange = tripDetails?.Amount_Range;
                filterFlights = tripDetails?.Filter_Flights;

                if(tripNew == 1){
                    mx1 = tripDetails?.Amount_Range[0]?.max;
                    mx2 = tripDetails?.Amount_Range[1]?.max;
                }
                else{
                    mx1 = tripDetails?.Amount_Range[0]?.max;
                }
            }

            //console.log("=== props.location.amountRange ===",props.location.amountRange);
            
            this.setState({
                hide:true,
                error:null,
                searchKey: searchKey,
                loading:false,
                searchData: searchData,
                //redirect:false,
                search:false,
                allFlights: allFlights,
                isLoaded:false,
                departureSelectFlight : 0,
                returnSelectFlight : 0,
                allAirlines:[],
                returnAllFlights: returnAllFlights,
                amountRange: amountRange,
                filterFlights:filterFlights,
                filterData:{
                    stop:null,
                    fareType:false,
                    departureTime:null,
                    arrivalTime:null,
                    airLines:null,
                    priceRange:mx1,
                    airlines:[],

                },
                roundFilterData:{
                    stop:null,
                    fareType:false,
                    departureTime:null,
                    arrivalTime:null,
                    airLines:null,
                    priceRange:mx2,
                    airlines:[],

                }

            });

            // setTimeout(() => {
            //     console.log("=== scrollTop ===");
            //     this.scrollTop();
            // }, 3000);
        } catch (error) {
            throw new Error(error);
        }
    }

    setLoader = (value) => {
        console.log('--- set loader ---',value);
        this.setState({
            isLoaded:value
        });
    }

    onSpinner = (value) => {
        console.log('--- set spinner ---',value);
        // this.setState({
        //     spinner:value
        // });
    }

    setFilter = (data) =>{
        console.log("=== Filter Data ===",data);
        this.setState({
            departureSelectFlight:0,
            returnSelectFlight:0,
            filterData:data,
            isLoaded: true
        });
        setTimeout(() => {
            this.setState({isLoaded: false});
        }, 500);
    }

    setRoundFilter = (data) =>{
        console.log("=== Round Filter Data ===",data);
        this.setState({
            roundFilterData:data
        })
    }

    setFlightIds = (data) =>{
         console.log("==== flight ids ===",data);
         if(data?.d){
             this.setState({
                departureSelectFlight:data.d
             });
            console.log("d",data?.d);
         }
         else{
            this.setState({
                returnSelectFlight:data.r
            });
            console.log("r",data?.r);
        }
        setTimeout(() => {
            this.setState({spinner: false});
        }, 1000);
    }

    toggleVisibility = () => this.setState({ hide: !this.state.hide });

    handleTabChange = (data) => {
        console.log("== activeFilterIndex ==", data);
        this.setState({ activeFilterIndex: data });
    };

    openFilter = () => this.setState({ activeFilterIndex: 0, filter: !this.state.filter });

    checkLogin = (status) =>{
        //this.setState({loggedIn: status});
    }

    openSearchModel = (data) =>this.setState({ openSearchModel: !this.state.openSearchModel });


    // scrollTop = () =>{
    //     setTimeout(() => {
    //         console.log("=== this.scrollTop(); ===");
    //         new CustomFunction().scrollTop();
    //     }, 5000);
    // }

    // const [searchData, setSearchData] = React.useState(prop.location.data)
    render(){
        if(!this.state.loading){
            if(!this.state.error){
                let searchKey = this.state.searchKey;
                let returnAllFlights = this.state.returnAllFlights;
                let filterData = this.state.filterData;
                let amountRange = this.state.amountRange;
                let filterFlights = this.state.filterFlights;
                let roundFilterData = this.state.roundFilterData;
                let departureSelectFlight = this.state.departureSelectFlight;
                let returnSelectFlight = this.state.returnSelectFlight;
                console.log("=== roundFilterData ===", this.state.roundFilterData);
                console.log("=== departureSelectFlight ===", departureSelectFlight, "===  returnSelectFlight ===" , returnSelectFlight);
                
                console.log('--- SearchResult ---',this.state.searchData);
                let data = this.state.searchData;
                
                if(searchKey){

                    let trip = data.trip;
                    
                    let stopFlights = this.state.allFlights.filter(r =>{
                        if(this.state.filterData.stop){
                            if(this.state.filterData.stop <= 1){
                                return r.stops == this.state.filterData.stop;
                            }
                            else{
                                return r.stops >= this.state.filterData.stop;
                            }
                        }
                        else{
                            return r.stops >= 0;
                        }
                    });

                    let refundableFilter = stopFlights.filter(r =>{
                        if(this.state.filterData.fareType){
                            return r.Fares.Refundable == this.state.filterData.fareType;
                        }
                        else{
                            return r;
                        }
                    });

                    let departureTimeFilter = refundableFilter.filter(r =>{
                        if(this.state.filterData.departureTime){
                            let t1 = Number(this.state.filterData.departureTime.split('-')[0]);
                            let t2 = Number(this.state.filterData.departureTime.split('-')[1]);
                            let rdt = Number(r.Segments[0].Departure_DateTime.split(' ')[1].split(':')[0]);
                            //let rat = Number(r.Segments[(r.Segments.length - 1)].Arrival_DateTime.split(' ')[1].split(':')[0]);
                            if(rdt >= t1 && rdt < t2){
                                return r;
                            }
                        }
                        else{
                            return r;
                        }
                    });

                    let arrivalTimeFilter = departureTimeFilter.filter(r =>{
                        if(this.state.filterData.arrivalTime){
                            let t1 = Number(this.state.filterData.arrivalTime.split('-')[0]);
                            let t2 = Number(this.state.filterData.arrivalTime.split('-')[1]);
                            //let rdt = Number(r.Segments[0].Departure_DateTime.split(' ')[1].split(':')[0]);
                            let rat = Number(r.Segments[(r.Segments.length - 1)].Arrival_DateTime.split(' ')[1].split(':')[0]);
                            if(rat >= t1 && rat < t2){
                                return r;
                            }
                        }
                        else{
                            return r;
                        }
                    });
                    let airlinesFilter = [];
                    let airliness = this.state.filterData.airlines;

                    console.log("=== airliness airliness ===", airliness);
                    arrivalTimeFilter.forEach(r =>{
                        if(airliness && airliness.length > 0){
                            for (let index = 0; index < airliness.length; index++) {
                                const res = airliness[index];
                                if(r.Airline_Code == res){
                                    console.log(`${r.Airline_Code} == ${res}`);
                                    //return r;
                                    airlinesFilter.push(r);
                                }
                                
                            }
                                
                        }
                        else{
                            //return r;
                            airlinesFilter.push(r);
                        }
                    });

                    console.log("== airlinesFilter ==");
                    console.log(airlinesFilter);

                    let priceFilter = airlinesFilter.filter(r =>{
                        if(this.state.filterData.priceRange){
                            return r.Fares.FareDetails.Total_Amount <= this.state.filterData.priceRange;
                        }
                        else{
                            return r;
                        }
                    });

                    let finalFilteredData = priceFilter;
                    
                    let rFinalFilteredData;
                    let selectedFlightsData = [];

                    console.log("=== this.state.returnAllFlights ===", this.state.returnAllFlights);
                    if(trip == 1){
                        let rStopFlights = this.state.returnAllFlights.filter(r =>{
                            if(this.state.roundFilterData.stop){
                                if(this.state.roundFilterData.stop <= 1){
                                    return r.stops == this.state.roundFilterData.stop;
                                }
                                else{
                                    return r.stops >= this.state.roundFilterData.stop;
                                }
                            }
                            else{
                                return r.stops >= 0;
                            }
                        })

                        let rRefundableFilter = rStopFlights.filter(r =>{
                            if(this.state.roundFilterData.fareType){
                                return r.Fares.Refundable == this.state.roundFilterData.fareType;
                            }
                            else{
                                return r;
                            }
                        })

                        let rDepartureTimeFilter = rRefundableFilter.filter(r =>{
                            if(this.state.roundFilterData.departureTime){
                                let t1 = Number(this.state.roundFilterData.departureTime.split('-')[0]);
                                let t2 = Number(this.state.roundFilterData.departureTime.split('-')[1]);
                                let rdt = Number(r.Segments[0].Departure_DateTime.split(' ')[1].split(':')[0]);
                                //let rat = Number(r.Segments[(r.Segments.length - 1)].Arrival_DateTime.split(' ')[1].split(':')[0]);
                                if(rdt >= t1 && rdt < t2){
                                    return r;
                                }
                            }
                            else{
                                return r;
                            }
                        })

                        let rArrivalTimeFilter = rDepartureTimeFilter.filter(r =>{
                            if(this.state.roundFilterData.arrivalTime){
                                let t1 = Number(this.state.roundFilterData.arrivalTime.split('-')[0]);
                                let t2 = Number(this.state.roundFilterData.arrivalTime.split('-')[1]);
                                //let rdt = Number(r.Segments[0].Departure_DateTime.split(' ')[1].split(':')[0]);
                                let rat = Number(r.Segments[(r.Segments.length - 1)].Arrival_DateTime.split(' ')[1].split(':')[0]);
                                if(rat >= t1 && rat < t2){
                                    return r;
                                }
                            }
                            else{
                                return r;
                            }
                        })
                        
                        let rAirlinesFilter = [];
                        
                        let rAirLiness = this.state.roundFilterData.airlines;

                        rArrivalTimeFilter.forEach(r =>{
                            if(rAirLiness && rAirLiness.length > 0){
                                for (let index = 0; index < rAirLiness.length; index++) {
                                    const res = rAirLiness[index];
                                    if(r.Airline_Code == res){
                                        console.log(`${r.Airline_Code} == ${res}`);
                                        //return r;
                                        rAirlinesFilter.push(r);
                                    }
                                    
                                }
                                    
                            }
                            else{
                                //return r;
                                rAirlinesFilter.push(r);
                            }
                        });

                        let rPriceFilter = rAirlinesFilter.filter(r =>{
                            if(this.state.roundFilterData.priceRange){
                                return r.Fares.FareDetails.Total_Amount <= this.state.roundFilterData.priceRange;
                            }
                            else{
                                return r;
                            }
                        })

                        rFinalFilteredData = rPriceFilter;
                    
                        if(departureSelectFlight == 0 && finalFilteredData.length > 0){
                            departureSelectFlight = finalFilteredData[0].Flight_Id;
                            selectedFlightsData.push(finalFilteredData[0]);
                        }
                        else{
                            let departureSelectFlights = this.state.allFlights.filter(r =>{
                                if(departureSelectFlight && departureSelectFlight > 0){
                                    return r.Flight_Id == departureSelectFlight;
                                }
                            });

                            if(departureSelectFlights && departureSelectFlights?.length >= 1){
                                selectedFlightsData.push(departureSelectFlights[0]);
                            }
                        }

                        if(returnSelectFlight == 0 && rFinalFilteredData.length > 0){
                            returnSelectFlight = rFinalFilteredData[0].Flight_Id;
                            selectedFlightsData.push(rFinalFilteredData[0]);
                        }
                        else{
                            let returnSelectFlights = this.state.returnAllFlights.filter(r =>{
                                if(returnSelectFlight && returnSelectFlight > 0){
                                    return r.Flight_Id == returnSelectFlight;
                                }
                            });

                            if(returnSelectFlights && returnSelectFlights?.length >= 1){
                                selectedFlightsData.push(returnSelectFlights[0]);
                            }
                        }
                        
                    }

                    console.log("=== all fly ===",priceFilter);
                    let paddingBottom = "10px";
                    if(trip == 1){
                        paddingBottom = "90px";
                    }
                    
                    let datas = [];
                    datas.push({id:1,label:<Headers LValue="Onword" RValue={<></>} />,body:<FlightDetails data={selectedFlightsData[0]} hidden={this.state.hide} travelClass={data.travellersData.seatClassesData.name} />});
                    datas.push({id:2,label:<Headers LValue="Return" RValue={<></>} />,body:<FlightDetails data={selectedFlightsData[1]} hidden={this.state.hide} travelClass={data.travellersData.seatClassesData.name} />});
                    
                    document.documentElement.style.setProperty('--app-header-height', this.state.searchData.trip? '121px': '128px');
                    
                    return (
                    <div className="SearchResult position-relative xs-pb-0" style={{backgroundColor:'#F8F8F8', height:'100vh', overflow:'auto', paddingBottom:paddingBottom}}>
                        {this.renderRedirect()}
                        <Header nav={this.props.nav} className="sm-device-block md-device-block lg-device-block xl-device-block" onLoggedIn={this.checkLogin} openSideBar={(status) =>this.props.openSideBar(status)} />
                        <Modal size="sm" dialogClassName="modal-90w" className="p-0" centered show={this.state.spinner} backdrop="static" keyboard={false}>
                            <Modal.Body className="p-0" 
                            // onClick={this.toggleDepart}
                            >
                                <card className="bg-white">
                                    
                                    <CustomSpinnner />
                                </card>
                            </Modal.Body>
                        </Modal>
                        <div className="main-container xs-main-container">
                            <div className="xs-device-block w-100 header" style={{top: 0, zIndex: 10}}>
                                <VerticalSearchDisplayCardForMobile openSearchModel={this.openSearchModel} className="xs-device-block" data={data} onHandel={this.searchView} openFilter={this.openFilter} />
                                <TopCallenderForMobile data={data} className="xs-device-block" visible={!this.state.searchData.trip} onHandel={this.setFinalData} />
                                <FooterFlightsDetailsForMobile data={selectedFlightsData} searchData={data} searchKey={searchKey} className="xs-device" toggle={this.toggleVisibility} />
                            </div>
                            <Grid container className="px-2 pb-0 xs-mt-0 body-container" style={{marginTop:90}}>
                                <Grid item xs={12} className="">
                                    <VarticalSearchCard visible={this.state.search} data={data} onHandel={this.setFinalData} />
                                    <VarticalSearchDisplayCard openFilter={this.openFilter} className="sm-device-block md-device-block lg-device-block xl-device-block" visible={!this.state.search} data={data} onHandel={this.searchView} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="py-3 xl-device-block">
                                    <OneWayRoundSearchFilter amountRange={amountRange} filterFlights={filterFlights} trip={trip} OneWayFilterData={filterData} SearchData={data} RoundFilterData={roundFilterData} onHandelOneWay={this.setFilter} onHandelRound={this.setRoundFilter} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={9} xl={9}  className="px-3 pe-1 py-0 xs-ps-0 xs-pe-0 xs-pb-0">
                                    <TopCallender data={data} className="sm-device-block md-device-block lg-device-block xl-device-block" visible={!this.state.searchData.trip} onHandel={this.setFinalData} />
                                    <OneWayRoundTrip data={finalFilteredData} selectedFlight={departureSelectFlight} data2={rFinalFilteredData} selectedFlight2={returnSelectFlight} searchData={data} allAirlines={finalFilteredData} returnAllFlights={returnAllFlights} loader={this.state.isLoaded} searchKey={searchKey} onHandel={this.setFlightIds} onSpinner={this.onSpinner} setLoader={this.setLoader} />
                                </Grid>
                            </Grid>
                            <FooterFlightsDetails data={selectedFlightsData} searchData={data} searchKey={searchKey} className="sm-device md-device lg-device xl-device" toggle={this.toggleVisibility} />
                            <Modal size="lg" dialogClassName="modal-90w" centered show={!this.state.hide} onHide={this.toggleVisibility} 
                            // backdrop="static" keyboard={false}
                            >
                                <Modal.Body className="p-0" 
                                // onClick={this.toggleVisibility}
                                >
                                    <card className="bg-white">
                                        <TreeViews classes="footer-flight-details-treeViews" datas={datas} dynamicNode={true} />
                                        {/* <Grid container >
                                            <Grid item xs={12} className="bg-gradient p-2">
                                                <h2 className="w-color">Onword</h2>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FlightDetails data={selectedFlightsData[0]} hidden={this.state.hide} travelClass={data.travellersData.seatClassesData.name} />
                                            </Grid>
                                            <Grid item xs={12} className="bg-gradient p-2">
                                                <h2 className="w-color">Return</h2>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FlightDetails data={selectedFlightsData[1]} hidden={this.state.hide} travelClass={data.travellersData.seatClassesData.name} />
                                            </Grid>
                                        </Grid> */}
                                    </card>
                                </Modal.Body>
                            </Modal>
                            <Modal size="lg" dialogClassName="modal-90w modal-100w xs-m-0" className="xs-ps-0" centered show={this.state.openSearchModel} onHide={this.openSearchModel} 
                            backdrop="static" keyboard={false}
                            >
                                <img src={multiply} className="position-absolute a" width="20px" style={{top:0, right:0, zIndex: 10}} onClick={this.openSearchModel} />
                                <Modal.Body className="p-0" 
                                // onClick={this.toggleVisibility}
                                >
                                    <card className="bg-white">
                                        <VarticalSearchCard visible={true} data={data} onHandel={this.setFinalData} /> 
                                    </card>
                                </Modal.Body>
                            </Modal>
                            <Modal size="lg" dialogClassName="modal-90w modal-100w xs-m-0" className="xs-ps-0" centered show={this.state.filter} onHide={this.openFilter} 
                            backdrop="static" keyboard={false}
                            >
                                <img src={multiply} className="position-absolute a" width="20px" style={{top:0, right:0, zIndex: 10}} onClick={this.openFilter} />
                                <Modal.Body className="p-0" 
                                // onClick={this.toggleVisibility}
                                >
                                    <card className="bg-white">
                                        <OneWayRoundSearchFilterForMobile activeFilterIndex={this.state.activeFilterIndex} amountRange={amountRange} filterFlights={filterFlights} trip={trip} OneWayFilterData={filterData} SearchData={data} RoundFilterData={roundFilterData} onHandelOneWay={this.setFilter} onHandelRound={this.setRoundFilter} handleTabChange={this.handleTabChange} onFilter={this.openFilter} /> 
                                    </card>
                                </Modal.Body>
                            </Modal>
                        </div>
                        {/* {this.scrollTop()} */}
                    </div>
                    );
                }
                else{
                    return(
                        <div className="SearchResult position-relative" style={{backgroundColor:'#F8F8F8',height:'100vh', overflow:'auto', paddingBottom:'10px'}}>
                            <Header nav={this.props.nav} onLoggedIn={this.checkLogin} className="sm-device-block md-device-block lg-device-block xl-device-block" />
                            <Grid container className="px-2 py-4 pb-0" style={{marginTop:'100px'}}>
                                <div className="xs-device-block position-fixed w-100" style={{top: 0, zIndex: 10}}>
                                    <VerticalSearchDisplayCardForMobile openSearchModel={this.openSearchModel} className="xs-device-block" data={data} onHandel={this.searchView} openFilter={this.openFilter} />
                                </div>
                                <Grid item xs={12} className="sm-device-block md-device-block lg-device-bloack xl-device-block">
                                    <VarticalSearchCard visible={this.state.search} data={data} onHandel={this.setFinalData} />
                                    <VarticalSearchDisplayCard visible={!this.state.search} data={data} onHandel={this.searchView} />
                                </Grid>
                                <Grid item xs={12} className="pt-2 d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
                                    <NotFound />
                                </Grid>
                            </Grid>
                            
                        </div>
                    );
                }
            }
            else{
                return <> <Header nav={this.props.nav} onLoggedIn={this.checkLogin} className="sm-device-block md-device-block lg-device-block xl-device-block" /> 
                <Grid container className="px-2 py-4 pb-0" style={{marginTop:'100px'}}>
                    <Grid item xs={12} className="pt-2 d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
                        <SomeThingWentWrong />
                    </Grid>
                </Grid> </>;
            }
            // else{
            //     return <> <Header /> <NotFound /> </>;
            // }
        }
        else{
            return <> <Header onLoggedIn={this.checkLogin} nav={this.props.nav} className="sm-device-block md-device-block lg-device-block xl-device-block" /> <Shimmer /> </>;
        }
    }

    // render(){   
    //         console.log(this.state);
    //     return <>{JSON.stringify(this.state)}</>;
    // }
}

export default SearchResult;