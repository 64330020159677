import { Row, Col, Form, Button } from 'react-bootstrap';
import React from "react";

import Services from "../services/services";
import displayMessages from "../displayMessages/displayMessages";

export default function SignupForm(prop) {
  try{
      const [validatedSignup, setValidatedSignup] = React.useState(false);
      const [loading, setLoading] = React.useState(false);
      const [valueName, setValueName] = React.useState('');
      const [valueEmail, setValueEmail] = React.useState('');
      const [valueMobile, setValueMobile] = React.useState('');
      const [valuePassword, setValuePassword] = React.useState('');
      const [loginError, setLoginError] = React.useState('none');
      const [loginSuccess, setLoginSuccess] = React.useState(false);
      const [token, setToken] = React.useState('');
      const [loginErrorMsg, setLoginErrorMsg] = React.useState('');
    
      const handleSubmitSignup = (event) => {
        //const form = event.currentTarget;
        //console.log("=== form ===", form.checkValidity());
        if (valueName !='' && valuePassword !='' && valueEmail !='' && valueMobile !='' && ValidateEmail(valueEmail) && IsMobileNumber(valueMobile)) {
          getFormValue();
          console.log("done=== ");
          setLoading(true);
        }
        else{
          event.preventDefault();
          event.stopPropagation();
          setValidatedSignup(true);
        }
    
        
      };

      const ValidateEmail = (mail) =>{
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
            return (true)
        }
        //alert("You have entered an invalid email address!");
        setLoginError('unset');
        setLoginErrorMsg("Please enter valid email address");
        return (false)
      }

      const IsMobileNumber = (txtMobile) =>{
        var mob = /^[1-9]{1}[0-9]{9}$/;
        //var txtMobile = document.getElementById(txtMobId);
        if (mob.test(txtMobile) == false) {
            // alert("Please enter valid mobile number.");
            // txtMobile.focus();
            setLoginError('unset');
            setLoginErrorMsg("Please enter valid mobile no.");
            return false;
        }
        return true;
      }


      const getFormValue = ()=>{
        console.log("=== valueUsername ===",valueName);
        console.log("=== valueEmail ===",valueEmail);
        console.log("=== valueMobile ===",valueMobile);
        console.log("=== valuePassword ===",valuePassword);
        try{
          new Services().signup(valueName, valueEmail, valueMobile, valuePassword).then(res => res.json()).then((results) =>{
            console.log("=== signup result ===", results);
            if(results.success){
              setValidatedSignup(false);
              setLoginSuccess(true);
              setToken(results.token);
              localStorage.setItem('OoroToken', results.token);
              localStorage.setItem('OoroUserData', JSON.stringify(results.message));
              console.log("=== localStorage token ===", localStorage.getItem('OoroToken'));
              console.log("=== localStorage userData ===", localStorage.getItem('OoroUserData'));
              prop.onHandle(false);
            }
            else{
              setValidatedSignup(true);
              setLoginError('unset');
              setLoginErrorMsg(results.message);
              new displayMessages().errorMsg({error: {message: `${results?.message} at signup()`, data: {name: valueName, email: valueEmail, mobile: valueMobile, password: valuePassword}, status: results?.status, type: results?.type}});
            }
          }, (error) => {
              console.log("== error ==", error);
            // new displayMessages().errorMsg({error:error});
          });
        } catch (error) {
          throw new Error(error);
        }
      }
    
      const checkLogin = () =>{
        console.log("==== checkLogin ===");
        if(loginSuccess){
          prop.onHandle(true);
        }
      }
    
      return (
        <Form noValidate validated={validatedSignup}>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationName" className="position-relative mb-4">
              <Form.Control
                required
                type="text"
                placeholder="Name"
                className="my-big-input pl-35"
                onChange={e => {setValueName(e.target.value); setValidatedSignup(true); setLoginError('none');}}
              />
              <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationEmail" className="position-relative mb-4">
              <Form.Control
                required
                type="email"
                placeholder="Email"
                className="my-big-input pl-35"
                onChange={e => {setValueEmail(e.target.value); setValidatedSignup(true); setLoginError('none');}}
              />
              <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationMobile" className="position-relative mb-4">
              <Form.Control
                required
                type="number"
                placeholder="Mobile No."
                className="my-big-input pl-35"
                onChange={e => {setValueMobile(e.target.value); setValidatedSignup(true); setLoginError('none');}}
              />
              <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter valid mobile no.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationPassword" className="position-relative mb-4">
              <Form.Control
                required
                type="password"
                placeholder="Password"
                className="my-big-input pl-35"
                onChange={e => {setValuePassword(e.target.value); setValidatedSignup(true); setLoginError('none');}}
              />
              <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter password
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <p className="text-center mt-5" style={{display: loginError, color:'#b10c00'}}> {loginErrorMsg} </p>
          <Button variant="contained" className="my-default-btn w-100-per mt-1" onClick={handleSubmitSignup}>
            Sign Up
          </Button>
        </Form>
      );
    } catch (error) {
      throw new Error(error);
    }
  }