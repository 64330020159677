import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import Login from "../login/Login.js";

import loginImage from "../../assets/registerImage.svg";
import SofiaLogo from "../../components/Icons/SidebarIcons/SofiaLogo.js";
import GoogleIcon from "../../components/Icons/AuthIcons/GoogleIcon.js";
import TwitterIcon from "../../components/Icons/AuthIcons/TwitterIcon.js";
import FacebookIcon from "../../components/Icons/AuthIcons/FacebookIcon.js";
import GithubIcon from "../../components/Icons/AuthIcons/GithubIcon.js";
import LinkedinIcon from "../../components/Icons/AuthIcons/LinkedinIcon.js";
import { registerUser } from "../../actions/register.js";
import userImg from "../../../../assets/Icons/blank-profile.jpeg";
import s from "./Profile.module.scss";

class Profile extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    let OoroUserData = localStorage.getItem('OoroUserData');
    console.log("=== userData ===", JSON.parse(OoroUserData));
    let userData = {};
    if(OoroUserData){
      userData = JSON.parse(OoroUserData);
    }
    this.state = {
      name: userData?.Name? userData.Name: '',
      email: userData?.Email? userData.Email: '',
      mobile: userData?.Mobile? userData.Mobile: '',
    };

    // this.changeEmail = this.changeEmail.bind(this);
    // this.changeName = this.changeName.bind(this);
    // this.changeMobile = this.changeMobile.bind(this);
    // this.doRegister = this.doRegister.bind(this);
  }

  changeEmail(event) {
    this.setState( { email: event.target.value });
  }

  changeName(event) {
    this.setState( { name: event.target.value });
  }

  changeMobile(event) {
    this.setState({ mobile: event.target.value });
  }

  doRegister(e) {
    // e.preventDefault();
    // this.props.dispatch(registerUser({
    //   creds: {
    //     email: this.state.email,
    //     password: this.state.password,
    //   },
    //   history: this.props.history
    // }));
  }

  render() {
    // const { from } = this.props.location.state || { from: { pathname: '/app' } };

    // if (Login.isAuthenticated(JSON.parse(localStorage.getItem('authenticated')))) {
    //   return (
    //     <Redirect to={from} />
    //   );
    // }

    let OoroUserData = localStorage.getItem('OoroUserData');
    console.log("=== userData ===", JSON.parse(OoroUserData));
    let userData = {};
    if(OoroUserData){
      userData = JSON.parse(OoroUserData);
    }
    else{
        window.location.reload();
    }

    return (
      <div className="auth-page">
        <Container className="col-12">
          <Row className="d-flex align-items-center">
            {/* <Col xs={12} lg={6} className="left-column">

            </Col> */}
            <Col xs={12} lg={6} className="right-column">
                <Widget className="widget-p-lg p-3">
                    <Row>
                        <Col>
                            <div className="d-flex">
                                <img className={s.image} style={{borderRadius: '50%'}} src={userImg} alt="..." />
                                <div className={s.userInfo}>
                                <p className="headline-3 mb-1">{userData?.Name}</p>
                                <p className="body-3 muted mb-1">{userData?.Email}</p>
                                <p className="body-3 muted mb-1">{userData?.Mobile}</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Button className="rounded-pill my-3 btn btn-outline-success float-end" type="button" color="secondary-red">Wallet: ₹{userData?.wallet ? userData.wallet : 0}</Button>
                        </Col>
                    </Row>
                </Widget>
                <Widget className="widget-auth widget-p-lg p-3">
                    <div className="d-flex align-items-center justify-content-between py-3">
                    <p className="auth-header mb-0">Edit Profile</p>
                    </div>
                    {/* <div className="auth-info my-2">
                    <p>This is a real app with Node.js backend - use <b>"admin@flatlogic.com / password"</b> to login!</p>
                    </div> */}
                    <form onSubmit={this.doRegister}>
                        <FormGroup className="my-3">
                        <FormText>Name</FormText>
                        <Input id="name" className="input-transparent pl-3" readOnly value={this.state.name} onChange={this.changeName} type="text" required name="name" placeholder="Enter Name" />
                    </FormGroup>
                    <FormGroup className="my-3">
                        <FormText>Email</FormText>
                        <Input id="email" className="input-transparent pl-3" readOnly value={this.state.email} onChange={this.changeEmail} type="email" required name="email" placeholder="Enter Email" />
                    </FormGroup>
                    <FormGroup  className="my-3">
                        <div className="d-flex justify-content-between">
                        <FormText>Mobile No.</FormText>
                        {/* <Link to="/error">Forgot password?</Link> */}
                        </div>
                        <Input id="mobile" className="input-transparent pl-3" readOnly value={this.state.mobile} onChange={this.changeMobile} type="number" required name="mobile" placeholder="Enter Mobile No."/>
                    </FormGroup>
                    <div className="bg-widget d-flex justify-content-center">
                        <Button className="rounded-pill my-3 btn btn-outline-primary" type="button" color="secondary-red">Submit</Button>
                    </div>
                    </form>
                </Widget>
            </Col>
          </Row>
        </Container>
        {/* <Footer /> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default Profile;
