import { exceptionLogsUrl } from "../services/services";

export default class DisplayMessages{
    errorMsg = (data) =>{
        console.log('--- Error Message ---', data.error);
        if(Object.keys(data?.error)?.length > 0){
            this.sendError(data.error).then(res => res.json().then(res => {
                console.log("=== error has been send === ", res);
            }));
        }
    }

    sendError = (data) =>{
        let f = fetch(exceptionLogsUrl,{
			method:'post',
            headers: { 'Content-Type': 'application/json' },
			body:JSON.stringify(data)
		})
		return f;
    }
}