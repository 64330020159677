import React, { useState } from "react";
import {
  Col,
  Row
} from "reactstrap";
import Widget from "../Widget/Widget";
import { CircularProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Services from "../../../../shared/services/services";
import DisplayMessages from "../../../../shared/displayMessages/displayMessages";
import FlightDetailsForTickets from "../FlightDetails/FlightDetails";
import bootstrapCss from '../../../../bootstrap.css';
import logo from "../../../../assets/Icons/logo-black.png";

const TicketsDetrails = function (prop) {

  const [flightsDatas, setFlightsDatas] = useState([]);
  const [datas, setDatas] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    // document.title = "Ooro Flights";
    let token = localStorage.getItem('OoroToken');
    if(token){
      new Services().getPaymentStatus(prop.userSession).then(res => res.json()).then(
        (result) => {
                console.log(result);
                if(result.success){
                    let data = result;
                    let flightsData = [];
                    
                    Object.values(data.flight_details).map((element, i) => {
                        // let traveler_details = Object.values(element.traveller_details);
                        // console.log(traveler_details[0]);
                        // let flightDetails = [];
                        // let travelerDetails = []; 
                        // flightDetails.push(element);
                        // travelerDetails.push(...Object.values(traveler_details[0]));
                        flightsData.push(element);
                    });


                    // data.traveller_details.map((element, i) => {
                    //     travelerDetails.push(element);
                    // });

                    console.log("=== flightsData ===", flightsData);
                    
                    setFlightsDatas(flightsData);
                    setDatas(data);
                    setLoading(false);
                    setError(false);
                }
                else{
                    let error = 'Data not found in get payment status api';
                    console.log("== error ==", error);
                    setLoading(true);
                    setError(true);

                    // this.setState({
                    //     loading: false,
                    //     error: error
                    // });
                    new DisplayMessages().errorMsg({error:error}); 
                }
                //this.setAllFlightsData(result);
            
        },
        (error) => {
            console.log("== error ==", error);
            setLoading(true);
            setError(true);
            // new DisplayMessages().errorMsg({error:error});
        }
      );
    }
    else{
      window.location.reload();
    }
  }, []);

  return (
  <div>
  <Row>
      <Col>
          <Row>
              <Col>
                  <Widget className="p-3 pt-4 pb-0">
                  {/* <div className={s.tableTitle}>
                      <div className="headline-2">{prop.userSession}</div>
                      <Dropdown
                      className="d-none d-sm-block"
                      nav
                      isOpen={tableDropdownOpen}
                      toggle={() => tableMenuOpen()}
                      >
                      <DropdownToggle nav>
                          <img className="d-none d-sm-block" src={moreIcon} alt="More..."/>
                      </DropdownToggle>
                      <DropdownMenu >
                          <DropdownItem>
                          <div>Copy</div>
                          </DropdownItem>
                          <DropdownItem>
                          <div>Edit</div>
                          </DropdownItem>
                          <DropdownItem>
                          <div>Delete</div>
                          </DropdownItem>
                      </DropdownMenu>
                      </Dropdown>
                  </div> */}
                  <>{ !loading ? (
                        <>
                            <Grid container>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    {datas.provider_details?.logo != '' ? (<img src={datas.provider_details.logo} width="120px" className="mb-2" />) : (<img src={logo} width="120px" className="mb-2" />)}
                                    
                                    {/* <h6 className="m-0 mb-1" style={{textTransform: 'uppercase', fontWeight: 'bolder'}}>
                                        {datas.provider_details.Brand}
                                    </h6> */}
                                    {/* <p className="m-0 mb-1">Jalalpur Bajar, Nea Uco Bank, Chapra</p>
                                    <p className="m-0 mb-1">Bihar - 841412</p> */}
                                    <p className="m-0 mb-1" style={{textTransform: 'uppercase', fontWeight: 'bolder'}}>
                                        Support : {datas.provider_details.support_contact}/{datas.provider_details.Support_email}
                                    </p>
                                    {/* <p className="m-0 mb-1" style={{textTransform: 'uppercase', fontWeight: 'bolder'}}>
                                        Mobile No : 7903551534
                                    </p> */}
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="text-center d-flex justify-content-center align-items-center">
                                    {/* <ul className="mb-1" style={{display: 'inline-flex', paddingLeft: 0}}>
                                        <li style={{listStyle: 'none'}}><img src="/static/media/6E.b3964e65.gif" width="65px" height="50px" /></li>
                                        <li style={{listStyle: 'none'}}><p className="m-0 mb-1 py-3"><b>INDIGO</b></p></li>
                                    </ul> */}
                                    <div>
                                        <h4 className="m-0" dangerouslySetInnerHTML={{ __html: datas.PNR }}></h4>
                                        <p className="m-0">Airline PNR</p>
                                        <p className="mb-3">
                                            <span class={datas.status+ ' badge badge badge-secondary'}>{datas.status}</span>
                                        </p>
                                    </div>
                                    
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="" style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                                    <div>
                                        <p className="m-0 mb-1">Reference Number : <b>{prop.userSession}</b></p>
                                        {/* <p className="m-0 mb-1">GDS PNR : <b dangerouslySetInnerHTML={{ __html: datas.PNR }}></b></p> */}
                                        <p className="m-0 mb-1">Issued On : <b>{datas.issued_date}</b></p>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            {   
                                flightsDatas.map( (res, i) => 
                                    (<Grid container className="border mb-3" style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="p-2" style={{background: '#00000066', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>
                                            { i === 0 ? (<h4>Onward</h4>) : ( <h4>Return</h4> )}
                                        </Grid>
                                        
                                            {
                                                res.map( e =>
                                                    (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="border-top p-1">
                                                        <FlightDetailsForTickets data={ e } />
                                                    </Grid>))
                                            }
                                    </Grid>)
                                )
                            }

                            <Grid container className="mb-3">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="p-2 border-bottom">
                                    <h4>Passenger Details ( <span style={{fontWeight: '400'}}>Phone : {datas.customer_info.mobile} &nbsp; &nbsp; &nbsp; Email : {datas.customer_info.email}</span> )</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="border-top p-1 px-0" style={{overflow: "auto"}}>
                                    <table className="table table-bordered m-0">
                                        <thead>
                                            <tr>
                                                <th style={{fontSize: '10px'}}>Passenger Name</th>
                                                {/* <th style={{fontSize: '10px'}}>Passenger Type</th>
                                                <th style={{fontSize: '10px'}}>Gender</th> */}
                                                <th style={{fontSize: '10px'}}>Baggage ADDONS</th>
                                                <th style={{fontSize: '10px'}}>Meals ADDONS</th>
                                                <th style={{fontSize: '10px'}}>Seats</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           {
                                                datas.traveller_details.map((e, i) =>
                                                    (<tr>
                                                        <td>{e.pname}</td>
                                                        {/* <td>Adult</td>
                                                        <td>Male</td> */}
                                                        <td>
                                                            {
                                                                e?.baggage?.map((ee, ii) =>{
                                                                    let type = "Onward";
                                                                    if(ii === 1){
                                                                        type = "Return";
                                                                    }
                                                                    
                                                                    if(flightsDatas.length === 1){
                                                                        type = null;
                                                                    }

                                                                    return ee? (<><span>{type? (<b>{type}: </b>): (<></>)}{ee}</span> {ii === 0 ? (<br />): (<></>)}</>) : (<></>)
                                                                })
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                e?.meals?.map((ee, ii) =>{
                                                                    let type = "Onward";
                                                                    if(ii === 1){
                                                                        type = "Return";
                                                                    }
                                                                    if(flightsDatas.length === 1){
                                                                        type = null;
                                                                    }

                                                                    return ee? (<><span>{type? (<b>{type}: </b>): (<></>)}{ee}</span> {ii === 0 ? (<br />): (<></>)}</>) : (<></>)
                                                                })
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                e?.seats?.map((ee, ii) =>{
                                                                    let type = "Onward";
                                                                    if(ii === 1){
                                                                        type = "Return";
                                                                    }
                                                                    if(flightsDatas.length === 1){
                                                                        type = null;
                                                                    }

                                                                    return ee? (<><span>{type? (<b>{type}: </b>): (<></>)}{ee}</span> {ii === 0 ? (<br />): (<></>)}</>) : (<></>)
                                                                })
                                                            }
                                                        </td>
                                                    </tr>)
                                                )
                                           } 
                                            
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>

                            <Grid container className="mb-3">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="p-2 border-bottom">
                                    <h4>Payment Details</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="border-top p-1 px-0" style={{overflow: "auto"}}>
                                    <table className="table table-bordered m-0">
                                        <tbody>
                                            <tr>
                                                <td>Base Fare</td>
                                                <td>{datas.Base_Fare ? '₹'+ datas.Base_Fare : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Taxes & Fees</td>
                                                <td>{datas.tax_fees ? '₹'+ datas.tax_fees : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Addons</td>
                                                <td>{datas.extra ? '₹'+ datas.extra : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Coupon Discount</td>
                                                <td>{datas.coupon_discount ? '- ₹'+ datas.coupon_discount : '- ₹0'}</td>
                                            </tr>
                                            <tr style={{background: '#dee2e6'}}>
                                                <td>Total</td>
                                                <td>₹{datas.total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                            
                        </>
                ) : (<>
                    <Grid container>
                        <Grid item xs={12} className="py-5 text-center">
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </>)}</>
                  </Widget>
              </Col>
          </Row>

      </Col>
  </Row>
</div>
  
  )
}

export default TicketsDetrails;
