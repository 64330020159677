import { Row, Col, Form, Button } from 'react-bootstrap';
import React from "react";

import Services from "../services/services";
import displayMessages from "../displayMessages/displayMessages";

export default function FogerPassword(prop) {
  try{
    const [validatedForget, setValidatedForget] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [valueUsername, setValueUsername] = React.useState('');
    const [ForgetError, setForgetError] = React.useState('none');
    const [ForgetSuccess, setForgetSuccess] = React.useState('none');
    const [LoginSuccess, setLoginSuccess] = React.useState(false);
    const [token, setToken] = React.useState('');
    const [ForgetErrorMsg, setForgetErrorMsg] = React.useState('');
    const [ForgetSuccessMsg, setForgetSuccessMsg] = React.useState('');
  
    const handleSubmitForget = (event) => {
      setForgetSuccess('none');
      setForgetSuccessMsg('');
      //const form = event.currentTarget;
      //console.log("=== form ===", form.checkValidity());
      if (valueUsername && ValidateEmail(valueUsername)) {
        getFormValue();
        console.log("done=== ");
        setLoading(true);
      }
      else{
        event.preventDefault();
        event.stopPropagation();
        setValidatedForget(true);
      }
  
      
    };

    const ValidateEmail = (mail) =>{
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
          return (true)
      }
      else if(IsMobileNumber(mail)){
        return (true)
      }
      //alert("You have entered an invalid email address!");
      setForgetError('unset');
      setForgetErrorMsg("Please enter valid email / mobile no.");
      return (false)
    }

    const IsMobileNumber = (txtMobile) =>{
      var mob = /^[1-9]{1}[0-9]{9}$/;
      //var txtMobile = document.getElementById(txtMobId);
      if (mob.test(txtMobile) == false) {
          // alert("Please enter valid mobile number.");
          // txtMobile.focus();
        //   setForgetError('unset');
        //   setForgetErrorMsg("Please enter valid mobile no.");
          return false;
      }
      return true;
    }


    const getFormValue = ()=>{
      console.log("=== valueUsername ===",valueUsername);
      new Services().forget(valueUsername).then(res => res.json()).then((results) =>{
        console.log("=== forget result ===", results);
        if(results.success){
          setForgetSuccess('unset');
          setForgetSuccessMsg(results.message);
          setForgetError('none');
          setForgetErrorMsg('');
          setValidatedForget(false);
          setLoginSuccess(true);
        //   setToken(results.token);
        //   localStorage.setItem('OoroToken', results.token);
        //   localStorage.setItem('OoroUserData', JSON.stringify(results.message));
        //   console.log("=== localStorage token ===", localStorage.getItem('OoroToken'));
        //   console.log("=== localStorage userData ===", localStorage.getItem('OoroUserData'));
        //   prop.onHandle(false);
        }
        else{
          setForgetSuccess('none');
          setForgetSuccessMsg('');
          setValidatedForget(true);
          setForgetError('unset');
          setForgetErrorMsg(results.message);
        }
      }, (error) => {
        console.log("== error ==", error);
        // new displayMessages().errorMsg({error:error});
      });
    }
  
    const checkLogin = () =>{
      console.log("==== checkForget ===");
      if(LoginSuccess){
        prop.onHandle(true);
      }
    }
  
    return (
      <Form noValidate validated={validatedForget}>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationName" className="position-relative mb-4">
            <Form.Control
              required
              type="text"
              placeholder="Email / Mobile No."
              className="my-big-input pl-35"
              onChange={e => {setValueUsername(e.target.value); setValidatedForget(true); setForgetError('none');}}
            />
            <Form.Control.Feedback type="invalid" className="text-start">
                Please enter valid emial / mobile no.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <p className="text-center mt-5" style={{display: ForgetError, color:'#b10c00'}}> {ForgetErrorMsg} </p>
        <p className="text-center mt-5" style={{display: ForgetSuccess, color:'green'}}> {ForgetSuccessMsg} </p>
        <Button variant="contained" className="my-default-btn w-100-per mt-1" onClick={handleSubmitForget}>
            Forget Password
        </Button>
      </Form>
    );
  } catch (error) {
    throw new Error(error);
  }
}