
import * as jwtEncrypt from 'jwt-token-encrypt';
import sign from "jwt-encode";


const encryption = {
    key: 'AAAAAAAAAAAAAA',
    algorithm: 'aes-256-cbc',
};

const jwtDetails = {
    secret: '1234567890', // to sign the token
    // Default values that will be automatically applied unless specified.
    // algorithm: 'HS256',
    // expiresIn: '12h',
    // notBefore: '0s',
    // Other optional values
    key: 'ooro',// is used as ISS but can be named iss too
};

const secret = 'secret';




export default class JWT {

    
    async generateToken(privateData, publicData, type = null){
        //let token;
        let generateToken = await jwtEncrypt.generateJWT(
            jwtDetails,
            publicData,
            encryption,
            privateData,
            type
        );
        
        //console.log("=== generateToken ===", generateToken);
        //console.log("=== token ===", token);
        return generateToken;
    }

    signed(data){
        return sign(data, secret);
    }

    decodeToken(token){
        return jwtEncrypt.readJWT(token, encryption);
    }

    decodeTokenForPrivate(token){
        return jwtEncrypt.readJWT(token, encryption, 'session');
    }

}