// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import plane1 from '../../assets/Icons/b-jal-a-20191228-200x200.jpg';
import plane2 from '../../assets/Icons/hawaiian-air-200x200.jpg';
import plane3 from '../../assets/Icons/plane-box-200x200-01-e1569402328635.png';

// import library
import React, {Component} from "react";
import Slider from "react-slick";
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0
};

class SupperOffer extends Component {
    

    componentDidMount(){
        
    };

    render(){
        try{
            return(
                <div className={this.props.className}>
                    <Grid container className="s-offer xl-device-block mb-5">
                        <Grid item xs className="px-4 mx-2">
                            <Card className="my-card p-3 border-radius">
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <span className="h2 h"><b>Supper Offers </b></span> 
                                            <ul className="sf-list">
                                                <li className="active">All Offers</li>
                                                {/* <li>Donestic Flights</li>
                                                <li>Inaternational Flights</li>
                                                <li className="more">More</li> */}
                                            </ul>
                                        </Grid>
                                        {/* <Grid item xs={1}>
                                            <Button variant="contained" type="button" className="shadow my-success-btn color-w bg-gradient border-0 pd-10 float-right next">
                                                <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                            </Button>
                                            <Button variant="contained" type="button" className="shadow my-success-btn disabled color-w bg-gradient border-0 pd-10 prev">
                                                <ArrowBackIosIcon></ArrowBackIosIcon>
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                    <Slider {...settings} container className="s-offer-slider">
                                        <div className="position-relative w-390-px mr-45-px">
                                        <Card className="my-card px-3 mt-5 border-radius box-shadow">
                                        <img src={plane2} className="size-200-200 border-radius box-shadow position-absolute n-img" />
                                        <p className="position-absolute text-danger n-text" style={{fontSize: '10px'}}>Offer end in 0d 1h 10m</p>
                                        <Button className="position-absolute bg-gradient border-0 btn-footer">Book Now</Button>
                                            <CardContent>
                                            <Grid container>
                                                <Grid item xs={4} ></Grid>
                                                <Grid item xs={8} className="pl-3" >
                                                    <p className="h5 h font-15">Flat 10% instant Discount on Domestic Flights</p>
                                                    <p className="s-p h5 p font-15">Exclusive offer for Creadit and Debit Cards!</p>
                                                </Grid>
                                            </Grid>
                                            </CardContent>
                                            <CardActions>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <p className="h5"><b>Coupon:FLYNOW</b></p>
                                                </Grid>
                                            </Grid>
                                            </CardActions>
                                        </Card>
                                        </div>
                                        <div className="position-relative w-390-px mr-45-px">
                                        <Card className="my-card px-3 mt-5 border-radius box-shadow">
                                        <img src={plane1} className="size-200-200 border-radius box-shadow position-absolute n-img" />
                                        <p className="position-absolute text-danger n-text" style={{fontSize: '10px'}}>Offer end in 0d 1h 10m</p>
                                        <Button className="position-absolute bg-gradient border-0 btn-footer">Book Now</Button>
                                            <CardContent>
                                            <Grid container>
                                                <Grid item xs={4} ></Grid>
                                                <Grid item xs={8} className="pl-3">
                                                    <p className="h5 h font-15">Flat 20% instant Discount on Domestic Flights</p>
                                                    <p className="s-p h5 p font-15">Exclusive offer for Creadit and Debit Cards!</p>
                                                </Grid>
                                            </Grid>
                                            </CardContent>
                                            <CardActions>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <p className="h5"><b>Coupon:FLYNOW</b></p>
                                                </Grid>
                                            </Grid>
                                            </CardActions>
                                        </Card>
                                        </div>
                                        <div className="position-relative w-390-px mr-45-px">
                                        <Card className="my-card px-3 mt-5 border-radius box-shadow">
                                        <img src={plane3} className="size-200-200 border-radius box-shadow position-absolute n-img" />
                                        <p className="position-absolute text-danger n-text" style={{fontSize: '10px'}}>Offer end in 0d 1h 10m</p>
                                        <Button className="position-absolute bg-gradient border-0 btn-footer">Book Now</Button>
                                            <CardContent>
                                            <Grid container>
                                                <Grid item xs={4} ></Grid>
                                                <Grid item xs={8} className="pl-3">
                                                    <p className="h5 h font-15">Flat 30% instant Discount on Domestic Flights</p>
                                                    <p className="s-p h5 p font-15">Exclusive offer for Creadit and Debit Cards!</p>
                                                </Grid>
                                            </Grid>
                                            </CardContent>
                                            <CardActions>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <p className="h5"><b>Coupon:FLYNOW</b></p>
                                                </Grid>
                                            </Grid>
                                            </CardActions>
                                        </Card>
                                        </div>
                                        <div className="position-relative w-390-px mr-45-px">
                                        <Card className="my-card px-3 mt-5 border-radius box-shadow">
                                        <img src={plane2} className="size-200-200 border-radius box-shadow position-absolute n-img" />
                                        <p className="position-absolute text-danger n-text" style={{fontSize: '10px'}}>Offer end in 0d 1h 10m</p>
                                        <Button className="position-absolute bg-gradient border-0 btn-footer">Book Now</Button>
                                            <CardContent>
                                            <Grid container>
                                                <Grid item xs={4} ></Grid>
                                                <Grid item xs={8} className="pl-3" >
                                                    <p className="h5 h font-15">Flat 10% instant Discount on Domestic Flights</p>
                                                    <p className="s-p h5 p font-15">Exclusive offer for Creadit and Debit Cards!</p>
                                                </Grid>
                                            </Grid>
                                            </CardContent>
                                            <CardActions>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <p className="h5"><b>Coupon:FLYNOW</b></p>
                                                </Grid>
                                            </Grid>
                                            </CardActions>
                                        </Card>
                                        </div>
                                        <div className="position-relative w-390-px mr-45-px">
                                        <Card className="my-card px-3 mt-5 border-radius box-shadow">
                                        <img src={plane1} className="size-200-200 border-radius box-shadow position-absolute n-img" />
                                        <p className="position-absolute text-danger n-text" style={{fontSize: '10px'}}>Offer end in 0d 1h 10m</p>
                                        <Button className="position-absolute bg-gradient border-0 btn-footer">Book Now</Button>
                                            <CardContent>
                                            <Grid container>
                                                <Grid item xs={4} ></Grid>
                                                <Grid item xs={8} className="pl-3">
                                                    <p className="h5 h font-15">Flat 20% instant Discount on Domestic Flights</p>
                                                    <p className="s-p h5 p font-15">Exclusive offer for Creadit and Debit Cards!</p>
                                                </Grid>
                                            </Grid>
                                            </CardContent>
                                            <CardActions>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <p className="h5"><b>Coupon:FLYNOW</b></p>
                                                </Grid>
                                            </Grid>
                                            </CardActions>
                                        </Card>
                                        </div>
                                        <div className="position-relative w-390-px mr-45-px">
                                        <Card className="my-card px-3 mt-5 border-radius box-shadow">
                                        <img src={plane3} className="size-200-200 border-radius box-shadow position-absolute n-img" />
                                        <p className="position-absolute text-danger n-text" style={{fontSize: '10px'}}>Offer end in 0d 1h 10m</p>
                                        <Button className="position-absolute bg-gradient border-0 btn-footer">Book Now</Button>
                                            <CardContent>
                                            <Grid container>
                                                <Grid item xs={4} ></Grid>
                                                <Grid item xs={8} className="pl-3">
                                                    <p className="h5 h font-15">Flat 30% instant Discount on Domestic Flights</p>
                                                    <p className="s-p h5 p font-15">Exclusive offer for Creadit and Debit Cards!</p>
                                                </Grid>
                                            </Grid>
                                            </CardContent>
                                            <CardActions>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <p className="h5"><b>Coupon:FLYNOW</b></p>
                                                </Grid>
                                            </Grid>
                                            </CardActions>
                                        </Card>
                                        </div>
                                    </Slider>
                                </CardContent>
                                <CardActions></CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default SupperOffer;