// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';

// import library
import React, {Component} from "react";
import { Row, Form, Button } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from "moment";
import Services from "../services/services";
import DisplayMessages from "../displayMessages/displayMessages";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const monthIndex = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

const Price = (prop) => {
	let day = new Date(prop.day);
	let yy = moment(day).format('YYYY');
	let mm = moment(day).format('MM');
	let dd = moment(day).format('DD');
	  // console.log(dd);
	  // console.log(mm);
	  // console.log(yy);
	let price = prop.price;
	//console.log(this.state.price);
	let yData = [];
	let mData = [];
	let dData = [];

	yData = price.filter(f => f.year == yy);
	if(yData.length > 0){
	  mData = yData[0]?.data.filter(f => f.month == mm);
	}
	if(mData.length > 0){
	  dData = mData[0]?.data.filter(f => f.day == dd);
	}
	
	if(dData.length > 0){
	  return  <span>₹ {dData[0].price}</span>;
	}else{
	  return <>N/A</>;
	}
};

class VerticalCalendarForMobile extends Component {

    constructor(props) {
		super(props);
		let getM;
		let getM1;
		let getD;
		let getD1;
		
		var today = new Date();
		var today1 = new Date();
		
		today1.setDate(today1.getDate() + 7);
        
		let date = {month :(today.getMonth()), date :today.getDate(), year :today.getFullYear()};
        let date1 = {month :(today1.getMonth()), date :today1.getDate(), year :today1.getFullYear()};
		
		if((today.getMonth() +1) < 10){
			getM = '0' + (today.getMonth() +1);
		}
		else{
			getM = (today.getMonth() +1);
		}

		if(today.getDate() < 10){
			getD = '0' + today.getDate();
		}
		else{
			getD = today.getDate();
		}

		if((today1.getMonth() +1) < 10){
			getM1 = '0' + (today1.getMonth() +1);
		}
		else{
			getM1 = (today1.getMonth() +1);
		}

		if(today1.getDate() < 10){
			getD1 = '0' + today1.getDate();
		}
		else{
			getD1 = today1.getDate();
		}
        
		let dateStr = today.getFullYear() +'-'+ getM +'-'+ getD;
        let dateStr1 = today1.getFullYear() +'-'+ getM1 +'-'+ getD1;
		
		this.state = {
			error: null,
			isLoaded: false,
			trip:props.data.trip,
			toSelectedAirport: props.data.to,
			fromSelectedAirport: props.data.from,
			allAirportsData: [],
			T10India: [],
			fromPlace: [],
			toPlace: [],
			toDropdown:false,
			fromDropdown:false,
			depart:false,
			departDate:props.data.departDate,
			departDateStr:props.data.departDateStr,
			return:false,
			returnDate:props.data.returnDate,
			returnDateStr:props.data.returnDateStr,
			travellers:false,
			travellersData:props.data.travellersData			,
			oneWayClass: "hr",
			roundTripClass: "",
			directFlight:props.data.directFlight,
			redirect:false,
			finelData:{},
			isBtnLoader:false,
			allFlights:[],
			returnAllFlights:[],
			price: [{year: `${today.getFullYear()}`, data: []}]
		};
		//console.log(this.state.departDateStr);
	}

    setDepartDateByFareData = (date) =>{
		console.log("** ddddd ***",date);
		try{
			let dep = '';
			let depObj = {};
			let ret = '';
			let retObj = {};
			if(this.state.trip === 0){
				let sDate = date;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				dep = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				depObj = date1;
				var td2 = new Date(sDate);
				td2.setDate(td2.getDate() + 7);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				ret = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				retObj = date2;
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					depart:false,
					returnDate :date2,
					returnDateStr :dStr2,
					return:false,
					travellers:true
				});
			}
			else{
				let sDate = date.from;
				let sDate2 = date.to;
				dep = date.from;
				ret = date.to;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				var td2 = new Date(sDate2);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					depart:false,
					returnDate :date2,
					returnDateStr :dStr2,
					return:false,
					travellers:true
				});
				
				
				
				
			}

			this.searchByFareDates(dep, depObj, ret, retObj);
		} catch (error) {
			throw new Error(error);
		}
		
	}

	searchByFareDates = (dep, depObj, ret, retObj) =>{
		try{
			let finelData = {
				trip:this.state.trip,
				fromSelectedAirport:this.state.fromSelectedAirport,
				toSelectedAirport:this.state.toSelectedAirport,
				departDate:depObj,
				departDateStr:dep,
				returnDate:retObj,
				returnDateStr:ret,
				travellersData:this.state.travellersData,
				directFlight:this.state.directFlight,
				oneWayClass:this.state.oneWayClass,
				roundTripClass:this.state.roundTripClass
			};

			localStorage.setItem("lastSearch", JSON.stringify(finelData));
			let lastSearch = localStorage.getItem("lastSearch");
			console.log(JSON.parse(lastSearch));

			let searchData = finelData;
			//let url = '/search-results?';
			let directFlight = 0;
			if(searchData.directFlight == true){
				directFlight = 1;
			}
			
			let url = '/search/'+searchData.fromSelectedAirport.ooro_AirportCode+'/'+searchData.toSelectedAirport.ooro_AirportCode+'/'+searchData.departDateStr+'/'+searchData.returnDateStr+'/'+searchData.travellersData.adult+'/'+searchData.travellersData.children+'/'+searchData.travellersData.infants+'/'+searchData.trip+'/'+directFlight+'/'+searchData.travellersData.seatClassesData.id;

			console.log('--- final url ---',url);
			
			this.props.onHandel(url);
		} catch (error) {
			throw new Error(error);
		}
	}

	setFareCalenderData = (from, to) =>{
		try{
			let ddd = new Date();
			let today = new Date();
			for (let index = 1; index < 6; index++) {
				let mmm = monthIndex[ddd.getMonth()];
				
				let data = {
					"Destination": to.ooro_AirportCode,
					"Month": mmm,
					"Origin": from.ooro_AirportCode,
					"Year": `${ddd.getFullYear()}`
				}
				new Services().fareCalender(data).then(res => res.json()).then(
					(result) => {
						console.log("fareCalender Data ", data, result);
						
						let priceData = []; 
						if(result.LowFares?.length > 0){
							result.LowFares.map(r =>{
								let d = r.TravelDate.split('/')[1];
								let amount = r.Amount;
								priceData.push({day: d, price: amount});
							});
						}

						let price = this.state.price;
						let priceYear = price.filter(r => r.year == ddd.getFullYear());

						if(priceYear.length > 0){
							price.map(e =>{
								if(e.year == ddd.getFullYear()){
									e.data.push({month: mmm, data: priceData});
								}
							});
						}
						else{
							price.push({year: ddd.getFullYear(), data: [{month: mmm, data: priceData}]});
						}

						console.log("=== price ===", price);
						this.setState({price: price});
					},
					(error) => {
						console.log("== error ==", error);
						// new DisplayMessages().errorMsg({error:error});
					}
				);
				ddd.setMonth(ddd.getMonth() + 1);
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	componentDidMount = () =>{
		let to = this.props?.data?.to;
		let from = this.props?.data?.from;

		this.setFareCalenderData(from, to);
	}

	scrollSection(){
		let element = document.getElementById('active-date');
		console.log("=== scrollSection for mobile ===", element);
		element?.scrollIntoView(
			{
				// behavior: smooth | auto;
				// block: 'center',
				inline: "center"
				// inline: start | center | end | nearest;
			}
		);
	}

    render(){
		try{
			let today = new Date();
			let slideData = [];

			for (let index = 1; index < 60; index++) {
				let cDate = moment(today).format('ddd, DD MMM');
				let cDateStr = moment(today).format('YYYY-MM-DD');
				let className = 'calendar-element px-3 xs-ps-1 xs-pe-1 text-center float-left';
				if(index > 1){
					className += ' border-left';
				}


				let activeClass = '';
				if(this.state?.departDateStr === cDateStr){
					activeClass = 'active-date';
				}

				slideData.push(
					<div id={activeClass} className={`${activeClass} ${className}`} style={{width:'125px', cursor: 'pointer'}} onClick={()=>this.setDepartDateByFareData(cDateStr)}>
						<h3 className="p p-1 m-0">{cDate}</h3>
						<h4 className="p-1 m-0"><Price price={this.state.price} day={cDateStr} /></h4>
					</div>
				);
				today.setDate(today.getDate() + 1);
			}

			let className = "vertical-calendar ";
			className += this.props.classes;
			return(
				<div className={className}>
					<Card className="vertical-calendar-card my-card position-relative" style={{borderRadius:'0px'}}>
						<Button className="position-absolute bg-gradient p-0" style={{height:'100%',width:'25px',left:'0',borderRadius:'0',border:'0px solid black', zIndex: '10'}}>
							<div className="rotation-90" style={{height: 15, width: 48, marginBottom: -13, marginLeft: -12, fontSize: 11}}>CALENDAR</div>
						</Button>
						<CardContent className="py-0 pb-1 pe-0 position-relative" style={{overflow:'auto'}}>
						<div className="calendar py-3 ps-4 pe-1 xs-ps-1 xs-pe-1" style={{width:'3850px'}} id={'calender-section'}>
							{slideData}
							{/* <div className="calendar-element px-3 xs-ps-1 xs-pe-1 text-center float-left" style={{width:'125px'}}>
								<h3 className="p p-1 m-0">Sun,17</h3>
								<h4 className="p-1 m-0">₹ 5200</h4>
							</div>
							<div className="calendar-element px-3 xs-ps-1 xs-pe-1 text-center border-left float-left" style={{width:'125px'}}>
								<h3 className="p p-1 m-0">Sun,17</h3>
								<h4 className="p-1 m-0">₹ 5200</h4>
							</div> */}
						</div>	
						</CardContent>
						{/* <CardActions>
							
						</CardActions> */}

						{this.scrollSection()}
					</Card>
				</div>
			);
		} catch (error) {
			throw new Error(error);
		}
    }
}

export default VerticalCalendarForMobile;