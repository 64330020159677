// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ShareIcon from '@material-ui/icons/Share';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { NavLink } from 'react-router-dom';
import { ArrowBack, ArrowForward, ArrowLeft, Filter, FilterList, Link } from "@material-ui/icons";
import Services from "../services/services";
import multiply from "../../assets/Icons/multiply.svg";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


class VerticalSearchDisplayCardForMobile extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:props.data,
            shareModel: false,
            url: null
        }
    }

    onClick = () =>{
        this.props.onHandel(true);
    }

    back = () => {
        window.history.back();
    }

    toggleShareModel = () => this.setState({shareModel: !this.state.shareModel});

    share = () =>{
        try{
            let url = window.location.href;
            new Services().urlShortner(url).then(res => res.json().then(res => {
                console.log(res);
                this.setState({url: res.url});
                this.toggleShareModel();
            }, (error) =>{
                console.log("== error ==", error);
            }));
        } catch (error) {
            throw new Error(error);
        }
    }

    copyToClipboard = () => {
        try{
            const elem = document.createElement('textarea');
            elem.value = this.state.url;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
        } catch (error) {
            throw new Error(error);
        }
    }

    render(){
        try{
            let style = {display:'none'};
            let returnStyle = {display:'none'};
            let departureStyle = {display:'none'};
            if(this.props.visible){
                style = {display:''};
            }
            if(this.props.data.trip){
                returnStyle = {display:''};
            }
            else{
                departureStyle = {display: ''}
            }
            let data = this.props.data;
            //   let dd =  new Date(data.departDateStr);
            //   let departDay =  dd.getDay();
            let className = "VarticalSearchDisplayCard " + this.props.className;
            let arrowStyle = {...departureStyle};
            let swapStyle = {...returnStyle};
            return(
                <div className={className}>
                    <Modal size="lg" dialogClassName="modal-95w xs-m-0" centered show={this.state.shareModel} onHide={this.toggleShareModel} 
                    backdrop="static" keyboard={false}
                    >
                        <img src={multiply} className="position-absolute a" width="20px" style={{top:12, right:5, zIndex: 10}} onClick={this.toggleShareModel} />
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">
                                <div className="row p-3">
                                    <h1>Copy Link</h1>
                                    <div className="col-9">
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text><Link /></InputGroup.Text>
                                            <FormControl id="inlineFormInputGroup" value={this.state.url} />
                                        </InputGroup>
                                    </div>
                                    <div className="col-3">
                                        <Button variant="outline-success" onClick={this.copyToClipboard}>Copy</Button>
                                    </div>
                                </div>
                            </card>
                        </Modal.Body>
                    </Modal>
                    <Card className="search-card my-card box-shadow xs-mt-0" style={{borderRadius: '0px'}}>
                        <Grid container className="py-3 bg-gradient color-w">
                            <Grid item xs={1} className="px-2 py-1 ps-1">
                                <span onClick={this.back}>
                                    <ArrowBack></ArrowBack>
                                </span>
                            </Grid>
                            <Grid item xs={9} className="px-2 py-1 pt-0 border" onClick={()=> this.props.openSearchModel(true)} style={{borderRadius: '5px', fontSize: '11px'}}>
                            <span>{data.from.ooro_AirportCode} <span style={arrowStyle}> <ArrowForward style={{width: '17px', height: '17px'}}></ArrowForward> </span> <span style={swapStyle}> <SwapHorizIcon style={{width: '17px', height: '17px'}}></SwapHorizIcon> </span></span> <span>{data.to.ooro_AirportCode} <span style={{fontSize: '16px'}}> . </span> </span> <span style={arrowStyle}> {data.departDate.date} {monthNames[(data.departDate.month)]} <span style={{fontSize: '16px'}}> . </span> </span> <span style={returnStyle}> {data.departDate.date} {monthNames[(data.departDate.month)]} <span style={{fontWeight:'bold'}}>-</span> {data.returnDate.date} {monthNames[(data.returnDate.month)]} <span style={{fontSize: '25px'}}> . </span> </span> <span> {data.travellersData.adult + data.travellersData.children + data.travellersData.infants} Travellers</span>
                            </Grid>
                            <Grid item xs={2} className="px-2 py-1 text-center">
                                <span onClick={() => this.props.openFilter(true)}>
                                    <FilterList />
                                </span>
                                <span onClick={this.share}>
                                    <ShareIcon></ShareIcon>
                                </span>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default VerticalSearchDisplayCardForMobile;