import React, { Component } from 'react'
import { Input, Form, Radio } from 'semantic-ui-react'
import Grid from '@material-ui/core/Grid';
import { Row, Button } from 'react-bootstrap';



export default class RedioGroup extends Component {
    state = {
        value: this.props.defaultValue
    }
    handleChange = (e, { value }) => {
    
      this.setState({ value });

      console.log(this.props.datas);
      let res = this.props.datas.filter(r => r.id == value)
      this.props.onHandel(res)
    }

  
    render() {
      try{
        let item = [];
        this.props.datas.forEach(element => {
          item.push(<Form.Field>
            <Radio
              label={element.name}
              name='radioGroup'
              value={element.id}
              checked={this.state.value == element.id}
              onChange={this.handleChange}
            />
          </Form.Field>);
        });
        return (
          <Form className="p-3">
            {item}
            {/* <Form.Field>
              <Radio
                label='Economy'
                name='radioGroup'
                value='1'
                checked={this.state.value == '1'}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Premium Economy'
                name='radioGroup'
                value='2'
                checked={this.state.value == '2'}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Business'
                name='radioGroup'
                value='3'
                checked={this.state.value == '3'}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='First Class'
                name='radioGroup'
                value='4'
                checked={this.state.value == '4'}
                onChange={this.handleChange}
              />
            </Form.Field> */}
          </Form>
        );
      } catch (error) {
        throw new Error(error);
      }
    }
  }