import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from "@material-ui/lab";
import SomeThingWentWrong from "../../shared/component/SomeThingWentWrong";
import DisplayMessages from "../../shared/displayMessages/displayMessages";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { msg: '', open: false, info: null, hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ open: true, msg: error.message, info, hasError: true });
    // You can also log the error to an error reporting service
    console.error("=== error boundry ===", error, info);
  }

  render() {
    if (this.state.hasError) {
      // window.alert(this.state.msg);
      let err = {message: `${this.state.msg} at ${window.location.href}`, data: this.state.info, status: 500, type: 'INTERNAL_SERVER_ERROR'}
      new DisplayMessages().errorMsg({error: err});
      // You can render any custom fallback UI
      return <>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={() => this.setState({open: false})}
        >
          <Alert onClose={() => this.setState({open: false})} severity="error">
            <h5>{this.state.msg}</h5>
          </Alert>
        </Snackbar>
        <SomeThingWentWrong />
      </>;
    }
    else{
      return this.props.children;
    }
  }
}