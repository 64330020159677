import "./TravelStoryDetails.scss";
import "./TravelStoryDetails.css";
import Header from '../../../shared/component/Header.js';
import LeaveComment from '../../../shared/component/LeaveComment.js';
import LiBlock from '../../../shared/component/LiBlock.js';
import Forms from '../../../shared/component/Form.js';
import banner from '../../../assets/Icons/june1.jpg';
import Grid from '@material-ui/core/Grid';
import CustomCalender from "../../../shared/component/CustomCalender";
//import Calendar from "../../../shared/component/calender/Calendar";
//import Input from '../../../shared/component/Input.js';

const checkLogin = (status) =>{
    //this.setState({loggedIn: status});
}

function TravelStoryDetails(prop) {
    try{
        return (
        <div className="TravelStoryDetails">
            <Header onLoggedIn={checkLogin} openSideBar={(status) =>prop.openSideBar(status)} nav={prop.nav}  />
            <img src={banner} className="w-100 banner" />
            {/* <CustomCalender /> */}
            <Grid container className="p-5 pb-0">
                <Grid item xs={7} className="ps-5 pe-3">
                    <p className="p">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
                    It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                    <p className="p">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
                    </p>
                    <p className="p">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                    </p>
                    <p className="p">
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                    </p>
                    <h3 className="mt-5 mb-2 pt-3">
                        Just check out these 12 places in Kerala and it won`t be long before Your purchasing your flight ticket.
                    </h3>
                </Grid>
                <Grid item xs={5} className="pe-5 ps-3">
                    <Forms></Forms>
                </Grid>
            </Grid>
            <Grid container className="p-2 pt-4">
                <Grid item xs={12} className="">
                    <hr className="m-0" />
                </Grid>
            </Grid>
            <Grid container className="px-5 pt-3 pb-0">
                <Grid item xs={7} className="">
                    <LeaveComment />
                </Grid>
            </Grid>
            <Grid container className="p-5" style={{backgroundColor:'#F8F8EE'}}>
                <Grid item xs={12} className="px-5 ms-3">
                    <Grid container className="">
                        <Grid item xs={3} className="">
                            <LiBlock heading="International Flight" />
                        </Grid>
                        <Grid item xs={3} className="">
                            <LiBlock heading="Domestic Flight" />
                        </Grid>
                        <Grid item xs={3} className="">
                            <LiBlock heading="Holiday" />
                        </Grid>
                        <Grid item xs={3} className="">
                            <LiBlock heading="Visa" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        );
    } catch (error) {
        throw new Error(error);
    }
}

export default TravelStoryDetails;