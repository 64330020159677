
import Grid from '@material-ui/core/Grid';
import LiBlock from './LiBlock';

function FooterLink(prop) {
    try{
        let cols = [];
        let keys = Object.keys(prop.data);
        let values = Object.values(prop.data);

        keys.map((e, i) =>{

            let heading = e.replace(/_/g, " ");
            cols.push( <Grid item xs={3} className="">
            <LiBlock heading={heading} data={values[i]} />
        </Grid>);

        });

        return (
            <Grid container className="p-5 sm-device-block md-device-block lg-device-block xl-device-block" style={{backgroundColor:'#1b1c1d'}}>
                <Grid item xs={12} className="px-5 ms-3 main-container">
                    <Grid container className="">
                        {cols}
                    </Grid>
                </Grid>
            </Grid>
        );
    } catch (error) {
        throw new Error(error);
    }
  }
  
  export default FooterLink;