// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
// import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { NavLink, Redirect } from 'react-router-dom';
import TravellersDropdown from "../ui/TravellersDropdown";
import CustomDropdown from "../ui/Dropdown";
import Calender from "./Calender";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Services from "../services/services";
import displayMessages from "../displayMessages/displayMessages";

import { allFlightsData } from '../../assets/flights';
import { allAirlinesData } from '../../assets/allAirlines';
import CustomCalender from "./CustomCalender";
import moment from "moment";

const HandleSearch = (prop) => {
	if(prop.status){
		return <CircularProgress color="secondary" className="btn-spinner" />;
	}
	else{
		return <FlightIcon className="spin-animation"></FlightIcon>;
	}
}


const TextDiv = (prop) =>(
	<Grid container className="w-100">
	  <Grid item xs={10}>
		<div>
		  <p className="p-0 m-0"><b>{prop.heading}</b></p>
		  <p className="sm-p p-0 m-0">{prop.desc}</p>
		</div>
	  </Grid>
	  <Grid item xs={2} className="text-right">
		<p className="p-0 m-0"><b>{prop.shortForm}</b></p>
	  </Grid>
	</Grid>
  )
  
  const Heading = (prop) => (
	<span>{prop.state}, {prop.country}</span>
  )


  const DisplayAirport = (prop) =>{
	  console.log(prop);
	if(prop.if && prop.if != ''){
		return(<>{prop.if}</>)
	}
	else{
		return(<>{prop.else}</>)
	}
  }
  
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];

  const monthIndex = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  const DisplayReturn = (prop) => {
	if(prop.trip){
		return(
			<>
			<span className="h3 bold" style={{lineHeight: '1px'}}>{prop.returnDate.date} {monthNames[(prop.returnDate.month)]}</span>
								
			<p className="sm-p h" style={{lineHeight: '20px'}}>{prop.returnDate.year}</p>
			</>
		)
	}
	else{
		return(
			<>
			<p className="sm-p h mt-n2" style={{lineHeight: '10px'}}> Book a round trip to save more </p>
							
			<p className="sm-p h" style={{lineHeight: '1px'}}>{prop.returnDate.year}</p>
			</>
		)
	}
  }

class VarticalSearchCard extends React.Component {
	constructor(props) {
		super(props);
		let getM;
		let getM1;
		let getD;
		let getD1;
		
		var today = new Date();
		var today1 = new Date();
		
		today1.setDate(today1.getDate() + 7);
        
		let date = {month :(today.getMonth()), date :today.getDate(), year :today.getFullYear()};
        let date1 = {month :(today1.getMonth()), date :today1.getDate(), year :today1.getFullYear()};
		
		if((today.getMonth() +1) < 10){
			getM = '0' + (today.getMonth() +1);
		}
		else{
			getM = (today.getMonth() +1);
		}

		if(today.getDate() < 10){
			getD = '0' + today.getDate();
		}
		else{
			getD = today.getDate();
		}

		if((today1.getMonth() +1) < 10){
			getM1 = '0' + (today1.getMonth() +1);
		}
		else{
			getM1 = (today1.getMonth() +1);
		}

		if(today1.getDate() < 10){
			getD1 = '0' + today1.getDate();
		}
		else{
			getD1 = today1.getDate();
		}
        
		let dateStr = today.getFullYear() +'-'+ getM +'-'+ getD;
        let dateStr1 = today1.getFullYear() +'-'+ getM1 +'-'+ getD1;
		
		this.state = {
			error: null,
			isLoaded: false,
			trip:props.data.trip,
			toSelectedAirport: props.data.to,
			fromSelectedAirport: props.data.from,
			allAirportsData: [],
			T10India: [],
			fromPlace: [],
			toPlace: [],
			toDropdown:false,
			fromDropdown:false,
			depart:false,
			departDate:props.data.departDate,
			departDateStr:props.data.departDateStr,
			return:false,
			returnDate:props.data.returnDate,
			returnDateStr:props.data.returnDateStr,
			travellers:false,
			travellersData:props.data.travellersData			,
			oneWayClass: "hr",
			roundTripClass: "",
			directFlight:props.data.directFlight,
			redirect:false,
			finelData:{},
			isBtnLoader:false,
			allFlights:[],
			returnAllFlights:[],
			price: [{year: `${today.getFullYear()}`, data: []}]
		};
		//console.log(this.state.departDateStr);
	}

	c = 6017;

	price = [{
		year:'2021',
		data: [
			{
				month:'06',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'07',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'08',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'09',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'10',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'11',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
		]
	}];
	
	componentDidMount() {
		try{
			if(this.state.trip){
				this.roundTrip()
			}
			
			let data = [{name:'getAll', value:'1'}]

			new Services().getAllAirports(data).then(res => res.json()).then(
				(result) => {
					this.setState({
						isLoaded: true,
						allAirportsData: result.message,
						T10India: result.T10India
					});
					console.log(this.state.allAirportsData);
					console.log(this.state.T10India);
				},
				(error) => {
					console.log("== error ==", error);
					this.setState({
						isLoaded: true,
						error
					});
					// new displayMessages().errorMsg({error:error});
				}
			);
			let to = this.props.data.to;
			let from = this.props.data.from;

			this.setFareCalenderData(from, to);
		} catch (error) {
			throw new Error(error);
		}
	}

	clearDropdown = () =>{
		this.setState({
			depart: false, toDropdown:false, fromDropdown:false, travellers:false, return:false
		})
	}

	toggleDepart = () => setTimeout(() => {
		this.setState({ depart: !this.state.depart, return: false, toDropdown:false, fromDropdown:false, travellers:false })
	}, 50);

	toggleReturn = () => setTimeout(() => {
		this.setState({ return: !this.state.return, depart: false, toDropdown:false, fromDropdown:false, travellers:false })
	}, 50);
	
	toggleTravellers = () => setTimeout(() => {
		this.setState({ travellers: !this.state.travellers, toDropdown:false, fromDropdown:false, depart:false, return: false, })
	}, 50);
	
	toggleToDropdown = () => setTimeout(() => {
		this.setState({ toDropdown: !this.state.toDropdown, fromDropdown:false, depart:false, travellers:false, return: false, })
	}, 50);

	toggleFromDropdown = () => setTimeout(() => {
		this.setState({ fromDropdown: !this.state.fromDropdown, toDropdown:false, depart:false, travellers:false })
	}, 50);

	fromSelectedAirport = (airportData) =>{
		console.log('--- from airport data --- ',airportData);
		this.setState({
			fromSelectedAirport: airportData,
			fromDropdown : false,
			toDropdown : true
		});

		let from = airportData;
		let to = this.state.toSelectedAirport;

		this.setFareCalenderData(from, to);
	}

	handelReturn = () =>{
		if(this.state.trip){
			this.toggleReturn();
		}
		else{
			this.roundTrip();
			this.toggleReturn();
		}
	}

	toSelectedAirport = (airportData) =>{
		console.log('--- to airport data --- ',airportData);
		this.setState({
			toSelectedAirport: airportData,
			toDropdown : false,
			depart:true
		});

		let to = airportData;
		let from = this.state.fromSelectedAirport;

		this.setFareCalenderData(from, to);
	}

	switchToFrom = () =>{
		let tempTo = this.state.toSelectedAirport;
		this.setState({
			toSelectedAirport:this.state.fromSelectedAirport,
			fromSelectedAirport:tempTo,
		});
	}

	setDepartDate = (date) =>{
		console.log("** ddddd ***",date);
		try{
			if(this.state.trip === 0){
				let sDate = date;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				var td2 = new Date(sDate);
				td2.setDate(td2.getDate() + 7);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					depart:false,
					returnDate :date2,
					returnDateStr :dStr2,
					return:false,
					travellers:true
				});
			}
			else{
				let sDate = date.from;
				let sDate2 = date.to;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				var td2 = new Date(sDate2);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					depart:false,
					returnDate :date2,
					returnDateStr :dStr2,
					return:false,
					travellers:true
				});
				
				
				
				
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	setReturnDate = (date) =>{
		console.log(date);
		try{
			let sDate;
			date.forEach(element => {
				sDate = element
				console.log(element);
			});
			var td1 = new Date(sDate);
			var dStr1 = sDate;
			var date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
			// var td2 = new Date(sDate);
			// td2.setDate(td2.getDate() + 7);
			// let getD = td2.getDate();
			// if(td2.getDate() < 10){
			// 	getD = '0'+td2.getDate();
			// }
			// let getM = (td2.getMonth()+1);
			// if((td2.getMonth()+1) < 10){
			// 	getM = '0'+(td2.getMonth()+1);
			// }
			// let dStr2 = td2.getFullYear() +'-'+ getM +'-'+ getD;
			// date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
			this.setState({
				returnDate :date1,
				returnDateStr :dStr1,
				return:false,
				travellers:true
			});
		} catch (error) {
            throw new Error(error);
        }
		
	}

	oneWay = () => {
		this.setState({
			trip:0,
			oneWayClass:"hr",
			roundTripClass:""
		})
		console.log('oneWay');
	}

	roundTrip = () => {
		this.setState({
			trip:1,
			oneWayClass:"",
			roundTripClass:"hr"
		})
		console.log('roundTrip');
	}

	selectedTravellers = (data) => {
		console.log('--- selectedTravellers ----',data);
		this.setState({
			travellersData: data,
			travellers:false
		});
	}

	setDirectFlight = (event) => {
		console.log(event.target.checked);
		this.setState({
			directFlight:event.target.checked
		})
	};

	getFlightsData = (data, finelData) => {
		try{
			new Services().searchFlight(data).then(res => res.json()).then(
				(result) => {
					console.log(result);
					let fData = [];
					let amountRange = [];
					let filterFlights = [];
					if(result?.TripDetails?.Flights?.Flights){
						fData = result.TripDetails.Flights.Flights;
						amountRange = result.TripDetails.Flights.Amount_Range;
						filterFlights = result.TripDetails.Flights.Filter_Flights;
					}
					else{
						let error = "something went wrong..."
						this.setState({
							isBtnLoader: false,
							error:error
						});
						new displayMessages().errorMsg({error: {message: `${error} at searchFlight()`, data, status: 400, type: "BAD_REQUEST"}});
					}
					
					this.setState({
						isBtnLoader: false,
						//redirect:true,
						allFlights: fData[0],
						returnAllFlights: fData[1],
						amountRange:amountRange,
						filterFlights:filterFlights
					});
					console.log('--- All Flights Data ---',this.state.allFlights);
					this.props.onHandel({finalData: finelData,allFlights: this.state.allFlights,returnAllFlights: this.state.returnAllFlights, amountRange: this.state.amountRange, filterFlights: this.state.filterFlights});
				},
				(error) => {
					console.log("== error ==", error);
					this.setState({
						isBtnLoader: false,
						error
					});
					// new displayMessages().errorMsg({error:error});
				}
			);
		} catch (error) {
			throw new Error(error);
		}
    }

	onSubmit = () => {
		try{
			// this.setState({
			// 	isBtnLoader:true
			// });

			// let finelData = {
			// 	trip:this.state.trip,
			// 	from:this.state.fromSelectedAirport,
			// 	to:this.state.toSelectedAirport,
			// 	departDate:this.state.departDate,
			// 	departDateStr:this.state.departDateStr,
			// 	returnDate:this.state.returnDate,
			// 	returnDateStr:this.state.returnDateStr,
			// 	traveller:this.state.travellers,
			// 	travellersData:this.state.travellersData,
			// 	directFlight:this.state.directFlight
			// };

			// let fromDate = finelData.departDate.month+'/'+finelData.departDate.date+'/'+finelData.departDate.year;

			// let data = [
			// 	{name:'travalType', value:'0'},
			// 	{name:'bookingType', value:finelData.trip},
			// 	{name:'origin', value:finelData.from.ooro_AirportCode},
			// 	{name:'destination', value:finelData.to.ooro_AirportCode},
			// 	{name:'travelDate', value:finelData.departDateStr},
			// 	{name:'returnDate', value:finelData.returnDateStr},
			// 	{name:'tripId', value:finelData.trip},
			// 	{name:'adultsCount', value:finelData.travellersData.adult.toString()},
			// 	{name:'childCount', value:finelData.travellersData.children.toString()},
			// 	{name:'infantCount', value:finelData.travellersData.infants.toString()},
			// 	{name:'classOfTraval', value:finelData.travellersData.seatClassesData.id},
			// 	{name:'inventoryType', value:'0'}
			// ];

			// this.setState({
			// 	finelData:finelData
			// })


			let finelData = {
				trip:this.state.trip,
				fromSelectedAirport:this.state.fromSelectedAirport,
				toSelectedAirport:this.state.toSelectedAirport,
				departDate:this.state.departDate,
				departDateStr:this.state.departDateStr,
				returnDate:this.state.returnDate,
				returnDateStr:this.state.returnDateStr,
				travellersData:this.state.travellersData,
				directFlight:this.state.directFlight,
				oneWayClass:this.state.oneWayClass,
				roundTripClass:this.state.roundTripClass
			};

			localStorage.setItem("lastSearch", JSON.stringify(finelData));
			let lastSearch = localStorage.getItem("lastSearch");
			console.log(JSON.parse(lastSearch));

			let searchData = this.state;
			//let url = '/search-results?';
			let directFlight = 0;
			if(searchData.directFlight == true){
				directFlight = 1;
			}
			
			let url = '/search/'+searchData.fromSelectedAirport.ooro_AirportCode+'/'+searchData.toSelectedAirport.ooro_AirportCode+'/'+searchData.departDateStr+'/'+searchData.returnDateStr+'/'+searchData.travellersData.adult+'/'+searchData.travellersData.children+'/'+searchData.travellersData.infants+'/'+searchData.trip+'/'+directFlight+'/'+searchData.travellersData.seatClassesData.id;

			console.log('--- final url ---',url);
			//return(<Redirect to={{pathname:'/search-results',data:finelData}} />);

			//console.log('--- body ----',data);
			
			//this.getFlightsData(data, finelData);


			// let fData = [];
			// let amountRange = [];
			// let filterFlights = [];
			
			// 	console.log("=== allFlightsData ===", allFlightsData);
			// 	fData = allFlightsData.TripDetails.Flights.Flights;
			// 	amountRange = allFlightsData.TripDetails.Flights.Amount_Range;
			// 	filterFlights = allFlightsData.TripDetails.Flights.Filter_Flights;
			
			// this.setState({
			// 	isBtnLoader: false,
			// 	//redirect:true,
			// 	allFlights: fData[0],
			// 	returnAllFlights: fData[1],
			// 	amountRange:amountRange,
			// 	filterFlights:filterFlights
			// });
			// console.log('--- All Flights Data ---',this.state);
			this.props.onHandel(url);
		} catch (error) {
			throw new Error(error);
		}
		
	}

	renderRedirect = () => {
		if (this.state.redirect) {
		  return <Redirect to={{pathname:'/search-results',data:this.state.finelData}} />
		}
	}

	setFareCalenderData = (from, to) =>{
		try{
			let ddd = new Date();
			let today = new Date();
			for (let index = 1; index < 6; index++) {
				let mmm = monthIndex[ddd.getMonth()];
				// if(mmm < 10){
				// 	mmm = `0${mmm}`;
				// }else{
				// 	mmm = `${mmm}`;
				// }
				let data = {
					"Destination": to.ooro_AirportCode,
					"Month": mmm,
					"Origin": from.ooro_AirportCode,
					"Year": `${ddd.getFullYear()}`
				}
				new Services().fareCalender(data).then(res => res.json()).then(
					(result) => {
						console.log("fareCalender Data ", data, result);
						// let data = [
						// 	{
						// 		month:'06',
						// 		data:[
						// 			{day:'01', price: this.c--},
						// 		]
						// 	}
						// ];

						let priceData = []; 
						if(result.LowFares?.length > 0){
							result.LowFares.map(r =>{
								let d = r.TravelDate.split('/')[1];
								let amount = r.Amount;
								priceData.push({day: d, price: amount});
							});
						}

						let price = this.state.price;
						let priceYear = price.filter(r => r.year == ddd.getFullYear());

						if(priceYear.length > 0){
							price.map(e =>{
								if(e.year == ddd.getFullYear()){
									e.data.push({month: mmm, data: priceData});
								}
							});
						}
						else{
							price.push({year: ddd.getFullYear(), data: [{month: mmm, data: priceData}]});
						}

						console.log("=== price ===", price);
						this.setState({price: price});
					},
					(error) => {
						console.log("== error ==", error);
						// new displayMessages().errorMsg({error:error});
					}
				);
				ddd.setMonth(ddd.getMonth() + 1);
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	

  render(){
    let style = {display:'none'};
    //let returnStyle = {display:'none'};
    if(this.props.visible){
      style = {display:''};
    }
	  let fromClass ='mb-4 h s-label';
	  let toClass ='mb-4 h s-label';
	  let departClass ='mb-2 h s-label';
	  let travellersClass ='mb-4 h s-label';
	//   let oneWayClass = "tab";
	//   let roundTripClass = "tab";
	//   if(this.state.trip){
	// 	roundTripClass +=" active";
	//   }
	//   else{
	// 	oneWayClass +=" active";
	//   }

	if(this.state.fromDropdown){
		//alert('hai');
		fromClass = 'mb-4 h s-label active-text';
	}

	if(this.state.toDropdown){
		//alert('hai');
		toClass = 'mb-4 h s-label active-text';
	}

	if(this.state.depart){
		//alert('hai');
		departClass = 'mb-2 h s-label active-text';
	}

	if(this.state.travellers){
		//alert('hai');
		travellersClass = 'mb-4 h s-label active-text';
	}

	let selectedDate = this.state.departDateStr;

	if(this.state.trip === 1){
		selectedDate = {from: this.state.departDateStr, to: this.state.returnDateStr}
	}
	  //console.log('items',this.state.allAirportsData);
	  let className = "VarticalSearchCard " + this.props.className;
      return(
        <div className={className} style={style}>
			{this.renderRedirect()}
			<Modal size="lg" dialogClassName="modal-90w" centered show={this.state.depart} onHide={this.toggleDepart} 
			// backdrop="static" keyboard={false}
			>
				<Modal.Body className="p-0" 
				// onClick={this.toggleDepart}
				>
					<card className="bg-white sm-device md-device lg-device xl-device">
							{/* <h1 className="m-0 p-2 text-center color-primary">Departure</h1> */}
							<CustomCalender isMobile={false} price={this.state.price} selectedDate={selectedDate} type={false} returnDate={this.setDepartDate} trip={this.state.trip} />
							{/* <Calender price={this.price} selectedDate={[this.state.departDateStr]} returnDate={this.setDepartDate} trip={0} /> */}
					</card>
					<card className="bg-white xs-device">
							{/* <h1 className="m-0 p-2 text-center color-primary">Departure</h1> */}
							<CustomCalender isMobile={true} price={this.state.price} selectedDate={selectedDate} type={false} returnDate={this.setDepartDate} trip={this.state.trip} />
							{/* <Calender price={this.price} selectedDate={[this.state.departDateStr]} returnDate={this.setDepartDate} trip={0} /> */}
					</card>
				</Modal.Body>
			</Modal>
			<Modal size="lg" dialogClassName="modal-90w" centered show={this.state.return} onHide={this.toggleReturn} 
			// backdrop="static" keyboard={false}
			>
				<Modal.Body className="p-0" 
				// onClick={this.toggleDepart}
				>
					<card className="bg-white sm-device md-device lg-device xl-device">
							{/* <h1 className="m-0 p-2 text-center color-primary">Return</h1> */}
							<CustomCalender isMobile={false} price={this.state.price} selectedDate={selectedDate} type={true} returnDate={this.setDepartDate} trip={this.state.trip} />
					</card>
					<card className="bg-white xs-device">
							{/* <h1 className="m-0 p-2 text-center color-primary">Return</h1> */}
							<CustomCalender isMobile={true} price={this.state.price} selectedDate={selectedDate} type={true} returnDate={this.setDepartDate} trip={this.state.trip} />
					</card>
				</Modal.Body>
			</Modal>
			{/* onClick={this.clearDropdown} */}
			<Card className="search-card my-card border-radius" style={{overflow: 'unset'}}>
				<CardContent className="position-relative xs-h-99-5-vh">
					<Grid container>
						<Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
							<Grid container>
								<Grid item xs={4} sm={4} md={3} lg={3} xl={3} className="tab" onClick={this.oneWay}>
									<p className="h">One Way</p>
									<hr className={this.state.oneWayClass} />
								</Grid>
								<Grid item xs={4} sm={4} md={3} lg={4} xl={4} className="tab" onClick={this.roundTrip}>
									<p className="h">Round TRIP</p>
									<hr className={this.state.roundTripClass} />
								</Grid>
								{/* <Grid item xs={4} sm={4} md={3} lg={3} xl={3} className="text-center">
									<p>M</p>
								</Grid> */}
							</Grid>
						</Grid>
						{/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
							<p>
								<img src={Ticket} style={{width:'25px',float:'right' ,marginTop:'3px'}} />
								<span className="l-b h5 h" style={{float:'right',marginRight:'0.75rem'}}>Book Flight Tickets</span>
							</p>
						</Grid> */}
					</Grid>
					{/* <hr /> */}
                    <Grid container>
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} className="p-0">
                            <Grid container className="mt-2 border py-1" style={{borderRadius:'10px'}}>
                                <Grid item xs={6} sm={2} md lg xl className="px-4 py-4">
                                    <label for="fromDropdown" style={{cursor:'pointer'}} onClick={this.toggleFromDropdown}>
                                        <p className={fromClass} style={{lineHeight: '1px'}}>FROM</p>
                                        <span className="h3 bold pt-2" style={{lineHeight: '1px'}}>
                                            <DisplayAirport if={this.state.fromSelectedAirport?.ooro_AirportName} else="Select" />
                                        </span>
                                    </label>
									<CustomDropdown visible={this.state.fromDropdown} id="fromDropdown" classes="position-absolute" selectedAirport={this.fromSelectedAirport} T10India={this.state.T10India} datas={this.state.allAirportsData} cId={this.state.fromSelectedAirport?.id} selectedId={this.state.toSelectedAirport?.id} />
                                </Grid>
                                <Grid item xs={6} sm={2} md lg xl className="border-left px-4 py-4 position-relative">
                                    <SwapHorizIcon className="color-primary" style={{position:'absolute',left:'-11px', top:'30px',cursor:'pointer'}} onClick={this.switchToFrom}></SwapHorizIcon>
                                    <label for="toDropdown" style={{cursor:'pointer'}} onClick={this.toggleToDropdown}>
                                        <p className={toClass} style={{lineHeight: '1px'}}>TO</p>
                                        
                                        <span className="h3 bold" style={{lineHeight: '1px'}}>
                                            <DisplayAirport if={this.state.toSelectedAirport?.ooro_AirportName} else="Select" />
                                        </span>
                                    </label>
									<CustomDropdown visible={this.state.toDropdown} id="toDropdown" classes="position-absolute" selectedAirport={this.toSelectedAirport} T10India={this.state.T10India} datas={this.state.allAirportsData} cId={this.state.toSelectedAirport?.id} selectedId={this.state.fromSelectedAirport?.id} />
                                </Grid>
                                <Grid item xs={6} sm md lg xl className="border-left border-xs-top px-4 py-2" onClick={this.toggleDepart} style={{cursor:'pointer'}}>
                                    <label for="" style={{cursor:'pointer'}}>
                                        <p className={departClass} style={{lineHeight: '1px'}}>DEPARTURE <EventNoteIcon className="color-primary"></EventNoteIcon></p>
                                        
                                        <span className="h3 bold" style={{lineHeight: '1px'}}>{this.state.departDate.date} {monthNames[(this.state.departDate.month)]}</span>
                                        
                                        <p className="sm-p h" style={{lineHeight: '20px'}}>{this.state.departDate.year}</p>
                                    </label>
                                </Grid>
                                <Grid item xs={6} sm md lg xl className="border-left border-xs-top px-4 py-2">
                                    <label for="" style={{cursor:'pointer'}} onClick={this.handelReturn}>
                                        <p className={departClass} style={{lineHeight: '1px'}}>RETURN <EventNoteIcon className="color-primary"></EventNoteIcon></p>
                                        <DisplayReturn returnDateStr={this.state.returnDateStr} returnDate={this.state.returnDate} trip={this.state.trip} />
                                    </label>
                                </Grid>
                                
                                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} className="px-3 py-3 border-left border-xs-top position-relative">
                                    <label for="" onClick={this.toggleTravellers}  style={{cursor:'pointer'}}>
                                        <p className={travellersClass} style={{lineHeight: '1px'}}>TRAVELLERS & CLASS</p>
                                        <span className="h3 bold pt-2" style={{lineHeight: '1px'}}>{this.state.travellersData.adult + this.state.travellersData.children + this.state.travellersData.infants}</span> <span className="bold h" style={{fontSize: '15px'}}>Traveller</span>
                                        <p className="sm-p h" style={{lineHeight: '10px'}}>{this.state.travellersData.seatClassesData.name}</p>
                                    </label>
									<TravellersDropdown visible={this.state.travellers} id="travellers" classes="position-absolute" onHandel={this.selectedTravellers} data={this.state.travellersData} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className="p-3 d-flex align-items-center lg-device-block xl-device-block">
                            <Button variant="contained" disabled={this.state.isBtnLoader} type="button" className="shadow my-success-btn color-w bg-gradient w-100-per border-0 pd-10" style={{fontSize:'14px'}} onClick={this.onSubmit}>
                            	<HandleSearch status={this.state.isBtnLoader} /> <br /> SEARCH FLIGHTS
                            </Button>
                        </Grid>
                    </Grid>
					
					<Grid container className="">
						{/* <Grid item xs>
							<Checkbox checked={this.state.directFlight} onChange={this.setDirectFlight}></Checkbox> <span style={{fontSize:'12px'}} >Direct Flights</span>
						</Grid> */}
						<Grid item xs={12} className="p-3 d-flex align-items-center xs-device-block sm-device-block md-device-block">
                            <Button variant="contained" disabled={this.state.isBtnLoader} type="button" className="shadow my-success-btn color-w bg-gradient w-100-per border-0 pd-10" style={{fontSize:'14px'}} onClick={this.onSubmit}>
                            	<HandleSearch status={this.state.isBtnLoader} /> <br /> SEARCH FLIGHTS
                            </Button>
                        </Grid>
					</Grid>
				</CardContent>
			</Card>
        </div>
      );
    }
}


export default VarticalSearchCard;