import React from "react";

import "./ReviewFlight.scss";
import "./ReviewFlight.css";
import TripDelay from '../../../assets/Icons/trip-delay.png';
import Header from '../../../shared/component/Header.js';
import FareDetailList from '../../../shared/component/FareDetailList.js';
import FlightMetaData from '../../../shared/component/FlightMetaData.js';
import VarticalSearchDisplayCard from '../../../shared/component/VarticalSearchDisplayCard.js';
import LiBlock from '../../../shared/component/LiBlock.js';
import Forms from '../../../shared/component/Form.js';
import banner from '../../../assets/Icons/june1.jpg';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Row, Form, Button, Modal, Spinner } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Footer from '../../../shared/component/Footer.js';
import PromoCode from '../../../shared/component/PromoCode.js';
import TravelersDetails from '../../../shared/component/TravelersDetails.js';
import TravelInsurance from '../../../shared/component/TravelInsurance.js';
import OurFamily from '../../../shared/component/OurFamily.js';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Inputs from "../../../shared/ui/Inputs";
import { Flag, Popup, Segment } from 'semantic-ui-react';
import Services, {paymentUrl} from "../../../shared/services/services";
import displayMessages from "../../../shared/displayMessages/displayMessages";
import * as QueryString from "query-string";
import { isExpired, decodeToken } from "react-jwt";
import JWT from "../../../shared/library/jwt";
import SomeThingWentWrong from "../../../shared/component/SomeThingWentWrong";
import Seat from "../../../assets/Icons/vectorpaint.svg";
import Meals from "../../../shared/component/Meals";
import Baggage from "../../../shared/component/Baggage";
import multiply from "../../../assets/Icons/multiply.svg";
import Loading from "../../../shared/component/Loading";
import SessionExpired from "../../../shared/component/SessionExpired";
import _, { map } from 'underscore';
import CustomFunction from "../../../shared/library/CustomFunction";
import SeatMaps from "../../../shared/component/SeatMaps";
import { ArrowBack, Lock } from "@material-ui/icons";
import { Base64 } from 'js-base64';
import Shimmer from "../../../shared/ui/Shimmer";
import ConfirmationPopup from "../../../shared/component/ConfirmationPopup";
import moment from "moment";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];

const perMin = 60000;
const min = (perMin*10);

const CheckNonStop = (prop) =>{
    if(prop.stops > 0){
        return <span>{prop.stops} Stop</span>
    }
    else{
        return <span>Non Stop</span>
    }
}

const FlightDetails = (prop) =>{
    let item = [];
    console.log("=== fareRules ===", prop.fareRules);
    console.log("=== FlightDetails data ===", prop.data);

    prop.data.Segments.forEach((e, i) =>{
        item.push(<FlightMetaData classes="mb-2" index={i} total={(prop.data.Segments.length - 1)} nextFlightDepartureDateTime={prop.data.Segments[(i + 1)]?.Departure_DateTime} data={e} travelClass={prop.travelClass} />)
    });


    let icon = [];

    if(prop.fareRules && prop.fareRules.length > 0){
        icon.push(<span className="a" onClick={() => prop.showFareRulesPopup(prop.fareRules)}><ErrorOutlineIcon></ErrorOutlineIcon></span>);
    }
    else{
        icon.push(<Popup
            trigger={<span className="a"><ErrorOutlineIcon></ErrorOutlineIcon></span>}
            content='No Fare Rules are available'
            inverted
          />); 
    }

    return(
        <Card className="box-shadow mb-4 flightDetails xs-mb-2">
            <Grid container>
                <Grid item xs={8} sm={7} md={7} lg={7} xl={7} className="px-3 py-4 xs-p-1">
                    <div className="w-100">
                        <h3 className="t-g-color float-left m-0 p-0 heading">{prop.heading}</h3>
                        <h3 className="float-left m-0 p-0 ps-3 sub-heading xs-ps-0">{prop.data.OrgName} <ArrowForwardIcon style={{width:'40px',height:'25px',marginTop:'-3px'}} className="arrowForword"></ArrowForwardIcon> {prop.data.DestName}</h3>
                    </div>
                    <br />
                    <br />
                    <p className="date-stop-time" style={{whiteSpace: 'nowrap'}}>{moment(prop.data.Segments[0]?.Departure_DateTime).format('DD MMM YYYY')} | <CheckNonStop stops={prop.data.stops} /> | {prop.data.DurH}h {prop.data.DurM}m</p>
                </Grid>
                <Grid item xs={4} sm={5} md={5} lg={5} xl={5} className="p-5 xs-p-1">
                    <p className="m-0 p-0 float-right fare-rules">Fare Rules {icon}</p>
                    <p className="m-0 p-0 text-success float-right pe-5 partially-refundable xs-pe-0">Partially Refundable</p>
                </Grid>
            </Grid>
            {item}
            
        </Card>
    );
}

const FareRules = (prop) =>{
    let item = [];
    if(prop.data.length > 0){
        prop.data.map(e =>{
            item.push(<div className="p-3" dangerouslySetInnerHTML={{__html:e.FareRuleDesc}} />);
        });

        return(<>{item}</>);
    }
    else{
        return(<></>);
    }
}


class ReviewFlight extends React.Component {
    
    state = {
        data: null,
        travelersDetails: null,
        gst: false,
        gstHolderName: null,
        gstNo: null,
        addSeats: false,
        addBaggage: false,
        addMeals: false,
        loading:true,
        error:false,
        noOfApiHits:0,
        countOfApiHits:0,
        noOfSeatsFeatching: 1,
        countOfSeatsFeatching: 0,
        flightsData: [],
        searchKey: null,
        travelClass:'',
        airRepricedData: [],
        sessionExpired: false,
        loggedIn: false,
        searchData: null,
        fareRules:[],
        coupon:[],
        seatMapData:[],
        mealsData:[],
        baggageData:[],
        contactInfoData:{mobile:'', email:''},
        gstData:{companyName:'', registrationNo:''},
        saveTravellerData: false,
        takeRisk: null,
        totalSeats: 0,
        parentIndex: 0,
        childIndex: 0,
        appliedCoupon: null,
        currentFareRules: null,
        fareRulesPopup: false,
        fetchingSeats: true,
        contryCode: '+91',
        customerMobile: '',
        customerEmail: '',
        customerEmailError: false,
        customerMobileError: false,
        paymentBtnLoader: false,
        showSheetMap: true,
        confirmationPopup: false,
        ccf: 0
        
    }

    constructor(props){
        super(props);

        if(localStorage.getItem('OoroToken')){
            this.setState({loggedIn: true});
        }
    }

    params = QueryString.parse(this.props.location.search);

    
    componentDidMount(){
        this.setData();
        
        // setTimeout(() => {
        //     console.log("== setTimeout ==");
        //     this.setState({sessionExpired: true}); 
        // }, min);

        // setInterval(() => {
        //     console.log("*** setInterval ***");
        // }, 60000);
    }

    setData = () =>{
        try{
            let params = QueryString.parse(this.props.location.search);
            const isMyTokenExpired = isExpired(params.SData);
            if(!isMyTokenExpired){
                const myDecodedToken = decodeToken(params.SData);
                // console.log("== myDecodedToken ==", myDecodedToken);
                const searchId = myDecodedToken?.data?.public?.searchId;
                
                const newToken = sessionStorage.getItem(searchId);
                console.log("newToken ====", newToken);

                const isMyTokenExpiredSData = isExpired(params.SData);
                if(!isMyTokenExpiredSData){
                    // const myDecodedTokenSData = decodeToken(newToken);
                    const myDecodedTokenSData = new JWT().decodeTokenForPrivate(newToken);
                    console.log("== myDecodedTokenSData ==", myDecodedTokenSData);

                    if(myDecodedTokenSData){

                        const data = myDecodedTokenSData?.data;

                        let travelersDetails = [];
                        let searchData = null;
                        let totalSeats = 0;
                        let showSheetMap = true;
                        let travelerCounts = {
                            ADT: 0,
                            CHD: 0,
                            INF: 0
                        }
                        console.log("== data ==", data);
                        if(data?.searchData){
                            searchData = data.searchData;
                        }

                        if(data?.searchData.travellersData.adult > 0){
                            totalSeats = (totalSeats + data.searchData.travellersData.adult);
                            travelerCounts.ADT = data.searchData.travellersData.adult;
                            travelersDetails.push({label:"Adult", type: 0, number:data.searchData.travellersData.adult, data:[{fName:'', lName:'', gender:'', dob:'', meals:[], baggage:[]}]});
                        }
                        if(data?.searchData.travellersData.children > 0){
                            totalSeats = (totalSeats + data.searchData.travellersData.children);
                            travelerCounts.CHD = data.searchData.travellersData.children;
                            travelersDetails.push({label:"Child", type: 1, number:data.searchData.travellersData.children, data:[{fName:'', lName:'', gender:'', dob:'', meals:[], baggage:[]}]});
                        }
                        if(data?.searchData.travellersData.infants > 0){
                            showSheetMap = false;
                            travelerCounts.INF = data.searchData.travellersData.infants;
                            this.setState({showSheetMap: false});
                            travelersDetails.push({label:"Infant", type: 2, number:data.searchData.travellersData.infants, data:[{fName:'', lName:'', gender:'', dob:'', meals:[], baggage:[]}]});
                        }
                        

                        let travelClass = data?.searchData.travellersData.seatClassesData.name;

                        let searchKey = data?.searchKey;
                        let flightsData = data?.flightsData;
                        let noOfApiHits = 3 * flightsData.length;
                        let countOfApiHits = 0;
                        let seatMapData = [];
                        let mealsData = [];
                        let baggageData = [];
                        let airRepriceRequests = [];
                        if(flightsData && flightsData.length > 0){
                            flightsData.forEach(element => {
                                airRepriceRequests.push({
                                    Fare_Id: element.Fares.Fare_Id,
                                    Flight_Key: element.Flight_Key
                                });

                                this.fareRules(searchKey, element.Fares.Fare_Id, element.Flight_Key);
                            });

                            setTimeout(() => {
                                this.airRepricing(searchKey, airRepriceRequests, travelersDetails, travelerCounts, data.searchData);
                            }, 1000);
                            
                        }

                        console.log("====== flightsData flightsData flightsData ======", flightsData);

                        this.setState({
                            data: data,
                            //loading: false,
                            flightsData: flightsData,
                            searchKey: searchKey,
                            travelClass: travelClass,
                            noOfApiHits: noOfApiHits,
                            countOfApiHits: countOfApiHits,
                            searchData: searchData,
                            seatMapData: seatMapData,
                            travelersDetails: travelersDetails,
                            totalSeats: totalSeats,
                            mealsData: mealsData,
                            baggageData: baggageData
                        });
                    }
                    else{
                        const error = "Data Not Found!";
                        console.log("== error ==", error);
                        this.setState({
                            loading: false,
                            error: error
                        });
                        new displayMessages().errorMsg({error:error});
                    }
                }
                else{
                    const error = "Token has been expired";
                    console.log("== error ==", error);
                    this.setState({
                        loading: false,
                        error: error
                    });
                    new displayMessages().errorMsg({error:error});
                }
            }
            else{
                const error = "S_Id Token has been expired";
                console.log("== error ==", error);
                this.setState({
                    loading: false,
                    error: error
                });
                new displayMessages().errorMsg({error:error});
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    checkDataFetching = () =>{
        try{
            if(this.state.countOfApiHits == this.state.noOfApiHits){
                this.setState({
                    loading:false
                });

                // setTimeout(() => {
                //     this.scrollTop();
                // }, 500);
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    checkSeatsFetching = () =>{
        try{
            if(this.state.countOfSeatsFeatching == this.state.noOfSeatsFeatching){
                this.setState({
                    fetchingSeats:false
                });
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    getSSR = (searchKey, flightKey, flightId, ccf = 0) =>{
        try{
            let data = [{name:'SearchKey', value:searchKey},{name:'FlightKey', value:flightKey}];
            
            new Services().getAirSSR(data).then(res => res.json()).then((r) =>{
                console.log("== getSSR results ==", r);
                let SSRFlightDetails = [];
                if(r.SSRFlightDetails && r.SSRFlightDetails.length > 0){
                    SSRFlightDetails = r.SSRFlightDetails[0].SSRDetails;
                }
                let flightsData = [];
                
                this.state.flightsData.map(e => {
                    let el = {};
                    // console.log("** e **", e);
                    // console.log("** Number(e.Flight_Id) **", Number(e.Flight_Id), Number(flightId));
                    if(Number(e.Flight_Id) == Number(flightId)){
                        //console.log("** flightId **", flightId);
                        el = {...e, ssrFlightDetails:SSRFlightDetails};
                    }
                    else{
                        el = {...e};
                    }

                    flightsData.push(el);
                    
                        
                });


                //console.log("*** flightsData ***", flightsData); 

                this.setState({
                    flightsData: flightsData,
                    countOfApiHits: (this.state.countOfApiHits +1),
                    ccf: ccf
                });
                this.checkDataFetching();
            },
            (error) => {
                console.log("== error ==", error);
                this.setState({
                    loading: false,
                    error: error
                });
                // new displayMessages().errorMsg({error:error});
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    getSeatMaps = () =>{
        try{
            //this.setState({noOfSeatsFeatching: this.state.airRepricedData.length});
            this.addSeats();
            //let data = [{name:'SearchKey', value:searchKey},{name:'FlightKey', value:flightKey}];

            let PAX_Details = [];
            let BookingFlightDetails = [];
            let c = 1;
            //let BookingSSRDetails = [];
            let cc = 1;
            this.state.travelersDetails.map((e, i) =>{
                e.data.map((ee, ii) =>{
                    let title = 'MSTR';
                    if(ee.gender == 'female'){
                        title = 'MISS';
                    }
                    let dobstr = null
                    if(e.type == 2){
                        dobstr = '';
                        if(ee.dob.d < 10){
                            dobstr = dobstr+'0'+ee.dob.d+'/';
                        }
                        else{
                            dobstr = dobstr+ee.dob.d+'/';
                        }

                        if(ee.dob.m < 10){
                            dobstr = dobstr+'0'+ee.dob.m+'/';
                        }
                        else{
                            dobstr = dobstr+ee.dob.m+'/';
                        }

                        if(ee.dob.y < 10){
                            dobstr = dobstr+'0'+ee.dob.y;
                        }
                        else{
                            dobstr = dobstr+ee.dob.y;
                        }
                    }
                    if(e.type == 0){
                        title = 'Mr';
                        if(ee.gender == 'female'){
                            title = 'Ms';
                        }
                    }
                    if(e.type != 2){
                        PAX_Details.push({
                            Pax_Id: c,
                            Pax_type: e.type,
                            Title: title,
                            First_Name: ee.fName,
                            Last_Name: ee.lName,
                            Gender: 0,
                            Age: null,
                            DOB: dobstr,
                            Passport_Number: null,
                            Passport_Issuing_Country: null,
                            Passport_Expiry: null,
                            Nationality: null,
                            FrequentFlyerDetails: null
                        });
                        c++;
                    }   
                });
            });

            this.state.airRepricedData.map((af, ai) =>{
                BookingFlightDetails.push(af.Flight.Flight_Key);
            });

            let isUser = null;

            let token = localStorage.getItem('OoroToken');

            if(token){
                isUser  = 1;
            }
            
            let jsonData = {
                "Auth_Header": {
                    "UserToken": token,
                    "is_user": isUser
                },
                "Search_Key": this.state.searchKey,
                "Flight_Keys": BookingFlightDetails,
                "PAX_Details": PAX_Details
            };

            console.log("=== jesonData ===", jsonData);


            new Services().getAirSeatMaps(jsonData).then(res => res.json()).then(r =>{
                let AirSeatMaps = [];
                console.warn("== getSeatMaps results ==", r);
                let seatsMapsDatas = [];
                if(r.AirSeatMaps && r.AirSeatMaps.length > 0){
                    r.AirSeatMaps.map(e =>{
                        let seatsMap = [];
                        e.Seat_Segments[0].Seat_Row.map((e2, index) =>{ 
                            let Seat_Details = new CustomFunction().filterUniqueData('SSR_TypeName', e2.Seat_Details);
                            e2.Seat_Details = new CustomFunction().checkKey((index + 1), Seat_Details);
                            seatsMap.push(new CustomFunction().checkKey((index + 1), e2.Seat_Details));
                        });
                        //return e1;
                        seatsMapsDatas.push({flightId: e.Flight_Id, seats: []});
                        AirSeatMaps.push({flightId: e.Flight_Id, seats: seatsMap});
                        console.warn("== seatsMap ==", seatsMap);
                    });
                    //AirSeatMaps = r.AirSeatMaps;
                }
                //console.log("=== this.state.flightsData ===", this.state.flightsData);
                
                let airRepricedData = [];
                
                this.state.airRepricedData.map(e => {
                    let el = {};
                    let asm = {};
                    let seatsMapsData = AirSeatMaps.filter(r => r.flightId == e.Flight.Flight_Id);
                    if(seatsMapsData.length > 0){
                        asm = seatsMapsData[0];
                    }
                    el = {...e, airSeatMaps:asm};
                    // console.log("** e **", e);
                    // console.log("** Number(e.Flight_Id) **", Number(e.Flight_Id), Number(flightId));
                    // if(Number(e.Flight_Id) == Number(af.Flight.Flight_Id)){
                    //     //console.log("** flightId **", flightId);
                    //     el = {...e, airSeatMaps:AirSeatMaps};
                    // }
                    // else{
                    //     el = {...e};
                    // }

                    airRepricedData.push(el);
                    
                        
                });


                //console.log("*** flightsData ***", flightsData); 

                this.setState({
                    airRepricedData: airRepricedData,
                    airSeatMaps: AirSeatMaps,
                    seatMapData: seatsMapsDatas,
                    countOfSeatsFeatching: (this.state.countOfSeatsFeatching +1)
                });
                // this.setState({
                //     airSeatMaps: AirSeatMaps,
                //     countOfApiHits: (this.state.countOfApiHits +1)
                // });
                this.checkSeatsFetching();
                //this.addSeats();
            },
            (error) => {
                console.log("== error ==", error);
                // new displayMessages().errorMsg({error:error});
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    airRepricing = (searchKey, airRepriceRequests, travelersDetails, travelerCounts, searchData) =>{
        try{
            let isUser = null;

            console.log("== travelersDetails ===",travelersDetails);

            let token = localStorage.getItem('OoroToken');

            if(token){
                isUser  = 1;
            }
            let jsonData = {
                "Auth_Header": {
                "UserToken": token,
                "is_user": isUser
                },
                "Search_Key": searchKey,
                "AirRepriceRequests": airRepriceRequests,
                "Customer_Mobile": null,
                "SinglePricing": true,
                "ADT": travelerCounts.ADT,
                "CHD": travelerCounts.CHD,
                "INF": travelerCounts.INF,
                "Search_Data": searchData
            };
            new Services().airRepricing(jsonData).then(res => res.json()).then(r =>{
                let airRepricedData = [];
                let coupon = [];
                let seatMapData = [];
                let mealsData = [];
                let baggageData = [];
                console.log("== airRepricing results ==", r);
                if(r.AirRepriceResponses && r.AirRepriceResponses.length > 0){
                    airRepricedData = r.AirRepriceResponses;

                    r.AirRepriceResponses.map(element =>{

                        console.warn("element", element);
                        seatMapData.push({flightId: element.Flight.Flight_Id, seats:[
                            // {
                            //     "ApplicablePaxTypes": [
                            //         0,
                            //         1
                            //     ],
                            //     "Currency_Code": "INR",
                            //     "Flight_ID": "5289365003608552535",
                            //     "Leg_Index": 0,
                            //     "SSR_Key": "3Iue1MFNQ8N2adqdfGRaaHue8rv7OD1T1Cwvo+K7vEXKDnFd9NGKOcjSZpQKyu+vPBrmf4oMsa07Y0zzd3mYo2J/DrxvypuDi6ShndenUAEqQiMt+8I1UWsq1mE9LWRMvv53VmECNVAxbcHq6nh+rvq8Vf5Pp0iq4t28tF7wCCyBrVGKLV3PTmw7JY8jH5S+mVIKS6N33XLsk8Dc26ONDbMcoUS4AqulK3ZjEXneLkPIPZIDcdzji6CsHQdid7hl3tJlOSQBdEz86zvL7KdonEx0lDPSNoO6vN803Prs60M=",
                            //     "SSR_Status": 0,
                            //     "SSR_Type": 3,
                            //     "SSR_TypeDesc": "SEAT-5C",
                            //     "SSR_TypeName": "5C",
                            //     "Segment_Id": 0,
                            //     "Segment_Wise": true,
                            //     "Total_Amount": 0,
                            //     "index": 0
                            // }
                        ]});
        
                        travelersDetails.map(e =>{
                            e.data.map(r =>{
                                r.meals.push({flightId: element.Flight.Flight_Id, meals: {}});
                                
                                r.baggage.push({flightId: element.Flight.Flight_Id, baggage: {}});
                            });
                        });
        
                        mealsData.push({flightId: element.Flight.Flight_Id, meals: {}});
                        baggageData.push({flightId: element.Flight.Flight_Id, baggage: {}});
        
                        this.getSSR(searchKey, element.Flight.Flight_Key, element.Flight.Flight_Id, r.ccf);
                        
                        // this.getSeatMaps(searchKey, element.Flight_Key, element.Flight_Id);
                    });
                }
                else{
                    let error = "Flight reprice data is null"
                    console.log("== error ==", error);
                    this.setState({
                        loading: false,
                        error: error
                    });
                    new displayMessages().errorMsg({error: {message: `${error} at airRepricing()`, data: jsonData, status: 400, type: "BAD_REQUEST"}});
                }

                if(r.coupon_codes && r.coupon_codes.length > 0){
                    coupon = r.coupon_codes;
                }

                let ard = this.state.airRepricedData;
                let cpn = this.state.coupon;

                ard.push(...airRepricedData);
                cpn = [...cpn, ...coupon];
                
                cpn = new CustomFunction().filterUniqueData('code', cpn);

                // this.setState({
                //     data: data,
                //     //loading: false,
                //     flightsData: flightsData,
                //     searchKey: searchKey,
                //     travelClass: travelClass,
                //     noOfApiHits: noOfApiHits,
                //     countOfApiHits: countOfApiHits,
                //     searchData: searchData,
                //     seatMapData: seatMapData,
                //     travelersDetails: travelersDetails,
                //     totalSeats: totalSeats,
                //     mealsData: mealsData,
                //     baggageData: baggageData
                // });
                

                console.warn("before set state", {
                    airRepricedData: ard,
                    coupon: cpn,
                    travelersDetails: travelersDetails,
                    mealsData: mealsData,
                    baggageData: baggageData,
                    countOfApiHits: (this.state.countOfApiHits + airRepriceRequests.length)
                });

                this.setState({
                    airRepricedData: ard,
                    coupon: cpn,
                    travelersDetails: travelersDetails,
                    mealsData: mealsData,
                    baggageData: baggageData,
                    countOfApiHits: (this.state.countOfApiHits + airRepriceRequests.length)
                });
                // this.setState({
                //     airSeatMaps: AirSeatMaps,
                //     countOfApiHits: (this.state.countOfApiHits +1)
                // });
                this.checkDataFetching();
            },
            (error) => {
                console.log("== error ==", error);
                this.setState({
                    loading: false,
                    error: error
                });
                // new displayMessages().errorMsg({error:error});
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    fareRules = (searchKey, fareId, flightKey) =>{
        try{
            new Services().fareRules(searchKey, fareId, flightKey).then(res => res.json()).then(r =>{
                let fareRules = [];
                console.log("== fareRules results ==", r);
                if(r.FareRules && r.FareRules.length > 0){
                    fareRules = r.FareRules;
                }

                let fr = this.state.fareRules;

                fr.push(fareRules);
                
                this.setState({
                    fareRules: fr,
                    countOfApiHits: (this.state.countOfApiHits +1)
                });
                // this.setState({
                //     airSeatMaps: AirSeatMaps,
                //     countOfApiHits: (this.state.countOfApiHits +1)
                // });
                this.checkDataFetching();
            },
            (error) => {
                console.log("== error ==", error);
                this.setState({
                    loading: false,
                    error: error
                });
                // new displayMessages().errorMsg({error:error});
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    add = (data, value) => {
        try{
            let baggageData = this.state.baggageData;
            let mealsData = this.state.mealsData;
            console.log("=== state ===", data);
            console.log("=== add ===", value);
            data.forEach(element => {
                if(element.label == value){
                return element.data.push({fName:'', lName:'', gender:'', dob:'', meals:mealsData, baggage:baggageData});
                }
            });
            this.setState({
                travelersDetails:data
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    addCancelGst = () =>{
        this.setState({gst: !this.state.gst});
    }

    addSeats = () =>{
        this.setState({addSeats: !this.state.addSeats});
    }

    toggleConfirmationPopup = () =>{
        this.setState({confirmationPopup: !this.state.confirmationPopup});
    }

    setGstHolderName = (indexData, value) =>{
        console.log("GstHolderName: ", indexData, value);
        this.setState({gstHolderName: value});
    }

    setGstNo = (indexData, value) =>{
        console.log("GstNo: ", indexData, value);
        this.setState({gstNo: value});
    }

    setCusomerEmail = (indexData, value) =>{
        console.log("CusomerEmail: ", indexData, value);
        let customerEmailError = this.validateEmail(value);
        this.setState({customerEmail: value, customerEmailError: !customerEmailError});
    }

    validateEmail = (emailAdress) =>{
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailAdress.match(regexEmail)) {
            return true; 
        } else {
            return false; 
        }
    }


    setCusomerMobile = (indexData, value) =>{
        console.log("CusomerMobile: ", indexData, value);
        value = value.replace(/[^\d]/g, '');
        let customerMobileError = this.validateMobile(value);
        this.setState({customerMobile: value, customerMobileError: !customerMobileError});
    }

    validateMobile = (mobileNo) =>{
        if(mobileNo.length == 10){
            return true;
        }
        else{
            return false;
        }
    }

    addBaggage = (data) =>{
        console.log("== addBaggage ==", data);
        this.setState({parentIndex: Number(data.parentIndex), childIndex: Number(data.childIndex), addBaggage: !this.state.addBaggage});
    }

    fareRulesPopup = () =>{
        this.setState({fareRulesPopup: !this.state.fareRulesPopup});
    }

    addMeals = (data) =>{
        console.log("== addMeals ==", data);
        this.setState({parentIndex: Number(data.parentIndex), childIndex: Number(data.childIndex), addMeals: !this.state.addMeals});
    }

    checkLogin = (status) =>{
        this.setState({loggedIn: status});
    }

    selectedSeats = (data) =>{
        console.log("=== selectedSeats ===", data);
        try{
            let seatMapData = this.state.seatMapData;

            seatMapData.map(e => {
                if(e.flightId == data.flightId){
                    e.seats = data.seats;
                }
            });

            this.setState({seatMapData: seatMapData});
        } catch (error) {
            throw new Error(error);
        }

    }

    setBaggageData = (data) =>{
        console.log(data);
        try{
            let parentIndex = this.state.parentIndex;
            let childIndex = this.state.childIndex;
            let travelersDetails = this.state.travelersDetails;
            console.log(travelersDetails, parentIndex, childIndex);
            let rb = travelersDetails[parentIndex]?.data[childIndex]?.baggage.filter(r => r.flightId != data.flightId)[0];
            console.log('== rb ==', rb);
            if(rb){
                travelersDetails[parentIndex].data[childIndex].baggage = [rb, data];
            }
            else{
                travelersDetails[parentIndex].data[childIndex].baggage = [data];
            }

            this.setState({travelersDetails: travelersDetails});
        } catch (error) {
            throw new Error(error);
        }
    }

    setMealsData = (data) =>{
        console.log(data);
        try{
            let parentIndex = this.state.parentIndex;
            let childIndex = this.state.childIndex;
            let travelersDetails = this.state.travelersDetails;
            let rb = travelersDetails[parentIndex]?.data[childIndex]?.meals.filter(r => r.flightId != data.flightId)[0];
            console.log('== rb ==', rb);
            if(rb){
                travelersDetails[parentIndex].data[childIndex].meals = [rb, data];
            }
            else{
                travelersDetails[parentIndex].data[childIndex].meals = [data];
            }

            this.setState({travelersDetails: travelersDetails});
        } catch (error) {
            throw new Error(error);
        }
    }

    setAppliedCoupon = (data) =>{
        console.log("=== Applied Coupon ===", data);
        this.setState({appliedCoupon: data});
    }

    cancelCoupon = (data) =>{
        console.log("=== Cancel Coupon ===", data);
        this.setState({appliedCoupon: null});
    }

    showFareRulesPopup = (data) =>{
        console.log(data);
        this.setState({currentFareRules: data});
        this.fareRulesPopup();
    }

    setFormValue = (data) =>{
        console.log(data);
        let travelersDetails = this.state.travelersDetails;
        
        travelersDetails[data.parentIndex].data = data.data;

        this.setState({travelersDetails: travelersDetails});
    }

    getNoOfDays = (startDate) =>{
        let today = new Date().toISOString().slice(0, 10)
        // const startDate  = '2021-04-15';
        const endDate    = today;
        const diffInMs   = new Date(endDate) - new Date(startDate)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays;
    }

    back = () => {
        window.history.back();
    }

    tempBooking = () =>{
        this.setState({paymentBtnLoader: true});
        console.log(this.state);
        let PAX_Details = [];
        let BookingFlightDetails = [];
        let c = 1;
        let extra = 0;
        let Base_Fare = 0;
        let tax_fees = 0;
        this.state.airRepricedData.map((af, ai) =>{
            Base_Fare = Base_Fare + af.Flight.Fares[0].FareDetails[0].Basic_Amount;
            tax_fees = tax_fees + af.Flight.Fares[0].FareDetails[0].AirportTax_Amount;
            let BookingSSRDetails = [];
            let details = [];
            let flightCount = 1;
            let fareType = null;
            let cc = 1;
            let flightType = "Onward";
            let Check_In_Baggage = null;
            let Hand_Baggage = null;

            this.state.travelersDetails.map((e, i) =>{
                e.data.map((ee, ii) =>{
                    let title = 'MSTR';
                    if(ee.gender == 'female'){
                        title = 'MISS';
                    }
                    let dobstr = null
                    if(e.type == 2){
                        dobstr = '';
                        if(ee.dob.d < 10){
                            dobstr = dobstr+'0'+ee.dob.d+'/';
                        }
                        else{
                            dobstr = dobstr+ee.dob.d+'/';
                        }

                        if(ee.dob.m < 10){
                            dobstr = dobstr+'0'+ee.dob.m+'/';
                        }
                        else{
                            dobstr = dobstr+ee.dob.m+'/';
                        }

                        if(ee.dob.y < 10){
                            dobstr = dobstr+'0'+ee.dob.y;
                        }
                        else{
                            dobstr = dobstr+ee.dob.y;
                        }
                    }
                    if(e.type == 0){
                        title = 'Mr';
                        if(ee.gender == 'female'){
                            title = 'Ms';
                        }
                    }
                    if(ai == 0){
                        PAX_Details.push({
                            Pax_Id: c,
                            Pax_type: e.type,
                            Title: title,
                            First_Name: ee.fName,
                            Last_Name: ee.lName,
                            Gender: 0,
                            Age: null,
                            DOB: dobstr,
                            Passport_Number: null,
                            Passport_Issuing_Country: null,
                            Passport_Expiry: null,
                            Nationality: null,
                            FrequentFlyerDetails: null
                        });
                    }
                    if(e.type != 2){
                        if(ee.baggage.filter(r => r.flightId == af.Flight.Flight_Id)[0].baggage?.SSR_Key){
                            let SSR_Baggage = ee.baggage.filter(r => r.flightId == af.Flight.Flight_Id)[0].baggage;
                            let SSR_Key_Baggage = SSR_Baggage.SSR_Key;
                            let SSR_TypeDesc = SSR_Baggage.SSR_TypeDesc;
                            let Total_Amount = SSR_Baggage.Total_Amount;
                            extra = extra + Total_Amount;
                            BookingSSRDetails.push({
                                Pax_Id: cc,
                                SSR_Key: SSR_Key_Baggage,
                                type: "Baggage",
                                descr: SSR_TypeDesc,
                                price: Total_Amount
                            });
                        }
                        if(ee.meals.filter(r => r.flightId == af.Flight.Flight_Id)[0].meals?.SSR_Key){
                            let SSR_Meals = ee.meals.filter(r => r.flightId == af.Flight.Flight_Id)[0].meals;
                            let SSR_Key_Meals = SSR_Meals.SSR_Key;
                            let SSR_TypeDesc = SSR_Meals.SSR_TypeDesc;
                            let Total_Amount = SSR_Meals.Total_Amount;
                            extra = extra + Total_Amount;
                            BookingSSRDetails.push({
                                Pax_Id: cc,
                                SSR_Key: SSR_Key_Meals,
                                type: "Meals",
                                descr: SSR_TypeDesc,
                                price: Total_Amount
                            });
                        }

                        if(this.state.seatMapData.filter(r => r.flightId == af.Flight.Flight_Id)[0]?.seats[(cc - 1)]?.SSR_Key){

                            let SSR_Seats = this.state.seatMapData.filter(r => r.flightId == af.Flight.Flight_Id)[0].seats[(cc - 1)];
                            let SSR_Key_Seats = SSR_Seats.SSR_Key;
                            let SSR_TypeDesc = SSR_Seats.SSR_TypeName;
                            let Total_Amount = SSR_Seats.Total_Amount;
                            extra = extra + Total_Amount;
                            BookingSSRDetails.push({
                                Pax_Id: cc,
                                SSR_Key: SSR_Key_Seats,
                                type: "Seat",
                                descr: SSR_TypeDesc,
                                price: Total_Amount
                            });
                        }
                        cc++;
                    }

                    c++;
                    
                });
            });
            if(BookingSSRDetails.length > 0){
                BookingFlightDetails.push({
                    Search_Key: this.state.searchKey,
                    BookingSSRDetails: BookingSSRDetails,
                    Flight_Key: af.Flight.Flight_Key,
                    Deal_Key: null,
                    details: details
                });
            }
            else{
                BookingFlightDetails.push({
                    Search_Key: this.state.searchKey,
                    BookingSSRDetails: null,
                    Flight_Key: af.Flight.Flight_Key,
                    Deal_Key: null,
                    details: details
                });
            }

            //console.log("af====[][][][]", af);
            if(ai == 1){
                flightType = "Return";
            }

            if(af?.Flight?.Fares[0]?.Refundable){
                fareType = 'Refundable';
            }
            if(af?.Flight?.Fares[0]?.FareDetails[0]?.Free_Baggage?.Hand_Baggage){
                Hand_Baggage = af.Flight    .Fares[0].FareDetails[0].Free_Baggage.Hand_Baggage;
            }

            if(af?.Flight?.Fares[0]?.FareDetails[0]?.Free_Baggage?.Check_In_Baggage){
                Check_In_Baggage = af.Flight.Fares[0].FareDetails[0].Free_Baggage.Check_In_Baggage;
            }

            af.Flight.Segments.map(e => {
                // console.log("==== eeeeee ====", e);
                details.push(
                    {
                        "type": flightType,
                        "flight": flightCount,
                        "fname": e.Airline_Name,
                        "flNo": e.Flight_Number,
                        "fare": fareType,
                        "dep": e.Origin_City,
                        "dtime": e.Departure_DateTime,
                        "dTerminal": e.Origin_Terminal,
                        "arr": e.Destination_City,
                        "artime": e.Arrival_DateTime,
                        "aTerninal": e.Destination_Terminal,
                        "cabin_bgg": Hand_Baggage,
                        "chkIn_bgg": Check_In_Baggage,
                        ...e
                    }
                );

                flightCount++;

            });
        });

        let isUser = null;

        let token = localStorage.getItem('OoroToken');

        if(token){
            isUser  = 1;
        }

        let gstNo = null;
        let gstHolderName = null;

        if(this.state.gst){
            gstNo = this.state.gstNo;
            gstHolderName = this.state.gstHolderName;
        }
        let coupon_code = 0;
        let coupon_discount = 0;

        
        if(this.state?.appliedCoupon){
            coupon_code = this.state.appliedCoupon?.code;
            coupon_discount = this.state.appliedCoupon?.amount;
        }

        let jsonData = {
            "Auth_Header": {
              "UserToken": token,
              "is_user": isUser
            },
            "Customer_Mobile": this.state.customerMobile,
            "Passenger_Mobile": this.state.customerMobile,
            "WhatsAPP_Mobile": null,
            "Passenger_Email": this.state.customerEmail,
            "PAX_Details": PAX_Details,
            "GST": this.state.gst,
            "GST_Number": gstNo,
            "GST_HolderName": gstHolderName,
            "GST_Address": null,
            "BookingFlightDetails": BookingFlightDetails,
            "Base_Fare": Base_Fare,
            "tax_fees": tax_fees,
            "extra": extra,
            "ccf": this.state.ccf,
            "coupon_code": coupon_code,
            "coupon_discount": coupon_discount
        };

        console.log("=== jesonData ===", jsonData);
        new Services().tempBooking(jsonData).then(res => res.json()).then(r =>{
            console.log(" === temp Booked! ===", r);
            let encodeData = Base64.encode(`${r.order_id}#${r.sid}`);
            window.location.replace(`${paymentUrl}?userSession=${encodeData}`);
        },
        (error) => {
            console.log("== error ==", error);
            this.setState({paymentBtnLoader: false});
            // new displayMessages().errorMsg({error:error});
        });
    }

    // scrollTop = () =>{
    //     new CustomFunction().scrollTop();
    // }
    

    render(){
        try{
            let data = this.state.data;
            let params = QueryString.parse(this.props.location.search);
            //console.log("== search ==",params.SData);

            const isMyTokenExpired = isExpired(params.SData);
            console.log("== isMyTokenExpired ==", isMyTokenExpired);

            if(isMyTokenExpired){
                return (
                <>
                    <Header nav={this.props.nav} openSideBar={(status) =>this.props.openSideBar(status)} onLoggedIn={(data) =>{}} />
                    <Grid container className="px-2 py-4 pb-0" style={{marginTop:'100px'}}>
                        <Grid item xs={12} className="pt-2 d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
                            <SomeThingWentWrong />
                        </Grid>
                    </Grid>
                </>
                );
            }
            else if(this.state.loading){
                return(
                    <>
                        <Header nav={this.props.nav} openSideBar={(status) =>this.props.openSideBar(status)} onLoggedIn={(data) =>{}} />
                        <Shimmer />
                        {/* <Grid container className="px-2 py-4 pb-0" style={{marginTop:'100px'}}>
                            <Grid item xs={12} className="pt-2 d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
                                
                            </Grid>
                        </Grid> */}
                    </>  
                );
            }
            else if(this.state.error){
                return (
                <>
                    <Header nav={this.props.nav} openSideBar={(status) =>this.props.openSideBar(status)} onLoggedIn={(data) =>{}} />
                    <Grid container className="px-2 py-4 pb-0" style={{marginTop:'100px'}}>
                        <Grid item xs={12} className="pt-2 d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
                            <SomeThingWentWrong />
                        </Grid>
                    </Grid>
                </>
                );
            }
            else if(data){
                let item = [];
                let travelClass = this.state.travelClass;
                let seatMapData = this.state.seatMapData;
                let totalSeats = this.state.totalSeats;
                console.log("=== state ===",this.state);
                let travelersDetails = this.state.travelersDetails;
                let flightsData = this.state.flightsData;
                let airRepricedData = this.state.airRepricedData;
                let data = this.state.data;
                console.log("=== travelersDetails ===", travelersDetails);
                let visibility = {display: 'none'};
                let addVisibility = {display: ''};

                let travelersDetailsError = false;

                travelersDetails.map(e =>{
                    if(e.number == e.data.length){
                        e.data.map(ee =>{
                            if(ee.fName != '' && ee.lName != '' && ee.gender != ''){
                                if(e.type == 2){
                                    if(ee.dob?.d > 0 && ee.dob?.m > 0 && ee.dob?.y > 0){
                                        if(ee.dateError && ee?.dateError === true){
                                            travelersDetailsError = true;
                                        }
                                        else{
                                            
                                        }
                                    }
                                    else{
                                        travelersDetailsError = true; 
                                    }
                                }
                            }
                            else{
                                travelersDetailsError = true; 
                            }
                        });
                    }
                    else{
                        travelersDetailsError = true;
                    }
                });

                let travelersDetailsAddBtn = [];
                let continueToPaymentBtn = [];
                let continueToPaymentBtnMobile = [];

                let gstError = false;

                if(this.state.gst){
                    if(this.state?.gstHolderName && this.state?.gstHolderName != "" && this.state?.gstNo && this.state?.gstNo != ""){

                    }
                    else{
                        gstError = true;
                    }
                }

                if(travelersDetailsError){
                    travelersDetailsAddBtn.push(<Popup
                        trigger={<Button variant="outline-secondary button"><Lock></Lock> ADD</Button>}
                        content='First fill all above travelers details'
                        inverted
                    />);
                    
                    continueToPaymentBtnMobile.push(
                        <Popup
                        trigger={<Button variant="contained" type="button" className="w-100 color-w bg-gradient border-0 pd-10">
                                <b> <Lock></Lock> Continue To Payment</b>
                            </Button>}
                        content='First fill all above details'
                        inverted
                    />
                    );
                    continueToPaymentBtn.push(
                        <Popup
                        trigger={<Button variant="contained" type="button" className="color-w bg-gradient border-0 pd-10">
                                <b> <Lock></Lock> Continue To Payment</b>
                            </Button>}
                        content='First fill all above details'
                        inverted
                    />
                    );
                }
                else{
                    console.log("gstError ==== [][][][][]", gstError);
                    travelersDetailsAddBtn.push(<Button variant="outline-secondary button" onClick={this.getSeatMaps}>ADD</Button>);
                    if(this.state?.customerMobile.length == 10 && this.validateMobile(this.state.customerMobile) && this.state?.customerEmail != '' && this.validateEmail(this.state.customerEmail) && !gstError){
                        
                        if(this.state.paymentBtnLoader){
                            continueToPaymentBtn.push(
                                <Button variant="contained" disabled type="button" className="color-w bg-gradient border-0 pd-10">
                                    <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                    Please Wait...
                                </Button>
                            );
                            continueToPaymentBtnMobile.push(
                                <Button variant="contained" disabled type="button" className="w-100 color-w bg-gradient border-0 pd-10">
                                    <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                    Please Wait...
                                </Button>
                                // <Button variant="contained" type="button" className="w-100 color-w bg-gradient border-0 pd-10">
                                //     <b>Continue To Payment</b>
                                // </Button>
                            );  
                        }
                        else{
                            continueToPaymentBtn.push(
                                <Button variant="contained" type="button" className="color-w bg-gradient border-0 pd-10" onClick={() =>this.toggleConfirmationPopup()}>
                                    <b>Continue To Payment</b>
                                </Button>
                            );
                            continueToPaymentBtnMobile.push(
                                <Button variant="contained" type="button" className="w-100 color-w bg-gradient border-0 pd-10" onClick={() =>this.toggleConfirmationPopup()}>
                                    <b>Continue To Payment</b>
                                </Button>
                            );
                        }
                    }
                    else{
                        continueToPaymentBtnMobile.push(
                            <Popup
                            trigger={<Button variant="contained" type="button" className="w-100 color-w bg-gradient border-0 pd-10">
                                    <b> <Lock></Lock> Continue To Payment</b>
                                </Button>}
                            content='First fill all above details'
                            inverted
                        />
                        );
                        continueToPaymentBtn.push(
                        <Popup
                        trigger={<Button variant="contained" type="button" className="color-w bg-gradient border-0 pd-10">
                                <b> <Lock></Lock> Continue To Payment</b>
                            </Button>}
                        content='First fill all above details'
                        inverted
                        />
                        );
                    }
                }

                if(this.state.gst){
                    visibility = {display: ''};
                    addVisibility = {display: 'none'};
                }
                
                flightsData.forEach((e, i) =>{
                    if(i == 1){
                        item.push(<FlightDetails data={e} fareRules={this.state.fareRules[i]} heading="RETURN" showFareRulesPopup={this.showFareRulesPopup} travelClass={travelClass} />);
                    }
                    else{
                        item.push(<FlightDetails data={e} fareRules={this.state.fareRules[i]} heading="DEPARTURE" showFareRulesPopup={this.showFareRulesPopup} travelClass={travelClass} />);
                    }

                    
                });

                let userData = localStorage.getItem('OoroUserData');
                let displayLoginBlock = 'none';
                if(userData){
                    userData = JSON.parse(userData);
                    displayLoginBlock = 'block';
                }
                let parentIndex = this.state.parentIndex;
                let childIndex = this.state.childIndex;

                let selectedBaggage = travelersDetails[parentIndex]?.data[childIndex]?.baggage;
                let selectedMeals = travelersDetails[parentIndex]?.data[childIndex]?.meals;
                //console.log("== this.state.currentFareRules ==", this.state.currentFareRules);

                let passengerList = [];

                travelersDetails.map(e =>{
                    if(e.label == "Adult" || e.label == "Child"){
                        e.data.map(ee =>{
                            passengerList.push(ee);
                        });
                    }
                });

                let selectedSeats = seatMapData;

                let passengerTr = [];
                passengerList.map((p, i) =>{
                    let td = [];
                    td.push(<td>{p?.fName} {p?.lName}</td>);
                    td.push(<td>{selectedSeats[0]?.seats[i]?.SSR_TypeName}</td>);
                    if(selectedSeats.length == 2){
                        td.push(<td>{selectedSeats[1]?.seats[i]?.SSR_TypeName}</td>);
                    }
                    passengerTr.push(<tr>{td}</tr>);
                });

                let seatsMapTable = [];
                let returnTh = [];
                if(selectedSeats.length == 2){
                    returnTh.push(<th>Return</th>);
                }

                if(selectedSeats[0]?.seats.length > 0 || selectedSeats[1]?.seats.length > 0){
                    seatsMapTable.push(
                        <Grid container className="px-5 py-2 pb-0 xs-px-1 xs-py-1">
                            <Grid item xs={12}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Travellers Name</th>
                                            <th>Departure</th>
                                            {returnTh}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {passengerTr}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    );
                }
                let seatsOrFeatching = [];
                if(this.state?.fetchingSeats){
                    seatsOrFeatching.push(
                        <Grid container className="px-2 py-4 pb-0" style={{marginTop:'100px'}}>
                            <Grid item xs={12} className="pt-2 d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
                                <Loading text="Fetching Seats For You" />
                            </Grid>
                        </Grid>
                    );
                }
                else{
                    seatsOrFeatching.push(<SeatMaps data={airRepricedData} travelersDetails={travelersDetails} seatMapData={seatMapData} totalSeats={totalSeats} onClosed={this.addSeats} onHandle={this.selectedSeats} />);
                }


                let customerMobileErrorText = [];
                let customerEmailErrorText = [];

                if(this.state.customerMobileError){
                    customerMobileErrorText.push(<p style={{fontSize: '10px', color:'red'}}>Please Entere Valid Mobile No.</p>);
                }

                if(this.state.customerEmailError){
                    customerEmailErrorText.push(<p style={{fontSize: '10px', color:'red'}}>Please Entere Valid Email</p>);
                }

                return (
                <div className="ReviewFlight xs-pb-8" style={{backgroundColor:'#F8F8F8', height:'100vh', overflow:'auto'}}>
                    <Header onLoggedIn={this.checkLogin} openSideBar={(status) =>this.props.openSideBar(status)} nav={this.props.nav} />
                    <Modal size="lg" dialogClassName="modal-90w" className="p-0" centered show={this.state.sessionExpired}
                    backdrop="static" keyboard={false}
                    >
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">
                                
                                <SessionExpired data={this.state.searchData} />
                            </card>
                        </Modal.Body>
                    </Modal>
                    <Modal size="lg" dialogClassName="modal-90w" className="p-0" centered show={this.state.confirmationPopup}
                    backdrop="static" keyboard={false}
                    >
                        <img src={multiply} className="position-absolute a" width="25px" style={{top:10, right:10, zIndex:10}} onClick={this.toggleConfirmationPopup} />
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">
                                
                                <ConfirmationPopup data={this.state} onConfirmClick={(status) => { this.toggleConfirmationPopup(); this.tempBooking();}} />
                            </card>
                        </Modal.Body>
                    </Modal>
                    <Modal size="lg" dialogClassName="modal-90w modal-95w" className="p-0" centered show={this.state.addSeats} onHide={this.addSeats} 
                    backdrop="static" keyboard={false}
                    >
                        <img src={multiply} className="position-absolute a" width="25px" style={{top:10, right:10, zIndex:10}} onClick={this.addSeats} />
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">
                                {seatsOrFeatching}
                            </card>
                        </Modal.Body>
                    </Modal>

                    <Modal size="lg" dialogClassName="modal-90w" className="p-0" centered show={this.state.addMeals} onHide={this.addMeals} 
                    backdrop="static" keyboard={false}
                    >
                        <img src={multiply} className="position-absolute a" width="20px" style={{top:10, right:10, zIndex:10}} onClick={this.addMeals} />
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">
                                
                                <Meals data={flightsData} onHandle={this.setMealsData} onClosed={this.addMeals} selectedData={selectedMeals} />
                            </card>
                        </Modal.Body>
                    </Modal>
                    <Modal size="lg" dialogClassName="modal-90w" className="p-0" centered show={this.state.addBaggage} onHide={this.addBaggage} 
                    backdrop="static" keyboard={false}
                    >
                        <img src={multiply} className="position-absolute a" width="20px" style={{top:10, right:10, zIndex:10}} onClick={this.addBaggage} />
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">
                                
                                <Baggage data={flightsData} onHandle={this.setBaggageData} onClosed={this.addBaggage} selectedData={selectedBaggage} />
                            </card>
                        </Modal.Body>
                    </Modal>
                    <Modal size="lg" dialogClassName="modal-90w" className="p-0" centered show={this.state.fareRulesPopup} onHide={this.fareRulesPopup} 
                    backdrop="static" keyboard={false}
                    >
                        <img src={multiply} className="position-absolute a" width="25px" style={{top:10, right:10, zIndex:10}} onClick={this.fareRulesPopup} />
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">

                                <FareRules data={this.state.currentFareRules} />
                                
                            </card>
                        </Modal.Body>
                    </Modal>
                    <div className="main-container">
                        <Grid container className="pb-0 xs-mt-8" style={{marginTop:'100px'}}>
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9} className="px-3 pe-1 py-3 xs-p-1">
                                <Grid container>
                                    <Grid item xs={12} className="mt-3 sm-device-block sm-device-block md-device-block lg-device-block xl-device-block">
                                        <h3 className="float-left mb-3">REVIEW YOUR FLIGHT DETAILS</h3>
                                        {/* <NavLink to="/search-results">
                                            <p className="float-right a m-0 p-0" style={{fontSize:'18px'}}>Back To Search</p>
                                        </NavLink> */}
                                    </Grid>
                                    <Grid item xs={12} className="mb-3 xs-mb-1">
                                        {item}
                                        <Card className="box-shadow px-3 login-details xs-px-1" style={{display:displayLoginBlock}}>
                                            <Grid container>
                                                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                                    <div className="float-left p-4 xs-p-1">
                                                        <h2 className="p logged-in-as">Logged In As</h2>
                                                    </div>
                                                    <div className="float-left p-3 xs-p-1">
                                                        <div className="bg-gradient p-2 mt-2 float-left" style={{borderRadius:'20px'}}>
                                                            <MailOutlineIcon className="svg-white mail-icon"></MailOutlineIcon>
                                                        </div>
                                                        <div className="float-left ps-3">
                                                            <p className="p-0 m-0" style={{fontSize:'20px'}}>{userData?.Email}</p>
                                                            <p className="p-0 m-0">Details will be send to this address</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                {/* <Grid item xs={4} className="p-4">
                                                    <Button className="bg-gradient p-2" style={{width:'135px',float:'right',color:'white',border:'0px solid black'}}>
                                                        Logout
                                                    </Button>
                                                </Grid> */}
                                            </Grid>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} className="py-3 xs-device-block xs-py-0 xs-mb-1">
                                        <Grid container className="px-2 xs-px-0 xs-py-0">
                                            <Grid item xs={12} className="p-2 mt-2 xs-mt-n1 xs-p-0 promo-code-details">
                                                <h3 className="p mb-1" style={{fontSize: '15px'}}>Promo Code</h3>
                                                <PromoCode data={this.state.coupon} appliedCoupon={this.state?.appliedCoupon} onHandle={this.setAppliedCoupon} onCancel={this.cancelCoupon} />
                                            </Grid>
                                            <Grid item xs={12} className="mt-3 xs-mt-1">
                                                <FareDetailList ccf={this.state.ccf} data={this.state.airRepricedData} seatMapData={this.state.seatMapData} travelerDetails={this.state.travelersDetails} appliedCoupon={this.state?.appliedCoupon} />
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="mb-3" style={{display:'none'}}> 
                                        <h2 className="p">Travel Insurance</h2>
                                    </Grid>
                                    <Grid item xs={12} className="mb-3" style={{display:'none'}}> 
                                        <TravelInsurance />
                                    </Grid>
                                    <Grid item xs={12} className="mb-3"> 
                                        <h2 className="p travellers-details-heading">Travellers Details</h2>
                                    </Grid>
                                    <Grid item xs={12} className="mb-3"> 
                                        <TravelersDetails travelersDetails={travelersDetails} data={data} onHandel={this.add} addBaggage={this.addBaggage} addMeals={this.addMeals} setFormValue={this.setFormValue}  />
                                    </Grid>
                                    <Grid item xs={12} className="mb-3"> 
                                        <h2 className="p travellers-details-heading">Contact Information</h2>
                                    </Grid>
                                    <Grid item xs={12} className="mb-3 contact-information"> 
                                        <Card className="box-shadow">
                                            <Grid container className="px-5 py-2 pb-0 xs-px-1 xs-py-0">
                                                <Grid item xs={2} sm={1} md={1} lg={1} xl={1} className="py-1">
                                                    <img src={TripDelay} width="50px" />
                                                </Grid>
                                                <Grid item xs={10} sm={11} md={11} lg={11} xl={11} className="py-4 xs-py-1 xs-pt-2">
                                                    <h4>Your ticket and flights information will be sent here.</h4>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="px-5 py-2 xs-px-1 xs-py-1 pb-4 xs-pb-1">
                                                <Grid item xs={12} className="py-2 border">
                                                    <Grid container className="py-3 px-4 xs-px-1 xs-py-1">
                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="px-2 xs-mb-1">
                                                            <Inputs label={<div style={{width:'60px',padding:'8px 5px',border: '1px solid rgba(34,36,38,.15)',borderRadius:'3px 0px 0px 3px'}}><Flag name='in'> </Flag><span>+91</span></div>} type="number" name="" id="" parentIndex="0" childIndex="0" placeHolder="Mobile No." value={this.state.customerMobile} classes="" style={{width:'100%'}} onChange={this.setCusomerMobile} />
                                                            {customerMobileErrorText}
                                                        </Grid>
                                                        {/* <Grid item xs={1} className="px-2"></Grid> */}
                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="px-2">
                                                            <Inputs name="" type="email" id="" placeHolder="Email" parentIndex="0" childIndex="0" value={this.state.customerEmail} classes="" style={{width:'100%'}} onChange={this.setCusomerEmail} />
                                                            {customerEmailErrorText}
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="px-2 xs-px-0">
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} className="mb-3 gst-block"> 
                                        <Card className="box-shadow">
                                            <Grid container className="px-5 py-2 xs-px-1 xs-py-1 pb-0">
                                                <Grid item xs={2} sm={1} md={1} lg={1} xl={1} className="py-1">
                                                    <img src={TripDelay} width="50px" />
                                                </Grid>
                                                <Grid item xs={8} sm={9} md={9} lg={9} xl={9} className="py-4">
                                                    <h4 className="p-0 m-0">Use GSTIN for this booking (Optional)</h4>
                                                    {/* <p className="p-0 m-0">Clam credit for GST charges. Your texes may get updated post submitting your GST details.</p> */}
                                                </Grid>
                                                <Grid item xs={2} className="py-3 text-right xs-py-0 xs-pe-1">
                                                    <Button variant="outline-secondary button" style={addVisibility} onClick={this.addCancelGst}>ADD</Button>
                                                    <Button variant="outline-danger button" style={visibility} onClick={this.addCancelGst}>CANCEL</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="px-5 py-2 pb-4 xs-px-1 xs-py-1" style={visibility}>
                                                <Grid item xs={12} className="py-2 border">
                                                    <Grid container className="py-3 px-4 xs-px-1 xs-py-1">
                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="px-2 xs-mb-1">
                                                            <label for="cName" className="mb-2">Company Name</label>
                                                            <Inputs name="" type="text" id="cName" placeHolder="Company Name" parentIndex="0" childIndex="0" value={this.state.gstHolderName} classes="" style={{width:'100%'}} onChange={this.setGstHolderName} />
                                                        </Grid>
                                                        {/* <Grid item xs={1} className="px-2"></Grid> */}
                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="px-2">
                                                            <label for="rNo" className="mb-2">Registration No.</label>
                                                            <Inputs name="" type="text" id="rNo" placeHolder="Registration No." parentIndex="0" childIndex="0" value={this.state.gstNo} classes="" style={{width:'100%'}} onChange={this.setGstNo} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="px-2 xs-px-0 xs-text-center pt-2">
                                                            {/* <Button variant="outline-secondary mt-4 xs-w-90">Verify</Button> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                    {this.state.showSheetMap ? (
                                        <Grid item xs={12} className="mb-3 seats-detail"> 
                                            <Card className="box-shadow">
                                                <Grid container className="px-5 py-2 pb-0 xs-px-1 xs-py-1">
                                                    <Grid item xs={2} sm={1} md={1} lg={1} xl={1} className="py-1">
                                                        <img src={Seat} width="50px" style={{color:'red'}} />
                                                    </Grid>
                                                    <Grid item xs={8} sm={9} md={9} lg={9} xl={9} className="py-4">
                                                        <h4 className="p-0 m-0">Add Seats for the flight (Optional)</h4>
                                                        {/* <p className="p-0 m-0">Clam credit for GST charges. Your texes may get updated post submitting your GST details.</p> */}
                                                    </Grid>
                                                    <Grid item xs={2} className="py-3 text-right">
                                                        {travelersDetailsAddBtn}
                                                    </Grid>
                                                </Grid>
                                                {seatsMapTable}
                                            </Card>
                                        </Grid>
                                    ) : (<></>) }
                                    <Grid item xs={12} className="mb-3 text-right sm-device-block md-device-block lg-device-block xl-device-block"> 
                                    {/* <NavLink to="/payment"> */}
                                        {continueToPaymentBtn}
                                    {/* </NavLink> */}
                                    </Grid>
                                </Grid>  
                                <div item xs={12} className="mb-3 xs-mb-0 xs-px-1 xs-pb-1 text-right xs-device-block position-absolute w-100" style={{bottom: 0, left: 0}}> 
                                    {continueToPaymentBtnMobile}
                                </div>  
                            </Grid>
                            <Grid item xs={3} className="py-3 sm-device-block md-device-block lg-device-block xl-device-block">
                                <Grid container className="px-2">
                                    <Grid item xs={12} className="mt-3">
                                        <h3>Fare Detail</h3>
                                        <FareDetailList ccf={this.state.ccf} data={this.state.airRepricedData} seatMapData={this.state.seatMapData} travelerDetails={this.state.travelersDetails} appliedCoupon={this.state?.appliedCoupon} />
                                    </Grid>
                                    <Grid item xs={12} className="p-2 mt-2">
                                        <h3>Promo Code</h3>
                                        <PromoCode data={this.state.coupon} appliedCoupon={this.state?.appliedCoupon} onHandle={this.setAppliedCoupon} onCancel={this.cancelCoupon} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        {/* <Grid container className="footer" >
                            <Grid item xs className="p-0">
                                <Card className="my-card p-0" style={{borderRadius:'0px'}}>
                                    <CardContent className="position-relative px-5">
                                        <OurFamily />
                                    </CardContent>
                                    <hr />
                                    <CardActions>
                                        <Footer />
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid> */}
                    </div>
                </div>
                );
            }
            else{
                return <></>;
            }
        } catch (error) {
            throw new Error(error);
        }
    }
  }
  
export default ReviewFlight;