// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import artboard1 from '../../assets/Icons/Artboard–1.png';
import iciciLogo from '../../assets/Icons/icici-logo.png';
import TripCancellation from '../../assets/Icons/trip-cancellation.png';
import TripDelay from '../../assets/Icons/trip-delay.png';
import LostBaggage from '../../assets/Icons/lost-baggage.png';
import MedicalExpenses from '../../assets/Icons/medical-expenses.png';
import ViewAll from '../../assets/Icons/view-all.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { Card } from "react-onsenui";
import Selects from "../ui/Selects";
import Inputs from "../ui/Inputs";
import TreeViews from "../ui/TreeViews";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Select } from 'semantic-ui-react'
import Checkbox from '@material-ui/core/Checkbox';
import baggage from "../../assets/Icons/baggage.svg";
import fork from "../../assets/Icons/fork.svg";


const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];

const dateOptions = [];
const monthOptions = [];
const yearOptions = [];

const currentDate = new Date();

for (let index = 1; index <= 31; index++) {
    dateOptions.push({ key: 'd'+index, value: index, text: index });
}

for (let index = 1; index <= 12; index++) {
    monthOptions.push({ key: 'm'+index, value: index, text: monthNames[index] });
}

for (let index = 0; index < 3; index++) {
    yearOptions.push({ key: 'y'+index, value: (currentDate.getFullYear() - index), text: (currentDate.getFullYear() - index) });
}


function Header(prop) {
    try{
        return(
            <div className="bg-white" style={{fontSize:'16px'}}>
                <span className="float-left">
                    <b>{prop.LValue}</b>
                </span>
                <span className="float-right" style={{fontSize:'16px'}}>
                    <b>{prop.RValue}</b>
                </span>
            </div>
        );
    } catch (error) {
        throw new Error(error);
    }
}

function RightV(prop){
    try{
        return(
            <span>{prop.v1}/{prop.v2}</span>
        );
    } catch (error) {
        throw new Error(error);
    }
}

function Body(prop) {
    try{
        const handelClick = (event) =>{
            //console.log(event);
            let value = event.currentTarget.dataset.value;
            console.log("=== value ===",value);
            // this.setState({value:value});
            prop.onHandel(value);
        }

        const addBaggage = (event) =>{
            let childIndex = event.currentTarget.dataset.childindex;
            let parentIndex = event.currentTarget.dataset.parentindex;

            console.log(event.currentTarget.dataset);
            prop.addBaggage({status:true, childIndex: childIndex, parentIndex: parentIndex});
        }

        const addMeals = (event) =>{
            let childIndex = event.currentTarget.dataset.childindex;
            let parentIndex = event.currentTarget.dataset.parentindex;

            console.log(event.currentTarget.dataset);
            prop.addMeals({status:true, childIndex: childIndex, parentIndex: parentIndex});
        }

        const setFirstName = (data, value) =>{
            let d = {fName: value, ...data};
            //console.log("=== complete value ===", d);
            let pd = prop.datas;
            pd[data.childIndex].fName = value;
            //console.log(pd);
            prop.setFormValue({parentIndex: data.parentIndex, data: pd});
        }

        const setGender = (value, parentIndex, childIndex) =>{
            let pd = prop.datas;
            pd[childIndex].gender = value;
            console.log(" == setGender ==",value, parentIndex, childIndex);
            prop.setFormValue({parentIndex: parentIndex, data: pd});
        }

        const getNoOfDays = (startDate) =>{
            let today = new Date().toISOString().slice(0, 10)
            // const startDate  = '2021-04-15';
            const endDate    = today;
            const diffInMs   = new Date(endDate) - new Date(startDate)
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            return diffInDays;
        }

        const setDOB = (event, value, parentIndex, childIndex, type) =>{
            let pd = prop.datas;
            if(pd[childIndex].dob ==  ''){
                let dob = {
                    d:0,
                    m:0,
                    y:0
                }
                if(type == 'd'){
                    dob.d = value;
                }
                else if(type == 'm'){
                    dob.m = value;
                }
                else if(type == 'y'){
                    dob.y = value;
                }
                pd[childIndex].dob = dob;
            }
            else{
                let dob = pd[childIndex].dob;
                if(type == 'd'){
                    dob.d = value;
                }
                else if(type == 'm'){
                    dob.m = value;
                }
                else if(type == 'y'){
                    dob.y = value;
                }
                pd[childIndex].dob = dob;
            }

            if(pd[childIndex].dob !='' && pd[childIndex].dob.d > 0 && pd[childIndex].dob.m > 0 && pd[childIndex].dob.y > 0){
                let startDate = `${pd[childIndex].dob.y}-${pd[childIndex].dob.m}-${pd[childIndex].dob.d}`;
                let noOfDays = getNoOfDays(startDate);
                console.log("===== No of infant days ====", noOfDays);
                if(noOfDays > 730){
                    pd[childIndex].dateError = true;
                }
                else{
                    pd[childIndex].dateError = false;
                }
            }
            
            console.log("== setDOB ==", value, parentIndex, childIndex, type);
            prop.setFormValue({parentIndex: parentIndex, data: pd});
        }

        const setLastName = (data, value) =>{
            let d = {lName: value, ...data};
            //console.log("=== complete value ===", d);
            let pd = prop.datas;
            pd[data.childIndex].lName = value;

            //console.log(pd);

            prop.setFormValue({parentIndex: data.parentIndex, data: pd});
        }

        const items = [];
        const Options = [
            { key: 'Male', value: 'male', text: 'Male' },
            { key: 'Female.', value: 'female', text: 'Female' },
        ]

        console.log(prop.datas);
        prop.datas.forEach((element, index) => {

            let dateError = [];
            if(element.dateError && element?.dateError === true){
                dateError.push(<label for="error" style={{color: 'red', fontSize: '12px'}}>Date should be less then or equal to 2 years</label>);
            }
            
            if(prop.label == 'Infant'){
                if((index+1) != prop.datas.length){
                    items.push(
                        <>
                        <Grid container className="pt-2">
                            <Grid item xs={1} className="py-3">
                                <label><b>{prop.label} {index + 1}</b></label>
                            </Grid>
                            <Grid item xs className="p-2">
                                <Inputs name="" id="" placeHolder="First Name/ Given Name" value={element.fName} parentIndex={prop.parentIndex} childIndex={index} onChange={setFirstName} classes="" style={{width:'100%'}} />
                            </Grid>
                            <Grid item xs className="p-2">
                                <Inputs name="" id="" placeHolder="Last Name/ Surname" value={element.lName} parentIndex={prop.parentIndex} childIndex={index} onChange={setLastName} classes="" style={{width:'100%'}} />
                            </Grid>
                            <Grid item xs className="p-2">
                                <Select style={{minWidth:'100%', borderRadius:'5px 0px 0px 5px'}} placeholder='Gender' options={Options} onChange={(event, data) => {setGender(data.value, prop.parentIndex, index)}} />
                                {/* <Selects classes="" placeHolder="Gender" Options={Options} style={{minWidth:'100%'}} onChange={(value) => {setGender(value, prop.parentIndex, index)}}  /> */}
                            </Grid>
                            <Grid item xs={2} className="p-1">
                                
                                {/* <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addBaggage}>
                                    <img src={baggage} width="20px" />
                                </IconButton>
                                <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addMeals}>
                                    <img src={fork} width="20px" />
                                </IconButton> */}
                                {/* <IconButton aria-label="delete" color="primary">
                                    <AddIcon />
                                </IconButton>     */}
                            </Grid>
                        </Grid>
                        <Grid container className="pb-2">
                            <Grid item xs={1} className="py-3"></Grid>
                            <Grid item xs={4} className="p-2">
                                <div style={{display:'inline-flex'}}>
                                    <Select style={{minWidth:'33%', borderRadius:'5px 0px 0px 5px'}} placeholder='Date' options={dateOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'd')}} />
                                    <Select style={{minWidth:'33%', borderRadius:'0px 0px 0px 0px'}} placeholder='Month' options={monthOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'm')}} />
                                    <Select style={{minWidth:'33%', borderRadius:'0px 5px 5px 0px'}} placeholder='Year' options={yearOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'y')}} />
                                    {/* <Inputs name="" id="" placeHolder="DOB" type="date" value="" classes="" style={{width:'100%'}} /> */}
                                </div>
                                {dateError}
                            </Grid>
                        </Grid>
                        </>
                    )
                }
                else if(prop.v1 == prop.v2){
                    items.push(
                        <>
                        <Grid container className="pt-2">
                            <Grid item xs={1} className="py-3">
                                <label><b>{prop.label} {index + 1}</b></label>
                            </Grid>
                            <Grid item xs className="p-2">
                                <Inputs name="" id="" placeHolder="First Name/ Given Name" value={element.fName} parentIndex={prop.parentIndex} childIndex={index} onChange={setFirstName} classes="" style={{width:'100%'}} />
                            </Grid>
                            <Grid item xs className="p-2">
                                <Inputs name="" id="" placeHolder="Last Name/ Surname" value={element.lName} parentIndex={prop.parentIndex} childIndex={index} onChange={setLastName} classes="" style={{width:'100%'}} />
                            </Grid>
                            <Grid item xs className="p-2">
                                <Select style={{minWidth:'100%', borderRadius:'5px 0px 0px 5px'}} placeholder='Gender' options={Options} onChange={(event, data) => {setGender(data.value, prop.parentIndex, index)}} />
                                {/* <Selects classes="" placeHolder="Gender" Options={Options} style={{minWidth:'100%'}} onChange={(value) => {setGender(value, prop.parentIndex, index)}} /> */}
                            </Grid>
                            <Grid item xs={2} className="p-1">
                                
                                {/* <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addBaggage}>
                                    <img src={baggage} width="20px" />
                                </IconButton>
                                <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addMeals}>
                                    <img src={fork} width="20px" />
                                </IconButton> */}
                                {/* <IconButton aria-label="delete" color="primary">
                                    <AddIcon />
                                </IconButton>     */}
                            </Grid>
                        </Grid>
                        <Grid container className="pb-2">
                            <Grid item xs={1} className="py-3"></Grid>
                            <Grid item xs={4} className="p-2">
                                <div style={{display:'inline-flex'}}>
                                    <Select style={{minWidth:'33%', borderRadius:'5px 0px 0px 5px'}} placeholder='Date' options={dateOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'd')}} />
                                    <Select style={{minWidth:'33%', borderRadius:'0px 0px 0px 0px'}} placeholder='Month' options={monthOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'm')}} />
                                    <Select style={{minWidth:'33%', borderRadius:'0px 5px 5px 0px'}} placeholder='Year' options={yearOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'y')}} />
                                    {/* <Inputs name="" id="" placeHolder="DOB" type="date" value="" classes="" style={{width:'100%'}} /> */}
                                </div>
                                {dateError}
                            </Grid>
                        </Grid>
                        </>
                    )
                }
                else{
                    items.push(
                        <>
                        <Grid container className="pt-2">
                            <Grid item xs={1} className="py-3">
                                <label><b>{prop.label} {index + 1}</b></label>
                            </Grid>
                            <Grid item xs className="p-2">
                                <Inputs name="" id="" placeHolder="First Name/ Given Name" value={element.fName} parentIndex={prop.parentIndex} childIndex={index} onChange={setFirstName} classes="" style={{width:'100%'}} />
                            </Grid>
                            <Grid item xs className="p-2">
                                <Inputs name="" id="" placeHolder="Last Name/ Surname" value={element.lName} parentIndex={prop.parentIndex} childIndex={index} onChange={setLastName} classes="" style={{width:'100%'}} />
                            </Grid>
                            <Grid item xs className="p-2">
                                <Select style={{minWidth:'100%', borderRadius:'5px 0px 0px 5px'}} placeholder='Gender' options={Options} onChange={(event, data) => {setGender(data.value, prop.parentIndex, index)}} />
                                {/* <Selects classes="select-gender" placeHolder="Gender" Options={Options} style={{minWidth: '100%'}} onChange={(value) => {setGender(value, prop.parentIndex, index)}} /> */}
                            </Grid>
                            <Grid item xs={2} className="p-1">
                                {/* <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addBaggage}>
                                    <img src={baggage} width="20px" />
                                </IconButton>
                                <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addMeals}>
                                    <img src={fork} width="20px" />
                                </IconButton> */}
                                <IconButton aria-label="delete" color="primary" data-value={prop.label} onClick={handelClick}>
                                    <AddIcon />
                                </IconButton>    
                            </Grid>
                        </Grid>
                        <Grid container className="pb-2">
                            <Grid item xs={1} className="py-3"></Grid>
                            <Grid item xs={4} className="p-2">
                                <div style={{display:'inline-flex'}}>
                                    <Select style={{minWidth:'33%', borderRadius:'5px 0px 0px 5px'}} placeholder='Date' options={dateOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'd')}} />
                                    <Select style={{minWidth:'33%', borderRadius:'0px 0px 0px 5px'}} placeholder='Month' options={monthOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'm')}} />
                                    <Select style={{minWidth:'33%', borderRadius:'0px 5px 5px 0px'}} placeholder='Year' options={yearOptions} onChange={(event, data) => {setDOB(event, data.value, prop.parentIndex, index, 'y')}} />
                                    {/* <Inputs name="" id="" placeHolder="DOB" type="date" value="" classes="" style={{width:'100%'}} /> */}
                                </div>
                                {dateError}
                            </Grid>
                        </Grid>
                        </>
                    )
                }
            }
            else{
                let addBtn = [];
                if((index+1) != prop.datas.length){
                    
                }
                else if(prop.v1 == prop.v2){
                    
                }
                else{
                    addBtn.push(
                        <IconButton aria-label="delete" color="primary" data-value={prop.label} onClick={handelClick}>
                            <AddIcon />
                        </IconButton> 
                    );    
                }

                items.push(
                    <Grid container className="py-2">
                        <Grid item xs={6} sm={1} md={1} lg={1} xl={1}    className="py-3">
                            <label><b>{prop.label} {index + 1}</b></label>
                        </Grid>
                        <Grid item xs={6} sm={2} md={2} lg={2} xl={2} className="p-1 xs-device-block text-end">
                            <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addBaggage}>
                                <img src={baggage} width="20px" />
                            </IconButton>
                            <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addMeals}>
                                <img src={fork} width="20px" />
                            </IconButton>
                            {addBtn}   
                        </Grid>
                        <Grid item xs={12} sm md lg xl className="p-2">
                            <Inputs name="" id="" placeHolder="First Name/ Given Name" value={element.fName} parentIndex={prop.parentIndex} childIndex={index} onChange={setFirstName} classes="" style={{width:'100%'}} />
                        </Grid>
                        <Grid item xs={12} sm md lg xl className="p-2">
                            <Inputs name="" id="" placeHolder="Last Name/ Surname" value={element.lName} parentIndex={prop.parentIndex} childIndex={index} onChange={setLastName} classes="" style={{width:'100%'}} />
                        </Grid>
                        <Grid item xs={12} sm md lg xl className="p-2">
                            <Select style={{minWidth:'100%', borderRadius:'5px 0px 0px 5px'}} placeholder='Gender' options={Options} onChange={(event, data) => {setGender(data.value, prop.parentIndex, index)}} />
                            {/* <Selects classes="select-gender" placeHolder="Gender" Options={Options} style={{minWidth: '100%'}} onChange={(value) => {setGender(value, prop.parentIndex, index)}} onChange={(value) => {setGender(value, prop.parentIndex, index)}} /> */}
                        </Grid>
                        <Grid item xs={6} sm={2} md={2} lg={2} xl={2} className="p-1 sm-device-block md-device-block lg-device-block xl-device-block">
                            <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addBaggage}>
                                <img src={baggage} width="20px" />
                            </IconButton>
                            <IconButton aria-label="delete" color="secondary" style={{color:'red'}} data-parentIndex={prop.parentIndex} data-childIndex={index} onClick={addMeals}>
                                <img src={fork} width="20px" />
                            </IconButton>
                            {addBtn}
                        </Grid>
                    </Grid>
                )
            }
        })  
        return(
            <>
            {items} 
            </>
        );
    } catch (error) {
        throw new Error(error);
    }
}

class TravelersDetails extends Component {
    state = {
        value: true,
    }

    
    hasChangeValue = (event) => this.setState({ value: event.target.value });

    getNoOfDays = (startDate) =>{
        let today = new Date().toISOString().slice(0, 10)
        // const startDate  = '2021-04-15';
        const endDate    = today;
        const diffInMs   = new Date(endDate) - new Date(startDate)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays;
    }

    componentDidMount(){
        
    };



    render(){
        try{
            let datas = [];
            this.props.travelersDetails.forEach((element, i) =>{
                let errorMsg = [];

                if(element.number == element.data.length){
                    errorMsg.push(<>{element.label}</>);
                }
                else{
                    errorMsg.push(<>{element.label} <span style={{color:'red', fontSize: '12px', marginLeft: '10px'}}>Please select / add {element.number - element.data.length} {element.label}</span></>);
                }
                datas.push({id:(i+1),label:<Header LValue={errorMsg} RValue={<RightV v1={element.data.length} v2={element.number} />} />,body:<Body parentIndex={i} label={element.label} datas={element.data} v1={element.data.length} v2={element.number} onHandel={(value) =>{this.props.onHandel(this.props.travelersDetails, value)}} addBaggage={(data) => this.props.addBaggage(data)}  addMeals={(data) => this.props.addMeals(data)} setFormValue={(data) =>this.props.setFormValue(data)} />});
            });
            return(
                <div className="TravellerDetails box-shadow">
                    <Grid container>
                        <Grid item xs={12} className="bg-white px-0 py-0 pt-0">
                            <TreeViews classes="TravellerDetails-TreeViews" datas={datas} />
                        </Grid>
                        {/* <Grid item xs={12} className="bg-white px-0 py-1">
                            <Checkbox /> <span className="pt-1">Save Entire Traveller Details</span>
                        </Grid> */}
                    </Grid>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default TravelersDetails;