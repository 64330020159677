// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import Indigo from '../../assets/Icons/indigo.png';

// import library
import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Input, Popup } from 'semantic-ui-react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FlightMetaData from "./FlightMetaData";
import { Cancel, Clear, Lock } from "@material-ui/icons";

class PromoCode extends Component {

    state = {
        codeId: '',
        error: null,
        login: false,
        logOut: false
    }

    promoCode = "";

    token = localStorage.getItem('OoroToken');
    userData = localStorage.getItem('OoroUserData');

    constructor(props){
        super(props);
        if(props?.appliedCoupon?.code){
            this.promoCode = props?.appliedCoupon?.code;
        }
    }

    componentDidMount(){
        try{
            setInterval(() => {
                this.token = localStorage.getItem('OoroToken');
                this.userData = localStorage.getItem('OoroUserData');
                //console.log("== check token ==", token);
                if(this.token){
                    //console.log(token);
                    this.setState({login: true});
                    //this.cancelCoupon();
                }
                else{
                    this.setState({login: false});
                }
                //console.log(this.state);
                if(this.state.login == this.state.logOut){
                    console.log(this.token);
                    this.cancelCoupon();
                    this.setState({logOut: !this.state.logOut});
                };

            }, 500);
        } catch (error) {
            throw new Error(error);
        }
    };



    handleChange = (event) => {
        let cid = Number(event.target.value);
        //this.setState({ codeId: event.target.value });
        let data = this.props.data.filter(r => r.id == cid);
        if(data.length > 0){
            this.promoCode = data[0]?.code;
            console.log("=== selectedPromoCode ===", data[0]);
            this.props.onHandle(data[0]);
        }
    };

    searchPromoCode = (event, promoCode) =>{
        this.promoCode = promoCode.value;
        this.setState({error: null});
        //console.log("=== promoCode ===", this.promoCode);
    }

    cancelCoupon = () =>{
        this.promoCode = '';
        this.setState({error: null});
        this.props.onCancel(true);
    }

    onApply = (event) =>{
        try{
            if(this.promoCode.length > 0){
                let selectedPromoCode = this.props.data.filter(r => r.code == this.promoCode.toUpperCase());
                if(selectedPromoCode.length > 0){
                    console.log("=== selectedPromoCode ===", selectedPromoCode[0]);
                    this.props.onHandle(selectedPromoCode[0]);
                    this.setState({error: null});
                }
                else{
                    this.setState({error: 'Invalid Coupon Code'});
                }
            }
            else{
                this.setState({error: 'Enter Coupon Code'});
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    render(){
        try{
            let item = [];

            
            this.props.data.map((e, i) =>{
                let cancel = [];
                // if(e.id == this.props?.appliedCoupon?.id){
                //     cancel.push(<Cancel className="a" style={{position: 'absolute', right: '-5px', top: '15px'}} onClick={this.cancelCoupon} />);
                // }
                let lock = [];
                
                if(i == (this.props.data.length - 1)){
                    if(e.type == "public"){
                        item.push(<FormControlLabel className="mt-2 position-relative" value={`${e.id}`} control={<Radio />} label={<div className="mb-3"><h4 className="p-0 m-0">{e.code}</h4><p className="p-0 m-0" style={{fontSize: '12px'}}>{e.shortDesc}</p><a href={`${e.tos_url}`} target="_blank">Terms and Conditions</a>{cancel}</div>} />);
                    }
                    else{
                        if(this.token){
                            item.push(<FormControlLabel className="mt-2" value={`${e.id}`} control={<Radio />} label={<div className="mb-3 w-100"><h4 className="p-0 m-0">{e.code}</h4><p className="p-0 m-0" style={{fontSize: '12px'}}>{e.shortDesc}</p><a href={`${e.tos_url}`} target="_blank">Terms and Conditions</a></div>} />);
                        }
                        else{
                            item.push(<Popup content='Log-in to apply this offer!' trigger={<FormControlLabel className="mt-2" value={`${e.id}`} control={<div style={{padding:'0px 10px'}}><Lock /></div>} label={<div className="mb-3 w-100"><h4 className="p-0 m-0">{e.code}</h4><p className="p-0 m-0" style={{fontSize: '12px'}}>{e.shortDesc}</p><a href={`${e.tos_url}`} target="_blank">Terms and Conditions</a></div>} />} />);
                        }
                    }
                }
                else{
                    if(e.type == "public"){
                        item.push(<FormControlLabel className="border-bottom mt-2 position-relative" value={`${e.id}`} control={<Radio />} label={<div className="mb-3 w-100"><h4 className="p-0 m-0">{e.code}</h4><p className="p-0 m-0" style={{fontSize: '12px'}}>{e.shortDesc}</p><a href={`${e.tos_url}`} target="_blank">Terms and Conditions</a>{cancel}</div>} />);
                    }
                    else{
                        if(this.token){
                            item.push(<FormControlLabel className="mt-2" value={`${e.id}`} control={<Radio />} label={<div className="mb-3 w-100"><h4 className="p-0 m-0">{e.code}</h4><p className="p-0 m-0" style={{fontSize: '12px'}}>{e.shortDesc}</p><a href={`${e.tos_url}`} target="_blank">Terms and Conditions</a></div>} />);
                        }
                        else{
                            item.push(<Popup content='Log-in to apply this offer!' trigger={<FormControlLabel className="mt-2" value={`${e.id}`} control={<div style={{padding:'0px 10px'}}><Lock /></div>} label={<div className="mb-3 w-100"><h4 className="p-0 m-0">{e.code}</h4><p className="p-0 m-0" style={{fontSize: '12px'}}>{e.shortDesc}</p><a href={`${e.tos_url}`} target="_blank">Terms and Conditions</a></div>} />} />);
                        }
                    }
                }
            });

            let displayError = "none";
            if(this.state.error){
                displayError = "";
            }
            let clear = [];
            if(this.props?.appliedCoupon?.id){
                clear.push(<Popup trigger={<Cancel className="a clear-cancel" style={{position: 'absolute', right: '0px', top: '-15px'}} onClick={this.cancelCoupon} />}
                    content='Cancel Coupon'
                    inverted
                />);
            }

            return(
                <div className="PromoCode">
                    <Card  className="p-3 pb-4 box-shadow xs-p-1 xs-pb-2">
                        <Grid container className="pb-2">
                            <Grid item xs={12} className="apply-promocode">
                            <h4 className="xs-mb-1 apply-coupan">Apply Coupon</h4>
                            <div className="position-relative" style={{width:'100%'}}>
                            <Input placeholder='Enter Coupon Code' style={{width:'100%'}} onChange={this.searchPromoCode} />
                            <span className="position-absolute" style={{color:'red',right:'8px',top:'9px',cursor:'pointer'}} onClick={this.onApply}> Apply</span>
                            </div>
                            <small className="px-1" style={{color:'red', display: displayError}}> {this.state.error} </small>
                            </Grid>
                        </Grid>
                    </Card>
                        <div style={{marginTop:'-10px',marginBottom:'-10px',textAlign:'center'}}>
                            <span className="box-shadow or" style={{margin:'auto !important',padding:'7px',fontSize:'15px',border:'1px solid black',borderRadius:'50px'}}>OR</span>
                        </div>
                    <Card  className="p-3 pt-4 box-shadow xs-p-1" style={{marginTop:'-5px',background: 'white',borderRadius:'0px 0px 5px 5px'}}>
                        <Grid container className="">
                            <Grid item xs={12} className="promocode-list pt-2 position-relative">
                                <h4 className="xs-m-0">Choose from the offers below</h4>
                                {clear}
                                <Grid container className="pl-3 xs-ps-1" style={{maxHeight:'400px',overflow:'auto'}}>
                                    <Grid item xs={12}>
                                        <RadioGroup aria-label="gender" name="gender1" value={`${this.props?.appliedCoupon?.id}`} onChange={this.handleChange}>
                                            {item}
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={11}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default PromoCode;