import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
//console.log(sww.default);

export default class CustomNavLink extends React.Component {

  render() {
    try{
      let isHttp = false;

      if(this.props.url.startsWith('http') || this.props.url.startsWith('https')){
          isHttp = true;
      }


      if(isHttp){
          return <a href={this.props.url} target={this.props.target} style={this.props?.style}> {this.props?.children? this.props.children: this.props.name} </a>
      }
      else{
          return <NavLink to={this.props.url} target={this.props.target} style={this.props?.style}> {this.props?.children? this.props.children: this.props.name} </NavLink>
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}