import React from 'react';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import { NavLink, Redirect } from 'react-router-dom';
const notFound = require('../../assets/Icons/notFoundNewNoBg.png');
console.log(notFound.default);

export default class NotFound extends React.Component {

    refresh(){
        window.location.reload();        
    }
    render() {
        try{
            return (
                <>
                    <Grid container className="not-found">
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}></Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="text-center">
                            <img src={notFound.default} style={{width:"70%"}} />
                            <h1>No Results Found</h1>
                            <Grid container className="pt-4">
                                <Grid item xs={6} className="text-center xs-px-3">
                                    <NavLink to={{pathname:'/',}}>
                                        <Button variant="contained" type="button" className="shadow my-success-btn color-w bg-gradient border-0 pd-10 position-relative" style={{width:200}}>
                                            Home 
                                        </Button>
                                    </NavLink>
                                </Grid>
                                <Grid item xs={6} className="text-center xs-px-3">
                                    <Button variant="contained" type="button" className="shadow my-success-btn color-w bg-gradient border-0 pd-10 position-relative" style={{width:200}} onClick={this.refresh}>
                                        Refresh 
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}></Grid>
                    </Grid>     
                </>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}