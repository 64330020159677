import React from 'react';
import Grid from '@material-ui/core/Grid';
import planSeat from "../../assets/Icons/plan-seat.svg";
import planSeat1 from "../../assets/Icons/plane-seat11.svg";
import planSeat2 from "../../assets/Icons/plane-seat12.svg";
import planSeat3 from "../../assets/Icons/plane-seat3.svg";
import planSeat4 from "../../assets/Icons/plane-seat4.svg";
import planSeat5 from "../../assets/Icons/plane-seat5.svg";
import planSeat6 from "../../assets/Icons/plane-seat6.svg";
import aeroplane from "../../assets/Icons/aeroplane.svg";
import user from "../../assets/Icons/user.svg";
import headPlane from "../../assets/Icons/headP.png";
import tailPlane from "../../assets/Icons/tailP.png";
import check from "../../assets/Icons/check.svg";
import close from "../../assets/Icons/close.svg";
import lExit from "../../assets/Icons/left-exit.png";
import rExit from "../../assets/Icons/right-exit.png";
import cup from "../../assets/Icons/cup.png";
import lavatory from "../../assets/Icons/lavatory.png";
import { Button } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';


const SeatsRow = (prop) =>{
    try{
        //console.log("=== SeatsRow ===", prop.data);
        if(prop.data.SSR_Status == 1){
            if(prop.no == 1){
                return (<>
                    <img src={planSeat2} width="40px" />
                    <p className="p-0 m-0 seat-amount">₹{prop.data.Total_Amount}</p>
                    <div className="position-absolute py-3 text-center" style={{top:5, height:'50px', width:'60px', backgroundColor:'#8080803d', color:'black'}}>
                        <img src={close} width="25" />
                    </div>
                </>);
            }
            else if(prop.no == 2){
                return (<>
                    <img src={planSeat2} width="40px" />
                    <p className="p-0 m-0 seat-amount">₹{prop.data.Total_Amount}</p>
                    <div className="position-absolute py-3 text-center" onClick={($event) =>{prop.onUnSelect(prop.data)}} style={{top:5, height:'50px', width:'60px', backgroundColor:'#8080800d', color:'black', border:'2px solid black'}}>
                    </div>
                </>);
            }
            else if(prop.no == 3){
                return (<>
                    <img src={planSeat2} width="40px" />
                    <p className="p-0 m-0 seat-amount">₹{prop.data.Total_Amount}</p>
                    <div className="position-absolute py-3 text-center" onClick={($event) =>{prop.onSelect(prop.data)}} style={{top:5, height:'50px', width:'60px', backgroundColor:'#8080800d', color:'black'}}>
                    </div>
                </>);
            }
            else if(prop.no == 4){
                return (<>
                    <img src={planSeat2} width="40px" />
                    {/* <p className="p-0 m-0 seat-amount">₹{prop.data.Total_Amount}</p> */}
                </>); 
            }
            else if(prop.no == 5){
                return (<>
                    <img src={planSeat2} width="40px" />
                    <p className="p-0 m-0 seat-amount">₹{prop.data.Total_Amount}</p>
                    <div className="position-absolute py-3 text-center" style={{top:5, height:'50px', width:'60px', backgroundColor:'#8080803d', color:'black'}}>
                    </div>
                </>);
            }
            else{
                return (<>
                    <img src={planSeat2} width="40px" onClick={($event) =>{prop.onSelect(prop.data)}} />
                    <p className="p-0 m-0 seat-amount">₹{prop.data.Total_Amount}</p>
                </>); 
            }
        }
        else{
            return (<>
                <img src={planSeat2} width="40px" />
                <p className="p-0 m-0 seat-amount">₹{prop.data.Total_Amount}</p>
                <div className="position-absolute py-3 text-center" style={{top:5, height:'50px', width:'60px', backgroundColor:'#8080803d', color:'black'}}>
                    <img src={close} width="25" />
                </div>
            </>); 
        }
        // if(prop.no == 5){
        //     return(
        //         <>
        //         <Grid container>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat} width="40px" />
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat6} width="40px" />
        //                 <div className="position-absolute py-3 text-center" style={{top:0, height:'90%', width:'50px', backgroundColor:'#8080803d', color:'black'}}>   
        //                 </div>
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat2} width="40px" />
        //                 <div className="position-absolute py-3 text-center" style={{top:0, height:'100%', width:'50px', backgroundColor:'#8080800f', color:'black', border:'2px solid #8A80F3'}}>
        //                     {/* <span style={{fontSize:'40px'}}>1</span> */}
        //                 </div>
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative pt-3">
        //                 {prop.no}
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat3} width="40px" />
        //                 <div className="position-absolute py-3 text-center" style={{top:0, height:'100%', width:'50px', backgroundColor:'#8080800f', color:'black', border:'2px solid #8A80F3'}}>
        //                     {/* <span style={{fontSize:'40px'}}>1</span> */}
        //                 </div>
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat4} width="40px" />
        //                 <div className="position-absolute py-3 text-center" style={{top:0, height:'100%', width:'50px', backgroundColor:'#8080800f', color:'black', border:'2px solid #8A80F3'}}>
        //                     {/* <span style={{fontSize:'40px'}}>1</span> */}
        //                 </div>
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat5} width="40px" />
        //             </Grid>
        //         </Grid>
        //         </>
        //     );
        // }
        // else{
        //     return(
        //         <>
        //         <Grid container>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat} width="40px" />
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat6} width="40px" />
        //                 <div className="position-absolute py-3 text-center" style={{top:0, height:'90%', width:'50px', backgroundColor:'#8080803d', color:'black'}}>   
        //                 </div>
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat2} width="40px" />
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative pt-3">
        //                 {prop.no}
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat3} width="40px" />
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat4} width="40px" />
        //             </Grid>
        //             <Grid item xs className="text-center p-1 a position-relative">
        //                 <img src={planSeat5} width="40px" />
        //             </Grid>
        //         </Grid>
        //         </>
        //     );
        // }
    } catch (error) {
        throw new Error(error);
    }
}

const XL = (prop) =>{
    try{
        if(prop.no == 1 || prop.no == 2 || prop.no == 5){
            return(
                <span className="position-absolute" style={{fontSize:'10px', top:5, left:20}}>XL</span>
            );
        }
        else{
            return(
                <></>
            );
        }
    } catch (error) {
        throw new Error(error);
    }
}

const Td = (prop) =>{
    try{
        let text = '';
        if(prop.index == 0){
            text = 'A';
        }

        if(prop.index == 1){
            text = 'B';
        }

        if(prop.index == 2){
            text = 'C';
        }

        if(prop.index == 3){
            text = 'D';
        }

        if(prop.index == 4){
            text = 'E';
        }

        if(prop.index == 5){
            text = 'F';
        }

        return(
            <td style={{backgroundColor:'white', minWidth:'30px', fontSize:'20px', fontWeight:'bolder'}} className="text-end">{text}</td>
        );
    } catch (error) {
        throw new Error(error);
    }
}

const TdSeats = (prop) =>{
    try{
        const selectSeat = (data) =>{
            prop.onSelect(data);
        }

        const unSelectSeat = (data) =>{
            prop.onUnSelect(data);
        }

        let indx = prop.indx;
        console.log("=== ss ===", prop.selectedSeats);
        console.log("=== ss_type ===", prop.data.SSR_TypeName);
        prop.selectedSeats.map(e =>{
            //console.log("=== ss ===", e);
            if(e.SSR_TypeName == prop.data.SSR_TypeName){
                indx = 2;
                console.log("== c1 ==", e.SSR_TypeName);
                console.log("== c2 ==", prop.data.SSR_TypeName);
            }
        });

        if(prop.data && prop.data.index == 0 && indx != 5){
            return(
                <td style={{backgroundColor:'white', minWidth:'70px', padding: '9px'}} className="text-center a position-relative"><XL no={0} />
                    <Tooltip title={`${prop.data.SSR_TypeName} ${prop.data.SSR_TypeDesc} | ₹${prop.data.Total_Amount}`} placement="top-end" arrow>
                        <SeatsRow no={indx} data={prop.data} onSelect={selectSeat} onUnSelect={unSelectSeat} />
                    </Tooltip>
                </td>
            );
        }
        else if(prop.data && prop.data.index == 0 && indx == 5){
            return(
                <td style={{backgroundColor:'white', minWidth:'70px', padding: '9px'}} className="text-center position-relative"><XL no={0} />
                <Tooltip title={`${prop.data.SSR_TypeName} ${prop.data.SSR_TypeDesc} | ₹${prop.data.Total_Amount}`} placement="top-end" arrow>
                    <SeatsRow no={indx} data={prop.data} onSelect={selectSeat} onUnSelect={unSelectSeat} />
                </Tooltip>
                </td>
            );
        }
        else{
            return(
                <td style={{backgroundColor:'white', minWidth:'70px', padding: '9px'}} className="text-center position-relative"><XL no={0} /><SeatsRow no={1} data={prop.data} /></td>
            );
        }
    } catch (error) {
        throw new Error(error);
    }
}

class SeatMapVertical extends React.Component{

    selectSeat = (data) =>{
        try{
            let seats = this.props.seatMapData.seats;

            seats.push(data);
            

            let d = {flightId: this.props.flightId, seats:seats};
            console.log("=== selectSeat ===", data);
            this.props.onHandle(d);
        } catch (error) {
            throw new Error(error);
        }
    }

    unSelectSeat = (data) =>{
        try{
            let seats = this.props.seatMapData.seats;
            let s = seats.filter(r => r.SSR_TypeName != data.SSR_TypeName);
            // seats.pop(data);

            let d = {flightId: this.props.flightId, seats:s};
            console.log("=== unSelectSeat ===", data);
            this.props.onHandle(d);
        } catch (error) {
            throw new Error(error);
        }
    }

    render(){
        console.log(this.props.travelersDetails);
        try{
            let passengerList = [];

            this.props.travelersDetails.map(e =>{
                if(e.label == "Adult" || e.label == "Child"){
                    e.data.map(ee =>{
                        passengerList.push(ee);
                    });
                }
            });

            console.log("== travelersDetails ==",this.props.travelersDetails);
            let selectedSeats = this.props.seatMapData.seats;
            //console.log("=== SeatMapVertical ===", this.props.data);
            
            let row = [];

            let indx = 3;

            if(this.props.totalSeats == selectedSeats.length){
                indx = 5;
            }

            for (let index = 0; index < 6; index++) {
                let td = [];
                let item = [];
                
                this.props.data.map((e, i) =>{
                    
                    if(i == 0){
                        td.push(<td style={{backgroundColor:'white'}}></td>);
                        // td.push(<td style={{backgroundColor:'white'}}></td>);
                        // td.push(<td style={{backgroundColor:'white'}}></td>);
                        td.push(<td style={{backgroundColor:'white'}} className="text-center">{(i+1)}</td>);
                        
                        // if(index == 0){
                        //     item.push(<td style={{backgroundColor:'white'}}><img src={lavatory} width="40px" /></td>);
                        //     item.push(<td style={{backgroundColor:'white'}}><img src={rExit} width="40px" /></td>);
                        // }
                        // else{
                        //     item.push(<td style={{backgroundColor:'white'}}></td>);
                        //     item.push(<td style={{backgroundColor:'white'}}></td>); 
                        // }

                        item.push(<Td index={index} />);
                        item.push(<TdSeats data={e[index]} selectedSeats={selectedSeats} indx={indx} rowIndex={i} colIndex={index} onSelect={this.selectSeat} onUnSelect={this.unSelectSeat} />);
                    }
                    // else if(i == (this.props.data.length - 1)){
                    //     td.push(<td style={{backgroundColor:'white'}} className="text-center">{(i+1)}</td>);
                    //     td.push(<td style={{backgroundColor:'white'}}></td>);
                    //     td.push(<td style={{backgroundColor:'white'}}></td>);
                    //     item.push(<TdSeats data={e.Seat_Details[index]} selectedSeats={selectedSeats} indx={indx} rowIndex={i} colIndex={index} onSelect={this.selectSeat} onUnSelect={this.unSelectSeat} />);
                    //     item.push(<td style={{backgroundColor:'white'}}><img src={cup} width="40px" /></td>);
                    //     item.push(<td style={{backgroundColor:'white'}}><img src={rExit} width="40px" /></td>);
                    // }
                    else{
                        td.push(<td style={{backgroundColor:'white'}} className="text-center">{(i+1)}</td>);
                        item.push(<TdSeats data={e[index]} selectedSeats={selectedSeats} indx={indx} rowIndex={i} colIndex={index} onSelect={this.selectSeat} onUnSelect={this.unSelectSeat} />);
                    }
                });

                row.push(<tr>{item}</tr>);
                if(index == 2){
                    row.push(<tr>{td}</tr>);
                }
            }

            let li = [];
            // <span>{this.props.passengerList[i].fName} {this.props.passengerList[i].lName}</span>
            selectedSeats.map((e, i) =>{
                li.push(<li className="py-2 me-3 position-relative passengerList" style={{fontSize:'20px', listStyle:'none', minWidth:'70px', padding: '9px', border:'1px solid gray'}}><XL no={0} /><SeatsRow no={4} data={e} /> {e.SSR_TypeName} <br /> <p style={{fontSize: '10px', marginTop: '5px', textAlign: 'center'}}>{passengerList[i]?.fName} {passengerList[i]?.lName}</p> </li>);
            })

            return(
                <Grid container className="p-0">
                    <Grid item xs={12} className="py-0 px-3" style={{backgroundColor:'#80808021', height:'380px', overflow:'auto', display:"inline-flex"}}>
                        <img src={headPlane} style={{width:'450px', height:'370px'}} />
                        <table>
                            {row}
                        </table>
                        <img src={tailPlane} style={{width:'450px', height:'369px'}} />
                    </Grid>
                    <Grid item xs={12} className="p-0">
                        <Grid container>
                            <Grid item xs={12} className="px-4 py-2" style={{backgroundColor:'#9e9e9e33'}}>
                                <h3> <img src={user} width="20px" /> <span className="ms-1" style={{fontSize:'15px'}}>Seat Allocated</span></h3>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={11} className="px-4 py-2">
                                <ol style={{display:'inline-flex', minHeight:'50px'}} className="p-0">
                                {li} 
                                </ol>
                            </Grid>
                            <Grid item xs={1} className="py-4 text-end pe-3">
                                <Button className="color-w bg-gradient border-0 pd-10" style={{width:80}} onClick={($event) => this.props.onClosed(false)}>Done</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default SeatMapVertical;