import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';


export default function TreeViews(prop) {
  console.log(prop);
  try{
    const classes = `TreeViews list-group ${prop.classes}`;
    const items = []
    let c='1';

    prop.datas.forEach((element, index) => {
      if(prop?.dynamicNode){
        if(element.body){
          items.push(<TreeItem className="list-group-item" nodeId={`${(index + 1)}`} label={element.label}>{element.body}</TreeItem>)
        }else{
          items.push(<TreeItem className="list-group-item" nodeId={`${(index + 1)}`} label={element.label} />)
        }
      }
      else{
        if(element.body){
          items.push(<TreeItem className="list-group-item" nodeId="1" label={element.label}>{element.body}</TreeItem>)
        }else{
          items.push(<TreeItem className="list-group-item" nodeId="1" label={element.label} />)
        }
      }
    });

    return (
      <TreeView
        className={classes}
        defaultExpanded={["1"]}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
      >
        {items}
        
        {/* <TreeItem className="list-group-item" style={{paddingLeft: '0px'}} nodeId="3" label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>Total Amount:</b></span><span className="float-right" style={{fontSize:'16px'}}><b>₹ 7964</b></span></div>} /> */}
      </TreeView>
    );
  } catch (error) {
    throw new Error(error);
  }
}
