export const allAirlinesData = [
        {
            id: "937",
            ooro_fname: "Alliance Air",
            ooro_faddress: "temp",
            ooro_fcode: "9I",
            ooro_fweb: "na"
        },
        {
            id: "936",
            ooro_fname: "Blue Air",
            ooro_faddress: "",
            ooro_fcode: "0B",
            ooro_fweb: ""
        },
        {
            id: "935",
            ooro_fname: "Darwin Airline",
            ooro_faddress: "",
            ooro_fcode: "0D",
            ooro_fweb: ""
        },
        {
            id: "934",
            ooro_fname: "Jetclub",
            ooro_faddress: "",
            ooro_fcode: "0J",
            ooro_fweb: ""
        },
        {
            id: "933",
            ooro_fname: "Kokshetau",
            ooro_faddress: "",
            ooro_fcode: "0K",
            ooro_fweb: ""
        },
        {
            id: "932",
            ooro_fname: "West Caribbean Costa Rica",
            ooro_faddress: "",
            ooro_fcode: "0W",
            ooro_fweb: ""
        },
        {
            id: "931",
            ooro_fname: "HongKong Airlines",
            ooro_faddress: "test",
            ooro_fcode: "HX",
            ooro_fweb: "http://www.hongkongairlines.com/en_HK/homepage"
        },
        {
            id: "930",
            ooro_fname: "Deutsche Bahn",
            ooro_faddress: "",
            ooro_fcode: "2A",
            ooro_fweb: ""
        },
        {
            id: "929",
            ooro_fname: "ATA Aerocondor",
            ooro_faddress: "",
            ooro_fcode: "2B",
            ooro_fweb: ""
        },
        {
            id: "928",
            ooro_fname: "SNCF",
            ooro_faddress: "",
            ooro_fcode: "2C",
            ooro_fweb: ""
        },
        {
            id: "927",
            ooro_fname: "Aero VIP",
            ooro_faddress: "",
            ooro_fcode: "2D",
            ooro_fweb: ""
        },
        {
            id: "926",
            ooro_fname: "San Juan Airlines/West Isle Air",
            ooro_faddress: "",
            ooro_fcode: "2G",
            ooro_fweb: ""
        },
        {
            id: "925",
            ooro_fname: "Star Up",
            ooro_faddress: "",
            ooro_fcode: "2I",
            ooro_fweb: ""
        },
        {
            id: "924",
            ooro_fname: "Air Burkina",
            ooro_faddress: "",
            ooro_fcode: "2J",
            ooro_fweb: ""
        },
        {
            id: "923",
            ooro_fname: "Aerogal Aerolineas Galapagos",
            ooro_faddress: "",
            ooro_fcode: "2K",
            ooro_fweb: ""
        },
        {
            id: "922",
            ooro_fname: "Helvetic Airways",
            ooro_faddress: "",
            ooro_fcode: "2L",
            ooro_fweb: ""
        },
        {
            id: "921",
            ooro_fname: "Moldavian Airlines",
            ooro_faddress: "",
            ooro_fcode: "2M",
            ooro_fweb: ""
        },
        {
            id: "920",
            ooro_fname: "Yuzmashavia",
            ooro_faddress: "",
            ooro_fcode: "2N",
            ooro_fweb: ""
        },
        {
            id: "919",
            ooro_fname: "Air Salone",
            ooro_faddress: "",
            ooro_fcode: "2O",
            ooro_fweb: ""
        },
        {
            id: "918",
            ooro_fname: "Air Philippines",
            ooro_faddress: "",
            ooro_fcode: "2P",
            ooro_fweb: ""
        },
        {
            id: "917",
            ooro_fname: "Air Cargo Carriers",
            ooro_faddress: "",
            ooro_fcode: "2Q",
            ooro_fweb: ""
        },
        {
            id: "916",
            ooro_fname: "VIA Rail Canada",
            ooro_faddress: "",
            ooro_fcode: "2R",
            ooro_fweb: ""
        },
        {
            id: "915",
            ooro_fname: "Island Express",
            ooro_faddress: "",
            ooro_fcode: "2S",
            ooro_fweb: ""
        },
        {
            id: "914",
            ooro_fname: "Air Guinee Express",
            ooro_faddress: "",
            ooro_fcode: "2U",
            ooro_fweb: ""
        },
        {
            id: "913",
            ooro_fname: "Welcome Air",
            ooro_faddress: "",
            ooro_fcode: "2W",
            ooro_fweb: ""
        },
        {
            id: "912",
            ooro_fname: "Alliance Airlines/Kenosha Aero",
            ooro_faddress: "",
            ooro_fcode: "3A",
            ooro_fweb: ""
        },
        {
            id: "911",
            ooro_fname: "Burkina Airlines",
            ooro_faddress: "",
            ooro_fcode: "3B",
            ooro_fweb: ""
        },
        {
            id: "910",
            ooro_fname: "Corporate Airlines",
            ooro_faddress: "",
            ooro_fcode: "3C",
            ooro_fweb: ""
        },
        {
            id: "909",
            ooro_fname: "Denim Air",
            ooro_faddress: "",
            ooro_fcode: "3D",
            ooro_fweb: ""
        },
        {
            id: "908",
            ooro_fname: "East Asia Airlines",
            ooro_faddress: "",
            ooro_fcode: "3E",
            ooro_fweb: ""
        },
        {
            id: "907",
            ooro_fname: "Atlant-Soyuz Airlines",
            ooro_faddress: "",
            ooro_fcode: "3G",
            ooro_fweb: ""
        },
        {
            id: "906",
            ooro_fname: "Air Inuit",
            ooro_faddress: "",
            ooro_fcode: "3H",
            ooro_fweb: ""
        },
        {
            id: "905",
            ooro_fname: "Jetstar Asia Airways",
            ooro_faddress: "",
            ooro_fcode: "3K",
            ooro_fweb: ""
        },
        {
            id: "904",
            ooro_fname: "Intersky",
            ooro_faddress: "",
            ooro_fcode: "3L",
            ooro_fweb: ""
        },
        {
            id: "903",
            ooro_fname: "Gulfstream International Airlines",
            ooro_faddress: "",
            ooro_fcode: "3M",
            ooro_fweb: ""
        },
        {
            id: "902",
            ooro_fname: "Air Urga",
            ooro_faddress: "",
            ooro_fcode: "3N",
            ooro_fweb: ""
        },
        {
            id: "901",
            ooro_fname: "Jubba Airways",
            ooro_faddress: "",
            ooro_fcode: "3O",
            ooro_fweb: ""
        },
        {
            id: "900",
            ooro_fname: "Air Guyane",
            ooro_faddress: "",
            ooro_fcode: "GG",
            ooro_fweb: ""
        },
        {
            id: "899",
            ooro_fname: "Turan Air",
            ooro_faddress: "",
            ooro_fcode: "3T",
            ooro_fweb: ""
        },
        {
            id: "898",
            ooro_fname: "TNT Airways",
            ooro_faddress: "",
            ooro_fcode: "3V",
            ooro_fweb: ""
        },
        {
            id: "897",
            ooro_fname: "Japan Air Commuter",
            ooro_faddress: "",
            ooro_fcode: "3X",
            ooro_fweb: ""
        },
        {
            id: "896",
            ooro_fname: "Baseops International",
            ooro_faddress: "",
            ooro_fcode: "3Y",
            ooro_fweb: ""
        },
        {
            id: "895",
            ooro_fname: "F.S. Air Service",
            ooro_faddress: "",
            ooro_fcode: "4A",
            ooro_fweb: ""
        },
        {
            id: "894",
            ooro_fname: "Aviastar-Tu Co.",
            ooro_faddress: "",
            ooro_fcode: "4B",
            ooro_fweb: ""
        },
        {
            id: "893",
            ooro_fname: "Air Sinai",
            ooro_faddress: "",
            ooro_fcode: "4D",
            ooro_fweb: ""
        },
        {
            id: "892",
            ooro_fname: "Tanana Air Service",
            ooro_faddress: "",
            ooro_fcode: "4E",
            ooro_fweb: ""
        },
        {
            id: "891",
            ooro_fname: "Air City",
            ooro_faddress: "",
            ooro_fcode: "4F",
            ooro_fweb: ""
        },
        {
            id: "890",
            ooro_fname: "Fly Linhas Aereas",
            ooro_faddress: "",
            ooro_fcode: "4H",
            ooro_fweb: ""
        },
        {
            id: "889",
            ooro_fname: "Knighthawk Air Express",
            ooro_faddress: "",
            ooro_fcode: "4I",
            ooro_fweb: ""
        },
        {
            id: "888",
            ooro_fname: "Nationwide Airlines",
            ooro_faddress: "",
            ooro_fcode: "CE",
            ooro_fweb: ""
        },
        {
            id: "887",
            ooro_fname: "Kenn Borek Air",
            ooro_faddress: "",
            ooro_fcode: "4K",
            ooro_fweb: ""
        },
        {
            id: "886",
            ooro_fname: "Air Astana11",
            ooro_faddress: "Address",
            ooro_fcode: "00",
            ooro_fweb: "aa.com"
        },
        {
            id: "885",
            ooro_fname: "Lan Argentina/Aero 2000",
            ooro_faddress: "",
            ooro_fcode: "4M",
            ooro_fweb: ""
        },
        {
            id: "884",
            ooro_fname: "Air North Charter",
            ooro_faddress: "",
            ooro_fcode: "4N",
            ooro_fweb: ""
        },
        {
            id: "883",
            ooro_fname: "Ocean Airlines",
            ooro_faddress: "",
            ooro_fcode: "4O",
            ooro_fweb: ""
        },
        {
            id: "882",
            ooro_fname: "Belair Airlines",
            ooro_faddress: "",
            ooro_fcode: "4T",
            ooro_fweb: ""
        },
        {
            id: "881",
            ooro_fname: "Warbelow's Air Ventures",
            ooro_faddress: "",
            ooro_fcode: "4W",
            ooro_fweb: ""
        },
        {
            id: "880",
            ooro_fname: "Air Mercury/Mercury Air Cargo",
            ooro_faddress: "",
            ooro_fcode: "4X",
            ooro_fweb: ""
        },
        {
            id: "879",
            ooro_fname: "Yute Air Alaska",
            ooro_faddress: "",
            ooro_fcode: "4Y",
            ooro_fweb: ""
        },
        {
            id: "878",
            ooro_fname: "South African Airlink",
            ooro_faddress: "",
            ooro_fcode: "4Z",
            ooro_fweb: ""
        },
        {
            id: "877",
            ooro_fname: "Euro Asia International",
            ooro_faddress: "",
            ooro_fcode: "5B",
            ooro_fweb: ""
        },
        {
            id: "876",
            ooro_fname: "CAL Cargo Airlines/Cavei Avir Levitanim",
            ooro_faddress: "",
            ooro_fcode: "5C",
            ooro_fweb: ""
        },
        {
            id: "875",
            ooro_fname: "Equaflight Service",
            ooro_faddress: "",
            ooro_fcode: "5E",
            ooro_fweb: ""
        },
        {
            id: "874",
            ooro_fname: "Arctic Circle Air Service",
            ooro_faddress: "",
            ooro_fcode: "5F",
            ooro_fweb: ""
        },
        {
            id: "873",
            ooro_fname: "Skyservice Airlines",
            ooro_faddress: "",
            ooro_fcode: "5G",
            ooro_fweb: ""
        },
        {
            id: "872",
            ooro_fname: "Fly540",
            ooro_faddress: "",
            ooro_fcode: "5H",
            ooro_fweb: ""
        },
        {
            id: "871",
            ooro_fname: "Trans Air Georgia",
            ooro_faddress: "",
            ooro_fcode: "5I",
            ooro_fweb: ""
        },
        {
            id: "870",
            ooro_fname: "Cebu Pacific Air",
            ooro_faddress: "",
            ooro_fcode: "5J",
            ooro_fweb: ""
        },
        {
            id: "869",
            ooro_fname: "Odessa Airlines",
            ooro_faddress: "",
            ooro_fcode: "5K",
            ooro_fweb: ""
        },
        {
            id: "868",
            ooro_fname: "Aerosur",
            ooro_faddress: "",
            ooro_fcode: "5L",
            ooro_fweb: ""
        },
        {
            id: "867",
            ooro_fname: "SIAT Sibaviatrans",
            ooro_faddress: "",
            ooro_fcode: "5M",
            ooro_fweb: ""
        },
        {
            id: "866",
            ooro_fname: "Aeroflot-Nord",
            ooro_faddress: "",
            ooro_fcode: "5N",
            ooro_fweb: ""
        },
        {
            id: "865",
            ooro_fname: "Europe Airport",
            ooro_faddress: "",
            ooro_fcode: "5O",
            ooro_fweb: ""
        },
        {
            id: "864",
            ooro_fname: "Sky Europe Airlines",
            ooro_faddress: "",
            ooro_fcode: "5P",
            ooro_fweb: ""
        },
        {
            id: "863",
            ooro_fname: "Servicios Aereos Profesionales",
            ooro_faddress: "",
            ooro_fcode: "5S",
            ooro_fweb: ""
        },
        {
            id: "862",
            ooro_fname: "LADE Lineas Aereas del Estado",
            ooro_faddress: "",
            ooro_fcode: "5U",
            ooro_fweb: ""
        },
        {
            id: "861",
            ooro_fname: "Lviv Airlines/Lvov Airlines",
            ooro_faddress: "",
            ooro_fcode: "5V",
            ooro_fweb: ""
        },
        {
            id: "860",
            ooro_fname: "Isles of Scilly Skybus",
            ooro_faddress: "",
            ooro_fcode: "5Y",
            ooro_fweb: ""
        },
        {
            id: "859",
            ooro_fname: "Bismillah Airlines",
            ooro_faddress: "",
            ooro_fcode: "5Z",
            ooro_fweb: ""
        },
        {
            id: "858",
            ooro_fname: "Aviacsa",
            ooro_faddress: "",
            ooro_fcode: "6A",
            ooro_fweb: ""
        },
        {
            id: "857",
            ooro_fname: "Britannia Airways Sweden",
            ooro_faddress: "",
            ooro_fcode: "6B",
            ooro_fweb: ""
        },
        {
            id: "856",
            ooro_fname: "Cape Smythe Air Services",
            ooro_faddress: "",
            ooro_fcode: "6C",
            ooro_fweb: ""
        },
        {
            id: "855",
            ooro_fname: "Pelita Air",
            ooro_faddress: "",
            ooro_fcode: "6D",
            ooro_fweb: ""
        },
        {
            id: "854",
            ooro_fname: "Indigo",
            ooro_faddress: "temp",
            ooro_fcode: "6E",
            ooro_fweb: "http:gmail.com"
        },
        {
            id: "853",
            ooro_fname: "Air Wales",
            ooro_faddress: "",
            ooro_fcode: "6G",
            ooro_fweb: ""
        },
        {
            id: "852",
            ooro_fname: "Israir",
            ooro_faddress: "",
            ooro_fcode: "6H",
            ooro_fweb: ""
        },
        {
            id: "851",
            ooro_fname: "Aviast",
            ooro_faddress: "",
            ooro_fcode: "6I",
            ooro_fweb: ""
        },
        {
            id: "850",
            ooro_fname: "Skynet Asia Airways",
            ooro_faddress: "",
            ooro_fcode: "6J",
            ooro_fweb: ""
        },
        {
            id: "849",
            ooro_fname: "Aklak Air",
            ooro_faddress: "",
            ooro_fcode: "6L",
            ooro_fweb: ""
        },
        {
            id: "848",
            ooro_fname: "Air Satellite",
            ooro_faddress: "",
            ooro_fcode: "6O",
            ooro_fweb: ""
        },
        {
            id: "847",
            ooro_fname: "Clubair Sixgo",
            ooro_faddress: "",
            ooro_fcode: "6P",
            ooro_fweb: ""
        },
        {
            id: "846",
            ooro_fname: "Slovak Airlines",
            ooro_faddress: "",
            ooro_fcode: "6Q",
            ooro_fweb: ""
        },
        {
            id: "845",
            ooro_fname: "Aerotransporte de Carga Union",
            ooro_faddress: "",
            ooro_fcode: "6R",
            ooro_fweb: ""
        },
        {
            id: "844",
            ooro_fname: "Kato Air",
            ooro_faddress: "",
            ooro_fcode: "6S",
            ooro_fweb: ""
        },
        {
            id: "843",
            ooro_fname: "Almaty Aviation",
            ooro_faddress: "",
            ooro_fcode: "6T",
            ooro_fweb: ""
        },
        {
            id: "842",
            ooro_fname: "Saravia Saratov Airlines",
            ooro_faddress: "",
            ooro_fcode: "6W",
            ooro_fweb: ""
        },
        {
            id: "841",
            ooro_fname: "Latcharter",
            ooro_faddress: "",
            ooro_fcode: "6Y",
            ooro_fweb: ""
        },
        {
            id: "840",
            ooro_fname: "Kras Air/Krasnojarsky Airlines",
            ooro_faddress: "",
            ooro_fcode: "7B",
            ooro_fweb: ""
        },
        {
            id: "839",
            ooro_fname: "Coyne Airways",
            ooro_faddress: "",
            ooro_fcode: "7C",
            ooro_fweb: ""
        },
        {
            id: "838",
            ooro_fname: "Utility Enterprise DonbassAero Airline",
            ooro_faddress: "",
            ooro_fcode: "7D",
            ooro_fweb: ""
        },
        {
            id: "837",
            ooro_fname: "Aeroline",
            ooro_faddress: "",
            ooro_fcode: "7E",
            ooro_fweb: ""
        },
        {
            id: "836",
            ooro_fname: "First Air",
            ooro_faddress: "",
            ooro_fcode: "7F",
            ooro_fweb: ""
        },
        {
            id: "835",
            ooro_fname: "MK Airlines",
            ooro_faddress: "",
            ooro_fcode: "7G",
            ooro_fweb: ""
        },
        {
            id: "834",
            ooro_fname: "ERA Aviation",
            ooro_faddress: "",
            ooro_fcode: "7H",
            ooro_fweb: ""
        },
        {
            id: "833",
            ooro_fname: "Coastal Travel Limited",
            ooro_faddress: "",
            ooro_fcode: "7I",
            ooro_fweb: ""
        },
        {
            id: "832",
            ooro_fname: "Tajik Air",
            ooro_faddress: "",
            ooro_fcode: "7J",
            ooro_fweb: ""
        },
        {
            id: "831",
            ooro_fname: "Kolavia/Kogalymavia Airlines",
            ooro_faddress: "",
            ooro_fcode: "7K",
            ooro_fweb: ""
        },
        {
            id: "830",
            ooro_fname: "Galaxy Air",
            ooro_faddress: "",
            ooro_fcode: "7O",
            ooro_fweb: ""
        },
        {
            id: "829",
            ooro_fname: "Metro Batavia",
            ooro_faddress: "",
            ooro_fcode: "7P",
            ooro_fweb: ""
        },
        {
            id: "828",
            ooro_fname: "Arctic Transportation Services",
            ooro_faddress: "",
            ooro_fcode: "7S",
            ooro_fweb: ""
        },
        {
            id: "827",
            ooro_fname: "Aero Express Del Ecuador",
            ooro_faddress: "",
            ooro_fcode: "7T",
            ooro_fweb: ""
        },
        {
            id: "826",
            ooro_fname: "Aviaenergo",
            ooro_faddress: "",
            ooro_fcode: "7U",
            ooro_fweb: ""
        },
        {
            id: "825",
            ooro_fname: "Pelican Air Services",
            ooro_faddress: "",
            ooro_fcode: "7V",
            ooro_fweb: ""
        },
        {
            id: "824",
            ooro_fname: "Mid Airlines",
            ooro_faddress: "",
            ooro_fcode: "7Y",
            ooro_fweb: ""
        },
        {
            id: "823",
            ooro_fname: "Laker Airways Bahamas/L.B. Limited",
            ooro_faddress: "",
            ooro_fcode: "7Z",
            ooro_fweb: ""
        },
        {
            id: "822",
            ooro_fname: "Caribbean Star Airlines",
            ooro_faddress: "",
            ooro_fcode: "8B",
            ooro_fweb: ""
        },
        {
            id: "821",
            ooro_fname: "Air Transport International ATI",
            ooro_faddress: "",
            ooro_fcode: "8C",
            ooro_fweb: ""
        },
        {
            id: "820",
            ooro_fname: "Bering Air",
            ooro_faddress: "",
            ooro_fcode: "8E",
            ooro_fweb: ""
        },
        {
            id: "819",
            ooro_fname: "Fischer Air",
            ooro_faddress: "",
            ooro_fcode: "8F",
            ooro_fweb: ""
        },
        {
            id: "818",
            ooro_fname: "Komiinteravia",
            ooro_faddress: "",
            ooro_fcode: "8J",
            ooro_fweb: ""
        },
        {
            id: "817",
            ooro_fname: "Angel Airlines",
            ooro_faddress: "",
            ooro_fcode: "8G",
            ooro_fweb: ""
        },
        {
            id: "816",
            ooro_fname: "Redhill Aviation",
            ooro_faddress: "",
            ooro_fcode: "8L",
            ooro_fweb: ""
        },
        {
            id: "815",
            ooro_fname: "Nordkalottflyg",
            ooro_faddress: "",
            ooro_fcode: "8N",
            ooro_fweb: ""
        },
        {
            id: "814",
            ooro_fname: "Pacific Coastal Airlines",
            ooro_faddress: "",
            ooro_fcode: "8P",
            ooro_fweb: ""
        },
        {
            id: "813",
            ooro_fname: "Edelweiss Air",
            ooro_faddress: "",
            ooro_fcode: "8R",
            ooro_fweb: ""
        },
        {
            id: "812",
            ooro_fname: "Scorpio Aviation",
            ooro_faddress: "",
            ooro_fcode: "8S",
            ooro_fweb: ""
        },
        {
            id: "811",
            ooro_fname: "Afriqiyah Airways",
            ooro_faddress: "",
            ooro_fcode: "8U",
            ooro_fweb: ""
        },
        {
            id: "810",
            ooro_fname: "Wright Air Service",
            ooro_faddress: "",
            ooro_fcode: "8V",
            ooro_fweb: ""
        },
        {
            id: "809",
            ooro_fname: "LASER Linea Aerea de Servicio Ejecutivo Regional",
            ooro_faddress: "",
            ooro_fcode: "8Z",
            ooro_fweb: ""
        },
        {
            id: "808",
            ooro_fname: "Pinnacle Airlines/Northwest Airlink",
            ooro_faddress: "",
            ooro_fcode: "9E",
            ooro_fweb: ""
        },
        {
            id: "807",
            ooro_fname: "Pacific Island Aviation",
            ooro_faddress: "",
            ooro_fcode: "9J",
            ooro_fweb: ""
        },
        {
            id: "806",
            ooro_fname: "Cape Air",
            ooro_faddress: "",
            ooro_fcode: "9K",
            ooro_fweb: ""
        },
        {
            id: "805",
            ooro_fname: "Colgan Air",
            ooro_faddress: "",
            ooro_fcode: "9L",
            ooro_fweb: ""
        },
        {
            id: "804",
            ooro_fname: "Central Mountain Air",
            ooro_faddress: "",
            ooro_fcode: "9M",
            ooro_fweb: ""
        },
        {
            id: "803",
            ooro_fname: "SATENA",
            ooro_faddress: "",
            ooro_fcode: "9N",
            ooro_fweb: ""
        },
        {
            id: "802",
            ooro_fname: "PB Air",
            ooro_faddress: "",
            ooro_fcode: "9Q",
            ooro_fweb: ""
        },
        {
            id: "801",
            ooro_fname: "Phuket Airlines",
            ooro_faddress: "",
            ooro_fcode: "9R",
            ooro_fweb: ""
        },
        {
            id: "800",
            ooro_fname: "Athabaska Airlines",
            ooro_faddress: "",
            ooro_fcode: "9T",
            ooro_fweb: ""
        },
        {
            id: "799",
            ooro_fname: "Air Moldova",
            ooro_faddress: "",
            ooro_fcode: "9U",
            ooro_fweb: ""
        },
        {
            id: "798",
            ooro_fname: "Avior Express/Aviones del Oriente",
            ooro_faddress: "",
            ooro_fcode: "9V",
            ooro_fweb: ""
        },
        {
            id: "797",
            ooro_fname: "Jet Airways",
            ooro_faddress: "aaa",
            ooro_fcode: "9W",
            ooro_fweb: "www.airline.com"
        },
        {
            id: "796",
            ooro_fname: "Itali Airlines",
            ooro_faddress: "",
            ooro_fcode: "9X",
            ooro_fweb: ""
        },
        {
            id: "795",
            ooro_fname: "L'Avion",
            ooro_faddress: "",
            ooro_fcode: "A0",
            ooro_fweb: ""
        },
        {
            id: "794",
            ooro_fname: "Cielos Airlines",
            ooro_faddress: "",
            ooro_fcode: "A2",
            ooro_fweb: ""
        },
        {
            id: "793",
            ooro_fname: "Aegean Airlines",
            ooro_faddress: "",
            ooro_fcode: "A3",
            ooro_fweb: ""
        },
        {
            id: "792",
            ooro_fname: "Airlinair",
            ooro_faddress: "",
            ooro_fcode: "A5",
            ooro_fweb: ""
        },
        {
            id: "791",
            ooro_fname: "Air Alps",
            ooro_faddress: "",
            ooro_fcode: "A6",
            ooro_fweb: ""
        },
        {
            id: "790",
            ooro_fname: "Air Comet",
            ooro_faddress: "",
            ooro_fcode: "A7",
            ooro_fweb: ""
        },
        {
            id: "789",
            ooro_fname: "Benin Golf Air",
            ooro_faddress: "",
            ooro_fcode: "A8",
            ooro_fweb: ""
        },
        {
            id: "788",
            ooro_fname: "Georgian Airlines/Air Zena",
            ooro_faddress: "",
            ooro_fcode: "A9",
            ooro_fweb: ""
        },
        {
            id: "787",
            ooro_fname: "American Airlines",
            ooro_faddress: "",
            ooro_fcode: "AA",
            ooro_fweb: ""
        },
        {
            id: "786",
            ooro_fname: "Air Berlin",
            ooro_faddress: "",
            ooro_fcode: "AB",
            ooro_fweb: ""
        },
        {
            id: "785",
            ooro_fname: "Air Canada",
            ooro_faddress: "",
            ooro_fcode: "AC",
            ooro_fweb: ""
        },
        {
            id: "784",
            ooro_fname: "Air Paradise International",
            ooro_faddress: "",
            ooro_fcode: "AD",
            ooro_fweb: ""
        },
        {
            id: "783",
            ooro_fname: "Mandarin Airlines",
            ooro_faddress: "",
            ooro_fcode: "AE",
            ooro_fweb: ""
        },
        {
            id: "782",
            ooro_fname: "Air France",
            ooro_faddress: "",
            ooro_fcode: "AF",
            ooro_fweb: ""
        },
        {
            id: "781",
            ooro_fname: "Air Contractors",
            ooro_faddress: "",
            ooro_fcode: "AG",
            ooro_fweb: ""
        },
        {
            id: "780",
            ooro_fname: "Air Algerie",
            ooro_faddress: "",
            ooro_fcode: "AH",
            ooro_fweb: ""
        },
        {
            id: "779",
            ooro_fname: "Air India",
            ooro_faddress: "Ahmedabad",
            ooro_fcode: "AI",
            ooro_fweb: "www.airline.com"
        },
        {
            id: "778",
            ooro_fname: "Aero Contractors",
            ooro_faddress: "",
            ooro_fcode: "AJ",
            ooro_fweb: ""
        },
        {
            id: "777",
            ooro_fname: "Air Asia1",
            ooro_faddress: "Test",
            ooro_fcode: "AK",
            ooro_fweb: "http://www.airasia.com/my/en/home.page?cid=1"
        },
        {
            id: "776",
            ooro_fname: "Aeromexico",
            ooro_faddress: "",
            ooro_fcode: "AM",
            ooro_fweb: ""
        },
        {
            id: "775",
            ooro_fname: "Australian Airlines",
            ooro_faddress: "",
            ooro_fcode: "AO",
            ooro_fweb: ""
        },
        {
            id: "774",
            ooro_fname: "Air One",
            ooro_faddress: "",
            ooro_fcode: "AP",
            ooro_fweb: ""
        },
        {
            id: "773",
            ooro_fname: "Aloha Airlines",
            ooro_faddress: "",
            ooro_fcode: "AQ",
            ooro_fweb: ""
        },
        {
            id: "772",
            ooro_fname: "Aerolineas Argentinas",
            ooro_faddress: "",
            ooro_fcode: "AR",
            ooro_fweb: ""
        },
        {
            id: "771",
            ooro_fname: "Alaska Airlines",
            ooro_faddress: "",
            ooro_fcode: "AS",
            ooro_fweb: ""
        },
        {
            id: "770",
            ooro_fname: "Royal Air Maroc",
            ooro_faddress: "",
            ooro_fcode: "AT",
            ooro_fweb: ""
        },
        {
            id: "769",
            ooro_fname: "Austral/Cielos del Sur",
            ooro_faddress: "",
            ooro_fcode: "AU",
            ooro_fweb: ""
        },
        {
            id: "768",
            ooro_fname: "Avianca",
            ooro_faddress: "",
            ooro_fcode: "AV",
            ooro_fweb: ""
        },
        {
            id: "767",
            ooro_fname: "Trans States Airlines/American Connection",
            ooro_faddress: "",
            ooro_fcode: "AX",
            ooro_fweb: ""
        },
        {
            id: "766",
            ooro_fname: "Finnair",
            ooro_faddress: "",
            ooro_fcode: "AY",
            ooro_fweb: ""
        },
        {
            id: "765",
            ooro_fname: "Alitalia",
            ooro_faddress: "",
            ooro_fcode: "AZ",
            ooro_fweb: ""
        },
        {
            id: "764",
            ooro_fname: "Belavia",
            ooro_faddress: "",
            ooro_fcode: "B2",
            ooro_fweb: ""
        },
        {
            id: "763",
            ooro_fname: "Bellview Airlines",
            ooro_faddress: "",
            ooro_fcode: "B3",
            ooro_fweb: ""
        },
        {
            id: "762",
            ooro_fname: "FlightLine",
            ooro_faddress: "",
            ooro_fcode: "B5",
            ooro_fweb: ""
        },
        {
            id: "761",
            ooro_fname: "JetBlue Airways",
            ooro_faddress: "",
            ooro_fcode: "B6",
            ooro_fweb: ""
        },
        {
            id: "760",
            ooro_fname: "UNI Airways Corporation",
            ooro_faddress: "",
            ooro_fcode: "B7",
            ooro_fweb: ""
        },
        {
            id: "759",
            ooro_fname: "Eritrean Airlines",
            ooro_faddress: "",
            ooro_fcode: "B8",
            ooro_fweb: ""
        },
        {
            id: "758",
            ooro_fname: "British Airways",
            ooro_faddress: "",
            ooro_fcode: "BA",
            ooro_fweb: ""
        },
        {
            id: "757",
            ooro_fname: "Seabornes Airlines",
            ooro_faddress: "",
            ooro_fcode: "BB",
            ooro_fweb: ""
        },
        {
            id: "756",
            ooro_fname: "Skymark Airlines",
            ooro_faddress: "",
            ooro_fcode: "BC",
            ooro_fweb: ""
        },
        {
            id: "755",
            ooro_fname: "bmi British Midland",
            ooro_faddress: "",
            ooro_fcode: "BD",
            ooro_fweb: ""
        },
        {
            id: "754",
            ooro_fname: "flybe/British European Airways",
            ooro_faddress: "",
            ooro_fcode: "BE",
            ooro_fweb: ""
        },
        {
            id: "753",
            ooro_fname: "Biman Bangladesh Airlines",
            ooro_faddress: "",
            ooro_fcode: "BG",
            ooro_fweb: ""
        },
        {
            id: "752",
            ooro_fname: "Hawkair",
            ooro_faddress: "",
            ooro_fcode: "BH",
            ooro_fweb: ""
        },
        {
            id: "751",
            ooro_fname: "Royal Brunei Airlines",
            ooro_faddress: "",
            ooro_fcode: "BI",
            ooro_fweb: ""
        },
        {
            id: "750",
            ooro_fname: "Nouvelair Tunisie",
            ooro_faddress: "",
            ooro_fcode: "BJ",
            ooro_fweb: ""
        },
        {
            id: "749",
            ooro_fname: "Potomac Air/US Airways Express",
            ooro_faddress: "",
            ooro_fcode: "BK",
            ooro_fweb: ""
        },
        {
            id: "748",
            ooro_fname: "Pacific Airlines",
            ooro_faddress: "",
            ooro_fcode: "BL",
            ooro_fweb: ""
        },
        {
            id: "747",
            ooro_fname: "Bayu Indonesia Air",
            ooro_faddress: "",
            ooro_fcode: "BM",
            ooro_fweb: ""
        },
        {
            id: "746",
            ooro_fname: "Horizon Airlines",
            ooro_faddress: "",
            ooro_fcode: "BN",
            ooro_fweb: ""
        },
        {
            id: "745",
            ooro_fname: "Air Botswana",
            ooro_faddress: "",
            ooro_fcode: "BP",
            ooro_fweb: ""
        },
        {
            id: "744",
            ooro_fname: "Aeromar",
            ooro_faddress: "",
            ooro_fcode: "BQ",
            ooro_fweb: ""
        },
        {
            id: "743",
            ooro_fname: "EVA Airways Corporation",
            ooro_faddress: "",
            ooro_fcode: "BR",
            ooro_fweb: ""
        },
        {
            id: "742",
            ooro_fname: "British International Helicopters",
            ooro_faddress: "",
            ooro_fcode: "BS",
            ooro_fweb: ""
        },
        {
            id: "741",
            ooro_fname: "Air Baltic",
            ooro_faddress: "",
            ooro_fcode: "BT",
            ooro_fweb: ""
        },
        {
            id: "740",
            ooro_fname: "SAS Braathens",
            ooro_faddress: "",
            ooro_fcode: "BU",
            ooro_fweb: ""
        },
        {
            id: "739",
            ooro_fname: "Blue Panorama Airlines",
            ooro_faddress: "",
            ooro_fcode: "BV",
            ooro_fweb: ""
        },
        {
            id: "738",
            ooro_fname: "BWIA West Indies Airways",
            ooro_faddress: "",
            ooro_fcode: "BW",
            ooro_fweb: ""
        },
        {
            id: "737",
            ooro_fname: "Coast Air",
            ooro_faddress: "",
            ooro_fcode: "BX",
            ooro_fweb: ""
        },
        {
            id: "736",
            ooro_fname: "Thomsonfly",
            ooro_faddress: "",
            ooro_fcode: "BY",
            ooro_fweb: ""
        },
        {
            id: "735",
            ooro_fname: "Keystone Air Service",
            ooro_faddress: "",
            ooro_fcode: "BZ",
            ooro_fweb: ""
        },
        {
            id: "734",
            ooro_fname: "Centralwings",
            ooro_faddress: "",
            ooro_fcode: "C0",
            ooro_fweb: ""
        },
        {
            id: "733",
            ooro_fname: "Zimex Aviation",
            ooro_faddress: "",
            ooro_fcode: "C4",
            ooro_fweb: ""
        },
        {
            id: "732",
            ooro_fname: "Commutair/Continental Connection",
            ooro_faddress: "",
            ooro_fcode: "C5",
            ooro_fweb: ""
        },
        {
            id: "731",
            ooro_fname: "CanJet",
            ooro_faddress: "",
            ooro_fcode: "C6",
            ooro_fweb: ""
        },
        {
            id: "730",
            ooro_fname: "Rico Linhas Aereas",
            ooro_faddress: "",
            ooro_fcode: "C7",
            ooro_fweb: ""
        },
        {
            id: "729",
            ooro_fname: "Cirrus Airways/Team Lufthansa",
            ooro_faddress: "",
            ooro_fcode: "C9",
            ooro_fweb: ""
        },
        {
            id: "728",
            ooro_fname: "Air China",
            ooro_faddress: "",
            ooro_fcode: "CA",
            ooro_fweb: ""
        },
        {
            id: "727",
            ooro_fname: "Scot Airways",
            ooro_faddress: "",
            ooro_fcode: "CB",
            ooro_fweb: ""
        },
        {
            id: "726",
            ooro_fname: "Macair",
            ooro_faddress: "",
            ooro_fcode: "CC",
            ooro_fweb: ""
        },
        {
            id: "725",
            ooro_fname: "Alliance Air",
            ooro_faddress: "",
            ooro_fcode: "CD",
            ooro_fweb: ""
        },
        {
            id: "724",
            ooro_fname: "City Airline",
            ooro_faddress: "",
            ooro_fcode: "CF",
            ooro_fweb: ""
        },
        {
            id: "723",
            ooro_fname: "Bemidji Airlines",
            ooro_faddress: "",
            ooro_fcode: "CH",
            ooro_fweb: ""
        },
        {
            id: "722",
            ooro_fname: "China Airlines",
            ooro_faddress: "",
            ooro_fcode: "CI",
            ooro_fweb: ""
        },
        {
            id: "721",
            ooro_fname: "China Cargo Airlines",
            ooro_faddress: "",
            ooro_fcode: "CK",
            ooro_fweb: ""
        },
        {
            id: "720",
            ooro_fname: "Lufthansa Cityline/Lufthansa Regional",
            ooro_faddress: "",
            ooro_fcode: "CL",
            ooro_fweb: ""
        },
        {
            id: "719",
            ooro_fname: "Copa Airlines",
            ooro_faddress: "",
            ooro_fcode: "CM",
            ooro_fweb: ""
        },
        {
            id: "718",
            ooro_fname: "Continental Airlines",
            ooro_faddress: "",
            ooro_fcode: "CO",
            ooro_fweb: ""
        },
        {
            id: "717",
            ooro_fname: "Sunshine Express Airlines",
            ooro_faddress: "",
            ooro_fcode: "CQ",
            ooro_fweb: ""
        },
        {
            id: "716",
            ooro_fname: "Air Sofia",
            ooro_faddress: "",
            ooro_fcode: "CT",
            ooro_fweb: ""
        },
        {
            id: "715",
            ooro_fname: "Cubana de Aviacion",
            ooro_faddress: "",
            ooro_fcode: "CU",
            ooro_fweb: ""
        },
        {
            id: "714",
            ooro_fname: "Air Chathams",
            ooro_faddress: "",
            ooro_fcode: "CV",
            ooro_fweb: ""
        },
        {
            id: "713",
            ooro_fname: "Air Marshall Islands",
            ooro_faddress: "",
            ooro_fcode: "CW",
            ooro_fweb: ""
        },
        {
            id: "712",
            ooro_fname: "Cathay Pacific",
            ooro_faddress: "",
            ooro_fcode: "CX",
            ooro_fweb: ""
        },
        {
            id: "711",
            ooro_fname: "Cyprus Airways",
            ooro_faddress: "",
            ooro_fcode: "CY",
            ooro_fweb: ""
        },
        {
            id: "710",
            ooro_fname: "China Southern Airlines",
            ooro_faddress: "",
            ooro_fcode: "CZ",
            ooro_fweb: ""
        },
        {
            id: "709",
            ooro_fname: "DHL Air Limited",
            ooro_faddress: "",
            ooro_fcode: "D0",
            ooro_fweb: ""
        },
        {
            id: "708",
            ooro_fname: "Skyline Nepc",
            ooro_faddress: "",
            ooro_fcode: "D2",
            ooro_fweb: ""
        },
        {
            id: "707",
            ooro_fname: "Daallo Airlines",
            ooro_faddress: "",
            ooro_fcode: "D3",
            ooro_fweb: ""
        },
        {
            id: "706",
            ooro_fname: "AlidauniA",
            ooro_faddress: "",
            ooro_fcode: "D4",
            ooro_fweb: ""
        },
        {
            id: "705",
            ooro_fname: "Dau Air",
            ooro_faddress: "",
            ooro_fcode: "D5",
            ooro_fweb: ""
        },
        {
            id: "704",
            ooro_fname: "Inter Air",
            ooro_faddress: "",
            ooro_fcode: "D6",
            ooro_fweb: ""
        },
        {
            id: "703",
            ooro_fname: "Djibouti Airlines",
            ooro_faddress: "",
            ooro_fcode: "D8",
            ooro_fweb: ""
        },
        {
            id: "702",
            ooro_fname: "Aeroflot-Don/Donavia",
            ooro_faddress: "",
            ooro_fcode: "D9",
            ooro_fweb: ""
        },
        {
            id: "701",
            ooro_fname: "Brit Air",
            ooro_faddress: "",
            ooro_fcode: "DB",
            ooro_fweb: ""
        },
        {
            id: "700",
            ooro_fname: "Golden Air",
            ooro_faddress: "",
            ooro_fcode: "DC",
            ooro_fweb: ""
        },
        {
            id: "699",
            ooro_fname: "Condor Flugdienst",
            ooro_faddress: "",
            ooro_fcode: "DE",
            ooro_fweb: ""
        },
        {
            id: "698",
            ooro_fname: "Aebal Aerolineas de Baleares",
            ooro_faddress: "",
            ooro_fcode: "DF",
            ooro_fweb: ""
        },
        {
            id: "697",
            ooro_fname: "South East Asian Airlines",
            ooro_faddress: "",
            ooro_fcode: "DG",
            ooro_fweb: ""
        },
        {
            id: "696",
            ooro_fname: "Independence Air",
            ooro_faddress: "",
            ooro_fcode: "DH",
            ooro_fweb: ""
        },
        {
            id: "695",
            ooro_fname: "dba Deutsche BA",
            ooro_faddress: "",
            ooro_fcode: "DI",
            ooro_fweb: ""
        },
        {
            id: "694",
            ooro_fname: "Virgin Blue Airways",
            ooro_faddress: "",
            ooro_fcode: "DJ",
            ooro_fweb: ""
        },
        {
            id: "693",
            ooro_fname: "Eastland Air",
            ooro_faddress: "",
            ooro_fcode: "DK",
            ooro_fweb: ""
        },
        {
            id: "692",
            ooro_fname: "Delta Airways",
            ooro_faddress: "",
            ooro_fcode: "DL",
            ooro_fweb: ""
        },
        {
            id: "691",
            ooro_fname: "Air Deccan",
            ooro_faddress: "",
            ooro_fcode: "DN",
            ooro_fweb: ""
        },
        {
            id: "690",
            ooro_fname: "Air Vallee",
            ooro_faddress: "",
            ooro_fcode: "DO",
            ooro_fweb: ""
        },
        {
            id: "689",
            ooro_fname: "First Choice Airways",
            ooro_faddress: "",
            ooro_fcode: "DP",
            ooro_fweb: ""
        },
        {
            id: "688",
            ooro_fname: "Coastal Air Transport",
            ooro_faddress: "",
            ooro_fcode: "DQ",
            ooro_fweb: ""
        },
        {
            id: "687",
            ooro_fname: "EasyJet Switzerland",
            ooro_faddress: "",
            ooro_fcode: "DS",
            ooro_fweb: ""
        },
        {
            id: "686",
            ooro_fname: "TAAG Angola Airlines",
            ooro_faddress: "",
            ooro_fcode: "DT",
            ooro_fweb: ""
        },
        {
            id: "685",
            ooro_fname: "Hemus Air",
            ooro_faddress: "",
            ooro_fcode: "DU",
            ooro_fweb: ""
        },
        {
            id: "684",
            ooro_fname: "PLL Scat Aircompany",
            ooro_faddress: "",
            ooro_fcode: "DV",
            ooro_fweb: ""
        },
        {
            id: "683",
            ooro_fname: "Aero-Charter Ukraine",
            ooro_faddress: "",
            ooro_fcode: "DW",
            ooro_fweb: ""
        },
        {
            id: "682",
            ooro_fname: "DAT Danish Air Transport",
            ooro_faddress: "",
            ooro_fcode: "DX",
            ooro_fweb: ""
        },
        {
            id: "681",
            ooro_fname: "Norwegian Air Shuttle",
            ooro_faddress: "",
            ooro_fcode: "DY",
            ooro_fweb: ""
        },
        {
            id: "680",
            ooro_fname: "Transcaraibes Air International",
            ooro_faddress: "",
            ooro_fcode: "DZ",
            ooro_fweb: ""
        },
        {
            id: "679",
            ooro_fname: "Eos Airlines",
            ooro_faddress: "",
            ooro_fcode: "E0",
            ooro_fweb: ""
        },
        {
            id: "678",
            ooro_fname: "Domodedovo Airlines",
            ooro_faddress: "",
            ooro_fcode: "E3",
            ooro_fweb: ""
        },
        {
            id: "677",
            ooro_fname: "Aero Asia International",
            ooro_faddress: "",
            ooro_fcode: "E4",
            ooro_fweb: ""
        },
        {
            id: "676",
            ooro_fname: "Samara Airlines",
            ooro_faddress: "",
            ooro_fcode: "E5",
            ooro_fweb: ""
        },
        {
            id: "675",
            ooro_fname: "Alpi Eagles",
            ooro_faddress: "",
            ooro_fcode: "E8",
            ooro_fweb: ""
        },
        {
            id: "674",
            ooro_fname: "Boston-Maine Airways/Pan Am Clipper Connection",
            ooro_faddress: "",
            ooro_fcode: "E9",
            ooro_fweb: ""
        },
        {
            id: "673",
            ooro_fname: "European Air Express",
            ooro_faddress: "",
            ooro_fcode: "EA",
            ooro_fweb: ""
        },
        {
            id: "672",
            ooro_fname: "Avialeasing Aviation Company",
            ooro_faddress: "",
            ooro_fcode: "EC",
            ooro_fweb: ""
        },
        {
            id: "671",
            ooro_fname: "Airblue",
            ooro_faddress: "",
            ooro_fcode: "ED",
            ooro_fweb: ""
        },
        {
            id: "670",
            ooro_fname: "Aero Airlines",
            ooro_faddress: "",
            ooro_fcode: "EE",
            ooro_fweb: ""
        },
        {
            id: "669",
            ooro_fname: "Far Eastern Air Transport",
            ooro_faddress: "",
            ooro_fcode: "EF",
            ooro_fweb: ""
        },
        {
            id: "668",
            ooro_fname: "Japan Asia Airways",
            ooro_faddress: "",
            ooro_fcode: "EG",
            ooro_fweb: ""
        },
        {
            id: "667",
            ooro_fname: "Air Nippon Network",
            ooro_faddress: "",
            ooro_fcode: "EH",
            ooro_fweb: ""
        },
        {
            id: "666",
            ooro_fname: "Aer Lingus",
            ooro_faddress: "",
            ooro_fcode: "EI",
            ooro_fweb: ""
        },
        {
            id: "665",
            ooro_fname: "New England Airlines",
            ooro_faddress: "",
            ooro_fcode: "EJ",
            ooro_fweb: ""
        },
        {
            id: "664",
            ooro_fname: "Emirates",
            ooro_faddress: "",
            ooro_fcode: "EK",
            ooro_fweb: ""
        },
        {
            id: "663",
            ooro_fname: "Air Nippon",
            ooro_faddress: "",
            ooro_fcode: "EL",
            ooro_fweb: ""
        },
        {
            id: "662",
            ooro_fname: "Empire Airlines",
            ooro_faddress: "",
            ooro_fcode: "EM",
            ooro_fweb: ""
        },
        {
            id: "661",
            ooro_fname: "Air Dolomiti",
            ooro_faddress: "",
            ooro_fcode: "EN",
            ooro_fweb: ""
        },
        {
            id: "660",
            ooro_fname: "Hewa Bora Airlines",
            ooro_faddress: "",
            ooro_fcode: "EO",
            ooro_fweb: ""
        },
        {
            id: "659",
            ooro_fname: "Iran Asseman Airlines",
            ooro_faddress: "",
            ooro_fcode: "EP",
            ooro_fweb: ""
        },
        {
            id: "658",
            ooro_fname: "TAME",
            ooro_faddress: "",
            ooro_fcode: "EQ",
            ooro_fweb: ""
        },
        {
            id: "657",
            ooro_fname: "ASTAR Air Cargo",
            ooro_faddress: "",
            ooro_fcode: "ER",
            ooro_fweb: ""
        },
        {
            id: "656",
            ooro_fname: "DHL Aviation",
            ooro_faddress: "",
            ooro_fcode: "ES",
            ooro_fweb: ""
        },
        {
            id: "655",
            ooro_fname: "Ethiopian Airlines",
            ooro_faddress: "",
            ooro_fcode: "ET",
            ooro_fweb: ""
        },
        {
            id: "654",
            ooro_fname: "Ecuatoriana",
            ooro_faddress: "",
            ooro_fcode: "EU",
            ooro_fweb: ""
        },
        {
            id: "653",
            ooro_fname: "Atlantic Southeast Airlines",
            ooro_faddress: "",
            ooro_fcode: "EV",
            ooro_fweb: ""
        },
        {
            id: "652",
            ooro_fname: "Eurowings/Lufthansa Regional",
            ooro_faddress: "",
            ooro_fcode: "EW",
            ooro_fweb: ""
        },
        {
            id: "651",
            ooro_fname: "Air Santo Domingo",
            ooro_faddress: "",
            ooro_fcode: "EX",
            ooro_fweb: ""
        },
        {
            id: "650",
            ooro_fname: "Etihad Airways",
            ooro_faddress: "",
            ooro_fcode: "EY",
            ooro_fweb: ""
        },
        {
            id: "649",
            ooro_fname: "Evergreen International Airlines",
            ooro_faddress: "",
            ooro_fcode: "EZ",
            ooro_fweb: ""
        },
        {
            id: "648",
            ooro_fname: "Fly Air/Fly Havayolu Tasimacilik",
            ooro_faddress: "",
            ooro_fcode: "F2",
            ooro_fweb: ""
        },
        {
            id: "647",
            ooro_fname: "Faso Airways",
            ooro_faddress: "",
            ooro_fcode: "F3",
            ooro_fweb: ""
        },
        {
            id: "646",
            ooro_fname: "Albarka Air",
            ooro_faddress: "",
            ooro_fcode: "F4",
            ooro_fweb: ""
        },
        {
            id: "645",
            ooro_fname: "Cosmic Air",
            ooro_faddress: "",
            ooro_fcode: "F5",
            ooro_fweb: ""
        },
        {
            id: "644",
            ooro_fname: "Flybaboo",
            ooro_faddress: "",
            ooro_fcode: "F7",
            ooro_fweb: ""
        },
        {
            id: "643",
            ooro_fname: "Freedom Airlines",
            ooro_faddress: "",
            ooro_fcode: "F8",
            ooro_fweb: ""
        },
        {
            id: "642",
            ooro_fname: "Frontier Airlines",
            ooro_faddress: "",
            ooro_fcode: "F9",
            ooro_fweb: ""
        },
        {
            id: "641",
            ooro_fname: "Safair",
            ooro_faddress: "",
            ooro_fcode: "FA",
            ooro_fweb: ""
        },
        {
            id: "640",
            ooro_fname: "Bulgaria Air/Balkan Air Tours",
            ooro_faddress: "",
            ooro_fcode: "FB",
            ooro_fweb: ""
        },
        {
            id: "639",
            ooro_fname: "Finncomm Airlines",
            ooro_faddress: "",
            ooro_fcode: "FC",
            ooro_fweb: ""
        },
        {
            id: "638",
            ooro_fname: "Thai AirAsia",
            ooro_faddress: "",
            ooro_fcode: "FD",
            ooro_fweb: ""
        },
        {
            id: "637",
            ooro_fname: "Primaris Airlines",
            ooro_faddress: "",
            ooro_fcode: "FE",
            ooro_fweb: ""
        },
        {
            id: "636",
            ooro_fname: "Ariana Afghan Airlines",
            ooro_faddress: "",
            ooro_fcode: "FG",
            ooro_fweb: ""
        },
        {
            id: "635",
            ooro_fname: "Futura International Airways",
            ooro_faddress: "",
            ooro_fcode: "FH",
            ooro_fweb: ""
        },
        {
            id: "634",
            ooro_fname: "Icelandair",
            ooro_faddress: "",
            ooro_fcode: "FI",
            ooro_fweb: ""
        },
        {
            id: "633",
            ooro_fname: "Air Pacific",
            ooro_faddress: "",
            ooro_fcode: "FJ",
            ooro_fweb: ""
        },
        {
            id: "632",
            ooro_fname: "Africa West Airlines",
            ooro_faddress: "",
            ooro_fcode: "FK",
            ooro_fweb: ""
        },
        {
            id: "631",
            ooro_fname: "Airtran",
            ooro_faddress: "",
            ooro_fcode: "FL",
            ooro_fweb: ""
        },
        {
            id: "630",
            ooro_fname: "Shanghai Airlines",
            ooro_faddress: "",
            ooro_fcode: "FM",
            ooro_fweb: ""
        },
        {
            id: "629",
            ooro_fname: "Regional Airlines",
            ooro_faddress: "",
            ooro_fcode: "FN",
            ooro_fweb: ""
        },
        {
            id: "628",
            ooro_fname: "Airlines of Tasmania",
            ooro_faddress: "",
            ooro_fcode: "FO",
            ooro_fweb: ""
        },
        {
            id: "627",
            ooro_fname: "Ryanair",
            ooro_faddress: "",
            ooro_fcode: "FR",
            ooro_fweb: ""
        },
        {
            id: "626",
            ooro_fname: "STAF Airlines",
            ooro_faddress: "",
            ooro_fcode: "FS",
            ooro_fweb: ""
        },
        {
            id: "625",
            ooro_fname: "Siem Reap Airways International",
            ooro_faddress: "",
            ooro_fcode: "FT",
            ooro_fweb: ""
        },
        {
            id: "624",
            ooro_fname: "Pulkovo Aviation Enterprise",
            ooro_faddress: "",
            ooro_fcode: "FV",
            ooro_fweb: ""
        },
        {
            id: "623",
            ooro_fname: "Ibex Airlines",
            ooro_faddress: "",
            ooro_fcode: "FW",
            ooro_fweb: ""
        },
        {
            id: "622",
            ooro_fname: "FedEx/Federal Express",
            ooro_faddress: "",
            ooro_fcode: "FX",
            ooro_fweb: ""
        },
        {
            id: "621",
            ooro_fname: "Northwest Regional Airlines",
            ooro_faddress: "",
            ooro_fcode: "FY",
            ooro_fweb: ""
        },
        {
            id: "620",
            ooro_fname: "Ghana International Airlines",
            ooro_faddress: "",
            ooro_fcode: "G0",
            ooro_fweb: ""
        },
        {
            id: "619",
            ooro_fname: "Gorkha Airlines",
            ooro_faddress: "",
            ooro_fcode: "G1",
            ooro_fweb: ""
        },
        {
            id: "618",
            ooro_fname: "Dobrolet Airlines",
            ooro_faddress: "",
            ooro_fcode: "G2",
            ooro_fweb: ""
        },
        {
            id: "617",
            ooro_fname: "GOL Transportes Aereos",
            ooro_faddress: "",
            ooro_fcode: "G3",
            ooro_fweb: ""
        },
        {
            id: "616",
            ooro_fname: "Allegiant Air",
            ooro_faddress: "",
            ooro_fcode: "G4",
            ooro_fweb: ""
        },
        {
            id: "615",
            ooro_fname: "Guine Bissau Airlines",
            ooro_faddress: "",
            ooro_fcode: "G6",
            ooro_fweb: ""
        },
        {
            id: "614",
            ooro_fname: "Go Air",
            ooro_faddress: "abc",
            ooro_fcode: "G8",
            ooro_fweb: ""
        },
        {
            id: "613",
            ooro_fname: "Air Arabia",
            ooro_faddress: "",
            ooro_fcode: "G9",
            ooro_fweb: ""
        },
        {
            id: "612",
            ooro_fname: "Garuda Indonesia",
            ooro_faddress: "",
            ooro_fcode: "GA",
            ooro_fweb: ""
        },
        {
            id: "611",
            ooro_fname: "ABX Air",
            ooro_faddress: "",
            ooro_fcode: "GB",
            ooro_fweb: ""
        },
        {
            id: "610",
            ooro_fname: "Gambia International Airlines",
            ooro_faddress: "",
            ooro_fcode: "GC",
            ooro_fweb: ""
        },
        {
            id: "609",
            ooro_fname: "Air Alpha Greenland",
            ooro_faddress: "",
            ooro_fcode: "GD",
            ooro_fweb: ""
        },
        {
            id: "608",
            ooro_fname: "Trans Asia Airways",
            ooro_faddress: "",
            ooro_fcode: "GE",
            ooro_fweb: ""
        },
        {
            id: "607",
            ooro_fname: "Gulf Air",
            ooro_faddress: "",
            ooro_fcode: "GF",
            ooro_fweb: ""
        },
        {
            id: "606",
            ooro_fname: "Air Company Itek-Air",
            ooro_faddress: "",
            ooro_fcode: "GI",
            ooro_fweb: ""
        },
        {
            id: "605",
            ooro_fname: "Eurofly",
            ooro_faddress: "",
            ooro_fcode: "GJ",
            ooro_fweb: ""
        },
        {
            id: "604",
            ooro_fname: "Miami Air",
            ooro_faddress: "",
            ooro_fcode: "GL",
            ooro_fweb: ""
        },
        {
            id: "603",
            ooro_fname: "Air Slovakia",
            ooro_faddress: "",
            ooro_fcode: "GM",
            ooro_fweb: ""
        },
        {
            id: "602",
            ooro_fname: "Kuzu Airlines Cargo Transport",
            ooro_faddress: "",
            ooro_fcode: "GO",
            ooro_fweb: ""
        },
        {
            id: "601",
            ooro_fname: "Gestair",
            ooro_faddress: "",
            ooro_fcode: "GP",
            ooro_fweb: ""
        },
        {
            id: "600",
            ooro_fname: "Big Sky Airlines",
            ooro_faddress: "",
            ooro_fcode: "GQ",
            ooro_fweb: ""
        },
        {
            id: "599",
            ooro_fname: "Aurigny Air Services",
            ooro_faddress: "",
            ooro_fcode: "GR",
            ooro_fweb: ""
        },
        {
            id: "598",
            ooro_fname: "Airfoyle",
            ooro_faddress: "",
            ooro_fcode: "GS",
            ooro_fweb: ""
        },
        {
            id: "597",
            ooro_fname: "GB Airways",
            ooro_faddress: "",
            ooro_fcode: "GT",
            ooro_fweb: ""
        },
        {
            id: "596",
            ooro_fname: "Kuban Airlines",
            ooro_faddress: "",
            ooro_fcode: "GW",
            ooro_fweb: ""
        },
        {
            id: "595",
            ooro_fname: "Pacificair/Pacific Airways Corporation",
            ooro_faddress: "",
            ooro_fcode: "GX",
            ooro_fweb: ""
        },
        {
            id: "594",
            ooro_fname: "Tri-MG Intra Asia Airlines",
            ooro_faddress: "",
            ooro_fcode: "GY",
            ooro_fweb: ""
        },
        {
            id: "593",
            ooro_fname: "Hooters Air, operated by Pace Airlines",
            ooro_faddress: "",
            ooro_fcode: "H1",
            ooro_fweb: ""
        },
        {
            id: "592",
            ooro_fname: "Sky Service",
            ooro_faddress: "",
            ooro_fcode: "H2",
            ooro_fweb: ""
        },
        {
            id: "591",
            ooro_fname: "Inter Islands Airlines",
            ooro_faddress: "",
            ooro_fcode: "H4",
            ooro_fweb: ""
        },
        {
            id: "590",
            ooro_fname: "MAVIAL Magadan Airlines",
            ooro_faddress: "",
            ooro_fcode: "H5",
            ooro_fweb: ""
        },
        {
            id: "589",
            ooro_fname: "Hageland Aviation Services",
            ooro_faddress: "",
            ooro_fcode: "H6",
            ooro_fweb: ""
        },
        {
            id: "588",
            ooro_fname: "Eagle Air",
            ooro_faddress: "",
            ooro_fcode: "H7",
            ooro_fweb: ""
        },
        {
            id: "587",
            ooro_fname: "Dalavia Far East Airways",
            ooro_faddress: "",
            ooro_fcode: "H8",
            ooro_fweb: ""
        },
        {
            id: "586",
            ooro_fname: "TAM Express",
            ooro_faddress: "",
            ooro_fcode: "H9",
            ooro_fweb: ""
        },
        {
            id: "585",
            ooro_fname: "Hawaiian Airlines",
            ooro_faddress: "",
            ooro_fcode: "HA",
            ooro_fweb: ""
        },
        {
            id: "584",
            ooro_fname: "Harbor Airlines",
            ooro_faddress: "",
            ooro_fcode: "HB",
            ooro_fweb: ""
        },
        {
            id: "583",
            ooro_fname: "Aero Tropics Air Services",
            ooro_faddress: "",
            ooro_fcode: "HC",
            ooro_fweb: ""
        },
        {
            id: "582",
            ooro_fname: "Hokkaido International Airlines/Air Do",
            ooro_faddress: "",
            ooro_fcode: "HD",
            ooro_fweb: ""
        },
        {
            id: "581",
            ooro_fname: "LGW Luftfahrtgesellschaft Walter",
            ooro_faddress: "",
            ooro_fcode: "HE",
            ooro_fweb: ""
        },
        {
            id: "580",
            ooro_fname: "TUIfly/Hapagfly",
            ooro_faddress: "",
            ooro_fcode: "HF",
            ooro_fweb: ""
        },
        {
            id: "579",
            ooro_fname: "Haiti International Airline",
            ooro_faddress: "",
            ooro_fcode: "HG",
            ooro_fweb: ""
        },
        {
            id: "578",
            ooro_fname: "Asian Express Airlines",
            ooro_faddress: "",
            ooro_fcode: "HJ",
            ooro_fweb: ""
        },
        {
            id: "577",
            ooro_fname: "Four Star Aviation",
            ooro_faddress: "",
            ooro_fcode: "HK",
            ooro_fweb: ""
        },
        {
            id: "576",
            ooro_fname: "Air Seychelles",
            ooro_faddress: "",
            ooro_fcode: "HM",
            ooro_fweb: ""
        },
        {
            id: "575",
            ooro_fname: "Heavylift Cargo Airlines",
            ooro_faddress: "",
            ooro_fcode: "HN",
            ooro_fweb: ""
        },
        {
            id: "574",
            ooro_fname: "Antinea Airlines",
            ooro_faddress: "",
            ooro_fcode: "HO",
            ooro_fweb: ""
        },
        {
            id: "573",
            ooro_fname: "America West Airlines",
            ooro_faddress: "",
            ooro_fcode: "HP",
            ooro_fweb: ""
        },
        {
            id: "572",
            ooro_fname: "Harmony Airways/HMY Airways",
            ooro_faddress: "",
            ooro_fcode: "HQ",
            ooro_fweb: ""
        },
        {
            id: "571",
            ooro_fname: "Hahn Air Lines",
            ooro_faddress: "",
            ooro_fcode: "HR",
            ooro_fweb: ""
        },
        {
            id: "570",
            ooro_fname: "Svenska Direktflyg",
            ooro_faddress: "",
            ooro_fcode: "HS",
            ooro_fweb: ""
        },
        {
            id: "569",
            ooro_fname: "Aeromist/Kharkiv",
            ooro_faddress: "",
            ooro_fcode: "HT",
            ooro_fweb: ""
        },
        {
            id: "568",
            ooro_fname: "Hainan Airlines",
            ooro_faddress: "",
            ooro_fcode: "HU",
            ooro_fweb: ""
        },
        {
            id: "567",
            ooro_fname: "transavia.com/Transavia Airlines",
            ooro_faddress: "",
            ooro_fcode: "HV",
            ooro_fweb: ""
        },
        {
            id: "566",
            ooro_fname: "North-Wright Airways",
            ooro_faddress: "",
            ooro_fcode: "HW",
            ooro_fweb: ""
        },
        {
            id: "565",
            ooro_fname: "Uzbekistan Airways",
            ooro_faddress: "",
            ooro_fcode: "HY",
            ooro_fweb: ""
        },
        {
            id: "564",
            ooro_fname: "SAT Sakhalin Airlines/Sakhalinskie Aviatrassy",
            ooro_faddress: "",
            ooro_fcode: "HZ",
            ooro_fweb: ""
        },
        {
            id: "563",
            ooro_fname: "Interstate Airlines",
            ooro_faddress: "",
            ooro_fcode: "I4",
            ooro_fweb: ""
        },
        {
            id: "562",
            ooro_fname: "Sky Eyes",
            ooro_faddress: "",
            ooro_fcode: "I6",
            ooro_fweb: ""
        },
        {
            id: "561",
            ooro_fname: "Paramount Air",
            ooro_faddress: "",
            ooro_fcode: "I7",
            ooro_fweb: ""
        },
        {
            id: "560",
            ooro_fname: "Iraqi Airlines",
            ooro_faddress: "",
            ooro_fcode: "IA",
            ooro_fweb: ""
        },
        {
            id: "559",
            ooro_fname: "Iberia",
            ooro_faddress: "",
            ooro_fcode: "IB",
            ooro_fweb: ""
        },
        {
            id: "558",
            ooro_fname: "Indian Airlines",
            ooro_faddress: "",
            ooro_fcode: "IC",
            ooro_fweb: ""
        },
        {
            id: "557",
            ooro_fname: "Interlink Airlines",
            ooro_faddress: "",
            ooro_fcode: "ID",
            ooro_fweb: ""
        },
        {
            id: "556",
            ooro_fname: "Solomon Airlines",
            ooro_faddress: "",
            ooro_fcode: "IE",
            ooro_fweb: ""
        },
        {
            id: "555",
            ooro_fname: "Islas Airways",
            ooro_faddress: "",
            ooro_fcode: "IF",
            ooro_fweb: ""
        },
        {
            id: "554",
            ooro_fname: "Meridiana",
            ooro_faddress: "",
            ooro_fcode: "IG",
            ooro_fweb: ""
        },
        {
            id: "553",
            ooro_fname: "Falcon Aviation",
            ooro_faddress: "",
            ooro_fcode: "IH",
            ooro_fweb: ""
        },
        {
            id: "552",
            ooro_fname: "IBC Airways",
            ooro_faddress: "",
            ooro_fcode: "II",
            ooro_fweb: ""
        },
        {
            id: "551",
            ooro_fname: "IMAIR",
            ooro_faddress: "",
            ooro_fcode: "IK",
            ooro_fweb: ""
        },
        {
            id: "550",
            ooro_fname: "Lankair Private",
            ooro_faddress: "",
            ooro_fcode: "IL",
            ooro_fweb: ""
        },
        {
            id: "549",
            ooro_fname: "Menajet",
            ooro_faddress: "",
            ooro_fcode: "IM",
            ooro_fweb: ""
        },
        {
            id: "548",
            ooro_fname: "MAT Macedonian Airlines",
            ooro_faddress: "",
            ooro_fcode: "IN",
            ooro_fweb: ""
        },
        {
            id: "547",
            ooro_fname: "Indonesian Airlines Aviapatria",
            ooro_faddress: "",
            ooro_fcode: "IO",
            ooro_fweb: ""
        },
        {
            id: "546",
            ooro_fname: "Atyrau Airways",
            ooro_faddress: "",
            ooro_fcode: "IP",
            ooro_fweb: ""
        },
        {
            id: "545",
            ooro_fname: "Augsburg Airways/Lufthansa Regional",
            ooro_faddress: "",
            ooro_fcode: "IQ",
            ooro_fweb: ""
        },
        {
            id: "544",
            ooro_fname: "Iran Air",
            ooro_faddress: "",
            ooro_fcode: "IR",
            ooro_fweb: ""
        },
        {
            id: "543",
            ooro_fname: "Island Airlines",
            ooro_faddress: "",
            ooro_fcode: "IS",
            ooro_fweb: ""
        },
        {
            id: "542",
            ooro_fname: "Wind Jet",
            ooro_faddress: "",
            ooro_fcode: "IV",
            ooro_fweb: ""
        },
        {
            id: "541",
            ooro_fname: "Air India Express",
            ooro_faddress: "",
            ooro_fcode: "IX",
            ooro_fweb: ""
        },
        {
            id: "540",
            ooro_fname: "Yemenia Yemen Airways",
            ooro_faddress: "",
            ooro_fcode: "IY",
            ooro_fweb: ""
        },
        {
            id: "539",
            ooro_fname: "AZAL Azerbaijan Airlines/AHY",
            ooro_faddress: "",
            ooro_fcode: "J2",
            ooro_fweb: ""
        },
        {
            id: "538",
            ooro_fname: "Northwestern Air Lease",
            ooro_faddress: "",
            ooro_fcode: "J3",
            ooro_fweb: ""
        },
        {
            id: "537",
            ooro_fname: "Buffalo Airways",
            ooro_faddress: "",
            ooro_fcode: "J4",
            ooro_fweb: ""
        },
        {
            id: "536",
            ooro_fname: "Avcom Aviation",
            ooro_faddress: "",
            ooro_fcode: "J6",
            ooro_fweb: ""
        },
        {
            id: "535",
            ooro_fname: "Centre-Avia Airlines",
            ooro_faddress: "",
            ooro_fcode: "J7",
            ooro_fweb: ""
        },
        {
            id: "534",
            ooro_fname: "Berjaya Air",
            ooro_faddress: "",
            ooro_fcode: "J8",
            ooro_fweb: ""
        },
        {
            id: "533",
            ooro_fname: "jazeera Airlines",
            ooro_faddress: "123",
            ooro_fcode: "J9",
            ooro_fweb: "www.multilinkworld.com"
        },
        {
            id: "532",
            ooro_fname: "Jackson Airways",
            ooro_faddress: "",
            ooro_fcode: "JA",
            ooro_fweb: ""
        },
        {
            id: "531",
            ooro_fname: "Helijet Airways",
            ooro_faddress: "",
            ooro_fcode: "JB",
            ooro_fweb: ""
        },
        {
            id: "530",
            ooro_fname: "JAL Express",
            ooro_faddress: "",
            ooro_fcode: "JC",
            ooro_fweb: ""
        },
        {
            id: "529",
            ooro_fname: "Jetairfly",
            ooro_faddress: "",
            ooro_fcode: "JF",
            ooro_fweb: ""
        },
        {
            id: "528",
            ooro_fname: "Vueling Airlines",
            ooro_faddress: "",
            ooro_fcode: "VY",
            ooro_fweb: ""
        },
        {
            id: "527",
            ooro_fname: "MyTravel Airways",
            ooro_faddress: "",
            ooro_fcode: "VZ",
            ooro_fweb: ""
        },
        {
            id: "526",
            ooro_fname: "Efata Papua Airlines",
            ooro_faddress: "",
            ooro_fcode: "W2",
            ooro_fweb: ""
        },
        {
            id: "525",
            ooro_fname: "Danu Oro Transportas",
            ooro_faddress: "",
            ooro_fcode: "W3",
            ooro_fweb: ""
        },
        {
            id: "524",
            ooro_fname: "Aero Services Executive",
            ooro_faddress: "",
            ooro_fcode: "W4",
            ooro_fweb: ""
        },
        {
            id: "523",
            ooro_fname: "Mahan Air",
            ooro_faddress: "",
            ooro_fcode: "W5",
            ooro_fweb: ""
        },
        {
            id: "522",
            ooro_fname: "West Isle Air",
            ooro_faddress: "",
            ooro_fcode: "W6",
            ooro_fweb: ""
        },
        {
            id: "521",
            ooro_fname: "Sayakhat",
            ooro_faddress: "",
            ooro_fcode: "W7",
            ooro_fweb: ""
        },
        {
            id: "520",
            ooro_fname: "CargoJet Airways",
            ooro_faddress: "",
            ooro_fcode: "W8",
            ooro_fweb: ""
        },
        {
            id: "519",
            ooro_fname: "KLM CityHopper",
            ooro_faddress: "",
            ooro_fcode: "WA",
            ooro_fweb: ""
        },
        {
            id: "518",
            ooro_fname: "Rwandair Express",
            ooro_faddress: "",
            ooro_fcode: "WB",
            ooro_fweb: ""
        },
        {
            id: "517",
            ooro_fname: "Islena Airlines",
            ooro_faddress: "",
            ooro_fcode: "WC",
            ooro_fweb: ""
        },
        {
            id: "516",
            ooro_fname: "DAS Air Limited",
            ooro_faddress: "",
            ooro_fcode: "WD",
            ooro_fweb: ""
        },
        {
            id: "515",
            ooro_fname: "Centurion Air Cargo",
            ooro_faddress: "DELHI",
            ooro_fcode: "WET",
            ooro_fweb: "MULTILINKWORLD.COM"
        },
        {
            id: "514",
            ooro_fname: "Wideroe",
            ooro_faddress: "",
            ooro_fcode: "WF",
            ooro_fweb: ""
        },
        {
            id: "513",
            ooro_fname: "Sunwing Airlines",
            ooro_faddress: "",
            ooro_fcode: "WG",
            ooro_fweb: ""
        },
        {
            id: "512",
            ooro_fname: "Air Labrador",
            ooro_faddress: "",
            ooro_fcode: "WJ",
            ooro_fweb: ""
        },
        {
            id: "511",
            ooro_fname: "American Falcon",
            ooro_faddress: "",
            ooro_fcode: "WK",
            ooro_fweb: ""
        },
        {
            id: "510",
            ooro_fname: "AeroPerlas",
            ooro_faddress: "",
            ooro_fcode: "WL",
            ooro_fweb: ""
        },
        {
            id: "509",
            ooro_fname: "Winair/Windward Islands Airways",
            ooro_faddress: "",
            ooro_fcode: "WM",
            ooro_fweb: ""
        },
        {
            id: "508",
            ooro_fname: "Southwest Airlines",
            ooro_faddress: "",
            ooro_fcode: "WN",
            ooro_fweb: ""
        },
        {
            id: "507",
            ooro_fname: "World Airways",
            ooro_faddress: "",
            ooro_fcode: "WO",
            ooro_fweb: ""
        },
        {
            id: "506",
            ooro_fname: "Island Air",
            ooro_faddress: "",
            ooro_fcode: "WP",
            ooro_fweb: ""
        },
        {
            id: "505",
            ooro_fname: "Romavia/Romanian Aviation Company",
            ooro_faddress: "",
            ooro_fcode: "WQ",
            ooro_fweb: ""
        },
        {
            id: "504",
            ooro_fname: "WestJet",
            ooro_faddress: "",
            ooro_fcode: "WS",
            ooro_fweb: ""
        },
        {
            id: "503",
            ooro_fname: "Nigeria Airways",
            ooro_faddress: "",
            ooro_fcode: "WT",
            ooro_fweb: ""
        },
        {
            id: "502",
            ooro_fname: "Tikal Jets",
            ooro_faddress: "",
            ooro_fcode: "WU",
            ooro_fweb: ""
        },
        {
            id: "501",
            ooro_fname: "Swe Fly",
            ooro_faddress: "",
            ooro_fcode: "WV",
            ooro_fweb: ""
        },
        {
            id: "500",
            ooro_fname: "bmibaby",
            ooro_faddress: "",
            ooro_fcode: "WW",
            ooro_fweb: ""
        },
        {
            id: "499",
            ooro_fname: "CityJet",
            ooro_faddress: "",
            ooro_fcode: "WX",
            ooro_fweb: ""
        },
        {
            id: "498",
            ooro_fname: "Oman Air",
            ooro_faddress: "",
            ooro_fcode: "WY",
            ooro_fweb: ""
        },
        {
            id: "497",
            ooro_fname: "West African Airlines",
            ooro_faddress: "",
            ooro_fcode: "WZ",
            ooro_fweb: ""
        },
        {
            id: "496",
            ooro_fname: "TUIfly/HLX.com",
            ooro_faddress: "",
            ooro_fcode: "X3",
            ooro_fweb: ""
        },
        {
            id: "495",
            ooro_fname: "Afrique Airlines",
            ooro_faddress: "",
            ooro_fcode: "X5",
            ooro_fweb: ""
        },
        {
            id: "494",
            ooro_fname: "City Star Airlines",
            ooro_faddress: "",
            ooro_fcode: "X9",
            ooro_fweb: ""
        },
        {
            id: "493",
            ooro_fname: "ARINC/Aeronautical Radio Inc.",
            ooro_faddress: "",
            ooro_fcode: "XA",
            ooro_fweb: ""
        },
        {
            id: "492",
            ooro_fname: "IATA/Int'l Air Transport Association",
            ooro_faddress: "",
            ooro_fcode: "XB",
            ooro_fweb: ""
        },
        {
            id: "491",
            ooro_fname: "KD Air",
            ooro_faddress: "",
            ooro_fcode: "XC",
            ooro_fweb: ""
        },
        {
            id: "490",
            ooro_fname: "ExpressJet Airlines",
            ooro_faddress: "",
            ooro_fcode: "XE",
            ooro_fweb: ""
        },
        {
            id: "489",
            ooro_fname: "Vladivostok Air",
            ooro_faddress: "",
            ooro_fcode: "XF",
            ooro_fweb: ""
        },
        {
            id: "488",
            ooro_fname: "Air Mali International",
            ooro_faddress: "",
            ooro_fcode: "XG",
            ooro_fweb: ""
        },
        {
            id: "487",
            ooro_fname: "Special Ground Handling Service",
            ooro_faddress: "",
            ooro_fcode: "XH",
            ooro_fweb: ""
        },
        {
            id: "486",
            ooro_fname: "AEROTEL/Aeronautical Telecommunications",
            ooro_faddress: "",
            ooro_fcode: "XI",
            ooro_fweb: ""
        },
        {
            id: "485",
            ooro_fname: "Mesaba Airlines",
            ooro_faddress: "",
            ooro_fcode: "XJ",
            ooro_fweb: ""
        },
        {
            id: "484",
            ooro_fname: "CCM Compagnie Corse Mediterrannee",
            ooro_faddress: "",
            ooro_fcode: "XK",
            ooro_fweb: ""
        },
        {
            id: "483",
            ooro_fname: "Alitalia Express",
            ooro_faddress: "",
            ooro_fcode: "XM",
            ooro_fweb: ""
        },
        {
            id: "482",
            ooro_fname: "China Xinjiang Airlines",
            ooro_faddress: "",
            ooro_fcode: "XO",
            ooro_fweb: ""
        },
        {
            id: "481",
            ooro_fname: "Xtra Airways/SkyValue USA",
            ooro_faddress: "",
            ooro_fcode: "XP",
            ooro_fweb: ""
        },
        {
            id: "480",
            ooro_fname: "Sun Express",
            ooro_faddress: "",
            ooro_fcode: "XQ",
            ooro_fweb: ""
        },
        {
            id: "479",
            ooro_fname: "SITA",
            ooro_faddress: "",
            ooro_fcode: "XS",
            ooro_fweb: ""
        },
        {
            id: "478",
            ooro_fname: "African Express Airways",
            ooro_faddress: "",
            ooro_fcode: "XU",
            ooro_fweb: ""
        },
        {
            id: "477",
            ooro_fname: "Ivoire Airways",
            ooro_faddress: "",
            ooro_fcode: "XV",
            ooro_fweb: ""
        },
        {
            id: "476",
            ooro_fname: "Hifly",
            ooro_faddress: "",
            ooro_fcode: "5K",
            ooro_fweb: ""
        },
        {
            id: "475",
            ooro_fname: "Cambodia Airlines",
            ooro_faddress: "",
            ooro_fcode: "Y6",
            ooro_fweb: ""
        },
        {
            id: "474",
            ooro_fname: "Flyjet",
            ooro_faddress: "",
            ooro_fcode: "Y7",
            ooro_fweb: ""
        },
        {
            id: "473",
            ooro_fname: "Sayany Airlines",
            ooro_faddress: "",
            ooro_fcode: "Y8",
            ooro_fweb: ""
        },
        {
            id: "472",
            ooro_fname: "Kish Air",
            ooro_faddress: "",
            ooro_fcode: "Y9",
            ooro_fweb: ""
        },
        {
            id: "471",
            ooro_fname: "South African Express",
            ooro_faddress: "",
            ooro_fcode: "YB",
            ooro_fweb: ""
        },
        {
            id: "470",
            ooro_fname: "Gomelavia",
            ooro_faddress: "",
            ooro_fcode: "YD",
            ooro_fweb: ""
        },
        {
            id: "469",
            ooro_fname: "Canadian Armed Forces",
            ooro_faddress: "",
            ooro_fcode: "YF",
            ooro_fweb: ""
        },
        {
            id: "468",
            ooro_fname: "South Airlines",
            ooro_faddress: "",
            ooro_fcode: "YG",
            ooro_fweb: ""
        },
        {
            id: "467",
            ooro_fname: "West Caribbean Airways",
            ooro_faddress: "",
            ooro_fcode: "YH",
            ooro_fweb: ""
        },
        {
            id: "466",
            ooro_fname: "Air Sunshine",
            ooro_faddress: "",
            ooro_fcode: "YI",
            ooro_fweb: ""
        },
        {
            id: "465",
            ooro_fname: "Cyprus Turkish Airline",
            ooro_faddress: "",
            ooro_fcode: "YK",
            ooro_fweb: ""
        },
        {
            id: "464",
            ooro_fname: "Yamal Airlines",
            ooro_faddress: "",
            ooro_fcode: "YL",
            ooro_fweb: ""
        },
        {
            id: "463",
            ooro_fname: "Montenegro Airlines",
            ooro_faddress: "",
            ooro_fcode: "YM",
            ooro_fweb: ""
        },
        {
            id: "462",
            ooro_fname: "Air Creebec",
            ooro_faddress: "",
            ooro_fcode: "YN",
            ooro_fweb: ""
        },
        {
            id: "461",
            ooro_fname: "Heli Air Monaco",
            ooro_faddress: "",
            ooro_fcode: "YO",
            ooro_fweb: ""
        },
        {
            id: "460",
            ooro_fname: "Helikopterservice Euro Air",
            ooro_faddress: "",
            ooro_fcode: "YQ",
            ooro_fweb: ""
        },
        {
            id: "459",
            ooro_fname: "Eagle Canyon Airlines",
            ooro_faddress: "",
            ooro_fcode: "YR",
            ooro_fweb: ""
        },
        {
            id: "458",
            ooro_fname: "Regional Compagnie Aerienne Europeenne",
            ooro_faddress: "",
            ooro_fcode: "YS",
            ooro_fweb: ""
        },
        {
            id: "457",
            ooro_fname: "Air Togo",
            ooro_faddress: "",
            ooro_fcode: "YT",
            ooro_fweb: ""
        },
        {
            id: "456",
            ooro_fname: "Mesa Airlines",
            ooro_faddress: "",
            ooro_fcode: "YV",
            ooro_fweb: ""
        },
        {
            id: "455",
            ooro_fname: "Orbit Express Airlines",
            ooro_faddress: "",
            ooro_fcode: "YW",
            ooro_fweb: ""
        },
        {
            id: "454",
            ooro_fname: "Styrian Airways",
            ooro_faddress: "",
            ooro_fcode: "Z1",
            ooro_fweb: ""
        },
        {
            id: "453",
            ooro_fname: "Zircon Airways",
            ooro_faddress: "",
            ooro_fcode: "Z4",
            ooro_fweb: ""
        },
        {
            id: "452",
            ooro_fname: "Dnieproavia",
            ooro_faddress: "",
            ooro_fcode: "Z6",
            ooro_fweb: ""
        },
        {
            id: "451",
            ooro_fname: "ADC Airlines",
            ooro_faddress: "",
            ooro_fcode: "Z7",
            ooro_fweb: ""
        },
        {
            id: "450",
            ooro_fname: "Mali Airways",
            ooro_faddress: "",
            ooro_fcode: "Z8",
            ooro_fweb: ""
        },
        {
            id: "449",
            ooro_fname: "Aero Zambia",
            ooro_faddress: "",
            ooro_fcode: "Z9",
            ooro_fweb: ""
        },
        {
            id: "448",
            ooro_fname: "Monarch Airlines",
            ooro_faddress: "",
            ooro_fcode: "ZB",
            ooro_fweb: ""
        },
        {
            id: "447",
            ooro_fname: "Royal Swazi National Airways",
            ooro_faddress: "",
            ooro_fcode: "ZC",
            ooro_fweb: ""
        },
        {
            id: "446",
            ooro_fname: "Cosmos Air",
            ooro_faddress: "",
            ooro_fcode: "ZE",
            ooro_fweb: ""
        },
        {
            id: "445",
            ooro_fname: "Atlantic Airlines de Honduras",
            ooro_faddress: "",
            ooro_fcode: "ZF",
            ooro_fweb: ""
        },
        {
            id: "444",
            ooro_fname: "Air Express Limited",
            ooro_faddress: "",
            ooro_fcode: "ZG",
            ooro_fweb: ""
        },
        {
            id: "443",
            ooro_fname: "Aigle Azur",
            ooro_faddress: "",
            ooro_fcode: "ZI",
            ooro_fweb: ""
        },
        {
            id: "442",
            ooro_fname: "Air Routing International",
            ooro_faddress: "",
            ooro_fcode: "ZJ",
            ooro_fweb: ""
        },
        {
            id: "441",
            ooro_fname: "Great Lakes Aviation",
            ooro_faddress: "",
            ooro_fcode: "ZK",
            ooro_fweb: ""
        },
        {
            id: "440",
            ooro_fname: "Regional Express/Hazelton Airlines",
            ooro_faddress: "",
            ooro_fcode: "ZL",
            ooro_fweb: ""
        },
        {
            id: "439",
            ooro_fname: "Great Plans Airlines/Ozark Air Lines",
            ooro_faddress: "",
            ooro_fcode: "Z1",
            ooro_fweb: ""
        },
        {
            id: "438",
            ooro_fname: "Silk Way Airlines",
            ooro_faddress: "",
            ooro_fcode: "ZP",
            ooro_fweb: ""
        },
        {
            id: "437",
            ooro_fname: "Aviacon Zitotrans",
            ooro_faddress: "",
            ooro_fcode: "ZR",
            ooro_fweb: ""
        },
        {
            id: "436",
            ooro_fname: "Air Zambezi",
            ooro_faddress: "",
            ooro_fcode: "ZT",
            ooro_fweb: ""
        },
        {
            id: "435",
            ooro_fname: "Helios Airways",
            ooro_faddress: "",
            ooro_fcode: "ZU",
            ooro_fweb: ""
        },
        {
            id: "434",
            ooro_fname: "Air Midwest",
            ooro_faddress: "",
            ooro_fcode: "ZV",
            ooro_fweb: ""
        },
        {
            id: "433",
            ooro_fname: "Air Wisconsin/United Express",
            ooro_faddress: "",
            ooro_fcode: "ZW",
            ooro_fweb: ""
        },
        {
            id: "432",
            ooro_fname: "Air Georgian/Air Alliance",
            ooro_faddress: "",
            ooro_fcode: "ZX",
            ooro_fweb: ""
        },
        {
            id: "431",
            ooro_fname: "ADA Air",
            ooro_faddress: "",
            ooro_fcode: "ZY",
            ooro_fweb: ""
        },
        {
            id: "430",
            ooro_fname: "Nordeste-Linhas Aereas Regionais",
            ooro_faddress: "",
            ooro_fcode: "JH",
            ooro_fweb: ""
        },
        {
            id: "429",
            ooro_fname: "TAM-Transportes Aereos Meridionais",
            ooro_faddress: "",
            ooro_fcode: "JJ",
            ooro_fweb: ""
        },
        {
            id: "428",
            ooro_fname: "Spanair",
            ooro_faddress: "",
            ooro_fcode: "JK",
            ooro_fweb: ""
        },
        {
            id: "427",
            ooro_fname: "Japan Airlines",
            ooro_faddress: "",
            ooro_fcode: "JL",
            ooro_fweb: ""
        },
        {
            id: "426",
            ooro_fname: "Air Jamaica",
            ooro_faddress: "",
            ooro_fcode: "JM",
            ooro_fweb: ""
        },
        {
            id: "425",
            ooro_fname: "Excel Airways",
            ooro_faddress: "",
            ooro_fcode: "JN",
            ooro_fweb: ""
        },
        {
            id: "424",
            ooro_fname: "JALWays",
            ooro_faddress: "",
            ooro_fcode: "JO",
            ooro_fweb: ""
        },
        {
            id: "423",
            ooro_fname: "Adria Airways",
            ooro_faddress: "",
            ooro_fcode: "JP",
            ooro_fweb: ""
        },
        {
            id: "422",
            ooro_fname: "Jetstar Airways",
            ooro_faddress: "",
            ooro_fcode: "JQ",
            ooro_fweb: ""
        },
        {
            id: "421",
            ooro_fname: "Aero California",
            ooro_faddress: "",
            ooro_fcode: "JR",
            ooro_fweb: ""
        },
        {
            id: "420",
            ooro_fname: "Air Koryo",
            ooro_faddress: "",
            ooro_fcode: "JS",
            ooro_fweb: ""
        },
        {
            id: "419",
            ooro_fname: "Lion Airlines/Lion Mentari Air",
            ooro_faddress: "",
            ooro_fcode: "JT",
            ooro_fweb: ""
        },
        {
            id: "418",
            ooro_fname: "JAT Yugoslav Airlines",
            ooro_faddress: "",
            ooro_fcode: "JU",
            ooro_fweb: ""
        },
        {
            id: "417",
            ooro_fname: "Bearskin Airlines",
            ooro_faddress: "",
            ooro_fcode: "JV",
            ooro_fweb: ""
        },
        {
            id: "416",
            ooro_fname: "Arrow Air",
            ooro_faddress: "",
            ooro_fcode: "JW",
            ooro_fweb: ""
        },
        {
            id: "415",
            ooro_fname: "Air Turks and Caicos",
            ooro_faddress: "",
            ooro_fcode: "JY",
            ooro_fweb: ""
        },
        {
            id: "414",
            ooro_fname: "Skyways",
            ooro_faddress: "",
            ooro_fcode: "JZ",
            ooro_fweb: ""
        },
        {
            id: "413",
            ooro_fname: "Eurolot",
            ooro_faddress: "",
            ooro_fcode: "K2",
            ooro_fweb: ""
        },
        {
            id: "412",
            ooro_fname: "Kalitta Air",
            ooro_faddress: "",
            ooro_fcode: "K4",
            ooro_fweb: ""
        },
        {
            id: "411",
            ooro_fname: "Wings of Alaska",
            ooro_faddress: "",
            ooro_fcode: "K5",
            ooro_fweb: ""
        },
        {
            id: "410",
            ooro_fname: "Zambia Skyways",
            ooro_faddress: "",
            ooro_fcode: "K8",
            ooro_fweb: ""
        },
        {
            id: "409",
            ooro_fname: "Skyward Aviation",
            ooro_faddress: "",
            ooro_fcode: "K9",
            ooro_fweb: ""
        },
        {
            id: "408",
            ooro_fname: "Dragonair/Hong Kong Dragon Airlines",
            ooro_faddress: "",
            ooro_fcode: "KA",
            ooro_fweb: ""
        },
        {
            id: "407",
            ooro_fname: "Druk Air",
            ooro_faddress: "",
            ooro_fcode: "KB",
            ooro_fweb: ""
        },
        {
            id: "406",
            ooro_fname: "Kaliningradavia",
            ooro_faddress: "",
            ooro_fcode: "KD",
            ooro_fweb: ""
        },
        {
            id: "405",
            ooro_fname: "Korean Air",
            ooro_faddress: "",
            ooro_fcode: "KE",
            ooro_fweb: ""
        },
        {
            id: "404",
            ooro_fname: "Blue1",
            ooro_faddress: "",
            ooro_fcode: "KF",
            ooro_fweb: ""
        },
        {
            id: "403",
            ooro_fname: "Linea Aerea IAACA-LAI",
            ooro_faddress: "",
            ooro_fcode: "KG",
            ooro_fweb: ""
        },
        {
            id: "402",
            ooro_fname: "Adam Air/Adam SkyConnection Airlines",
            ooro_faddress: "",
            ooro_fcode: "KI",
            ooro_fweb: ""
        },
        {
            id: "401",
            ooro_fname: "BMED/British Mediterranean Airways",
            ooro_faddress: "",
            ooro_fcode: "KJ",
            ooro_fweb: ""
        },
        {
            id: "400",
            ooro_fname: "AtlasJet",
            ooro_faddress: "",
            ooro_fcode: "KK",
            ooro_fweb: ""
        },
        {
            id: "399",
            ooro_fname: "KLM Royal Dutch Airlines",
            ooro_faddress: "",
            ooro_fcode: "KL",
            ooro_fweb: ""
        },
        {
            id: "398",
            ooro_fname: "Air Malta",
            ooro_faddress: "",
            ooro_fcode: "KM",
            ooro_fweb: ""
        },
        {
            id: "397",
            ooro_fname: "China United Airlines",
            ooro_faddress: "",
            ooro_fcode: "KN",
            ooro_fweb: ""
        },
        {
            id: "396",
            ooro_fname: "Alaska Central Express",
            ooro_faddress: "",
            ooro_fcode: "KO",
            ooro_fweb: ""
        },
        {
            id: "395",
            ooro_fname: "Kenya Airways",
            ooro_faddress: "",
            ooro_fcode: "KQ",
            ooro_fweb: ""
        },
        {
            id: "394",
            ooro_fname: "Comores Aviation",
            ooro_faddress: "",
            ooro_fcode: "KR",
            ooro_fweb: ""
        },
        {
            id: "393",
            ooro_fname: "Penair/Peninsula Airways",
            ooro_faddress: "",
            ooro_fcode: "KS",
            ooro_fweb: ""
        },
        {
            id: "392",
            ooro_fname: "Kuwait Airways",
            ooro_faddress: "abc",
            ooro_fcode: "KU",
            ooro_fweb: "www.ku.com"
        },
        {
            id: "391",
            ooro_fname: "Kavminvodyavia",
            ooro_faddress: "",
            ooro_fcode: "KV",
            ooro_fweb: ""
        },
        {
            id: "390",
            ooro_fname: "Kas Air",
            ooro_faddress: "",
            ooro_fcode: "KW",
            ooro_fweb: ""
        },
        {
            id: "389",
            ooro_fname: "Cayman Airways",
            ooro_faddress: "",
            ooro_fcode: "KX",
            ooro_fweb: ""
        },
        {
            id: "388",
            ooro_fname: "Air Sao Tome e Principe",
            ooro_faddress: "",
            ooro_fcode: "KY",
            ooro_fweb: ""
        },
        {
            id: "387",
            ooro_fname: "Nippon Cargo Airlines",
            ooro_faddress: "",
            ooro_fcode: "KZ",
            ooro_fweb: ""
        },
        {
            id: "386",
            ooro_fname: "Lynden Air Cargo",
            ooro_faddress: "",
            ooro_fcode: "L2",
            ooro_fweb: ""
        },
        {
            id: "385",
            ooro_fname: "LTU Austria",
            ooro_faddress: "",
            ooro_fcode: "L3",
            ooro_fweb: ""
        },
        {
            id: "384",
            ooro_fname: "Tbilaviamsheni",
            ooro_faddress: "",
            ooro_fcode: "L6",
            ooro_fweb: ""
        },
        {
            id: "383",
            ooro_fname: "Laoag International Airlines",
            ooro_faddress: "",
            ooro_fcode: "L7",
            ooro_fweb: ""
        },
        {
            id: "382",
            ooro_fname: "Air Luxor GB",
            ooro_faddress: "",
            ooro_fcode: "L8",
            ooro_fweb: ""
        },
        {
            id: "381",
            ooro_fname: "Teamline Air Luftfahrt",
            ooro_faddress: "",
            ooro_fcode: "L9",
            ooro_fweb: ""
        },
        {
            id: "380",
            ooro_fname: "LAN Airlines",
            ooro_faddress: "",
            ooro_fcode: "LA",
            ooro_fweb: ""
        },
        {
            id: "379",
            ooro_fname: "Air Costa",
            ooro_faddress: "Shivranjani",
            ooro_fcode: "LB",
            ooro_fweb: "http://www.aircosta.in/"
        },
        {
            id: "378",
            ooro_fname: "Varig Log",
            ooro_faddress: "",
            ooro_fcode: "LC",
            ooro_fweb: ""
        },
        {
            id: "377",
            ooro_fname: "Air Hong Kong",
            ooro_faddress: "",
            ooro_fcode: "LD",
            ooro_fweb: ""
        },
        {
            id: "376",
            ooro_fname: "FlyNordic/Nordic East Airlink",
            ooro_faddress: "",
            ooro_fcode: "LF",
            ooro_fweb: ""
        },
        {
            id: "375",
            ooro_fname: "Luxair",
            ooro_faddress: "",
            ooro_fcode: "LG",
            ooro_fweb: ""
        },
        {
            id: "374",
            ooro_fname: "Lufthansa",
            ooro_faddress: "",
            ooro_fcode: "LH",
            ooro_fweb: ""
        },
        {
            id: "373",
            ooro_fname: "LIAT",
            ooro_faddress: "",
            ooro_fcode: "LI",
            ooro_fweb: ""
        },
        {
            id: "372",
            ooro_fname: "Sierra National Airlines",
            ooro_faddress: "",
            ooro_fcode: "LJ",
            ooro_fweb: ""
        },
        {
            id: "371",
            ooro_fname: "Livingston",
            ooro_faddress: "",
            ooro_fcode: "LM",
            ooro_fweb: ""
        },
        {
            id: "370",
            ooro_fname: "Lybian Arab Airlines",
            ooro_faddress: "",
            ooro_fcode: "LN",
            ooro_fweb: ""
        },
        {
            id: "369",
            ooro_fname: "LOT Polish Airlines",
            ooro_faddress: "",
            ooro_fcode: "LO",
            ooro_fweb: ""
        },
        {
            id: "368",
            ooro_fname: "LAN Peru",
            ooro_faddress: "",
            ooro_fcode: "LP",
            ooro_fweb: ""
        },
        {
            id: "367",
            ooro_fname: "Lebanese Air Transport",
            ooro_faddress: "",
            ooro_fcode: "LQ",
            ooro_fweb: ""
        },
        {
            id: "366",
            ooro_fname: "Lacsa/Lineas Aereas Costarricenses SA",
            ooro_faddress: "",
            ooro_fcode: "LR",
            ooro_fweb: ""
        },
        {
            id: "365",
            ooro_fname: "Jet2.com",
            ooro_faddress: "",
            ooro_fcode: "LS",
            ooro_fweb: ""
        },
        {
            id: "364",
            ooro_fname: "LTU International Airways",
            ooro_faddress: "",
            ooro_fcode: "LT",
            ooro_fweb: ""
        },
        {
            id: "363",
            ooro_fname: "Albanian Airlines",
            ooro_faddress: "",
            ooro_fcode: "LV",
            ooro_fweb: ""
        },
        {
            id: "362",
            ooro_fname: "Pacific Wings",
            ooro_faddress: "",
            ooro_fcode: "LW",
            ooro_fweb: ""
        },
        {
            id: "361",
            ooro_fname: "Swiss International Air Lines",
            ooro_faddress: "",
            ooro_fcode: "LX",
            ooro_fweb: ""
        },
        {
            id: "360",
            ooro_fname: "El Al Israel",
            ooro_faddress: "",
            ooro_fcode: "LY",
            ooro_fweb: ""
        },
        {
            id: "359",
            ooro_fname: "Mahfooz Aviation",
            ooro_faddress: "",
            ooro_fcode: "M2",
            ooro_fweb: ""
        },
        {
            id: "358",
            ooro_fname: "ABSA Aerolinhas Brasileiras",
            ooro_faddress: "",
            ooro_fcode: "M3",
            ooro_fweb: ""
        },
        {
            id: "357",
            ooro_fname: "Amerijet International",
            ooro_faddress: "",
            ooro_fcode: "M6",
            ooro_fweb: ""
        },
        {
            id: "356",
            ooro_fname: "Tropical Airways D'Haiti",
            ooro_faddress: "",
            ooro_fcode: "M7",
            ooro_fweb: ""
        },
        {
            id: "355",
            ooro_fname: "Motor Sich Aviakompania",
            ooro_faddress: "",
            ooro_fcode: "M9",
            ooro_fweb: ""
        },
        {
            id: "354",
            ooro_fname: "Malev Hungarian Airlines",
            ooro_faddress: "",
            ooro_fcode: "MA",
            ooro_fweb: ""
        },
        {
            id: "353",
            ooro_fname: "Execaire",
            ooro_faddress: "",
            ooro_fcode: "MB",
            ooro_fweb: ""
        },
        {
            id: "352",
            ooro_fname: "Air Mobility Command",
            ooro_faddress: "",
            ooro_fcode: "MC",
            ooro_fweb: ""
        },
        {
            id: "351",
            ooro_fname: "Air Madagascar",
            ooro_faddress: "",
            ooro_fcode: "MD",
            ooro_fweb: ""
        },
        {
            id: "350",
            ooro_fname: "MEA Middle East Airlines Airliban",
            ooro_faddress: "",
            ooro_fcode: "ME",
            ooro_fweb: ""
        },
        {
            id: "349",
            ooro_fname: "Xiamen Airlines",
            ooro_faddress: "",
            ooro_fcode: "MF",
            ooro_fweb: ""
        },
        {
            id: "348",
            ooro_fname: "Champion Air",
            ooro_faddress: "",
            ooro_fcode: "MG",
            ooro_fweb: ""
        },
        {
            id: "347",
            ooro_fname: "Malaysia Airlines",
            ooro_faddress: "",
            ooro_fcode: "MH",
            ooro_fweb: ""
        },
        {
            id: "346",
            ooro_fname: "SilkAir",
            ooro_faddress: "",
            ooro_fcode: "MI",
            ooro_fweb: ""
        },
        {
            id: "345",
            ooro_fname: "Air Mauritius",
            ooro_faddress: "",
            ooro_fcode: "MK",
            ooro_fweb: ""
        },
        {
            id: "344",
            ooro_fname: "ATTICO African Transport Trading and Investment Co.",
            ooro_faddress: "",
            ooro_fcode: "ML",
            ooro_fweb: ""
        },
        {
            id: "343",
            ooro_fname: "Euroatlantic Airways",
            ooro_faddress: "",
            ooro_fcode: "MM",
            ooro_fweb: ""
        },
        {
            id: "342",
            ooro_fname: "Comair Commercial Airlines/kulula.com",
            ooro_faddress: "",
            ooro_fcode: "MN",
            ooro_fweb: ""
        },
        {
            id: "341",
            ooro_fname: "Abu Dhabi Amiri Flight",
            ooro_faddress: "",
            ooro_fcode: "MO",
            ooro_fweb: ""
        },
        {
            id: "340",
            ooro_fname: "Martinair",
            ooro_faddress: "",
            ooro_fcode: "MP",
            ooro_fweb: ""
        },
        {
            id: "339",
            ooro_fname: "American Eagle",
            ooro_faddress: "",
            ooro_fcode: "MQ",
            ooro_fweb: ""
        },
        {
            id: "338",
            ooro_fname: "Air Mauritanie",
            ooro_faddress: "",
            ooro_fcode: "MR",
            ooro_fweb: ""
        },
        {
            id: "337",
            ooro_fname: "Egypt Air",
            ooro_faddress: "",
            ooro_fcode: "MS",
            ooro_fweb: ""
        },
        {
            id: "336",
            ooro_fname: "Thomas Cook Airlines",
            ooro_faddress: "",
            ooro_fcode: "MT",
            ooro_fweb: ""
        },
        {
            id: "335",
            ooro_fname: "China Eastern Airlines",
            ooro_faddress: "",
            ooro_fcode: "MU",
            ooro_fweb: ""
        },
        {
            id: "334",
            ooro_fname: "Armenian International Airlines",
            ooro_faddress: "",
            ooro_fcode: "MV",
            ooro_fweb: ""
        },
        {
            id: "333",
            ooro_fname: "Maya Island Air",
            ooro_faddress: "",
            ooro_fcode: "MW",
            ooro_fweb: ""
        },
        {
            id: "332",
            ooro_fname: "Mexicana Airlines",
            ooro_faddress: "",
            ooro_fcode: "MX",
            ooro_fweb: ""
        },
        {
            id: "331",
            ooro_fname: "Midwest Airlines",
            ooro_faddress: "",
            ooro_fcode: "YX",
            ooro_fweb: ""
        },
        {
            id: "330",
            ooro_fname: "Merpati Nusantara Airlines",
            ooro_faddress: "",
            ooro_fcode: "MZ",
            ooro_fweb: ""
        },
        {
            id: "329",
            ooro_fname: "Kabo Airlines",
            ooro_faddress: "",
            ooro_fcode: "N2",
            ooro_fweb: ""
        },
        {
            id: "328",
            ooro_fname: "Omskavia Airline",
            ooro_faddress: "",
            ooro_fcode: "N3",
            ooro_fweb: ""
        },
        {
            id: "327",
            ooro_fname: "Minerva Airlines",
            ooro_faddress: "",
            ooro_fcode: "N4",
            ooro_fweb: ""
        },
        {
            id: "326",
            ooro_fname: "Kyrgyz International Airlines",
            ooro_faddress: "",
            ooro_fcode: "N5",
            ooro_fweb: ""
        },
        {
            id: "325",
            ooro_fname: "Lagun Air",
            ooro_faddress: "",
            ooro_fcode: "N7",
            ooro_fweb: ""
        },
        {
            id: "324",
            ooro_fname: "CR Airways",
            ooro_faddress: "",
            ooro_fcode: "N8",
            ooro_fweb: ""
        },
        {
            id: "323",
            ooro_fname: "North Coast Aviation",
            ooro_faddress: "",
            ooro_fcode: "N9",
            ooro_fweb: ""
        },
        {
            id: "322",
            ooro_fname: "Sterling Airlines",
            ooro_faddress: "",
            ooro_fcode: "NB",
            ooro_fweb: ""
        },
        {
            id: "321",
            ooro_fname: "National Jet Systems",
            ooro_faddress: "",
            ooro_fcode: "NC",
            ooro_fweb: ""
        },
        {
            id: "320",
            ooro_fname: "SkyEurope Airlines",
            ooro_faddress: "",
            ooro_fcode: "NE",
            ooro_fweb: ""
        },
        {
            id: "319",
            ooro_fname: "Air Vanuatu",
            ooro_faddress: "",
            ooro_fcode: "NF",
            ooro_fweb: ""
        },
        {
            id: "318",
            ooro_fname: "Lauda Air",
            ooro_faddress: "",
            ooro_fcode: "NG",
            ooro_fweb: ""
        },
        {
            id: "317",
            ooro_fname: "ANA All Nippon Airways",
            ooro_faddress: "test",
            ooro_fcode: "NH",
            ooro_fweb: "test.com"
        },
        {
            id: "316",
            ooro_fname: "Portugalia",
            ooro_faddress: "",
            ooro_fcode: "NI",
            ooro_fweb: ""
        },
        {
            id: "315",
            ooro_fname: "Spirit Airlines",
            ooro_faddress: "",
            ooro_fcode: "NK",
            ooro_fweb: ""
        },
        {
            id: "314",
            ooro_fname: "Shaheen Air",
            ooro_faddress: "",
            ooro_fcode: "NL",
            ooro_fweb: ""
        },
        {
            id: "313",
            ooro_fname: "Air Madrid",
            ooro_faddress: "",
            ooro_fcode: "NM",
            ooro_fweb: ""
        },
        {
            id: "312",
            ooro_fname: "VIM Airlines",
            ooro_faddress: "",
            ooro_fcode: "NN",
            ooro_fweb: ""
        },
        {
            id: "311",
            ooro_fname: "Aus-Air",
            ooro_faddress: "",
            ooro_fcode: "NO",
            ooro_fweb: ""
        },
        {
            id: "310",
            ooro_fname: "Air Japan",
            ooro_faddress: "",
            ooro_fcode: "NQ",
            ooro_fweb: ""
        },
        {
            id: "309",
            ooro_fname: "Pamir Air",
            ooro_faddress: "",
            ooro_fcode: "NR",
            ooro_fweb: ""
        },
        {
            id: "308",
            ooro_fname: "Binter Canarias",
            ooro_faddress: "",
            ooro_fcode: "NT",
            ooro_fweb: ""
        },
        {
            id: "307",
            ooro_fname: "Japan Transocean Air",
            ooro_faddress: "",
            ooro_fcode: "NU",
            ooro_fweb: ""
        },
        {
            id: "306",
            ooro_fname: "Northwest Airlines",
            ooro_faddress: "",
            ooro_fcode: "NW",
            ooro_fweb: ""
        },
        {
            id: "305",
            ooro_fname: "Air Macau",
            ooro_faddress: "",
            ooro_fcode: "NX",
            ooro_fweb: ""
        },
        {
            id: "304",
            ooro_fname: "Air Iceland/Flugfelag Islands",
            ooro_faddress: "",
            ooro_fcode: "NY",
            ooro_fweb: ""
        },
        {
            id: "303",
            ooro_fname: "Air New Zealand",
            ooro_faddress: "",
            ooro_fcode: "NZ",
            ooro_fweb: ""
        },
        {
            id: "302",
            ooro_fname: "Oceanic Airlines",
            ooro_faddress: "",
            ooro_fcode: "O2",
            ooro_fweb: ""
        },
        {
            id: "301",
            ooro_fname: "OceanAir",
            ooro_faddress: "",
            ooro_fcode: "O6",
            ooro_fweb: ""
        },
        {
            id: "300",
            ooro_fname: "Olympic Airlines",
            ooro_faddress: "",
            ooro_fcode: "OA",
            ooro_fweb: ""
        },
        {
            id: "299",
            ooro_fname: "Astrakhan Airlines",
            ooro_faddress: "",
            ooro_fcode: "OB",
            ooro_fweb: ""
        },
        {
            id: "298",
            ooro_fname: "Omni Aviacao e Tecnologia/PGA Express",
            ooro_faddress: "",
            ooro_fcode: "OC",
            ooro_fweb: ""
        },
        {
            id: "297",
            ooro_fname: "Asia Overnight Express Corporation",
            ooro_faddress: "",
            ooro_fcode: "OE",
            ooro_fweb: ""
        },
        {
            id: "296",
            ooro_fname: "Air Finland",
            ooro_faddress: "",
            ooro_fcode: "OF",
            ooro_fweb: ""
        },
        {
            id: "295",
            ooro_fname: "Comair/Delta Connection",
            ooro_faddress: "",
            ooro_fcode: "OH",
            ooro_fweb: ""
        },
        {
            id: "294",
            ooro_fname: "Overland Airways",
            ooro_faddress: "",
            ooro_fcode: "OJ",
            ooro_fweb: ""
        },
        {
            id: "293",
            ooro_fname: "CSA Czech Airlines",
            ooro_faddress: "",
            ooro_fcode: "OK",
            ooro_fweb: ""
        },
        {
            id: "292",
            ooro_fname: "OLT Ostfriesische Lufttransport",
            ooro_faddress: "",
            ooro_fcode: "OL",
            ooro_fweb: ""
        },
        {
            id: "291",
            ooro_fname: "MIAT Mongolian Airlines",
            ooro_faddress: "",
            ooro_fcode: "OM",
            ooro_fweb: ""
        },
        {
            id: "290",
            ooro_fname: "Air Nauru",
            ooro_faddress: "",
            ooro_fcode: "ON",
            ooro_fweb: ""
        },
        {
            id: "289",
            ooro_fname: "Skywest Airlines/Delta Connection/United Express",
            ooro_faddress: "",
            ooro_fcode: "OO",
            ooro_fweb: ""
        },
        {
            id: "288",
            ooro_fname: "Chalks International Airlines Flying Boat",
            ooro_faddress: "",
            ooro_fcode: "CHK",
            ooro_fweb: ""
        },
        {
            id: "287",
            ooro_fname: "Arkefly/TUI Airlines Nederlands",
            ooro_faddress: "",
            ooro_fcode: "OR",
            ooro_fweb: ""
        },
        {
            id: "286",
            ooro_fname: "Aeropelican Air Services",
            ooro_faddress: "",
            ooro_fcode: "OT",
            ooro_fweb: ""
        },
        {
            id: "285",
            ooro_fname: "Croatia Airlines",
            ooro_faddress: "",
            ooro_fcode: "OU",
            ooro_fweb: ""
        },
        {
            id: "284",
            ooro_fname: "Estonian Air",
            ooro_faddress: "",
            ooro_fcode: "OV",
            ooro_fweb: ""
        },
        {
            id: "283",
            ooro_fname: "Executive Airlines",
            ooro_faddress: "",
            ooro_fcode: "OW",
            ooro_fweb: ""
        },
        {
            id: "282",
            ooro_fname: "Orient Thai Airlines/One-Two-GO",
            ooro_faddress: "",
            ooro_fcode: "OX",
            ooro_fweb: ""
        },
        {
            id: "281",
            ooro_fname: "Omni Air Express/Omni Air International",
            ooro_faddress: "",
            ooro_fcode: "OY",
            ooro_fweb: ""
        },
        {
            id: "280",
            ooro_fname: "Asiana Airlines",
            ooro_faddress: "",
            ooro_fcode: "OZ",
            ooro_fweb: ""
        },
        {
            id: "279",
            ooro_fname: "Proflight Commuter Services",
            ooro_faddress: "",
            ooro_fcode: "P0",
            ooro_fweb: ""
        },
        {
            id: "278",
            ooro_fname: "Utair/Tyumenaviatrans",
            ooro_faddress: "",
            ooro_fcode: "P2",
            ooro_fweb: ""
        },
        {
            id: "277",
            ooro_fname: "Aerorepublica",
            ooro_faddress: "",
            ooro_fcode: "P5",
            ooro_fweb: ""
        },
        {
            id: "276",
            ooro_fname: "Trans Air",
            ooro_faddress: "",
            ooro_fcode: "P6",
            ooro_fweb: ""
        },
        {
            id: "275",
            ooro_fname: "Russian Sky Airlines/East Line Airlines",
            ooro_faddress: "",
            ooro_fcode: "P7",
            ooro_fweb: ""
        },
        {
            id: "274",
            ooro_fname: "Pantanal Linhas Aereas Sul-Matogrossenses",
            ooro_faddress: "",
            ooro_fcode: "P8",
            ooro_fweb: ""
        },
        {
            id: "273",
            ooro_fname: "Nas Air",
            ooro_faddress: "",
            ooro_fcode: "P9",
            ooro_fweb: ""
        },
        {
            id: "272",
            ooro_fname: "Florida Coastal Airlines",
            ooro_faddress: "",
            ooro_fcode: "PA",
            ooro_fweb: ""
        },
        {
            id: "271",
            ooro_fname: "Continental Airways",
            ooro_faddress: "abc",
            ooro_fcode: "PC",
            ooro_fweb: "www.ContinentalAirlines.com"
        },
        {
            id: "270",
            ooro_fname: "Porter Airlines",
            ooro_faddress: "",
            ooro_fcode: "PD",
            ooro_fweb: ""
        },
        {
            id: "269",
            ooro_fname: "Air Europe Italy",
            ooro_faddress: "",
            ooro_fcode: "PE",
            ooro_fweb: ""
        },
        {
            id: "268",
            ooro_fname: "Palestinian Airlines",
            ooro_faddress: "",
            ooro_fcode: "PF",
            ooro_fweb: ""
        },
        {
            id: "267",
            ooro_fname: "Bangkok Airways",
            ooro_faddress: "",
            ooro_fcode: "PG",
            ooro_fweb: ""
        },
        {
            id: "266",
            ooro_fname: "Polynesian Airline of Samoa",
            ooro_faddress: "",
            ooro_fcode: "PH",
            ooro_fweb: ""
        },
        {
            id: "265",
            ooro_fname: "Sun Air/Sunflower Airlines",
            ooro_faddress: "",
            ooro_fcode: "PI",
            ooro_fweb: ""
        },
        {
            id: "264",
            ooro_fname: "Air Saint-Pierre",
            ooro_faddress: "",
            ooro_fcode: "PJ",
            ooro_fweb: ""
        },
        {
            id: "263",
            ooro_fname: "Pakistan International Airlines",
            ooro_faddress: "",
            ooro_fcode: "PK",
            ooro_fweb: ""
        },
        {
            id: "262",
            ooro_fname: "Airstars",
            ooro_faddress: "",
            ooro_fcode: "PL",
            ooro_fweb: ""
        },
        {
            id: "261",
            ooro_fname: "Tropic Air",
            ooro_faddress: "",
            ooro_fcode: "PM",
            ooro_fweb: ""
        },
        {
            id: "260",
            ooro_fname: "Polar Air Cargo",
            ooro_faddress: "",
            ooro_fcode: "PO",
            ooro_fweb: ""
        },
        {
            id: "259",
            ooro_fname: "Jet Aviation Business Jets",
            ooro_faddress: "",
            ooro_fcode: "PP",
            ooro_fweb: ""
        },
        {
            id: "258",
            ooro_fname: "Panafrican Airways",
            ooro_faddress: "",
            ooro_fcode: "PQ",
            ooro_fweb: ""
        },
        {
            id: "257",
            ooro_fname: "Philippine Airlines",
            ooro_faddress: "",
            ooro_fcode: "PR",
            ooro_fweb: ""
        },
        {
            id: "256",
            ooro_fname: "Ukraine International Airlines",
            ooro_faddress: "",
            ooro_fcode: "PS",
            ooro_fweb: ""
        },
        {
            id: "255",
            ooro_fname: "West Air Sweden",
            ooro_faddress: "",
            ooro_fcode: "PT",
            ooro_fweb: ""
        },
        {
            id: "254",
            ooro_fname: "Pluna",
            ooro_faddress: "",
            ooro_fcode: "PU",
            ooro_fweb: ""
        },
        {
            id: "253",
            ooro_fname: "Pan Air Lineas Aereas",
            ooro_faddress: "",
            ooro_fcode: "PV",
            ooro_fweb: ""
        },
        {
            id: "252",
            ooro_fname: "Precision Air Services",
            ooro_faddress: "",
            ooro_fcode: "PW",
            ooro_fweb: ""
        },
        {
            id: "251",
            ooro_fname: "Air Niugini",
            ooro_faddress: "",
            ooro_fcode: "PX",
            ooro_fweb: ""
        },
        {
            id: "250",
            ooro_fname: "Surinam Airways",
            ooro_faddress: "",
            ooro_fcode: "PY",
            ooro_fweb: ""
        },
        {
            id: "249",
            ooro_fname: "TAM Mercosur/Transportes Aereos del Mercosur",
            ooro_faddress: "",
            ooro_fcode: "PZ",
            ooro_fweb: ""
        },
        {
            id: "248",
            ooro_fname: "Zambian Airways",
            ooro_faddress: "",
            ooro_fcode: "Q3",
            ooro_fweb: ""
        },
        {
            id: "247",
            ooro_fname: "Swazi Express Airways",
            ooro_faddress: "",
            ooro_fcode: "Q4",
            ooro_fweb: ""
        },
        {
            id: "246",
            ooro_fname: "40 Mile Air",
            ooro_faddress: "",
            ooro_fcode: "Q5",
            ooro_fweb: ""
        },
        {
            id: "245",
            ooro_fname: "Aero Condor",
            ooro_faddress: "",
            ooro_fcode: "Q6",
            ooro_fweb: ""
        },
        {
            id: "244",
            ooro_fname: "Trans Air Congo",
            ooro_faddress: "",
            ooro_fcode: "Q8",
            ooro_fweb: ""
        },
        {
            id: "243",
            ooro_fname: "Afrinat International Airlines",
            ooro_faddress: "",
            ooro_fcode: "Q9",
            ooro_fweb: ""
        },
        {
            id: "242",
            ooro_fname: "Click Mexicana",
            ooro_faddress: "",
            ooro_fcode: "QA",
            ooro_fweb: ""
        },
        {
            id: "241",
            ooro_fname: "Air Corridor",
            ooro_faddress: "",
            ooro_fcode: "QC",
            ooro_fweb: ""
        },
        {
            id: "240",
            ooro_fname: "Air Class Lineas Aereas",
            ooro_faddress: "",
            ooro_fcode: "QD",
            ooro_fweb: ""
        },
        {
            id: "239",
            ooro_fname: "Crossair Europe/ECA Europe Continental Airways",
            ooro_faddress: "",
            ooro_fcode: "QE",
            ooro_fweb: ""
        },
        {
            id: "238",
            ooro_fname: "Qantas Airways",
            ooro_faddress: "",
            ooro_fcode: "QF",
            ooro_fweb: ""
        },
        {
            id: "237",
            ooro_fname: "Altyn Air Airlines",
            ooro_faddress: "",
            ooro_fcode: "QH",
            ooro_fweb: ""
        },
        {
            id: "236",
            ooro_fname: "Cimber Air/Team Lufthansa",
            ooro_faddress: "",
            ooro_fcode: "QI",
            ooro_fweb: ""
        },
        {
            id: "235",
            ooro_fname: "Air Canada Jazz",
            ooro_faddress: "",
            ooro_fcode: "QK",
            ooro_fweb: ""
        },
        {
            id: "234",
            ooro_fname: "Aero Lanka/Serendib Express",
            ooro_faddress: "",
            ooro_fcode: "QL",
            ooro_fweb: ""
        },
        {
            id: "233",
            ooro_fname: "Air Malawi",
            ooro_faddress: "",
            ooro_fcode: "QM",
            ooro_fweb: ""
        },
        {
            id: "232",
            ooro_fname: "Origin Pacific Airways",
            ooro_faddress: "",
            ooro_fcode: "QO",
            ooro_fweb: ""
        },
        {
            id: "231",
            ooro_fname: "Alliance Airlines",
            ooro_faddress: "",
            ooro_fcode: "QQ",
            ooro_fweb: ""
        },
        {
            id: "230",
            ooro_fname: "Qatar Airways",
            ooro_faddress: "",
            ooro_fcode: "QR",
            ooro_fweb: ""
        },
        {
            id: "229",
            ooro_fname: "African Safari Airways",
            ooro_faddress: "",
            ooro_fcode: "QS",
            ooro_fweb: ""
        },
        {
            id: "228",
            ooro_fname: "TAMPA Transportes Aereos Mercantiles Panamericanos",
            ooro_faddress: "",
            ooro_fcode: "QT",
            ooro_fweb: ""
        },
        {
            id: "227",
            ooro_fname: "East African Airlines",
            ooro_faddress: "",
            ooro_fcode: "QU",
            ooro_fweb: ""
        },
        {
            id: "226",
            ooro_fname: "Lao Airlines",
            ooro_faddress: "",
            ooro_fcode: "QV",
            ooro_fweb: ""
        },
        {
            id: "225",
            ooro_fname: "Blue Wings",
            ooro_faddress: "",
            ooro_fcode: "QW",
            ooro_fweb: ""
        },
        {
            id: "224",
            ooro_fname: "Horizon Air",
            ooro_faddress: "",
            ooro_fcode: "QX",
            ooro_fweb: ""
        },
        {
            id: "223",
            ooro_fname: "European Air Transport",
            ooro_faddress: "",
            ooro_fcode: "QY",
            ooro_fweb: ""
        },
        {
            id: "222",
            ooro_fname: "Indonesia AirAsia/Awair",
            ooro_faddress: "",
            ooro_fcode: "QZ",
            ooro_fweb: ""
        },
        {
            id: "221",
            ooro_fname: "Royal Airlines",
            ooro_faddress: "",
            ooro_fcode: "R0",
            ooro_fweb: ""
        },
        {
            id: "220",
            ooro_fname: "Orenburg Airlines",
            ooro_faddress: "",
            ooro_fcode: "R2",
            ooro_fweb: ""
        },
        {
            id: "219",
            ooro_fname: "Yakutia",
            ooro_faddress: "",
            ooro_fcode: "R3",
            ooro_fweb: ""
        },
        {
            id: "218",
            ooro_fname: "Russia State Transport Company",
            ooro_faddress: "",
            ooro_fcode: "R4",
            ooro_fweb: ""
        },
        {
            id: "217",
            ooro_fname: "Malta Air Charter",
            ooro_faddress: "",
            ooro_fcode: "R5",
            ooro_fweb: ""
        },
        {
            id: "216",
            ooro_fname: "Air Sprska",
            ooro_faddress: "",
            ooro_fcode: "R6",
            ooro_fweb: ""
        },
        {
            id: "215",
            ooro_fname: "Aserca Airlines",
            ooro_faddress: "",
            ooro_fcode: "R7",
            ooro_fweb: ""
        },
        {
            id: "214",
            ooro_fname: "Kyrgyzstan Airlines",
            ooro_faddress: "",
            ooro_fcode: "R8",
            ooro_fweb: ""
        },
        {
            id: "213",
            ooro_fname: "Camai Air/Village Aviation",
            ooro_faddress: "",
            ooro_fcode: "R9",
            ooro_fweb: ""
        },
        {
            id: "212",
            ooro_fname: "Royal Nepal Airlines",
            ooro_faddress: "",
            ooro_fcode: "RA",
            ooro_fweb: ""
        },
        {
            id: "211",
            ooro_fname: "Syrian Arab Airlines",
            ooro_faddress: "",
            ooro_fcode: "RB",
            ooro_fweb: ""
        },
        {
            id: "210",
            ooro_fname: "Atlantic Airways",
            ooro_faddress: "",
            ooro_fcode: "RC",
            ooro_fweb: ""
        },
        {
            id: "209",
            ooro_fname: "Ryan International Airlines",
            ooro_faddress: "",
            ooro_fcode: "RD",
            ooro_fweb: ""
        },
        {
            id: "208",
            ooro_fname: "Aer Arann Express",
            ooro_faddress: "",
            ooro_fcode: "RE",
            ooro_fweb: ""
        },
        {
            id: "207",
            ooro_fname: "Florida West International Airways",
            ooro_faddress: "",
            ooro_fcode: "RF",
            ooro_fweb: ""
        },
        {
            id: "206",
            ooro_fname: "Varig",
            ooro_faddress: "",
            ooro_fcode: "RG",
            ooro_fweb: ""
        },
        {
            id: "205",
            ooro_fname: "Republic Express Airlines",
            ooro_faddress: "",
            ooro_fcode: "RH",
            ooro_fweb: ""
        },
        {
            id: "204",
            ooro_fname: "Mandala Airlines",
            ooro_faddress: "",
            ooro_fcode: "RI",
            ooro_fweb: ""
        },
        {
            id: "203",
            ooro_fname: "Royal Jordanian",
            ooro_faddress: "",
            ooro_fcode: "RJ",
            ooro_fweb: ""
        },
        {
            id: "202",
            ooro_fname: "Royal Khmer Airlines",
            ooro_faddress: "",
            ooro_fcode: "RK",
            ooro_fweb: ""
        },
        {
            id: "201",
            ooro_fname: "Royal Phnom Penh Airways",
            ooro_faddress: "",
            ooro_fcode: "RL",
            ooro_fweb: ""
        },
        {
            id: "200",
            ooro_fname: "Tarom",
            ooro_faddress: "",
            ooro_fcode: "RO",
            ooro_fweb: ""
        },
        {
            id: "199",
            ooro_fname: "Chautauqua Airlines/US Airways Express",
            ooro_faddress: "",
            ooro_fcode: "RP",
            ooro_fweb: ""
        },
        {
            id: "198",
            ooro_fname: "Kam Air",
            ooro_faddress: "",
            ooro_fcode: "RQ",
            ooro_fweb: ""
        },
        {
            id: "197",
            ooro_fname: "Royal Air Force",
            ooro_faddress: "",
            ooro_fcode: "RR",
            ooro_fweb: ""
        },
        {
            id: "196",
            ooro_fname: "Oman Royal Flight",
            ooro_faddress: "",
            ooro_fcode: "RS",
            ooro_fweb: ""
        },
        {
            id: "195",
            ooro_fname: "Redair",
            ooro_faddress: "",
            ooro_fcode: "RV",
            ooro_fweb: ""
        },
        {
            id: "194",
            ooro_fname: "Republic Airlines",
            ooro_faddress: "",
            ooro_fcode: "RW",
            ooro_fweb: ""
        },
        {
            id: "193",
            ooro_fname: "Aviaexpress",
            ooro_faddress: "",
            ooro_fcode: "RX",
            ooro_fweb: ""
        },
        {
            id: "192",
            ooro_fname: "Euro Exec Express",
            ooro_faddress: "",
            ooro_fcode: "RY",
            ooro_fweb: ""
        },
        {
            id: "191",
            ooro_fname: "SANSA Servicios Aereos Nacionales SA",
            ooro_faddress: "",
            ooro_fcode: "RZ",
            ooro_fweb: ""
        },
        {
            id: "190",
            ooro_fname: "Slok Air Gambia",
            ooro_faddress: "",
            ooro_fcode: "S0",
            ooro_fweb: ""
        },
        {
            id: "189",
            ooro_fname: "Jet Lite",
            ooro_faddress: "",
            ooro_fcode: "S2",
            ooro_fweb: ""
        },
        {
            id: "188",
            ooro_fname: "Santa Barbara Airlines",
            ooro_faddress: "",
            ooro_fcode: "S3",
            ooro_fweb: ""
        },
        {
            id: "187",
            ooro_fname: "Sata International",
            ooro_faddress: "",
            ooro_fcode: "S4",
            ooro_fweb: ""
        },
        {
            id: "186",
            ooro_fname: "Trast Aero",
            ooro_faddress: "",
            ooro_fcode: "S5",
            ooro_fweb: ""
        },
        {
            id: "185",
            ooro_fname: "Salmon Air",
            ooro_faddress: "",
            ooro_fcode: "S6",
            ooro_fweb: ""
        },
        {
            id: "184",
            ooro_fname: "S7 Airlines/Siberia Airlines",
            ooro_faddress: "",
            ooro_fcode: "S7",
            ooro_fweb: ""
        },
        {
            id: "183",
            ooro_fname: "Shovkoviy Shlyah Airlines",
            ooro_faddress: "",
            ooro_fcode: "S8",
            ooro_fweb: ""
        },
        {
            id: "182",
            ooro_fname: "South African Airways",
            ooro_faddress: "",
            ooro_fcode: "SA",
            ooro_fweb: ""
        },
        {
            id: "181",
            ooro_fname: "Aircalin/Air Caledonie International",
            ooro_faddress: "",
            ooro_fcode: "SB",
            ooro_fweb: ""
        },
        {
            id: "180",
            ooro_fname: "Shandong Airlines",
            ooro_faddress: "",
            ooro_fcode: "SC",
            ooro_fweb: ""
        },
        {
            id: "179",
            ooro_fname: "Sudan Airways",
            ooro_faddress: "",
            ooro_fcode: "SD",
            ooro_fweb: ""
        },
        {
            id: "178",
            ooro_fname: "Star Airlines",
            ooro_faddress: "",
            ooro_fcode: "SE",
            ooro_fweb: ""
        },
        {
            id: "177",
            ooro_fname: "Tassili Airlines",
            ooro_faddress: "",
            ooro_fcode: "SF",
            ooro_fweb: ""
        },
        {
            id: "176",
            ooro_fname: "Spice Jet",
            ooro_faddress: "asdfa",
            ooro_fcode: "SG",
            ooro_fweb: "safdda"
        },
        {
            id: "175",
            ooro_fname: "FlyMe Sweden",
            ooro_faddress: "",
            ooro_fcode: "SH",
            ooro_fweb: ""
        },
        {
            id: "174",
            ooro_fname: "Skynet Airlines",
            ooro_faddress: "",
            ooro_fcode: "SI",
            ooro_fweb: ""
        },
        {
            id: "173",
            ooro_fname: "Freedom Air International",
            ooro_faddress: "",
            ooro_fcode: "SJ",
            ooro_fweb: ""
        },
        {
            id: "172",
            ooro_fname: "Sas - Scandinavian Airlines",
            ooro_faddress: "",
            ooro_fcode: "SK",
            ooro_fweb: ""
        },
        {
            id: "171",
            ooro_fname: "Thai Lion Mentari",
            ooro_faddress: "",
            ooro_fcode: "SL",
            ooro_fweb: "test.com"
        },
        {
            id: "170",
            ooro_fname: "Swedline Express",
            ooro_faddress: "",
            ooro_fcode: "SM",
            ooro_fweb: ""
        },
        {
            id: "169",
            ooro_fname: "Brussels Airlines",
            ooro_faddress: "",
            ooro_fcode: "SN",
            ooro_fweb: ""
        },
        {
            id: "168",
            ooro_fname: "Sosoliso Airlines",
            ooro_faddress: "",
            ooro_fcode: "SO",
            ooro_fweb: ""
        },
        {
            id: "167",
            ooro_fname: "SATA Air Acores",
            ooro_faddress: "",
            ooro_fcode: "SP",
            ooro_fweb: ""
        },
        {
            id: "166",
            ooro_fname: "Singapore Airlines",
            ooro_faddress: "",
            ooro_fcode: "SQ",
            ooro_fweb: ""
        },
        {
            id: "165",
            ooro_fname: "Swiss Air",
            ooro_faddress: "",
            ooro_fcode: "SR",
            ooro_fweb: ""
        },
        {
            id: "164",
            ooro_fname: "Corsairfly",
            ooro_faddress: "",
            ooro_fcode: "SS",
            ooro_fweb: ""
        },
        {
            id: "163",
            ooro_fname: "Germania",
            ooro_faddress: "",
            ooro_fcode: "ST",
            ooro_fweb: ""
        },
        {
            id: "162",
            ooro_fname: "Aeroflot-Soviet Airlines",
            ooro_faddress: "",
            ooro_fcode: "SU",
            ooro_fweb: ""
        },
        {
            id: "161",
            ooro_fname: "Saudi Arabian Airlines",
            ooro_faddress: "",
            ooro_fcode: "SV",
            ooro_fweb: ""
        },
        {
            id: "160",
            ooro_fname: "Air Namibia",
            ooro_faddress: "",
            ooro_fcode: "SW",
            ooro_fweb: ""
        },
        {
            id: "159",
            ooro_fname: "Aeroejecutivo",
            ooro_faddress: "",
            ooro_fcode: "SX",
            ooro_fweb: ""
        },
        {
            id: "158",
            ooro_fname: "Sun Country Airlines",
            ooro_faddress: "",
            ooro_fcode: "SY",
            ooro_fweb: ""
        },
        {
            id: "157",
            ooro_fname: "Thai Air Cargo",
            ooro_faddress: "",
            ooro_fcode: "T2",
            ooro_fweb: ""
        },
        {
            id: "156",
            ooro_fname: "Eastern Airways",
            ooro_faddress: "",
            ooro_fcode: "T3",
            ooro_fweb: ""
        },
        {
            id: "155",
            ooro_fname: "Turkmenistan Airlines",
            ooro_faddress: "",
            ooro_fcode: "T5",
            ooro_fweb: ""
        },
        {
            id: "154",
            ooro_fname: "Tavrey/Tavria Aviakompania",
            ooro_faddress: "",
            ooro_fcode: "T6",
            ooro_fweb: ""
        },
        {
            id: "153",
            ooro_fname: "Twin Jet",
            ooro_faddress: "",
            ooro_fcode: "T7",
            ooro_fweb: ""
        },
        {
            id: "152",
            ooro_fname: "Transportes Aereos Neuquen",
            ooro_faddress: "",
            ooro_fcode: "T8",
            ooro_fweb: ""
        },
        {
            id: "151",
            ooro_fname: "Taca International Airlines",
            ooro_faddress: "",
            ooro_fcode: "TA",
            ooro_fweb: ""
        },
        {
            id: "150",
            ooro_fname: "Air Tanzania",
            ooro_faddress: "",
            ooro_fcode: "TC",
            ooro_fweb: ""
        },
        {
            id: "149",
            ooro_fname: "Tulip Air",
            ooro_faddress: "",
            ooro_fcode: "TD",
            ooro_fweb: ""
        },
        {
            id: "148",
            ooro_fname: "flyLAL/Lithuanian Airlines",
            ooro_faddress: "",
            ooro_fcode: "TE",
            ooro_fweb: ""
        },
        {
            id: "147",
            ooro_fname: "Malmo Aviation",
            ooro_faddress: "",
            ooro_fcode: "TF",
            ooro_fweb: ""
        },
        {
            id: "146",
            ooro_fname: "Thai Airways International",
            ooro_faddress: "",
            ooro_fcode: "TG",
            ooro_fweb: ""
        },
        {
            id: "145",
            ooro_fname: "Transmile Air Services",
            ooro_faddress: "",
            ooro_fcode: "TH",
            ooro_fweb: ""
        },
        {
            id: "144",
            ooro_fname: "TolAir Services",
            ooro_faddress: "",
            ooro_fcode: "TI",
            ooro_fweb: ""
        },
        {
            id: "143",
            ooro_fname: "Turkish Airlines",
            ooro_faddress: "",
            ooro_fcode: "TK",
            ooro_fweb: ""
        },
        {
            id: "142",
            ooro_fname: "Trans Mediterranean Airlines",
            ooro_faddress: "",
            ooro_fcode: "TL",
            ooro_fweb: ""
        },
        {
            id: "141",
            ooro_fname: "LAM",
            ooro_faddress: "",
            ooro_fcode: "TM",
            ooro_fweb: ""
        },
        {
            id: "140",
            ooro_fname: "Air Tahiti Nui",
            ooro_faddress: "",
            ooro_fcode: "TN",
            ooro_fweb: ""
        },
        {
            id: "139",
            ooro_fname: "President Airlines",
            ooro_faddress: "",
            ooro_fcode: "TO",
            ooro_fweb: ""
        },
        {
            id: "138",
            ooro_fname: "Tap Air Portugal",
            ooro_faddress: "",
            ooro_fcode: "TP",
            ooro_fweb: ""
        },
        {
            id: "137",
            ooro_fname: "Tandem Aereo",
            ooro_faddress: "",
            ooro_fcode: "TQ",
            ooro_fweb: ""
        },
        {
            id: "136",
            ooro_fname: "Scoot Airways",
            ooro_faddress: "malad",
            ooro_fcode: "TR",
            ooro_fweb: "www.sccot.com"
        },
        {
            id: "135",
            ooro_fname: "Air Transat",
            ooro_faddress: "",
            ooro_fcode: "TS",
            ooro_fweb: ""
        },
        {
            id: "134",
            ooro_fname: "Tunisair",
            ooro_faddress: "",
            ooro_fcode: "TU",
            ooro_fweb: ""
        },
        {
            id: "133",
            ooro_fname: "Air Caraibes",
            ooro_faddress: "",
            ooro_fcode: "TX",
            ooro_fweb: ""
        },
        {
            id: "132",
            ooro_fname: "Air Caledonie",
            ooro_faddress: "",
            ooro_fcode: "TY",
            ooro_fweb: ""
        },
        {
            id: "131",
            ooro_fname: "ATA Airlines/American Trans Air",
            ooro_faddress: "",
            ooro_fcode: "T1Z",
            ooro_fweb: ""
        },
        {
            id: "130",
            ooro_fname: "easyJet",
            ooro_faddress: "",
            ooro_fcode: "U2",
            ooro_fweb: ""
        },
        {
            id: "129",
            ooro_fname: "Avies",
            ooro_faddress: "",
            ooro_fcode: "U3",
            ooro_fweb: ""
        },
        {
            id: "128",
            ooro_fname: "Progress Multitrade",
            ooro_faddress: "",
            ooro_fcode: "U4",
            ooro_fweb: ""
        },
        {
            id: "127",
            ooro_fname: "USA 3000 Airlines/Brendan Airways",
            ooro_faddress: "",
            ooro_fcode: "U5",
            ooro_fweb: ""
        },
        {
            id: "126",
            ooro_fname: "Ural Airlines",
            ooro_faddress: "",
            ooro_fcode: "U6",
            ooro_fweb: ""
        },
        {
            id: "125",
            ooro_fname: "Northern Dene Airways",
            ooro_faddress: "",
            ooro_fcode: "U7",
            ooro_fweb: ""
        },
        {
            id: "124",
            ooro_fname: "Armavia",
            ooro_faddress: "",
            ooro_fcode: "U8",
            ooro_fweb: ""
        },
        {
            id: "123",
            ooro_fname: "Tatarstan Joint Stock Aircompany",
            ooro_faddress: "",
            ooro_fcode: "U9",
            ooro_fweb: ""
        },
        {
            id: "122",
            ooro_fname: "United Airlines",
            ooro_faddress: "",
            ooro_fcode: "UA",
            ooro_fweb: ""
        },
        {
            id: "121",
            ooro_fname: "Myanmar Airlines",
            ooro_faddress: "",
            ooro_fcode: "8M",
            ooro_fweb: ""
        },
        {
            id: "120",
            ooro_fname: "Lan Express/Ladeco",
            ooro_faddress: "",
            ooro_fcode: "UC",
            ooro_fweb: ""
        },
        {
            id: "119",
            ooro_fname: "Hex'Air",
            ooro_faddress: "",
            ooro_fcode: "UD",
            ooro_fweb: ""
        },
        {
            id: "118",
            ooro_fname: "Transeuropean Airlines",
            ooro_faddress: "",
            ooro_fcode: "UE",
            ooro_fweb: ""
        },
        {
            id: "117",
            ooro_fname: "UM Air/Ukrainian Mediterranean Airlines",
            ooro_faddress: "",
            ooro_fcode: "UF",
            ooro_fweb: ""
        },
        {
            id: "116",
            ooro_fname: "Tuninter",
            ooro_faddress: "",
            ooro_fcode: "UG",
            ooro_fweb: ""
        },
        {
            id: "115",
            ooro_fname: "Eurasia Aircompany",
            ooro_faddress: "",
            ooro_fcode: "UH",
            ooro_fweb: ""
        },
        {
            id: "114",
            ooro_fname: "Eurocypria",
            ooro_faddress: "",
            ooro_fcode: "UI",
            ooro_fweb: ""
        },
        {
            id: "113",
            ooro_fname: "SriLankan Airlines",
            ooro_faddress: "",
            ooro_fcode: "UL",
            ooro_fweb: ""
        },
        {
            id: "112",
            ooro_fname: "Air Zimbabwe",
            ooro_faddress: "",
            ooro_fcode: "UM",
            ooro_fweb: ""
        },
        {
            id: "111",
            ooro_fname: "Transaero Airlines",
            ooro_faddress: "",
            ooro_fcode: "UN",
            ooro_fweb: ""
        },
        {
            id: "110",
            ooro_fname: "Helicopters Hong Kong",
            ooro_faddress: "",
            ooro_fcode: "UO",
            ooro_fweb: ""
        },
        {
            id: "109",
            ooro_fname: "Bahamasair",
            ooro_faddress: "",
            ooro_fcode: "UP",
            ooro_fweb: ""
        },
        {
            id: "108",
            ooro_fname: "O'Connor-Mt. Gambier's Airline",
            ooro_faddress: "",
            ooro_fcode: "UQ",
            ooro_fweb: ""
        },
        {
            id: "107",
            ooro_fname: "US Airways",
            ooro_faddress: "",
            ooro_fcode: "US",
            ooro_fweb: ""
        },
        {
            id: "106",
            ooro_fname: "Air Orient",
            ooro_faddress: "",
            ooro_fcode: "UT",
            ooro_fweb: ""
        },
        {
            id: "105",
            ooro_fname: "Air Austral",
            ooro_faddress: "",
            ooro_fcode: "UU",
            ooro_fweb: ""
        },
        {
            id: "104",
            ooro_fname: "Universal Airways",
            ooro_faddress: "",
            ooro_fcode: "UV",
            ooro_fweb: ""
        },
        {
            id: "103",
            ooro_fname: "Universal Airlines",
            ooro_faddress: "",
            ooro_fcode: "UW",
            ooro_fweb: ""
        },
        {
            id: "102",
            ooro_fname: "Air Europa",
            ooro_faddress: "",
            ooro_fcode: "UX",
            ooro_fweb: ""
        },
        {
            id: "101",
            ooro_fname: "Cameroon Airlines",
            ooro_faddress: "",
            ooro_fcode: "UY",
            ooro_fweb: ""
        },
        {
            id: "100",
            ooro_fname: "Buraq Air Transport",
            ooro_faddress: "",
            ooro_fcode: "UZ",
            ooro_fweb: ""
        },
        {
            id: "99",
            ooro_fname: "Conviasa",
            ooro_faddress: "",
            ooro_fcode: "V0",
            ooro_fweb: ""
        },
        {
            id: "98",
            ooro_fname: "Karat",
            ooro_faddress: "",
            ooro_fcode: "V2",
            ooro_fweb: ""
        },
        {
            id: "97",
            ooro_fname: "Carpatair",
            ooro_faddress: "",
            ooro_fcode: "V3",
            ooro_fweb: ""
        },
        {
            id: "96",
            ooro_fname: "Reem Air",
            ooro_faddress: "",
            ooro_fcode: "V4",
            ooro_fweb: ""
        },
        {
            id: "95",
            ooro_fname: "Royal Aruban Airlines",
            ooro_faddress: "",
            ooro_fcode: "V5",
            ooro_fweb: ""
        },
        {
            id: "94",
            ooro_fname: "Voyager Airline",
            ooro_faddress: "",
            ooro_fcode: "V6",
            ooro_fweb: ""
        },
        {
            id: "93",
            ooro_fname: "Air Senegal International",
            ooro_faddress: "",
            ooro_fcode: "V7",
            ooro_fweb: ""
        },
        {
            id: "92",
            ooro_fname: "Iliamna Air Taxi",
            ooro_faddress: "",
            ooro_fcode: "V8",
            ooro_fweb: ""
        },
        {
            id: "91",
            ooro_fname: "BAL Bashkirian Airlines",
            ooro_faddress: "",
            ooro_fcode: "V9",
            ooro_fweb: ""
        },
        {
            id: "90",
            ooro_fname: "Volare Airlines",
            ooro_faddress: "",
            ooro_fcode: "VA",
            ooro_fweb: ""
        },
        {
            id: "89",
            ooro_fname: "Voyageur Airways",
            ooro_faddress: "",
            ooro_fcode: "VC",
            ooro_fweb: ""
        },
        {
            id: "88",
            ooro_fname: "Swedjet Airways",
            ooro_faddress: "",
            ooro_fcode: "VD",
            ooro_fweb: ""
        },
        {
            id: "87",
            ooro_fname: "Avensa",
            ooro_faddress: "",
            ooro_fcode: "VE",
            ooro_fweb: ""
        },
        {
            id: "86",
            ooro_fname: "Valuair",
            ooro_faddress: "",
            ooro_fcode: "VF",
            ooro_fweb: ""
        },
        {
            id: "85",
            ooro_fname: "VLM",
            ooro_faddress: "",
            ooro_fcode: "VG",
            ooro_fweb: ""
        },
        {
            id: "84",
            ooro_fname: "Aeropostal",
            ooro_faddress: "",
            ooro_fcode: "VH",
            ooro_fweb: ""
        },
        {
            id: "83",
            ooro_fname: "Volga-Dnepr Airlines",
            ooro_faddress: "",
            ooro_fcode: "VI",
            ooro_fweb: ""
        },
        {
            id: "82",
            ooro_fname: "Jatayu Gelang Sejahtera",
            ooro_faddress: "",
            ooro_fcode: "VJ",
            ooro_fweb: ""
        },
        {
            id: "81",
            ooro_fname: "Virgin Nigeria Airways",
            ooro_faddress: "",
            ooro_fcode: "VK",
            ooro_fweb: ""
        },
        {
            id: "80",
            ooro_fname: "Air VIA Bulgarian Airways",
            ooro_faddress: "",
            ooro_fcode: "VL",
            ooro_fweb: ""
        },
        {
            id: "79",
            ooro_fname: "Viaggio Air",
            ooro_faddress: "",
            ooro_fcode: "VM",
            ooro_fweb: ""
        },
        {
            id: "78",
            ooro_fname: "Vietnam Airlines",
            ooro_faddress: "",
            ooro_fcode: "VN",
            ooro_fweb: ""
        },
        {
            id: "77",
            ooro_fname: "Tyrolean Airways/Austrian Arrows",
            ooro_faddress: "",
            ooro_fcode: "VO",
            ooro_fweb: ""
        },
        {
            id: "76",
            ooro_fname: "TACV Cabo Verde Airlines",
            ooro_faddress: "",
            ooro_fcode: "VR",
            ooro_fweb: ""
        },
        {
            id: "75",
            ooro_fname: "Virgin Atlantic Airways",
            ooro_faddress: "",
            ooro_fcode: "VS",
            ooro_fweb: ""
        },
        {
            id: "74",
            ooro_fname: "Air Tahiti",
            ooro_faddress: "",
            ooro_fcode: "VT",
            ooro_fweb: ""
        },
        {
            id: "73",
            ooro_fname: "Societe Nouvelle Air Ivoire",
            ooro_faddress: "",
            ooro_fcode: "VU",
            ooro_fweb: ""
        },
        {
            id: "72",
            ooro_fname: "Aerosvit/Aerosweet Airlines",
            ooro_faddress: "",
            ooro_fcode: "VV",
            ooro_fweb: ""
        },
        {
            id: "71",
            ooro_fname: "Transportes Aeromar",
            ooro_faddress: "",
            ooro_fcode: "VW",
            ooro_fweb: ""
        },
        {
            id: "70",
            ooro_fname: "Virgin America",
            ooro_faddress: "",
            ooro_fcode: "VX",
            ooro_fweb: ""
        },
        {
            id: "69",
            ooro_fname: "Kingfisher Airlines",
            ooro_faddress: "",
            ooro_fcode: "IT",
            ooro_fweb: ""
        },
        {
            id: "68",
            ooro_fname: "MDLR Airlines",
            ooro_faddress: "Delhi",
            ooro_fcode: "9H",
            ooro_fweb: "http://www.mdlrairlines.in"
        },
        {
            id: "67",
            ooro_fname: "Austrian Airlines",
            ooro_faddress: "test",
            ooro_fcode: "OS",
            ooro_fweb: "http://os/"
        },
        {
            id: "66",
            ooro_fname: "GMG Airlines",
            ooro_faddress: "test",
            ooro_fcode: "Z5",
            ooro_fweb: "www.gmg.com"
        },
        {
            id: "65",
            ooro_fname: "Safi Airways",
            ooro_faddress: "Test",
            ooro_fcode: "4Q",
            ooro_fweb: "safi.com"
        },
        {
            id: "64",
            ooro_fname: "Jet Konnect",
            ooro_faddress: "JetAirways",
            ooro_fcode: "9W",
            ooro_fweb: "www.jetairways.com"
        },
        {
            id: "63",
            ooro_fname: "Tiger Airways",
            ooro_faddress: "au",
            ooro_fcode: "TT",
            ooro_fweb: "http://booking.tigerairways.com"
        },
        {
            id: "62",
            ooro_fname: "Air North",
            ooro_faddress: "Air North",
            ooro_fcode: "TL",
            ooro_fweb: "airnorth.com"
        },
        {
            id: "61",
            ooro_fname: "Scoot",
            ooro_faddress: "Singapore",
            ooro_fcode: "TZ",
            ooro_fweb: "http://www.flyscoot.com/index.php/en/"
        },
        {
            id: "60",
            ooro_fname: "Air Mediterranee",
            ooro_faddress: "Airport of Tarbes Lourdes Pyr?n?es 65290 Jullian",
            ooro_fcode: "ML",
            ooro_fweb: "http://www.air-mediterranee.fr/404.cfm"
        },
        {
            id: "59",
            ooro_fname: "Jazeera Airways K.S.C",
            ooro_faddress: "Kuwait International Airport",
            ooro_fcode: "J9",
            ooro_fweb: "www.jazeeraairways.com"
        },
        {
            id: "58",
            ooro_fname: "Peach Aviation",
            ooro_faddress: "Kansai International Airport Izumisano",
            ooro_fcode: "MM",
            ooro_fweb: "flypeach.com"
        },
        {
            id: "57",
            ooro_fname: "Virgin Australia",
            ooro_faddress: "Australia",
            ooro_fcode: "VA",
            ooro_fweb: "virginaustralia.com"
        },
        {
            id: "56",
            ooro_fname: "Volaris",
            ooro_faddress: "Mexico City",
            ooro_fcode: "Y4",
            ooro_fweb: "Volaris.mx"
        },
        {
            id: "55",
            ooro_fname: "Volotea",
            ooro_faddress: "Barcelona",
            ooro_fcode: "V7",
            ooro_fweb: "www.Volotea.com"
        },
        {
            id: "54",
            ooro_fname: "Wizz Air Hungary Airlines Ltd",
            ooro_faddress: "Hungary",
            ooro_fcode: "W6",
            ooro_fweb: "www.wizzair.com/"
        },
        {
            id: "53",
            ooro_fname: "WOW Air",
            ooro_faddress: "Iceland",
            ooro_fcode: "X9",
            ooro_fweb: "www.wowiceland.co.uk"
        },
        {
            id: "52",
            ooro_fname: "Bahrain air",
            ooro_faddress: "Mohamed Centre Muharraq",
            ooro_fcode: "BN",
            ooro_fweb: "http://www.bahrainair.net/"
        },
        {
            id: "51",
            ooro_fname: "Azul Brazilian Airlines",
            ooro_faddress: "Brazil",
            ooro_fcode: "AD",
            ooro_fweb: "http://www.voeazul.com.br/"
        },
        {
            id: "50",
            ooro_fname: "Belle Air",
            ooro_faddress: "Tirana, Albania",
            ooro_fcode: "LZ",
            ooro_fweb: "http://www.belleair.eu/"
        },
        {
            id: "49",
            ooro_fname: "Brindabella Airlines",
            ooro_faddress: "Australia",
            ooro_fcode: "FQ",
            ooro_fweb: "Brindabella Airlines"
        },
        {
            id: "48",
            ooro_fname: "Calm Air International LP",
            ooro_faddress: "Thompson, Manitoba",
            ooro_fcode: "MO",
            ooro_fweb: "http://www.calmair.com"
        },
        {
            id: "47",
            ooro_fname: "Corendon Airlines",
            ooro_faddress: "Antalya Turkey",
            ooro_fcode: "XC",
            ooro_fweb: "www.corendon.com"
        },
        {
            id: "46",
            ooro_fname: "FireFly",
            ooro_faddress: "Sultan Abdul Aziz Shah Airport",
            ooro_fcode: "FY",
            ooro_fweb: "www.fireflyz.com.my"
        },
        {
            id: "45",
            ooro_fname: "Sama",
            ooro_faddress: "King Fahad International Airport",
            ooro_fcode: "ZS",
            ooro_fweb: "www.flysama.com"
        },
        {
            id: "44",
            ooro_fname: "Germanwings",
            ooro_faddress: "Cologne",
            ooro_fcode: "4U",
            ooro_fweb: "germanwings.com"
        },
        {
            id: "43",
            ooro_fname: "Sky Airlines",
            ooro_faddress: "Dusseldorf",
            ooro_fcode: "ZY",
            ooro_fweb: "http://www.skyairlines.net/"
        },
        {
            id: "42",
            ooro_fname: "Caribbean Airlines",
            ooro_faddress: "Customer Relations Department National",
            ooro_fcode: "BW",
            ooro_fweb: "caribbean-airlines.com/"
        },
        {
            id: "41",
            ooro_fname: "Avion Express ",
            ooro_faddress: "Iceland",
            ooro_fcode: "X9",
            ooro_fweb: "http://wowair.com/en/terms"
        },
        {
            id: "40",
            ooro_fname: "Pegasus Airlines",
            ooro_faddress: "Istanbul",
            ooro_fcode: "PC",
            ooro_fweb: "http://www.flypgs.com/en/"
        },
        {
            id: "39",
            ooro_fname: "Starlink Aviation",
            ooro_faddress: "9025 Ryan Avenue",
            ooro_fcode: "Q4",
            ooro_fweb: "http://www.starlinkaviation.com/index.html"
        },
        {
            id: "38",
            ooro_fname: "SKYTRANS REGIONAL PTY LTD.",
            ooro_faddress: "Australia",
            ooro_fcode: "Q6",
            ooro_fweb: "http://www.skytrans.com.au/"
        },
        {
            id: "37",
            ooro_fname: "Hong Kong Express Airways Limited",
            ooro_faddress: "http://www.hongkongairlines.com/en_HK/homepage",
            ooro_fcode: "UO",
            ooro_fweb: "www.hongkongexpress.com"
        },
        {
            id: "36",
            ooro_fname: "Aerosvit Airlines",
            ooro_faddress: "GPO 1631 ",
            ooro_fcode: "VV",
            ooro_fweb: "www.aerosvit.com/"
        },
        {
            id: "35",
            ooro_fname: "Blekingeflyg",
            ooro_faddress: "Ronneby",
            ooro_fcode: "DC",
            ooro_fweb: "http://www.blekingeflyg.se/mainmenu/book.aspx"
        },
        {
            id: "34",
            ooro_fname: "Blue Islands Airlines",
            ooro_faddress: "Saint Peter Port, Guernsey",
            ooro_fcode: "SI",
            ooro_fweb: "www.blueislands.com"
        },
        {
            id: "33",
            ooro_fname: "Interjet",
            ooro_faddress: "Mexico City",
            ooro_fcode: "4O",
            ooro_fweb: "www.interjet.com"
        },
        {
            id: "32",
            ooro_fname: "Jet4you",
            ooro_faddress: "Casablanca, Morocco",
            ooro_fcode: "8J",
            ooro_fweb: "www.jet4you.com"
        },
        {
            id: "31",
            ooro_fname: "Kalmarflyg",
            ooro_faddress: "Stockholm",
            ooro_fcode: "DC",
            ooro_fweb: "http://www.sverigeflyg.se/"
        },
        {
            id: "30",
            ooro_fname: "Kullaflyg",
            ooro_faddress: "Stockholm",
            ooro_fcode: "DC",
            ooro_fweb: "http://www.sverigeflyg.se/"
        },
        {
            id: "29",
            ooro_fname: "Mango Airlines",
            ooro_faddress: "OR Tambo International Airport",
            ooro_fcode: "JE",
            ooro_fweb: "flymango.com"
        },
        {
            id: "28",
            ooro_fname: "Peruvian Airlines",
            ooro_faddress: "Peru",
            ooro_fcode: "P9",
            ooro_fweb: "Peruvian.pe/en"
        },
        {
            id: "27",
            ooro_fname: "SkyWork Airlines AG",
            ooro_faddress: "Belp",
            ooro_fcode: "SX",
            ooro_fweb: "http://www.flyskywork.com/en"
        },
        {
            id: "26",
            ooro_fname: "Sundsvallsflyg",
            ooro_faddress: "Sweden",
            ooro_fcode: "DC",
            ooro_fweb: "http://www.sundsvallsflyg.se/"
        },
        {
            id: "25",
            ooro_fname: "Sverigeflyg",
            ooro_faddress: "Stockholm",
            ooro_fcode: "DC",
            ooro_fweb: "http://www.sverigeflyg.se/"
        },
        {
            id: "24",
            ooro_fname: "flynas",
            ooro_faddress: "Riyadh",
            ooro_fcode: "XY",
            ooro_fweb: "vcbvcb/bn.ghj"
        },
        {
            id: "23",
            ooro_fname: "Malindo Air",
            ooro_faddress: "",
            ooro_fcode: "OD",
            ooro_fweb: "www.mihinlanka.com"
        },
        {
            id: "22",
            ooro_fname: "Mihin Lanka",
            ooro_faddress: "",
            ooro_fcode: "MJ",
            ooro_fweb: "www.mihinlanka.com"
        },
        {
            id: "21",
            ooro_fname: "Myanmar Airways",
            ooro_faddress: "test",
            ooro_fcode: "UB",
            ooro_fweb: "http://maiair.com"
        },
        {
            id: "20",
            ooro_fname: "Fly Dubai",
            ooro_faddress: "Dubai",
            ooro_fcode: "FZ",
            ooro_fweb: "www.fludubai.com"
        },
        {
            id: "19",
            ooro_fname: "Vistara",
            ooro_faddress: "Mumbai",
            ooro_fcode: "UK",
            ooro_fweb: "https://www.airvistara.com"
        },
        {
            id: "18",
            ooro_fname: "Air Astana",
            ooro_faddress: "Kazakhstan",
            ooro_fcode: "KC",
            ooro_fweb: "http://airastana.com/kaz/en-us/"
        },
        {
            id: "17",
            ooro_fname: "Air Costa1",
            ooro_faddress: "Mumbai",
            ooro_fcode: "LB1",
            ooro_fweb: "http://www.aircosta.in/"
        },
        {
            id: "16",
            ooro_fname: "Air Pegagus",
            ooro_faddress: "Ahmedabad",
            ooro_fcode: "OP",
            ooro_fweb: "www.iata.org"
        },
        {
            id: "15",
            ooro_fname: "Air Asia",
            ooro_faddress: "asia",
            ooro_fcode: "I5",
            ooro_fweb: "http://www.airasia.com/my/en/home.page?cid=1"
        },
        {
            id: "14",
            ooro_fname: "Trujet",
            ooro_faddress: "aaa",
            ooro_fcode: "2T",
            ooro_fweb: "http://www.trujet.com/ebooking/home/"
        },
        {
            id: "13",
            ooro_fname: "Bhutan Airlines",
            ooro_faddress: "aaa",
            ooro_fcode: "B3",
            ooro_fweb: "http://www.bhutanairlines.bt/"
        },
        {
            id: "12",
            ooro_fname: "Air AsiaX",
            ooro_faddress: "Malaysia",
            ooro_fcode: "D7",
            ooro_fweb: "http://www.airasiax.com/"
        },
        {
            id: "11",
            ooro_fname: "Air Manas",
            ooro_faddress: "Delhi",
            ooro_fcode: "ZM",
            ooro_fweb: "airmanas.com"
        },
        {
            id: "10",
            ooro_fname: "shenzhen airlines",
            ooro_faddress: "delhi",
            ooro_fcode: "ZH",
            ooro_fweb: "shenzhenairlines.com"
        },
        {
            id: "9",
            ooro_fname: "Air Carnival",
            ooro_faddress: "chennai",
            ooro_fcode: "2S",
            ooro_fweb: "www.aircarnival.in"
        },
        {
            id: "8",
            ooro_fname: "Thai Smile",
            ooro_faddress: "Delhi",
            ooro_fcode: "WE",
            ooro_fweb: "thaiairways.com"
        },
        {
            id: "7",
            ooro_fname: "Indonesia AirAsia Extra",
            ooro_faddress: "Indonesia",
            ooro_fcode: "XT",
            ooro_fweb: "http://www.airasia.com"
        },
        {
            id: "6",
            ooro_fname: "Thai Lion",
            ooro_faddress: "Delhi",
            ooro_fcode: "SL1",
            ooro_fweb: "Thailion.com"
        },
        {
            id: "5",
            ooro_fname: "Philipines Air Asia",
            ooro_faddress: "delhi",
            ooro_fcode: "Z2",
            ooro_fweb: "airasia.com"
        },
        {
            id: "4",
            ooro_fname: "Zoom Air",
            ooro_faddress: "Delhi",
            ooro_fcode: "ZO",
            ooro_fweb: "http://www.zoomair.co.in"
        },
        {
            id: "3",
            ooro_fname: "ASKY AIRLINE",
            ooro_faddress: "test",
            ooro_fcode: "KP",
            ooro_fweb: "www.flyasky.com"
        },
        {
            id: "2",
            ooro_fname: "NokScoot",
            ooro_faddress: "Thailand",
            ooro_fcode: "XW",
            ooro_fweb: "nokscoot"
        }
    ]