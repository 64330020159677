// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import logo from '../../assets/Icons/logo.png';
import blankLogo from '../../assets/Icons/012218f4d43ade31f4e6146e2178f4be.jpg';

// import library
import React, {Component} from "react";
import { Input } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import CustomNavLink from "./CustonNavLink";

class LiBlock extends Component {
    

  componentDidMount(){
      
  };

  render(){
    try{
      let li = [];

      this.props.data.map((e, i) =>{
        li.push(<li><CustomNavLink url={e.url} target={e.target} name={e.name} style={{color: '#f8f9fa', textDecoration: 'none'}}></CustomNavLink></li>);
      });
      return(
        <div className="LiBlock" style={{color:'white'}}>
          <h3>{this.props.heading}</h3>
          <ul className="ul">
              {li}
          </ul>
        </div>
      );
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default LiBlock;