import React, { useState } from 'react';
import moment from "moment";
import { ArrowBackIos, ArrowForwardIos, Today } from '@material-ui/icons';


const dayName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const dayNameShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function MonthsView(prop) {
  try{
    // const [startDate, setStartDate] = useState()
    // const [endDate, setEndDate] = useState()
    // const [focus, setFocus] = useState(START_DATE)
    // const handleFocusChange = newFocus => {
    //   setFocus(newFocus || START_DATE)
    // }

    const Price = (prop) => {
      let day = new Date(prop.day);
      let yy = moment(day).format('YYYY');
      let mm = moment(day).format('MM');
      let dd = moment(day).format('DD');
        // console.log(dd);
        // console.log(mm);
        // console.log(yy);
      let price = prop.price;
      //console.log(this.state.price);
      let yData = [];
      let mData = [];
      let dData = [];
  
      yData = price.filter(f => f.year == yy);
      if(yData.length > 0){
        mData = yData[0]?.data.filter(f => f.month == mm);
      }
      if(mData.length > 0){
        dData = mData[0]?.data.filter(f => f.day == dd);
      }
      
      if(dData.length > 0){
        return  <span>₹ {dData[0].price}</span>;
      }else{
        return <></>;
      }
    };

    let cDate = new Date();

    let cDateOnly = new Date(moment(cDate).format("YYYY-MM-DD"));
    
    
    let date = new Date(moment(prop.date).format("YYYY-MM-") + "01");
    console.log(date);

    var dt = new Date(prop.date);
    var month = dt.getMonth();
    var year = dt.getFullYear();
    let daysInMonth = new Date(year, month, 0).getDate();
    console.log(daysInMonth);
    let currentDayNameIndex;
    if(date.getDay() !== 0){
      currentDayNameIndex = date.getDay() -1;
    }else{
      currentDayNameIndex = 6;
    }

    //console.log("=== currentDayNameIndex ===", currentDayNameIndex);
    let currentDayStatus = false;

    let currentMonth = date.getMonth();
    console.log("prop.selectedDate", prop.selectedDate);
    let selectedDate = prop.selectedDate;
    let startDate = '';
    let endDate = null;
    if(selectedDate){
      if(typeof(selectedDate) === 'object'){
        startDate = selectedDate?.from;
        endDate = selectedDate?.to;
      }
      else if(typeof(selectedDate) == 'string'){
        startDate = selectedDate;
      }
      else{
        startDate = moment(cDate).format("YYYY-MM-DD");
      }
    }
    else{
      startDate = moment(cDate).format("YYYY-MM-DD");
    }
    

    let th = [];
    // let td = [];
    let tr = [];


    for (let index = 0; index < dayNameShort.length; index++) {

      if(index == (dayNameShort.length - 1)){
        th.push(<th className="text-center" style={{color:'red'}}>{dayNameShort[index]}</th>);
      }
      else{
        th.push(<th className="text-center" >{dayNameShort[index]}</th>);
      }
      
    }

    let noOfDays = 31;
    let cD = 1;
    let endNoOfDays = (noOfDays / 7);
    let betweenSelectedStatus = false;
    let sDates = new Date(startDate);
    let eDates = new Date(endDate);
    //console.log(endNoOfDays);
    for (let index = 0; index < 6; index++) {
      let td = [];
      for (let index = 0; index < 7; index++) {

        if(currentDayStatus){
          if(cD <= noOfDays){
            if(currentMonth == date.getMonth()){
              let betweenSelected = '';
              let selected = '';
              let disabled = false;
              if(betweenSelectedStatus){
                betweenSelected = 'between-selected';
                if(startDate == moment(date).format("YYYY-MM-DD")){
                  selected = "selected";
                  betweenSelected = '';
                  betweenSelectedStatus = true;
                }
                else if(endDate && endDate == moment(date).format("YYYY-MM-DD")){
                  selected = "selected";
                  betweenSelected = '';
                  betweenSelectedStatus = false;
                }
                // else if(sDates < date){
                //   betweenSelected = 'between-selected';
                //   betweenSelectedStatus = true;
                // }
                else if(sDates > date){
                  betweenSelected = '';
                  betweenSelectedStatus = false;
                }
              }
              else{
                betweenSelected = '';
                if(startDate == moment(date).format("YYYY-MM-DD")){
                  selected = "selected";
                  betweenSelected = '';
                  betweenSelectedStatus = true;
                }
                else if(endDate && endDate == moment(date).format("YYYY-MM-DD")){
                  selected = "selected";
                  betweenSelected = '';
                  betweenSelectedStatus = false;
                }
                else if(sDates <= date && eDates >= date){
                  betweenSelected = 'between-selected';
                  betweenSelectedStatus = true;
                }
                
                // else if(sDates > date){
                //   betweenSelected = '';
                //   betweenSelectedStatus = false;
                // }
              }

              if(sDates > date){
                if(prop?.trip === 1){
                  if(prop?.selectType){
                    disabled = true;
                  }
                }
                if(prop?.type){
                  disabled = true;
                }
              }

              if(cDateOnly > date){
                //console.log("ahgshs",cDate, date);
                disabled = true;
              }

              if(prop?.trip === 0){
                betweenSelected = '';
              }

              if(!endDate){
                betweenSelected = '';
              }

              if(endDate == startDate){
                betweenSelected = '';
              }
              
              let cdd = moment(date).format("YYYY-MM-DD");
              
              if(disabled){
                td.push(<td className={`aa text-center td-h disabled ${selected} ${betweenSelected}`}>{date.getDate()}<br /> <Price price={prop.price} day={cdd} /></td>);
              }
              else{
                td.push(<td className={`aa text-center td-h calender-td ${selected} ${betweenSelected}`} onClick={() =>{prop.clicked(cdd)}}>{date.getDate()}<br /><Price price={prop.price} day={cdd} /></td>);
              }
              
              date.setDate(date.getDate() + 1);
            }
            else{
              td.push(<td className="td-h"></td>);
            }
          }
          else{
            td.push(<td className="td-h"></td>);
          }
        }
        else{
          if(currentDayNameIndex == index){
            currentDayStatus = true;
            if(cD <= noOfDays){
              if(currentMonth == date.getMonth()){
                let betweenSelected = '';
                let selected = '';
                let disabled = false;
                if(betweenSelectedStatus){
                  betweenSelected = 'between-selected';
                  if(startDate == moment(date).format("YYYY-MM-DD")){
                    selected = "selected";
                    betweenSelected = '';
                    betweenSelectedStatus = true;
                  }
                  else if(endDate && endDate == moment(date).format("YYYY-MM-DD")){
                    selected = "selected";
                    betweenSelected = '';
                    betweenSelectedStatus = false;
                  }
                  // else if(sDates < date){
                  //   betweenSelected = 'between-selected';
                  //   betweenSelectedStatus = true;
                  // }
                  else if(sDates > date){
                    betweenSelected = '';
                    betweenSelectedStatus = false;
                  }
                }
                else{
                  betweenSelected = '';
                  if(startDate == moment(date).format("YYYY-MM-DD")){
                    selected = "selected";
                    betweenSelected = '';
                    betweenSelectedStatus = true;
                  }
                  else if(endDate && endDate == moment(date).format("YYYY-MM-DD")){
                    selected = "selected";
                    betweenSelected = '';
                    betweenSelectedStatus = false;
                  }
                  else if(sDates <= date && eDates >= date){
                    betweenSelected = 'between-selected';
                    betweenSelectedStatus = true;
                  }
                  // else if(sDates > date){
                  //   betweenSelected = '';
                  //   betweenSelectedStatus = false;
                  // }
                }

                if(sDates > date){
                  if(prop?.trip === 1){
                    if(prop?.selectType){
                      console.log("disable 1 ===");
                      disabled = true;
                    }
                  }
                  if(prop?.type){
                    console.log("disable 2 ===");
                    disabled = true;
                  }
                }

                if(cDateOnly > date){
                  // console.log("ahgshs",cDate, date);
                  console.log("disable 3 ===");
                  disabled = true;
                }

                if(prop?.trip === 0){
                  betweenSelected = '';
                }

                if(!endDate){
                  betweenSelected = '';
                }
                console.log("endDate", endDate);
                let cdd = moment(date).format("YYYY-MM-DD");

                if(disabled){
                  td.push(<td className={`aa text-center td-h disabled ${selected} ${betweenSelected}`}>{date.getDate()}<br /><Price price={prop.price} day={cdd} /></td>);
                }
                else{
                  td.push(<td className={`aa text-center td-h calender-td ${selected} ${betweenSelected}`}  onClick={(d) =>{prop.clicked(cdd)}}>{date.getDate()}<br /><Price price={prop.price} day={cdd} /></td>);
                }
                date.setDate(date.getDate() + 1);
              }
              else{
                td.push(<td className="td-h"></td>);
              }
            }
            else{
              td.push(<td className="td-h"></td>);
            }
          }
          else{
            td.push(<td></td>);
          }
          
        }
        
      }
      tr.push(<tr style={{borderBottom: '0px solid white'}}>{td}</tr>);

      
    }

    const Header = (prop) =>{
      let preview = [];

      if(prop.prevStatus){
        preview.push(<span className="float-start ms-3 a"><ArrowBackIos onClick={() =>{prop.onPreview(true)}} /></span>);
      }

      preview.push(<span>{prop.monthName}</span>);
      
      if(prop.status){
        preview.push(<span className="float-end me-3 a"><ArrowForwardIos onClick={() =>{prop.onNext(true)}} /></span>);
      }
      // else{
      //   return(<>
      //     {preview} {prop.monthName}
      //   </>)
      // }

      return(<>{preview}</>);
      
    }


    return (
      <table className="table my-calender">
        <thead>
          <tr>
            <th colSpan="7" className="text-center"> <Header status={prop.status} prevStatus={prop.prevStatus} monthName={prop.monthName} onPreview={(date) => prop.onPreview(date)} onNext={(date) => prop.onNext(date)} /></th>
          </tr>
          <tr>
            {th}
          </tr>
        </thead>
        <tbody>
          {tr}
        </tbody>
      </table>
    );
  } catch (error) {
		throw new Error(error);
	}
}

const DisplayDate = (prop) =>{
  console.log("*** DisplayDate prop ***",prop);
  try{
    if(prop.selectType){
      return <span class="me-2" style={{borderBottom: '2px solid #9391F7'}}> <Today /> Select Return Date</span>
    }
    else if(prop.date){
      return <span class="me-2"> <Today /> {prop.date}</span>
    }
    else{
      return <span class="me-2"> <Today /> Select Return Date</span>
    }
  } catch (error) {
    throw new Error(error);
  }
}

class CustomCalender extends React.Component {
  

  cDate = new Date();
  selectedDate = this.props.selectedDate;
  //selectedDate = {from:'2021-05-20',to: '2021-05-25'};
  //selectedDate = '2021-05-20';
  
  trip = this.props.trip;
  type = this.props.type;
  //trip = 1;
  constructor(props){
    super(props)
    console.log(typeof(this.selectedDate));
    if(this.selectedDate){
      if(typeof(this.selectedDate) === 'object'){
        this.state = {
          startDate: this.selectedDate.from
        };
      }
      else if(typeof(this.selectedDate) == 'string'){
        this.state = {
          startDate: this.selectedDate
        };
      }
      else{
        this.state = {
          startDate: moment(this.cDate).format("YYYY-MM-DD")
        };
      }
    }
    else{
      this.state = {
        startDate: moment(this.cDate).format("YYYY-MM-DD")
      };
    }
    
  }


  componentDidMount(){
    console.log(this.props.price);
    this.setState({
      selectedDate: this.selectedDate,
      selectType:false
    });
  }
  
  prev = (data) =>{
    let startDate = this.state.startDate;
    let date = new Date(moment(startDate).format('YYYY-MM-01'));
    date.setMonth(date.getMonth()- 1);
    this.setState({startDate:moment(date).format("YYYY-MM-DD")});
  }

  next = (data) =>{
    let startDate = this.state.startDate;
    let date = new Date(moment(startDate).format('YYYY-MM-01'));
    date.setMonth(date.getMonth()+ 1);
    this.setState({startDate:moment(date).format("YYYY-MM-DD")});
  }

  clicked = (d) =>{
    try{
      let selectedDate = this.state.selectedDate;
      if(this.trip === 0){
        this.setState({selectedDate: d});
        this.props.returnDate(d);
      }
      else{
        //console.log();
        if(this.type){
          let sd = {from: selectedDate.from, to: d};
          this.setState({selectedDate: sd, selectType: false});
          this.props.returnDate(sd);
        }
        else{
          if(this.state.selectType){
            let sd = {from: selectedDate.from, to: d};
            this.setState({selectedDate: sd, selectType: false});
            this.props.returnDate(sd);
          }
          else{
            this.setState({selectedDate: {from: d}, selectType: true});
          }
        }
      }

      console.log("=== state ===", this.state);
      console.log("=== click ===", d);
    } catch (error) {
      throw new Error(error);
    }
  }
  render(){
    try{

      // const [startDate, setStartDate] = useState(moment(cDate).format("YYYY-MM-DD"));
      // const [selectedDate, setSelectedDate] = useState({from:'2021-05-20',to: '2021-05-25'});

      let selectedDate = this.state.selectedDate;
      let startDate = this.state.startDate;
      let sDate = new Date(startDate);
      
      console.log("sDate.getMonth()", sDate.getMonth());

      let eeDD = moment(sDate).format('YYYY-MM-01');

      console.log("*** eeDD ** ", eeDD);
      let eDate = new Date(eeDD);
      eDate.setMonth(sDate.getMonth()+ 1);
      console.log("start date ****", startDate);
      console.log("sDate ****", sDate);
      console.log("eDate ****", eDate);

      let sMonthName = moment(sDate).format("MMMM, YYYY");
      let eMonthName = moment(eDate).format("MMMM, YYYY");

      let ssDate = new Date(moment(sDate).format("YYYY-MM-01"));
      let ccDate = new Date(moment(this.cDate).format("YYYY-MM-01"));

      let sDateStr;
      let eDateStr;
      let trip = this.trip;
      let prevStatus = true;
      let isMobile = this.props.isMobile;
      console.log("ssDate", ssDate, "ccDate", ccDate);

      if(ssDate <= ccDate){
        //console.log("**** ssDate ****",ssDate);
        prevStatus = false;
      }

      console.log("**** prevStatus ****", prevStatus);
      
      if(selectedDate){
          
          console.log(this.state);

          if(trip === 1){
            sDateStr = moment(selectedDate.from).format("DD MMMM, YYYY");
            eDateStr = moment(selectedDate.to).format("DD MMMM, YYYY");
          }
          else{
            sDateStr = moment(selectedDate).format("DD MMMM, YYYY");
            eDateStr = null;
          }

          let selectType = this.state.selectType;
          let td = [];
          td.push(<td><MonthsView type={this.type} price={this.props.price} selectType={this.state.selectType} trip={trip} status={isMobile} onPreview={this.prev} prevStatus={prevStatus} onNext={this.next} clicked={this.clicked} monthName={sMonthName} date={sDate} selectedDate={selectedDate} /></td>);

          if(isMobile === false){
            td.push(<td><MonthsView type={this.type} price={this.props.price} selectType={this.state.selectType} trip={trip} status={true} prevStatus={false} onPreview={this.prev} onNext={this.next} clicked={this.clicked}  monthName={eMonthName} date={eDate} selectedDate={selectedDate} /></td>);
          }

        return (
          <>
            <table className="table">
              <tr style={{boxShadow: '0px 2px 3px 1px #000000f2'}}>
                <td colSpan="2" class="py-3">
                  <span class="ms-2"><Today /> {sDateStr}</span>
                  <span> - </span>
                  <DisplayDate date={eDateStr} selectType={selectType} />
                </td>
              </tr>
              <tr>
                {td}
              </tr>
            </table>
          </>
        );
      }
      else{
        return <></>;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default CustomCalender;