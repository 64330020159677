import React from "react";
import RoundSearchList from './RoundSearchList.js';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import CustomSpinnner from "./CustomSpinner.js";



export default class RoundTrip extends React.Component {
    
    state = {
        spinner: false
    }

    handleChange = (event) => {
        this.props.onSpinner(true);
        //this.setState({spinner: true});
        let value = event.target.value;
        console.log("=== value ===", value);
        // this.setState({
        //     value:value
        // })
        if(this.props.type == "0"){
            this.props.onHandel({d:value});
            //this.setState({spinner: false});
        }else{
            this.props.onHandel({r:value});
            //this.setState({spinner: false});
        }
    };


    render(){
        console.log("=== RoundTrip ===", this.props);
        try{
            if(this.props.data?.length == 0){
                return(
                    <Grid container>
                        <Grid item xs={12} className="py-5 text-center">
                            <p className="text-danger">No data found</p>
                        </Grid>
                    </Grid>
                );
            }
            else if(this.props.data?.length > 0){
                //this.props.setLoader(true);
                // let airlines = this.props.allAirlines.filter(r => r.)
                //this.props.setLoader(true);
                let item = [];
                this.props.data.forEach((element, i) => {
                    item.push(<RoundSearchList classes="box-shadow mb-3" data={element} searchKey={this.props.searchKey} searchData={this.props.searchData}  />)
                });
                //this.props.setLoader(false);
                //this.props.setLoader(false);
                return(
                    <>
                        <Modal size="sm" dialogClassName="modal-90w" className="p-0" centered show={this.state.spinner}
                        backdrop="static" keyboard={false}
                        >
                            <Modal.Body className="p-0" 
                            // onClick={this.toggleDepart}
                            >
                                <card className="bg-white">
                                    
                                    <CustomSpinnner />
                                </card>
                            </Modal.Body>
                        </Modal>
                        <div className="sm-device md-device lg-device xl-device round-trip">
                            <Grid container className="mt-2 py-0 pt-3">
                                {/* <Grid item xs={2} className="py-3 text-center">
                                    <h3 className="h6">AIRLINES</h3>
                                </Grid> */}
                                <Grid item xs={3} className="py-3 text-center">
                                    <h4 className="h6">DEPARTURE</h4>
                                </Grid>
                                <Grid item xs={3} className="py-3 text-end">
                                    <h3 className="h6">DURATION</h3>
                                </Grid>
                                <Grid item xs={3} className="py-3 text-center">
                                    <h3 className="h6">ARRIVAL</h3>
                                </Grid>
                                <Grid item xs={3} className="py-3 text-center">
                                    <h3 className="h6">PRICE</h3>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container>
                            <Grid item xs={12} className="mt-3 xs-mt-0 xs-pt-1 sm-device-block md-device-block lg-device-block xl-device-block">
                                <RadioGroup aria-label="gender" name="gender1" value={this.props.selectedFlight} onChange={this.handleChange}>
                                    {item}    
                                </RadioGroup>
                                {/* <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" /> */}
                            </Grid>
                            <Grid item xs={12} className="mt-3 xs-mt-0 xs-pt-1 xs-device-block xs-round-trip-section" style={{overflow: 'auto'}}>
                                <RadioGroup aria-label="gender" name="gender1" value={this.props.selectedFlight} onChange={this.handleChange}>
                                    {item}    
                                </RadioGroup>
                                {/* <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" />
                                <SearchList classes="box-shadow mb-3" /> */}
                            </Grid>
                        </Grid>
                    </>
                );

            }
            else{
                return(
                    <>
                    </>
                );
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}