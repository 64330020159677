import React, { useState } from 'react'
import { Input } from 'semantic-ui-react'
import Grid from '@material-ui/core/Grid';
import { Row, Button } from 'react-bootstrap';
import RedioGroup from "./RedioGroup";
import CouterNumber from "./CouterNumber";


const TravellersDropdown = (prop) => {
  console.log(prop.data);
  try{
    const [adultData, setAdultData] = React.useState({value: Number(prop.data.adult), addBtn:false, subBtn:false, maxValue:9});
    const [childrenData, setChildrenData] = React.useState({value: Number(prop.data.children), addBtn:false, subBtn:false, maxValue:9});
    const [infantsData, setInfantsData] = React.useState({value: Number(prop.data.infants), addBtn:false, subBtn:false, maxValue: Number(prop.data.adult)});
    const [seatClassesData, setSeatClassesData] = React.useState(prop.data.seatClassesData);
    //const [subBtn, setSubBtn] = React.useState(true);

    const adults = (data) => {
      
        setAdultData(data);
        if(data.value < infantsData.value){
          setInfantsData({value:0, addBtn:infantsData.addBtn, subBtn:infantsData.subBtn, maxValue:(data.value)});
        }
        else{
          setInfantsData({value:infantsData.value, addBtn:infantsData.addBtn, subBtn:infantsData.subBtn, maxValue:(data.value)});
        }
        
      // setTimeout(() => {
      //   console.log('--- Adults ---',adultData);
      // }, 1000);
      //checkState();
      console.log('--- Adults ---',data);
    }

    const children = (data) => {
      setChildrenData(data);
      console.log('--- Children ---',data);
      //checkState();
    }

    const infants = (data) => {
      // if(data.value < 9){
        
      // }else{
      //   setAdultData(data);
      // }
      setInfantsData(data);
      console.log('--- Infants ---',data);
      //checkState();
    }

    const seatClasses = (data) =>{
        setSeatClassesData(data[0]);
        console.log('--- seatClasses ---', data[0]);
    } 

    const finelData = (e) => {
      // console.log(adultData);
      // console.log(childrenData);
      // console.log(infantsData);
      // console.log(seatClassesData);
      let finelDatas = {
        adult:adultData.value,
        children:childrenData.value,
        infants:infantsData.value,
        seatClassesData:seatClassesData,
      }
      console.log('---- finel travallers data ---',finelDatas);
      prop.onHandel(finelDatas);

    }

    let visible = '';
    if(!prop.visible){
      visible = 'none';
    }
    let classes = 'TravellersDropdown '+prop.classes;
    return(
      <div className={classes} style={{display:visible, zIndex: 999}}>
          <CouterNumber text="Adults" value={adultData.value} value2={childrenData.value} value3={infantsData.value} onHandel={adults} addBtn={adultData.addBtn} subBtn={adultData.subBtn} maxValue={adultData.maxValue} minVal={1} />
          <CouterNumber text="Children" value={childrenData.value} value2={adultData.value} value3={infantsData.value} onHandel={children} addBtn={childrenData.addBtn} subBtn={childrenData.subBtn} maxValue={childrenData.maxValue} minVal={0} />
          <CouterNumber text="Infants" value={infantsData.value} value2={childrenData.value} value3={adultData.value} onHandel={infants} addBtn={infantsData.addBtn} subBtn={infantsData.subBtn} maxValue={infantsData.maxValue} minVal={0} />
          <RedioGroup defaultValue={seatClassesData.id} classes="Class" datas={[{id:0,name:'Economy'},{id:1,name:'Business'},{id:2,name:'First Class'},{id:3,name:'Premium Economy'}]} onHandel={seatClasses} />
          <Grid container>
              <Grid item xs={12} className="text-center p-3 py-2">
                  <Button className="bg-gradient" style={{width:'60px', fontSize:'10px', color:'white',border:'0px solid black'}} onClick={finelData}  adultData={adultData} childrenData={childrenData} infantsData={infantsData} seatClasses={seatClassesData}>
                      Add
                  </Button>
              </Grid>
          </Grid>
      </div>
    );
  } catch (error) {
    throw new Error(error);
  }
}

export default TravellersDropdown