import React, { createContext, useState, useContext } from 'react';

import SomeThingWentWrong from '../../shared/component/SomeThingWentWrong';
import Loading from '../../shared/component/Loading';
import Services from '../../shared/services/services';
// Create the context
export const AllAirportsContext = createContext();

// Create a provider component to wrap the root of your app
export const AllAirportsProvider = ({ children }) => {
  const [allAirports, setAllAirports] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    getAllAirports();
  }, []);

  const getAllAirports = () =>{
    let data = [{name:'getAll', value:'1'}]
		console.log("=== getAllAirports ===");
		new Services().getAllAirports(data).then(res => res.json()).then(
      (result) => {
        setAllAirports(result.message);
        setLoaded(true);
        console.log('--- allAirlines ----', allAirports);
			},
			(error) => {
				console.log("== error ==", error);
        setError(error);
        setLoaded(true);
        // new displayMessages().errorMsg({error:error});
      }
		);
  }

  if(!loaded){
    return(
      <div className="app">
        <Loading text="Loading...." />
      </div>
    );
  }
  else if(loaded && error != null){
    return(
      <div className="app pt-5 align-items-center">
        <SomeThingWentWrong />
      </div>
    );
  }
  else{
    console.log('--- allAirlines allAirlines ----', allAirports);
    return (
      <AllAirportsContext.Provider value={{ allAirports, setAllAirports }}>
        {children}
      </AllAirportsContext.Provider>
    );
  }
};

// Create a custom hook to access the global state anywhere in your app
export const useAllAirportsContext = () => useContext(AllAirportsContext);