// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';
import Path2481 from '../../assets/Icons/Path-2481.png';
import Group1206 from '../../assets/Icons/Group_1206.png';
import Path2482 from '../../assets/Icons/Path2482.png';
import rupeeIcon from '../../assets/Icons/icons8-rupee-24.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FlightDetails from "./FlightDetails";
import JWT from "../library/jwt";
import _, { map } from 'underscore';
import { ArrowForward } from "@material-ui/icons";
import CustomFunction from "../library/CustomFunction";



const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getNoOfDays = (d1,d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    
    // To calculate the time difference of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();
    
    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}

const DirectFlight = (prop) => {
    //console.log(prop);
    if(prop.data.stop == 0){
        return <p className="pt-3" style={{fontSize: '11px'}}>Non Stop </p>
    }
    else {
        //let via = '';
        let via = prop.data.segments[0].Destination_City.split('(')[0];
        for (let index = 1; index < prop.data.segments.length-1; index++) {
            via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
            
        }
        return <p className="pt-3" style={{fontSize: '11px'}}>{prop.data.stop} Stop, via {via} </p>
    }
}

const DirectFlightForMObile = (prop) => {
    try{
        //console.log(prop);
        if(prop.data.stop == 0){
            return <p className="pt-0" style={{fontSize: '11px'}}>Non Stop </p>
        }
        else {
            //let via = '';
            let via = prop.data.segments[0].Destination_City.split('(')[0];
            for (let index = 1; index < prop.data.segments.length-1; index++) {
                via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
                
            }
            // , via {via}
            return <p className="pt-0" style={{fontSize: '11px'}}>{prop.data.stop} Stop </p>
        }
    } catch (error) {
        throw new Error(error);
    }
}

const DayHoursMins = (prop) =>{
    try{
        //console.log(prop);
        if(prop.data.DurD == 0){
            return(<>{prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
        }
        else{
            return(<>{prop.data.DurD}Day {prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
        }
    } catch (error) {
        throw new Error(error);
    }
}

class FlightMetaDataForConfirmation extends Component {

    constructor(props){
        super(props);
        // this.fetchToken();
        //this.state = {isToggleOn: true};
        
    }
    
    state = {
        hide: true,
        loaded: true
    }

    toggleVisibility = () => this.setState({ hide: !this.state.hide })

    
    className = "search-least "+this.props.classes;


    componentDidMount = () =>{
        console.log("=== componentDidMount ===");
    }

    componentWillUnmount = () =>{
        console.log("=== componentWillUnmount ===");
    }

    // componentDidUpdate = () =>{
    //     console.log("=== componentDidUpdate ===");
    //     this.fetchToken();
    // }
    

    render() {
        try{
            //console.log("=== token ===", this.state.token);
            console.log("tokan ====");
            if(this.state.loaded){
                let dep = this.props.data.Segments[0].Departure_DateTime.split(' ');
                let ariv = this.props.data.Segments[this.props.data.Segments.length - 1].Arrival_DateTime.split(' ');

                let next = getNoOfDays(dep[0], ariv[0]);
                let nextText = '';
                if(next == 1){
                    nextText = "NEXT DAY";
                }
                if(next > 1){
                    //console.log(ariv[0]);
                    let dd = new Date(ariv[0]);
                    let dd1 = dd.getDay();
                    //console.log(dd);
                    //console.log(dd1);
                    nextText = "On "+dayName[dd1];
                }

                
                const images = new CustomFunction().getImage(this.props.data.Airline_Code+'.gif');
                //images.default
                //console.log(images.default);
                let urlPath =`/review-flight?SData=${this.state.token}`
                return(
                <div className={this.className}>
                    <Card className="search-card my-card" style={{borderRadius: 0}}>
                        <CardContent className="sm-device md-device lg-device xl-device p-0">
                            <Grid container className="mt-2 py-1">
                                <Grid item xs={4} sm={2} md={2} lg={2} xl={2} className="py-1 px-3">
                                    <img src={images.default} width="65px" height="50px" className="float-left" />
                                    
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="py-1 position-relative text-center">
                                    <div className="float-left ps-2 py-0 m-0">
                                        <p className="p-0 m-0"><b>{this.props.data.AlName.toUpperCase()}</b></p>
                                        <p className="gray-color f-w-600 pt-2">{this.props.data.Segments[0].Airline_Code}-{this.props.data.Segments[0].Flight_Number}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="py-1 position-relative text-center">
                                    <h2 className="h3 p-0 m-0">{dep[1]}</h2>
                                    <p><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.OrgName.split('(')[0]}</b></p>
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="px-0 text-center">
                                    <p className="pb-2" style={{fontSize: '11px'}}> <DayHoursMins data={this.props.data} /> </p>
                                    <div style={{borderTop:'2px dashed gray',width:'100px',margin:'auto'}} className="position-relative">
                                        <FiberManualRecordIcon className="position-absolute gradient2-color" style={{top:'-12px',left:'20px'}}></FiberManualRecordIcon>
                                        <img src={Path2481} width="25px" className="float-left position-absolute" style={{right:0,top:'-13px'}} />
                                    </div>
                                    {/* <DirectFlight data={{segments:this.props.data.Segments,stop:this.props.data.stops}} /> */}
                                </Grid>
                                <Grid item xs={4} sm md lg xl className="py-0 text-center">
                                    <h2 className="h3 p-0 m-0" >{ariv[1]}</h2>
                                    <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.DestName.split('(')[0]}</b></p>
                                    <p className="text-danger p-0 m-0" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardContent className="xs-device p-0" data-fid={this.props.data.Flight_Id} onClick={this.setFlightData}>
                            
                            <Grid container className="mt-0 py-1 pb-1">
                                <Grid item xs={3} sm={2} md={2} lg={2} xl={2} className="py-1 pt-2 px-2">
                                    <div className="text-center float-start">
                                        <img src={images.default} width="35px" height="25px" className="" />
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={2} lg={2} xl={2} className="py-1 pt-2">
                                    <div className="py-0 m-0">
                                        <p className="p-0 m-0 mt-1" style={{fontSize:'9px'}}><b>{this.props.data.AlName.toUpperCase()}</b></p>
                                        <p className="gray-color f-w-600 pt-1">{this.props.data.Segments[0].Airline_Code}-{this.props.data.Segments[0].Flight_Number}</p>
                                    </div>
                                </Grid>
                                
                                <Grid item xs={5} sm md lg xl className="py-1 position-relative text-center">
                                    <div className="float-start py-0 text-start">
                                        <h2 className="h3 p-0 m-0">{dep[1]}</h2>
                                        <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.OrgName.split('(')[0]}</b></p>
                                        <p className="py-0 m-0"><DayHoursMins data={this.props.data} /></p>
                                    </div>
                                    <ArrowForward />
                                    <div className="float-end py-0 text-end">
                                        <h2 className="h3 p-0 m-0" >{ariv[1]}</h2>
                                        <p className="py-1 m-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.DestName.split('(')[0]}</b></p>
                                        {/* <p className="text-danger p-0 m-0" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p> */}
                                        <DirectFlightForMObile data={{segments:this.props.data.Segments,stop:this.props.data.stops}} />
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
                );
            }
            else{
                return <></>;
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default FlightMetaDataForConfirmation;