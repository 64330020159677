// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";


// Images
import Indigo from '../../assets/Icons/indigo.png';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import OverView from './OverView';
import FareSummaryRules from './FareSummaryRules';
import FreeBuggage from './FreeBuggage';

function TabPanel(props) {
  try{
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === 0 && (
          <OverView data={props.data} travelClass={props.travelClass} />
        )}
        {value === 1 && (
          <Grid container className="">
              <Grid item xs={8} className="p-0">
                  <FreeBuggage data={props.data} />
              </Grid>
              <Grid item xs={4} className="p-0">
                  <FareSummaryRules data={props.data} />
              </Grid>
          </Grid>
        )}
        {/* {value === 2 && (
          <Grid container className="">
              <Grid item xs={12} className="">
                  <FreeBuggage data={props.data} />
              </Grid>
          </Grid>
        )} */}
      </div>
    );
  } catch (error) {
    throw new Error(error);
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FlightDetails(prop) {
  try{
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    //console.log(prop);
    return (
      <div className={classes.root} hidden={prop.hidden}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Fare Summary & Free Buggage" {...a11yProps(1)} />
            {/* <Tab label="Free Buggage" {...a11yProps(2)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel data={prop.data} travelClass={prop.travelClass} value={value} index={0}>
          Item One
        </TabPanel>
        <TabPanel data={prop.data} travelClass={prop.travelClass} value={value} index={1}>
          Item Two
        </TabPanel>
        {/* <TabPanel data={prop.data} travelClass={prop.travelClass} value={value} index={2}>
          Item Three
        </TabPanel> */}
      </div>
    );
  } catch (error) {
		throw new Error(error);
	}
}
