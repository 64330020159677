// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import footer from '../../assets/Icons/footer.png';
import logo from '../../assets/Icons/logo.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import CopyrightIcon from '@material-ui/icons/Copyright';

import { NavLink } from "react-router-dom";
import CustomNavLink from "./CustonNavLink";

class Footer extends Component {
    

    componentDidMount(){
        
    };

    render(){
        try{

            let li = [];

            this.props?.data?.footer_links?.map(e =>{
                li.push(<CustomNavLink url={e.url} target={e.target} name={<li className="p">{e.name}</li>} style={{color: '#9F9F9F', textDecoration: 'none'}}></CustomNavLink>);
            });

            return(
                <Grid container>
                    {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="text-center mt-3">
                        <img src={logo} alt="logo" className="f-img" />
                        <p className="h4" style={{color:'#0E3C58'}}>CONNECT WITH US</p>
                        <p className="p"><b>{this.props.data.contact_cols[0].customer_support.email}</b></p>
                    </Grid>
                    <Grid item xs={9} className="text-center mt-3 lg-device-block xl-device-block">
                        <img src={footer} className="footer-img" />
                    </Grid> */}
                    <Grid item xs={12} lg={7} xl={7} className="text-center my-4 lg-device-block xl-device-block">
                        <ul className="list">
                            {li}
                        </ul>
                    </Grid>
                    <Grid item xs={12}  lg={5} xl={5} className="text-center mt-0 py-4">
                        {this.props?.data?.contact_cols ?<p className="p" dangerouslySetInnerHTML={{__html: this.props?.data?.contact_cols[0]?.customer_support?.footer_legal}}></p>: null}
                    </Grid>
                </Grid>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default Footer;