import "./Confirmation.scss";
import "./Confirmation.css";
import Header from '../../../shared/component/Header.js';
import LeaveComment from '../../../shared/component/LeaveComment.js';
import LiBlock from '../../../shared/component/LiBlock.js';
import Forms from '../../../shared/component/Form.js';
import banner from '../../../assets/Icons/ticketbanner1.jpg';
import checkmark from '../../../assets/Icons/checkmark.png';
import cancel from '../../../assets/Icons/cancel.png';
import failed from '../../../assets/Icons/report.png';
import hold from '../../../assets/Icons/pause.png';
import Grid from '@material-ui/core/Grid';
import * as QueryString from "query-string";
import CustomCalender from "../../../shared/component/CustomCalender";
import { Call, Info, LocationCityOutlined, LocationOn, MailOutline } from "@material-ui/icons";
import React from "react";
import Services from "../../../shared/services/services";
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from "react-router-dom";
import DisplayMessages from "../../../shared/displayMessages/displayMessages";

//import Calendar from "../../../shared/component/calender/Calendar";
//import Input from '../../../shared/component/Input.js';

const checkLogin = (status) =>{
    //this.setState({loggedIn: status});
}

function Confirmation(prop) {
    try{
        const [statusData, setStatusData] = React.useState({success: true, message: 'Your booking is initiated to Airline, we will notify you the confirm status within 15 minutes maximum.', status: 'INITIATED'});
        const [expire, setExpire] = React.useState(false);
        const [window, setWindow] = React.useState('web');
        const [userSession, setUserSession] = React.useState('');

        React.useEffect(() => {
            let params = QueryString.parse(prop.location.search);
            console.log("params ====", params);
            setWindow(params.window);
            setUserSession(params.userSession);
            let timer = setInterval(()=> { console.log("Calling ===="); getPaymentStatus(params.userSession)}, 5000);

            // new Services().getPaymentStatus(params.userSession).then(res => res.json()).then(res =>{
            //     if(res != ''){
            //         console.log("==== paymet status ====", res);
            //         // res.status = "INITIATED";
            //         setStatusData(res);
            //         if(res.success === true && res.status == 'INITIATED'){
            //             getPaymentStatus(params.userSession);
            //         }
            //     }
            // });

            setTimeout(() => {
                setExpire(true);
                clearInterval(timer);
            }, 60000);

        }, []);


        const getPaymentStatus = (userSession) => {
            new Services().getPaymentStatus(userSession).then(res => res.json()).then(res =>{
                console.log("==== paymet status ====", res);
                // res.status = "INITIATED";
                setStatusData(res);
            }, error =>{
                console.log(error);
                // new DisplayMessages().errorMsg({error: error});
            });
        }



        // console.log(prop);
        return (
        <div className="TravelStoryDetails mb-5">
            {console.log(statusData)}
            {
                window == 'web'? (<>
                    <Header onLoggedIn={checkLogin} openSideBar={(status) =>prop.openSideBar(status)} nav={prop.nav}  />
                    <img src={banner} className="w-100 banner mt-5 desktop" style={{height: '300px'}} />
                </>): <></>
            }
            <Grid container className="p-5 pb-0 mt-5 xs-p-3 xs-p-5">
                <Grid item xs sm md lg xl></Grid>
                <Grid item xs={12} sm={9} md={7} lg={6} xl={5} className="pe-5 ps-3 xs-p-0 xs-mt-5">
                    <div className="row box-shadow" style={{borderRadius: '10px'}}>
                        {statusData?.success ? (
                            <>
                            {statusData?.status == 'INITIATED' ? 
                                (<>
                                    {expire === true ? (<>
                                        <div className="col-md-12 bg-gradient color-w py-3" style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                                            <h1><Info color="inherit" style={{width: '30px', height: '30px'}} /> Initiated </h1>
                                        </div>
                                        <div className="col-md-12 text-center py-5">
                                            {/* <CircularProgress style={{width: '80px', height: '80px'}} /> */}
                                            <p className="m-0" dangerouslySetInnerHTML={{__html: statusData?.exception_msg}}></p>
                                        </div>
                                    </>) : (<>
                                        <div className="col-md-12 bg-gradient color-w py-3" style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                                            <h1><CircularProgress color="inherit" style={{width: '30px', height: '30px'}} /> Processing... </h1>
                                        </div>
                                        <div className="col-md-12 text-center py-5">
                                            {/* <CircularProgress style={{width: '80px', height: '80px'}} /> */}
                                            <p className="m-0" dangerouslySetInnerHTML={{__html: statusData?.message}}></p>
                                        </div>
                                    </>)}
                                </>) : (<></>)}
                            {statusData?.status == 'HOLD' ? 
                                (<>
                                    <div className="col-md-12 bg-gradient color-w py-3" style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                                        <h1><img src={hold} style={{width: '50px', marginRight: '10px'}} /> Hold </h1>
                                    </div>
                                    <div className="col-md-12 text-center py-5">
                                        {/* <CircularProgress style={{width: '80px', height: '80px'}} /> */}
                                        <p className="m-0" dangerouslySetInnerHTML={{__html: statusData?.message}}></p>
                                        <NavLink to="/">
                                            <button type="button" class="bg-gradient border-0 btn-footer btn btn-primary mt-3" style={{width:'100px'}}>
                                                Go Home
                                            </button>
                                        </NavLink>
                                    </div>
                                </>) : (<></>)}
                            {statusData?.status == 'FAILED' ? 
                                (<>
                                    <div className="col-md-12 bg-gradient color-w py-3" style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                                        <h1><img src={failed} style={{width: '50px', marginRight: '10px'}} /> Failed </h1>
                                    </div>
                                    <div className="col-md-12 text-center py-5">
                                        {/* <CircularProgress style={{width: '80px', height: '80px'}} /> */}
                                        <p className="m-0" dangerouslySetInnerHTML={{__html: statusData?.message}}></p>
                                        <NavLink to="/">
                                            <button type="button" class="bg-gradient border-0 btn-footer btn btn-primary mt-3" style={{width:'100px'}}>
                                                Go Home
                                            </button>
                                        </NavLink>
                                    </div>
                                </>) : (<></>)}
                            {statusData?.status == 'CANCELLED' ? 
                                (<>
                                    <div className="col-md-12 bg-gradient color-w py-3" style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                                        <h1><img src={cancel} style={{width: '50px', marginRight: '10px'}} /> Cancelled </h1>
                                    </div>
                                    <div className="col-md-12 text-center py-5">
                                        {/* <CircularProgress style={{width: '80px', height: '80px'}} /> */}
                                        <p className="m-0" dangerouslySetInnerHTML={{__html: statusData?.message}}></p>
                                        <NavLink to="/">
                                            <button type="button" class="bg-gradient border-0 btn-footer btn btn-primary mt-3" style={{width:'100px'}}>
                                                Go Home
                                            </button>
                                        </NavLink>
                                    </div>
                                </>) : (<></>)}
                            {statusData?.status == 'CONFIRMED' ? 
                                (<>
                                    <div className="col-md-12 bg-gradient color-w py-3" style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                                        <h1><img src={checkmark} style={{width: '50px', marginRight: '10px'}} />Done</h1>
                                    </div>
                                    <div className="col-md-12 text-center py-5">
                                        {/* <h2 className="p-0 m-0">₹ 7,450.00 Transfered to RJIO-RJIO900070609425</h2> */}
                                        <p className="m-0" dangerouslySetInnerHTML={{__html: statusData?.message}}></p>

                                        {/* <h4>Your payment reciept has been send on your email, please check.</h4> */}
                                        <NavLink to="/">
                                            <button type="button" class="bg-gradient border-0 btn-footer btn btn-primary mt-3" style={{width:'100px'}}>
                                                Go Home
                                            </button>
                                        </NavLink>
                                    </div>
                                </>) : (<></>)}
                            </>
                        ) : (<>
                            <div className="col-md-12 bg-gradient color-w py-3" style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                                <h1>! Oops </h1>
                            </div>
                            <div className="col-md-12 text-center py-5 my-4">
                                <p className="m-0"> Something went wrong... </p>
                                <NavLink to="/">
                                    <button type="button" class="bg-gradient border-0 btn-footer btn btn-primary mt-3" style={{width:'100px'}}>
                                        Go Home
                                    </button>
                                </NavLink>
                            </div>
                        </>)}
                    </div>
                </Grid>
                <Grid item xs sm md lg xl></Grid>
            </Grid>
            
        </div>
        );
    } catch (error) {
        throw new Error(error);
    }
}

export default Confirmation;