// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import gNight from '../../assets/Icons/night.png';
import gEvening from '../../assets/Icons/sunrise.png';
import gAfternoon from '../../assets/Icons/sun.png';
import gMorning from '../../assets/Icons/Group_1207.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Row, Button } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { Replay } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react';
import Slider from '@material-ui/core/Slider';
import BtnGroup from "./BtnGroup";
import TimesView from "./TimesView";
import { allAirlinesData } from "../../assets/allAirlines";
// import { ContinuousSlider } from './ContinuousSlider';

export default function SearchFilterForMobile(prop){
    try{
        const [value, setValue] = React.useState(2000);
        //const [fData, setFilterData] = React.useState(prop.data);
        const arr = prop.data.airlines;
        const handleChange = (event, newValue) => {
            //setValue(newValue);

            let filterData = {
                stop:prop.data.stop,
                fareType:prop.data.fareType,
                departureTime:prop.data.departureTime,
                arrivalTime:prop.data.arrivalTime,
                airLines:prop.data.airLines,
                priceRange:newValue,
                airlines:prop.data.airlines,

            }
            //setFilterData(filterData);
            console.log("=== filter data ===",filterData);
            prop.onHandel(filterData);
        };

        const setStop = (number) =>{
            let filterData = {
                stop:number,
                fareType:prop.data.fareType,
                departureTime:prop.data.departureTime,
                arrivalTime:prop.data.arrivalTime,
                airLines:prop.data.airLines,
                priceRange:prop.data.priceRange,
                airlines:prop.data.airlines,

            }
            //setFilterData(filterData);
            console.log("=== filter data ===",filterData);
            prop.onHandel(filterData);
        }

        const setDepartureTime = (data) =>{
            let filterData = {
                stop:prop.data.stop,
                fareType:prop.data.fareType,
                departureTime:data,
                arrivalTime:prop.data.arrivalTime,
                airLines:prop.data.airLines,
                priceRange:prop.data.priceRange,
                airlines:prop.data.airlines,

            }
            //setFilterData(filterData);
            console.log("=== filter data ===",filterData);
            prop.onHandel(filterData);
        }

        const setArrivalTime = (data) =>{
            let filterData = {
                stop:prop.data.stop,
                fareType:prop.data.fareType,
                departureTime:prop.data.departureTime,
                arrivalTime:data,
                airLines:prop.data.airLines,
                priceRange:prop.data.priceRange,
                airlines:prop.data.airlines,

            }
            //setFilterData(filterData);
            console.log("=== filter data ===",filterData);
            prop.onHandel(filterData);
        }

        const setRefundable = (event, value) =>{
            //console.log("=== Fare Value ===",value);
            let filterData = {
                stop:prop.data.stop,
                fareType:value,
                departureTime:prop.data.departureTime,
                arrivalTime:prop.data.arrivalTime,
                airLines:prop.data.airLines,
                priceRange:prop.data.priceRange,
                airlines:prop.data.airlines,

            }
            //setFilterData(filterData);
            console.log("=== filter data ===",filterData);
            prop.onHandel(filterData);
        }

        const setAirlines = (event, value) =>{
            console.log("=== Fare Value ===",value);
            let v = event.currentTarget.value;
            if(value){
                arr.push(v);
            }
            else{
                var index = arr.indexOf(v);
                if (index > -1) {
                    arr.splice(index, 1);
                }
            }
            console.log("=== value ===",arr);
            let filterData = {
                stop:prop.data.stop,
                fareType:prop.data.fareType,
                departureTime:prop.data.departureTime,
                arrivalTime:prop.data.arrivalTime,
                airLines:prop.data.airLines,
                priceRange:prop.data.priceRange,
                airlines:arr,

            }
            //setFilterData(filterData);
            console.log("=== filter data ===",filterData);
            prop.onHandel(filterData);
        }

        const resetFilter = (event) =>{
            //console.log("=== Reset Filter ===",value);
            let filterData = {
                stop:null,
                fareType:false,
                departureTime:null,
                arrivalTime:null,
                airLines:null,
                priceRange:prop.max,
                airlines:[],

            }
            //setFilterData(filterData);
            console.log("=== filter data ===",filterData);
            prop.onHandel(filterData);
        }


        console.log(prop);
        let items = [];
        prop.filterFlights.map((r, i) => {
            let checked = false;
            prop.data.airlines.forEach(element => {
                if(element == r.Airline_Code){
                    checked = true;
                }
            });
            console.log("=== checked ===", checked);
            let airlinesName = allAirlinesData.filter(an => an.ooro_fcode == r.Airline_Code)[0];
            items.push(
            <Form.Field className="form-group mb-2">
                <Checkbox className="px-0 py-1" value={r.Airline_Code} checked={checked} onChange={setAirlines} /> <label><b>{ airlinesName.ooro_fname } ({r.Count})</b></label>
                {/* <label className="float-right"><b>₹ 9457</b></label> */}
            </Form.Field>
            );
        });

        return(
            <div className="position-relative">
                <Card className="search-filter-for-mobile search-card my-card border-radius mb-3 pb-4"  style={{height: '91vh', overflow: 'auto'}}>
                    <CardContent className="p-2">
                        <Grid container className="">
                            {/* <Grid item xs={12}>
                                <Tooltip title="Filter" aria-label="Filter" placement="left">
                                    <Button className="bg-gradient float-start me-3" onClick={resetFilter}>
                                        Done
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Reset" aria-label="Reset" placement="left">
                                    <Button className="bg-gradient float-end me-3" onClick={resetFilter}>
                                        <Replay className="a w-color" style={{fill:'white'}} />
                                    </Button>
                                </Tooltip>
                            </Grid> */}
                            {/* <Grid item xs={12} className="">
                                <hr className="m-0 my-3 p-0" />
                            </Grid> */}
                            <Grid item xs={12} className="">
                                <ExpandMoreIcon></ExpandMoreIcon>
                                <span><b>STOPS</b></span> 
                                
                                {/* <IconButton color="primary"  aria-label="Reset">
                                    
                                </IconButton> */}
                                <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                                    <Grid item xs={12} className="px-3">
                                        <BtnGroup value={prop.data.stop} onHandel={setStop} />
                                    </Grid>
                                    {/* <Grid item xs={4} className="px-3">
                                        <Form.Field className="form-group text-center">
                                            <Input type="text" className="w-100 input-text-center" value="0" />
                                            <small>₹ 5005</small>
                                        </Form.Field>
                                    </Grid>
                                    <Grid item xs={4} className="px-3">
                                        <Form.Field className="form-group text-center">
                                            <Input type="text" className="w-100 input-text-center" value="1" />
                                            <small>₹ 4178</small>
                                        </Form.Field>
                                    </Grid>
                                    <Grid item xs={4} className="px-3">
                                        <Form.Field className="form-group text-center">
                                            <Input type="text" className="w-100 input-text-center" value="1+" />
                                            <small>₹ 9457</small>
                                        </Form.Field>
                                    </Grid> */}
                                    <Grid item xs={12} className="">
                                        <hr className="m-0 my-3 p-0" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="">
                                <ExpandMoreIcon></ExpandMoreIcon> <span><b>FARE TYPE</b></span>
                                <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                                    <Grid item xs={12} className="px-3">
                                        <Form.Field className="form-group">
                                            <Checkbox checked={prop.data.fareType} className="px-0 py-1" onChange={setRefundable} /> <label>Refundable</label>
                                        </Form.Field>
                                    </Grid>
                                    <Grid item xs={12} className="">
                                        <hr className="m-0 my-3 p-0" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="">
                                <ExpandMoreIcon></ExpandMoreIcon> <span><b>DEPARTURE TIMES</b></span>
                                <p className="ps-2"><b style={{color:'#A1A1A1'}}>From {prop.from}</b></p>
                                <TimesView data={prop.data.departureTime} onHandel={setDepartureTime} />
                            </Grid>
                            <Grid item xs={12} className="mb-3">
                                <ExpandMoreIcon></ExpandMoreIcon> <span><b>ARRIVAL TIMES</b></span>
                                <p className="ps-2"><b style={{color:'#A1A1A1'}}>At {prop.to}</b></p>
                                <TimesView data={prop.data.arrivalTime} onHandel={setArrivalTime} />
                            </Grid>
                            <Grid item xs={12} className="mb-3">
                                <ExpandMoreIcon></ExpandMoreIcon> <span><b>AIRLINES</b></span>
                                <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                                    <Grid item xs={12} className="px-3">
                                        {/* <Form.Field className="form-group mb-2">
                                            <Checkbox className="px-0 py-1"></Checkbox> <label><b>Air Asia (23)</b></label> <label className="float-right"><b>₹ 9457</b></label>
                                        </Form.Field> */}
                                        {items}
                                        
                                        {/* <p><small><b className="gradient-color" style={{cursor:'pointer'}}>+1 Airlines</b></small></p> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="mb-3">
                                <ExpandMoreIcon></ExpandMoreIcon> <span><b>Price Range</b></span>
                                <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                                    <Grid item xs={12} className="px-3">
                                        <Card className="box-shadow p-3">
                                            <Grid container>
                                                <Grid item xs={12} className="pt-4">
                                                    <Slider className="mt-1" value={prop.data.priceRange} valueLabelDisplay="on" min={prop.min} step={100} max={prop.max} onChange={handleChange} aria-labelledby="continuous-slider" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <small className="float-left">₹ {prop.min}</small>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <small className="float-right">₹ {prop.max}</small>
                                                </Grid>
                                            </Grid>
                                        </Card> 
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} className="">
                                <ExpandMoreIcon></ExpandMoreIcon> <span><b>CONNECTING AIRPORT</b></span>
                                <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                                    <Grid item xs={12} className="px-3">
                                        <Form.Field className="form-group mb-2">
                                            <Checkbox className="px-0 py-1"></Checkbox> <label><b>Ahamdabad</b></label> 
                                        </Form.Field>
                                        <Form.Field className="form-group mb-2">
                                            <Checkbox className="px-0 py-1"></Checkbox> <label><b>Banglore</b></label> 
                                        </Form.Field>
                                        <Form.Field className="form-group mb-2">
                                            <Checkbox className="px-0 py-1"></Checkbox> <label><b>Bhopal</b></label> 
                                        </Form.Field>
                                        <Form.Field className="form-group mb-2">
                                            <Checkbox className="px-0 py-1"></Checkbox> <label><b>Chandigar</b></label> 
                                        </Form.Field>
                                        <Form.Field className="form-group mb-2">
                                            <Checkbox className="px-0 py-1"></Checkbox> <label><b>Cochin</b></label>
                                        </Form.Field>
                                        <p><small><b className="gradient-color" style={{cursor:'pointer'}}>+10 Airports</b></small></p>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </Grid>	
                    </CardContent>
                    {/* <CardActions>
                        
                    </CardActions> */}
                </Card>
                <Grid container className="position-absolute p-2 bg-gradient" style={{bottom: '-16px', backgroundColor: 'white', zIndex: 10}}>
                    <Grid item xs={12}>
                        <Tooltip title="Filter" aria-label="Filter" placement="left">
                            <Button className="float-start me-3 a" style={{backgroundColor: 'white', zIndex: 10}} onClick={() => prop.onFilter(true)}>
                                Done
                            </Button>
                        </Tooltip>
                        <Tooltip title="Reset" aria-label="Reset" placement="left">
                            <Button className="float-end me-3 a" style={{backgroundColor: 'white', zIndex: 10}} onClick={resetFilter}>
                                <Replay className="a w-color" style={{fill:'#7F73EE'}} />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
        );
    } catch (error) {
        throw new Error(error);
    }
}

// export default SearchFilter;