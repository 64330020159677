import React from "react";
import './Login.css';
import './Login.scss';
import logo from '../../../assets/Icons/logo.png';
import plane from '../../../assets/Icons/plane.png';
import female from '../../../assets/Icons/Group 1200.png';
import male from '../../../assets/Icons/Group 1201.png';
import { Row, Form, Button } from 'react-bootstrap';
//import Buttons from '../../../shared/component/Buttons.js';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LockIcon from '@material-ui/icons/Lock';
// import "../../../assets/Icons/logo.png";
import { NavLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { Tab } from 'semantic-ui-react'
import Services from "../../../shared/services/services";
import displayMessages from "../../../shared/displayMessages/displayMessages";
import SignupForm from "../../../shared/component/SignupForm";
import FogerPassword from "../../../shared/component/FogerPassword";

function Login(prop) {
  const [login, setLogin] = React.useState('unset');
  const [loginClass, setLoginClass] = React.useState('shadow color-w bg-gradient border-0 my-success-btn');
  const [signup, setSignup] = React.useState('none');
  const [signupClass, setSignupClass] = React.useState('my-default-btn border-primary');
  const [validatedLogin, setValidatedLogin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [valueUsername, setValueUsername] = React.useState('');
  const [valuePassword, setValuePassword] = React.useState('');
  const [loginError, setLoginError] = React.useState('none');
  const [loginSuccess, setLoginSuccess] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [loginErrorMsg, setLoginErrorMsg] = React.useState('');

  const handleSubmitLogin = (event) => {
    //const form = event.currentTarget;
    //console.log("=== form ===", form.checkValidity());
    if (valueUsername !='' && valuePassword !='') {
      getFormValue();
      setLoading(true);
    }
    else{
      event.preventDefault();
      event.stopPropagation();
      setValidatedLogin(true);
    }

    
  };


  const getFormValue = ()=>{
    console.log("=== valueUsername ===",valueUsername);
    console.log("=== valuePassword ===",valuePassword);
    new Services().login(valueUsername, valuePassword).then(res => res.json()).then((results) =>{
      console.log("=== login result ===", results);
      if(results.success){
        setValidatedLogin(false);
        setLoginSuccess(true);
        setToken(results.token);
        localStorage.setItem('OoroToken', results.token);
        localStorage.setItem('OoroUserData', JSON.stringify(results.message));
        console.log("=== localStorage token ===", localStorage.getItem('OoroToken'));
        console.log("=== localStorage userData ===", localStorage.getItem('OoroUserData'));
        prop.onHandle(false);
      }
      else{
        setValidatedLogin(true);
        setLoginError('unset');
        setLoginErrorMsg(results.message);
        new displayMessages().errorMsg({error: {message: `${results?.message} at login()`, data: {userName: valueUsername, password: valuePassword}, status: results?.status, type: results?.type}});
      }
    }, (error) => {
      console.log("== error ==", error);
      // new displayMessages().errorMsg({error:error});
    });
  }

  const checkLogin = () =>{
    console.log("==== checkLogin ===");
    if(loginSuccess){
      prop.onHandle(true);
    }
  }


  const panes = [
    {
      menuItem: 'Sign in',
      render: () => <Tab.Pane attached={false}>
            <Grid container>
                <Grid item xs={12} sm={6} md={5} lg={4} xl={4} className="h-90vh overflow-auto bg-gradient d-flex justify-content-center align-items-center">
                    <div className="px-4">
                    <Row className="text-center">
                        <img src={logo} alt="logo" className="logo m-auto" />
                        <h2 className="mt-5" style={{color: 'white', lineHeight: '42px', fontSize: '30px'}}> Welcome! </h2>
                        <p className="p mt-2 mini-heading"> Sign in to your account </p>
                    </Row>
                    <Row className="text-center pt-5 pb-3 form-div">
                        <Form noValidate validated={validatedLogin}>
                        <Form.Group controlId="formBasicUserNameMobile" className="position-relative mb-4">
                            <Form.Control type="text" required placeholder="Enter your username" value={valueUsername} autoComplete={true} className="my-big-input" onChange={e => {setValueUsername(e.target.value); console.log(e.target); setValidatedLogin(true); setLoginError('none');}} />
                            <PersonOutlineIcon className="position-absolute my-icon" />
                            {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text> */}
                            {/* <Form.Control.Feedback type="invalid">
                                Please choose a username.
                            </Form.Control.Feedback> */}
                        </Form.Group>
                        <Form.Group controlId="formBasicPasswordMobile" className="position-relative mb-5">
                            {/* <Form.Label>Password</Form.Label> */}
                            <Form.Control type="password" required placeholder="Enter your password" value={valuePassword} autoComplete="on" className="my-big-input" onChange={e => {setValuePassword(e.target.value); console.log(e.target); setValidatedLogin(true); setLoginError('none');}} />
                            <LockIcon className="position-absolute my-icon" />
                        </Form.Group>
                        {/* <p className="color-w text-right mb-5"> Forget password? </p> */}
                        <p className="text-center mt-5" style={{display: loginError, color:'#b10c00'}}> {loginErrorMsg} </p>
                            <Button variant="contained" type="button" className="my-default-btn w-100-per mt-2" onClick={handleSubmitLogin}>
                            Login
                            </Button>
                        </Form>
                    </Row>
                    </div>
                </Grid>
            </Grid>  
        </Tab.Pane>,
    },
    {
      menuItem: 'Sign Up',
      render: () => <Tab.Pane attached={false}>
          <Grid container>
            <Grid item xs={12} sm={6} md={7} lg={8} xl={8} className="h-90vh bg-gradient d-flex justify-content-center align-items-center">
            <div className="px-4">
                <Row className="text-center pt-2">
                    <img src={logo} alt="logo" className="logo m-auto" />
                    <h2 className="mt-5" style={{color: 'white', lineHeight: '42px', fontSize: '30px'}}> Welcome! </h2>
                    <p className="p mt-1 mini-heading"> Signup to your account </p>
                </Row>
                <Row className="text-center pt-5 pb-2 form-div">
                  <SignupForm onHandle={(status)=> prop.onHandle(status)} />
                    {/* <Form>
                    <Form.Group controlId="formBasicName" className="position-relative mb-4">
                        <Form.Control type="text" placeholder="Name" className="my-big-input pl-35" />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="position-relative mb-4">
                        <Form.Control type="text" placeholder="Email" className="my-big-input pl-35" />
                    </Form.Group>
                    <Form.Group controlId="formBasicMobile" className="position-relative mb-4">
                        <Form.Control type="text" placeholder="Mobile No." className="my-big-input pl-35" />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="position-relative mb-4">
                        <Form.Control type="text" placeholder="Password" className="my-big-input pl-35" />
                    </Form.Group>
                    <NavLink to="/home">
                        <Button variant="contained" type="submit" className="my-default-btn w-100-per mt-1">
                        Sign Up
                        </Button>
                    </NavLink>
                    </Form> */}
                </Row>
                </div>
            
            </Grid>
        </Grid>
      </Tab.Pane>,
    },
    {
      menuItem: 'Forget Password',
      render: () => <Tab.Pane attached={false}>
          <Grid container>
            <Grid item xs={12} sm={6} md={7} lg={8} xl={8} className="h-90vh bg-gradient d-flex justify-content-center align-items-center">
            <div className="px-4">
                <Row className="text-center pt-2">
                    <img src={logo} alt="logo" className="logo m-auto" />
                    <h2 className="mt-5" style={{color: 'white', lineHeight: '42px', fontSize: '30px'}}> Welcome! </h2>
                    <p className="p mt-1 mini-heading"> Forget Your Password </p>
                </Row>
                <Row className="text-center pt-5 pb-2 form-div">
                  <FogerPassword />
                    {/* <Form>
                      <Form.Group controlId="formBasicName" className="position-relative mb-4">
                          <Form.Control type="text" placeholder="Email / Mobile No." className="my-big-input pl-35" />
                      </Form.Group>
                      <Button variant="contained" type="submit" className="my-default-btn w-100-per mt-1">
                        Forget Password
                      </Button>
                    </Form> */}
                </Row>
                </div>
            
            </Grid>
        </Grid>
      </Tab.Pane>,
    }
  ];
  


  return (
    <>
    <Card className="Login-mobile xs-device">
      {checkLogin}
      <Tab menu={{ pointing: true }} className="login-signup-tab" panes={panes} />
      
    </Card>
    </>
  );
}

export default Login;
