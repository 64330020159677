import React from "react";
import PropTypes from "prop-types";
import {Redirect, withRouter} from "react-router";
import {connect} from "react-redux";

import {
  Navbar,
  Nav,
  NavItem,
  InputGroupAddon,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
} from "reactstrap";

import { NavLink } from 'react-router-dom';

import { logoutUser } from "../../actions/auth.js";
import { closeSidebar, openSidebar } from "../../actions/navigation.js";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon.js";
import SearchBarIcon from "../Icons/HeaderIcons/SearchBarIcon.js";
import BellIcon from "../Icons/HeaderIcons/BellIcon.js";
import SearchIcon from "../Icons/HeaderIcons/SearchIcon.js";

import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon.js";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon.js";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon.js";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import basketIcon from "../../assets/navbarMenus/basketIcon.svg";
import calendarIcon from "../../assets/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../assets/navbarMenus/envelopeIcon.svg";
import mariaImage from "../../assets/navbarMenus/mariaImage.jpg";
import notificationImage from "../../assets/navbarMenus/notificationImage.jpg";
import userImg from "../../../../assets/Icons/blank-profile.jpeg";

// import img
import logoBlack from '../../../../assets/Icons/logo-black.png';
import logo from '../../../../assets/Icons/logo.png';
import blankLogo from '../../../../assets/Icons/012218f4d43ade31f4e6146e2178f4be.jpg';

import s from "./Header.module.scss";
import "animate.css";

class Header extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);

    this.state = {
      menuOpen: false,
      notificationsOpen: false,
      searchFocused: false,
    };
  }

  doLogout() {
    localStorage.removeItem('OoroToken');
    localStorage.removeItem('OoroUserData');
    window.location.reload();
  }

  toggleSidebar() {
    if (this.props.sidebarOpened) {
      this.props.onHandelToggle();
    } else {
      const paths = window.location.pathname.split('/');
      paths.pop();
      this.props.onHandelToggle();
    }
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  toggleNotifications() {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen,
    });
  }

  redirectToUrl = (url) =>{
    return(<Redirect to={url} />);
  }

  render() {

    let OoroUserData = localStorage.getItem('OoroUserData');
    console.log("=== userData ===", JSON.parse(OoroUserData));
    let userData = {};
    if(OoroUserData){
      userData = JSON.parse(OoroUserData);
    }

    return (
      <Navbar className={`${s.root} d-print-none`}>
        <div>
          <NavLink
            onClick={this.toggleSidebar}
            className={`d-md-none mr-3 ${s.navItem}`}
            to="#"
          >
            <MenuIcon className={s.menuIcon} />
          </NavLink>
        </div>
        <Nav className="ml-auto">
          <Dropdown isOpen={this.state.notificationsOpen} toggle={this.toggleNotifications} nav id="basic-nav-dropdown" className="ml-3">
            <DropdownToggle nav caret className="navbar-dropdown-toggle">
              <span className={`${s.avatar} rounded-circle float-left me-2`}>
                <img src={userImg} alt="User"/>
              </span>
              <span className="small d-none d-sm-block ms-1 me-2 body-1">{userData.Name}</span>
            </DropdownToggle>
            <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
              <NavLink to="/user/profile" style={{textdecoration: 'none'}}><DropdownItem className={s.dropdownProfileItem}><ProfileIcon/><span>Profile</span></DropdownItem></NavLink> 
              <NavLink to="/user/Change Password" style={{textdecoration: 'none'}}><DropdownItem className={s.dropdownProfileItem}><TasksIcon/><span>Chnage Password</span></DropdownItem></NavLink>
              <NavItem>
                <NavLink onClick={this.doLogout} to="#">
                  <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img src={logoutIcon} alt="Logout"/><span className="ml-1">Logout</span></button>
                </NavLink>
              </NavItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>
    )
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default Header;

