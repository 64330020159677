// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import Indigo from '../../assets/Icons/indigo.png';

// import library
import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import FareDetailList from "./FareDetailList";
import FareRulesList from "./FareRulesList";
import Grid from '@material-ui/core/Grid';

class FareSummaryRules extends Component {
    

    componentDidMount(){
        
    };

    render(){
        try{
            return(
                // <div className="free-buggage  p-3 py-5">
                    <Grid container className="p-1 py-3">
                        <Grid item xs={12} className="p-0">
                            <h3>Fare Detail</h3>
                            <FareDetailList data={[{Flight: {Destination:this.props.data.Destination, Origin:this.props.data.Origin, Fares: [{FareDetails: [{AirportTax_Amount: 0, Basic_Amount: this.props.data.Fares.FareDetails.Total_Amount, Total_Amount: this.props.data.Fares.FareDetails.Total_Amount}]}]}}]} />
                        </Grid>
                        {/* <Grid item xs={7} className="p-2">
                            <h3>Fare Rules</h3>
                            <FareRulesList />
                        </Grid> */}
                    </Grid>
                // </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default FareSummaryRules;