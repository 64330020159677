// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import testimonial from '../../assets/Icons/testimonial-icon-png-7-Transparent-Images.png';
import coma from '../../assets/Icons/icon-testimonial1.png';
import pl1 from '../../assets/Icons/7d1a3f77eee9f34782c6f88e97a6c888.jpg';
import pl2 from '../../assets/Icons/pexels-photo-220453.jpeg';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Button } from 'react-bootstrap';
import Slider from "react-slick";


const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0
};

class Testimonial extends Component {
    

    componentDidMount(){
        
    };

    render(){
        try{
            let slideData = [];

            this.props.data.data.map(e =>{
                slideData.push(<div className="position-relative w-h mx-5x p-3 box-shadow text-center" style={{cursor:'pointer',border:'1px solid #C8C8C8'}}>
                <img src={testimonial} width="40px" style={{margin: 'auto'}} />
                <br />
                <img src={coma} width="10px" className="float-left mt-2" />
                <br />
                <p className="s-p float-left mb-1 pt-2 pb-3 text-left h-c border-bottom">
                {e.comment}
                </p>
                <br />
                <img src={e.profile.img} className="float-left mt-2 pl" />
                <div className="float-left text-left p-3 pl-3">
                    <p className="sb-p font-bolder mb-0 h-c">{e.profile.name}</p>
                    <p className="sm-p mb-0 h-c">{e.profile.about}</p>
                </div>
                <div className="footer-block">

                </div>
            </div>);
            });

            this.props.data.data.map(e =>{
                slideData.push(<div className="position-relative w-h mx-5x p-3 box-shadow text-center" style={{cursor:'pointer',border:'1px solid #C8C8C8'}}>
                <img src={testimonial} width="40px" style={{margin: 'auto'}} />
                <br />
                <img src={coma} width="10px" className="float-left mt-2" />
                <br />
                <p className="s-p float-left mb-1 pt-2 pb-3 text-left h-c border-bottom">
                {e.comment}
                </p>
                <br />
                <img src={e.profile.img} className="float-left mt-2 pl" />
                <div className="float-left text-left p-3 pl-3">
                    <p className="sb-p font-bolder mb-0 h-c">{e.profile.name}</p>
                    <p className="sm-p mb-0 h-c">{e.profile.about}</p>
                </div>
                <div className="footer-block">

                </div>
            </div>);
            });

            return(
                <div className="position-relative">
                    {/* <Button variant="contained" type="button" className="position-absolute shadow my-success-btn color-w border-0 pd-10 float-right next" style={{backgroundColor:'white'}}>
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </Button>
                    <Button variant="contained" type="button" className="shadow position-absolute my-success-btn color-w border-0 pd-10 prev" style={{backgroundColor:'white'}}>
                        <ArrowBackIosIcon></ArrowBackIosIcon>
                    </Button> */}
                    <Grid container>
                        <Grid item xs={12} className="text-center mb-5 mt-3">
                            <span className="h2 h"><b> TESTIMONIALS </b></span> 
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Slider {...settings} container className="my-3 c testimonial-slider" style={{width:'90%'}}>
                                {slideData}
                            </Slider>
                        </Grid>
                    </Grid>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default Testimonial;