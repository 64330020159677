// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import gNight from '../../assets/Icons/night.png';
import gEvening from '../../assets/Icons/sunrise.png';
import gAfternoon from '../../assets/Icons/sun.png';
import gMorning from '../../assets/Icons/Group_1207.png';


// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';

export default class TimesView extends React.Component {

    setTimeValue = (event) =>{
        let value = event.currentTarget.dataset.value;
        console.log("=== value ===",value);
        this.setState({value:value});
        this.props.onHandel(value);
    }

    render() {
        try{
            if(this.props.data){
                if(this.props.data == "3-9"){
                    return (
                        <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="3-9" className="border p-2 text-center box-shadow a active-card-border">
                                    <img src={gMorning} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3AM - 9AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="9-15" className="border p-2 text-center box-shadow a">
                                    <img src={gAfternoon} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9AM - 3PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="15-21" className="border p-2 text-center box-shadow a">
                                    <img src={gEvening} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3PM - 9PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="21-3" className="border p-2 text-center box-shadow a">
                                    <img src={gNight} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9PM - 3AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="">
                                <hr className="m-0 my-3 p-0" />
                            </Grid>
                        </Grid>
                    );
                }
                else if(this.props.data == "9-15"){
                    return (
                        <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="3-9" className="border p-2 text-center box-shadow a">
                                    <img src={gMorning} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3AM - 9AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="9-15" className="border p-2 text-center box-shadow a active-card-border">
                                    <img src={gAfternoon} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9AM - 3PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="15-21" className="border p-2 text-center box-shadow a">
                                    <img src={gEvening} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3PM - 9PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="21-3" className="border p-2 text-center box-shadow a">
                                    <img src={gNight} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9PM - 3AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="">
                                <hr className="m-0 my-3 p-0" />
                            </Grid>
                        </Grid>
                    );
                }
                else if(this.props.data == "15-21"){
                    return (
                        <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="3-9" className="border p-2 text-center box-shadow a">
                                    <img src={gMorning} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3AM - 9AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="9-15" className="border p-2 text-center box-shadow a">
                                    <img src={gAfternoon} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9AM - 3PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="15-21" className="border p-2 text-center box-shadow a active-card-border">
                                    <img src={gEvening} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3PM - 9PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="21-3" className="border p-2 text-center box-shadow a">
                                    <img src={gNight} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9PM - 3AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="">
                                <hr className="m-0 my-3 p-0" />
                            </Grid>
                        </Grid>
                    );
                }
                else if(this.props.data == "21-3"){
                    return (
                        <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="3-9" className="border p-2 text-center box-shadow a">
                                    <img src={gMorning} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3AM - 9AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="9-15" className="border p-2 text-center box-shadow a">
                                    <img src={gAfternoon} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9AM - 3PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="15-21" className="border p-2 text-center box-shadow a">
                                    <img src={gEvening} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3PM - 9PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="21-3" className="border p-2 text-center box-shadow a active-card-border">
                                    <img src={gNight} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9PM - 3AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="">
                                <hr className="m-0 my-3 p-0" />
                            </Grid>
                        </Grid>
                    );
                }
                else{
                    return (
                        <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="3-9" className="border p-2 text-center box-shadow a">
                                    <img src={gMorning} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3AM - 9AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="9-15" className="border p-2 text-center box-shadow a">
                                    <img src={gAfternoon} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9AM - 3PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="15-21" className="border p-2 text-center box-shadow a">
                                    <img src={gEvening} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3PM - 9PM</p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className="px-2">
                                <div onClick={this.setTimeValue} data-value="21-3" className="border p-2 text-center box-shadow a">
                                    <img src={gNight} width='20px' />
                                    <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9PM - 3AM</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="">
                                <hr className="m-0 my-3 p-0" />
                            </Grid>
                        </Grid>
                    );
                }
            }
            else{
                return (
                    <Grid container className="mt-2" style={{borderRadius:'10px'}}>
                        <Grid item xs={3} className="px-2">
                            <div onClick={this.setTimeValue} data-value="3-9" className="border p-2 text-center box-shadow a">
                                <img src={gMorning} width='20px' />
                                <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3AM - 9AM</p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="px-2">
                            <div onClick={this.setTimeValue} data-value="9-15" className="border p-2 text-center box-shadow a">
                                <img src={gAfternoon} width='20px' />
                                <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9AM - 3PM</p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="px-2">
                            <div onClick={this.setTimeValue} data-value="15-21" className="border p-2 text-center box-shadow a">
                                <img src={gEvening} width='20px' />
                                <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>3PM - 9PM</p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="px-2">
                            <div onClick={this.setTimeValue} data-value="21-3" className="border p-2 text-center box-shadow a">
                                <img src={gNight} width='20px' />
                                <p className="p-0 m-0 mt-1" style={{fontSize:'10px'}}>9PM - 3AM</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} className="">
                            <hr className="m-0 my-3 p-0" />
                        </Grid>
                    </Grid>
                );
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}