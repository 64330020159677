// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import Indigo from '../../assets/Icons/indigo.png';
import Path2481 from '../../assets/Icons/Path-2481.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { NavLink } from 'react-router-dom';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Image from 'react-bootstrap/Image';
import CustomFunction from "../library/CustomFunction";

const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const dayNameShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


const DayHoursMins = (prop) =>{
    try{
        // console.log(prop);

        // let DateTimeobj = prop.data.Departure_DateTime.split(' ');
        // let dateObj = DateTimeobj[1].split('/');
        // let timeObj = DateTimeobj[1].split(':');
        // let Departure_DateTime = dateObj[2]+'/'+dateObj[0]+'/'+dateObj[1]+' '+timeObj[0]+':'+timeObj[1]+':00';

        // DateTimeobj = prop.data.Arrival_DateTime.split(' ');
        // dateObj = DateTimeobj[1].split('/');
        // timeObj = DateTimeobj[1].split(':');
        // let Arrival_DateTime = dateObj[2]+'/'+dateObj[0]+'/'+dateObj[1]+' '+timeObj[0]+':'+timeObj[1]+':00';


        // console.log("=== timeDiffCalc ===",timeDiffCalc(new Date(prop.data.Departure_DateTime), new Date(prop.data.Arrival_DateTime)));

        let dateTimeData = timeDiffCalc(new Date(prop.data.Departure_DateTime), new Date(prop.data.Arrival_DateTime));

        if(dateTimeData.DurD == 0){
            return(<>{dateTimeData.DurH}Hrs {dateTimeData.DurM}Mins </>);
        }
        else{
            return(<>{dateTimeData.DurD}Day {dateTimeData.DurH}Hrs {dateTimeData.DurM}Mins </>);
        }
    } catch (error) {
        throw new Error(error);
    }
}


const timeDiffCalc = (dateFuture, dateNow) =>{
    try{
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        console.log('calculated days', days);

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        console.log('calculated hours', hours);

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        console.log('minutes', minutes);

        let difference = '';
        if (days > 0) {
        difference += (days === 1) ? `${days} day, ` : `${days} days, `;
        }

        difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

        difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`; 

        return {DurD:days, DurH:hours, DurM: minutes,};
    } catch (error) {
        throw new Error(error);
    }
}




function FlightData(prop){
    try{
        //console.log(props);
        return (
            <div className={prop.classes}>
                <h2 className="p-0 m-0">{prop.data.time}</h2>
                <h4 className="p-0 m-0">{prop.data.date}</h4>
                <p className="p-0 m-0 sm-p">{prop.data.airport.toUpperCase()}</p>
                {/* <p className="p-0 m-0 sm-p">NETAJI SUBHAS CHANDRA |KOLKATA |IN |India [CCU]</p> */}
                <p className="p-0 m-0 sm-p">TERMINAL {prop.data.terminal}</p>
            </div> 
        );
    } catch (error) {
        throw new Error(error);
    }
}

function FlightCard(prop){
    try{
        let originCity = prop.data.Origin_City;
        let originTerminal = prop.data.Origin_Terminal;
        let destinationCity = prop.data.Destination_City;
        let destinationTerminal = prop.data.Destination_Terminal;
        let dDate = prop.data.Departure_DateTime.split(' ')[0];
        let dTime = prop.data.Departure_DateTime.split(' ')[1];

        let aDate = prop.data.Arrival_DateTime.split(' ')[0];
        let aTime = prop.data.Arrival_DateTime.split(' ')[1];

        let departureDate = dDate.split('/');
        let arrivalDate = aDate.split('/');
        
        let departureD = new Date(departureDate[2]+'-'+departureDate[0]+'-'+departureDate[1]);
        let arrivalD = new Date(arrivalDate[2]+'-'+arrivalDate[0]+'-'+arrivalDate[1]);

        let departureDateStr = dayNameShort[departureD.getDay()].toUpperCase()+', '+departureD.getDate()+' '+monthNames[departureD.getMonth()]+' '+departureD.getFullYear();
        let arrivalDateStr = dayNameShort[arrivalD.getDay()].toUpperCase()+', '+arrivalD.getDate()+' '+monthNames[arrivalD.getMonth()]+' '+arrivalD.getFullYear();


        return(
            <Card className="flight-card box-shadow">
                <CardContent className="xs-p-1">
                    <Grid container>
                        <Grid item xs sm={4} md={4} lg={4} xl={4}>
                            <FlightData data={{time: dTime, date: departureDateStr, airport: originCity, terminal: originTerminal}} classes="text-right" />
                        </Grid>
                        <Grid item xs={5} sm={4} md={4} lg={4} xl={4} className="text-center px-5 py-3 xs-px-2"> 
                        <p className="pb-2" style={{fontSize: '11px'}}><DayHoursMins data={prop.data} /> </p> 
                        <div style={{borderTop:'2px dashed gray',width:'',margin:'auto'}} className="position-relative">
                            <FiberManualRecordIcon className="position-absolute gradient2-color" style={{top:'-12px',left:'40%'}}></FiberManualRecordIcon>
                            <img src={Path2481} width="25px" className="float-left position-absolute" style={{right:0,top:'-13px'}} />
                        </div>
                        </Grid>
                        <Grid item xs sm={4} md={4} lg={4} xl={4} className="">  
                            <FlightData data={{time: aTime, date: arrivalDateStr, airport: destinationCity, terminal: destinationTerminal}} classes="text-left" />
                        </Grid>
                    </Grid>
                </CardContent>
                {/* <CardActions></CardActions> */}
            </Card>
        );
    } catch (error) {
		throw new Error(error);
	}
}

function getDifferenceInDays(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  const noOfDays = (diffInMs / (1000 * 60 * 60 * 24)).toString().split('.')[0];
  return noOfDays;
}

function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return (diffInMs / (1000 * 60 * 60)).toString().split('.')[0];
}

function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60) % 60;
}

function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}

class FlightMetaData extends Component {
    
    componentDidMount(){
        
    };

    render(){
        try{
            let item = [];
            const images = new CustomFunction().getImage(this.props.data.Airline_Code+'.gif');
            let className = 'flight-meta-data '+this.props.classes;
            if(this.props.index < this.props.total){
                const date1 = new Date(this.props.data.Arrival_DateTime);
                const date2 = new Date(this.props.nextFlightDepartureDateTime);
                console.log(getDifferenceInDays(date1, date2));
                console.log(getDifferenceInHours(date1, date2));
                console.log(getDifferenceInMinutes(date1, date2));
                console.log(getDifferenceInSeconds(date1, date2));
                item.push(
                    <Grid container className="py-3">
                        <Grid item xs={12} className="px-3">
                            <p style={{position:'relative'}} className="ps-4"><WatchLaterIcon style={{position:'absolute', left:0}} className="svg-icon"></WatchLaterIcon> <b className="ps-2">Change Plane At {this.props.data.Destination_City} |IN |India ({this.props.data.Destination}), Layover Time: {getDifferenceInHours(date1, date2)}Hr. {getDifferenceInMinutes(date1, date2)}Min</b></p>
                        </Grid>
                    </Grid>
                );
            }
            return(
                <div className={className}>
                    <Grid container className="py-3 bg-light-gray">
                        <Grid item xs={6} className="px-3">
                            <img src={images.default} width="65px" height="50px" className="float-left airlines-logo" />
                            <div className="float-left ps-2 py-0 m-0">
                                <p className="p-0 m-0 airline-name"><b>{this.props.data.Airline_Name}</b></p>
                                <p className="gray-color f-w-600 pt-2 xs-pt-0">{this.props.data.Airline_Code}-{this.props.data.Flight_Number}</p>
                            </div>
                        </Grid>
                        <Grid item xs={6} className="px-3">
                            <div className="float-right">
                                <p className="m-0 mb-2 p-0 xs-mb-0"><b>Travel Class</b></p>
                                <p className="gray-color m-0 p-0">{this.props.travelClass}</p>
                            </div>
                            {/* <div className="float-right me-3">
                                <p className="m-0 mb-2 p-0"><b>Aircraft</b></p>
                                <p className="gray-color m-0 p-0">{this.props.data.Airline_Name} {this.props.data.Flight_Number}</p>
                            </div> */}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs className="p-2 xs-p-0"></Grid>
                        <Grid item xs={12} sm={11} md={11} lg={11} xl={11} className="p-4 xs-p-1">
                            <FlightCard data={this.props.data} />
                        </Grid>
                        <Grid item xs className="p-2 xs-p-0"></Grid>
                    </Grid>
                    {item}
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default FlightMetaData;