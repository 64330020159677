import React from 'react'
import { Input,Button } from 'semantic-ui-react'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CustomFunction from "../library/CustomFunction";



const CustomDropdown = (prop) => {
  try{
    //countryOption.push(<TextDiv heading={<Heading state="Uttar Pradesh" country="India" />} desc="ahjshja sjahsj hjas jas hja" shortForm="UTP" />);
    const [dropdownData, setdropdownData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [cValue, setCValue] = React.useState('');

    let visible = '';
    if(!prop.visible){
      visible = 'none';
    }
    // console.log(prop.datas);
    // setDropdownData(prop);
    //console.log('----- dropdownData ---- ',dropdownData);
    let classes = 'box-shadow c-dropdown my-search-dropdown '+prop.classes;

    const chooseLocation = (event, newValue) =>{
      console.log('--- Choose prop ---',prop);
      console.log('--- Choose newValue ---',newValue);
      let selectedAirportData = prop.datas.filter(f => (f.id == newValue.id))
      prop.selectedAirport(selectedAirportData[0]);
      setdropdownData([]);
      setCValue('');
    }


    const TextDiv = (prop) =>{
      try{
        if(prop.selectedId != prop.id){
          if(prop.cId == prop.id){
            return(
              <Button className="my-btn-a" onClick={chooseLocation} id={prop.id}>
                <Grid container className="w-100 border-bottom p-2 c-dropdown-list-item selected" style={{cursor:'pointer'}}>
                  <Grid item xs={10}>
                    <div>
                      <p className="p-0 m-0"><b>{prop.heading}</b></p>
                      <p className="sm-p p-0 m-0">{prop.desc}</p>
                    </div>
                  </Grid>
                  <Grid item xs={2} className="text-right">
                    <p className="p-0 m-0"><b>{prop.shortForm}</b></p>
                  </Grid>
                </Grid>
              </Button>
            )
          }
          else{
            return(
              <Button className="my-btn-a" onClick={chooseLocation} id={prop.id}>
                <Grid container className="w-100 border-bottom p-2 c-dropdown-list-item" style={{cursor:'pointer'}}>
                  <Grid item xs={10}>
                    <div>
                      <p className="p-0 m-0"><b>{prop.heading}</b></p>
                      <p className="sm-p p-0 m-0">{prop.desc}</p>
                    </div>
                  </Grid>
                  <Grid item xs={2} className="text-right">
                    <p className="p-0 m-0"><b>{prop.shortForm}</b></p>
                  </Grid>
                </Grid>
              </Button>
            )
          }
        }
        else{
          return(<></>)
        }
      } catch (error) {
        throw new Error(error);
      }
    }
    
    const Heading = (prop) => (
      <span>{prop.state}, {prop.country}</span>
    )
    
    const inputCondition = (prop) => {
      if(prop.isLoading){
        return(<Input loading placeholder={prop.placeholder} />)
      }
      else{
        return(<Input placeholder={prop.placeholder} />)
      }
    }
    
    const setDropdownData = (datas, filterStr, cId) =>{
      try{
        filterStr = filterStr.toLowerCase();
        
        let countryOption = [];
        let result = [];


        let result3 = datas.filter(f => f.ooro_AirportName.toLowerCase().startsWith(filterStr));
        let result4 = datas.filter(f => f.ooro_AirportName.toLowerCase().includes(filterStr));

        let result2 = datas.filter(f => f.ooro_AirportCode.toLowerCase().startsWith(filterStr));

        // console.log("=== r3 ===", result3);
          
          
        result = [...result3 ,...result2, ...result4];


        
        

        result = new CustomFunction().filterUniqueData('ooro_AirportCode', result);

        console.log(result)
        result.forEach((element, i) => {
          //let obj = {key: element.id, value: element.id, text: (<TextDiv heading={<Heading state={element.ooro_AirportName} country={element.ooro_CountryName} />} desc={element.ooro_AirportName} shortForm={element.ooro_AirportCode} />)}
          //console.log(obj);
          countryOption.push(<TextDiv selectedId={prop.selectedId} cId={cId} id={element.id} heading={<Heading state={element.ooro_AirportName} country={element.ooro_CountryName} />} desc={element.ooro_AirportName} shortForm={element.ooro_AirportCode} />);
        });
        return countryOption;
      } catch (error) {
        throw new Error(error);
      }
    }
    

    const handleChange = (event, newValue) => {
      
      console.log(newValue);
      setCValue(newValue.value);
      if(newValue.value.length >=2 ){
        console.log(newValue.value);
        let res = setDropdownData(newValue.datas, newValue.value, newValue.cId)

        if(res.length == 0){
          let msg = [];
          msg.push(<p className="px-2 py-3">No results found...</p>);
          res = msg;
        }

        console.log(res);
        setdropdownData(res);
      }
      else{
        let ress = setDropdownData(newValue.T10India, newValue.value, prop.cId)
        console.log(ress);
        setdropdownData(ress);
      }
      console.log('--- dropdownData ---',dropdownData);
      //setdropdownData(newValue);
    };

    const handleClick = (event, newValue) => {
      console.log(event.target.attributes.value.value);
      //setCValue(newValue.value);
      if(event.target.attributes.value.value == ''){
        let countryOption = [];
        prop.T10India.forEach((element, i) => {
          //let obj = {key: element.id, value: element.id, text: (<TextDiv heading={<Heading state={element.ooro_AirportName} country={element.ooro_CountryName} />} desc={element.ooro_AirportName} shortForm={element.ooro_AirportCode} />)}
          //console.log(obj);
          countryOption.push(<TextDiv selectedId={prop.selectedId} cId={prop.cId} id={element.id} heading={<Heading state={element.ooro_AirportName} country={element.ooro_CountryName} />} desc={element.ooro_AirportName} shortForm={element.ooro_AirportCode} />);
        });
        setdropdownData(countryOption);
        console.log('--- On click dropdownData ---',dropdownData);
        //setdropdownData(newValue);
      }
    };

    const Message = (prop) =>{
      //console.log(dropdownData.length);
      if(!dropdownData.length){
        return(
          <p className="py-3 px-2">
            Please Enter atleast 2 letter of airport.
          </p>
        )
      }else{
        return(<></>)
      }
    };

    React.useEffect(() =>{
      if(dropdownData.length == 0){
        let itms = [];
        prop.T10India.forEach((element, i) => {
          //let obj = {key: element.id, value: element.id, text: (<TextDiv heading={<Heading state={element.ooro_AirportName} country={element.ooro_CountryName} />} desc={element.ooro_AirportName} shortForm={element.ooro_AirportCode} />)}
          //console.log(obj);
          itms.push(<TextDiv selectedId={prop.selectedId} cId={prop.cId} id={element.id} heading={<Heading state={element.ooro_AirportName} country={element.ooro_CountryName} />} desc={element.ooro_AirportName} shortForm={element.ooro_AirportCode} />);
        });
        setdropdownData(itms)
      }
    })
    
    return(
      <Card id={prop.id} className={classes} style={{display:visible,width:'260px',backgroundColor:'white',zIndex:'999'}}>
        <Grid container>
          <Grid item xs={12} className="p-0">
            <Input placeholder='Search...' onChange={handleChange} value={cValue} cId={prop.cId} className="my-sUi-input-border-0" style={{width:'100%',borderRadius:'0px'}} datas={prop.datas} T10India={prop.T10India} />
          </Grid>
          <Grid item xs={12} className="c-dropdown-list" style={{maxHeight:'260px',overflow:'auto'}}>
            {dropdownData}
            <Message />
          </Grid>
        </Grid>
      </Card>
    );
  } catch (error) {
    throw new Error(error);
  }
}

export default CustomDropdown