// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';
import Path2481 from '../../assets/Icons/Path-2481.png';
import Group1206 from '../../assets/Icons/Group_1206.png';
import Path2482 from '../../assets/Icons/Path2482.png';
import Indigo from '../../assets/Icons/indigo.png';
import uk from "../../assets/AL1/UK.gif";
import rupeeIcon from '../../assets/Icons/icons8-rupee-24.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { NavLink } from 'react-router-dom';
import FlightDetails from "./FlightDetails";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CustomSpinnner from "./CustomSpinner";
import CustomFunction from "../library/CustomFunction";


const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const float2int = (value) => {
    return value | 0;
}

const getNoOfDays = (d1,d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    
    // To calculate the time difference of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();
    
    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}

const DirectFlight = (prop) => {
    try{
        //console.log(prop);
        if(prop.data.stop == 0){
            return <p className="pt-3 " style={{fontSize: '9px', height:'45px'}}>Non Stop </p>
        }
        else {
            //let via = '';
            let via = prop.data.segments[0].Destination_City.split('(')[0];
            for (let index = 1; index < prop.data.segments.length-1; index++) {
                via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
                
            }
            return <p className="pt-3" style={{fontSize: '9px', height:'45px'}}>{prop.data.stop} Stop, via {via} </p>
        }
    } catch (error) {
        throw new Error(error);
    }
}

const DirectFlightForMobile = (prop) => {
    try{
        //console.log(prop);
        if(prop.data.stop == 0){
            return <p className="pt-0 " style={{fontSize: '9px'}}>Non Stop </p>
        }
        else {
            //let via = '';
            // , via {via}
            let via = prop.data.segments[0].Destination_City.split('(')[0];
            for (let index = 1; index < prop.data.segments.length-1; index++) {
                via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
                
            }
            return <p className="pt-0" style={{fontSize: '9px'}}>{prop.data.stop} Stop </p>
        }
    } catch (error) {
        throw new Error(error);
    }
}

const DayHoursMins = (prop) =>{
    try{
        //console.log(prop);
        if(prop.data.DurD == 0){
            return(<>{prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
        }
        else{
            return(<>{prop.data.DurD}Day {prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
        }
    } catch (error) {
        throw new Error(error);
    }
}

class RoundSearchList extends Component {

    // constructor(props){
    //     super(props);
    //     this.state = {isToggleOn: true};
    // }
    
    state = {
        hide: true,
        spinner: false
    }

    toggleVisibility = () => this.setState({ hide: !this.state.hide });

    toggleSpinner = () => {
        this.setState({ spinner: true });
        setTimeout(() => {
            this.setState({ spinner: false });
        }, 1000);
    }

    
    className = "search-least xs-mb-1 "+this.props.classes;

    render() {
        try{
            //console.log(this.props.data);
            let dep = this.props.data.Segments[0].Departure_DateTime.split(' ');
            let ariv = this.props.data.Segments[this.props.data.Segments.length - 1].Arrival_DateTime.split(' ');

            let next = getNoOfDays(dep[0], ariv[0]);
            let nextText = '';
            if(next == 1){
                nextText = "NEXT DAY";
            }
            if(next > 1){
                //console.log(ariv[0]);
                let dd = new Date(ariv[0]);
                let dd1 = dd.getDay();
                //console.log(dd);
                //console.log(dd1);
                nextText = "On "+dayName[dd1];
            }

            
            const images = new CustomFunction().getImage(this.props.data.Airline_Code+'.gif');
            //images.default
            //console.log(images.default);
            return(
            <div className={this.className}>
                <Modal size="sm" dialogClassName="modal-90w" className="p-0" centered show={this.state.spinner}
                backdrop="static" keyboard={false}
                >
                    <Modal.Body className="p-0" 
                    // onClick={this.toggleDepart}
                    >
                        <card className="bg-white">
                            
                            <CustomSpinnner />
                        </card>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" dialogClassName="modal-90w" centered show={!this.state.hide} onHide={this.toggleVisibility} 
                // backdrop="static" keyboard={false}
                >
                    <Modal.Body className="p-0" 
                    // onClick={this.toggleVisibility}
                    >
                        <card className="bg-white">
                            <FlightDetails data={this.props.data} hidden={this.state.hide} travelClass={this.props.searchData.travellersData.seatClassesData.name} />
                        </card>
                    </Modal.Body>
                </Modal>
                <Card className="search-card my-card xs-pt-0 xs-ps-0 xs-pe-0 xs-pb-0">
                    <Grid container className="mt-2 pb-0 pt-2 px-3 xs-pt-0 xs-ps-1 xs-pe-1 xs-pb-0 xs-mt-0 md-px-1">
                        <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                            <FormControlLabel className="float-left me-0" value={this.props.data.Flight_Id} control={<Radio />} onClick={() => this.toggleSpinner()} label="" />
                            <span className="float-left py-2 m-0 sm-device md-device lg-device xl-device"><b className="py-0 px-2">{this.props.data.AlName.toUpperCase()}</b>
                                <span className="gray-color f-w-600 py-0 px-2" style={{borderLeft: '2px solid #9e9e9e'}}>{this.props.data.Segments[0].Airline_Code}-{this.props.data.Segments[0].Flight_Number}</span>
                            </span>
                            <span className="float-right py-2 m-0 xs-device" style={{fontSize: '8px'}}><b className="py-0 px-2">{this.props.data.AlName.toUpperCase()}</b>
                                <span className="gray-color f-w-600 py-0 px-2" style={{borderLeft: '2px solid #9e9e9e'}}>{this.props.data.Segments[0].Airline_Code}-{this.props.data.Segments[0].Flight_Number}</span>
                            </span>
                            
                        </Grid>
                        <Grid item xs={5} className="sm-device-block md-device-block lg-device-block xl-device-block">
                            <span className="float-right">
                                <img src={Group1206} width="18px" />
                                <span className="text-danger pt-2" style={{fontSize:'9px'}}><b>{this.props.data.Fares.Seats_Available} LEFT</b>
                                </span>
                            </span>
                            <span className="float-right me-2" style={{right:10,bottom:5}}><img src={Path2482} width="17px" /></span>
                        </Grid>
                    </Grid>
                    <CardContent className="pt-0 pb-1 sm-device-block md-device-block lg-device-block xl-device-block md-px-1">
                        
                        <Grid container className="m-0 p-0 pb-2" style={{borderBottom:'0px dashed #adacac69'}}>
                            <Grid item xs={1} className="py-1">
                                <img src={images.default} width="45px" className="float-left air-plane-logo" />
                                
                            </Grid>
                            <Grid item xs className="py-1 position-relative text-center">
                                <h2 className="h3 p-0 m-0" style={{fontSize:'15px'}}>{dep[1]}</h2>
                                <p><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.OrgName.split('(')[0]}</b></p>
                            </Grid>
                            <Grid item xs className="px-0 text-center">
                                <p className="pb-2" style={{fontSize: '9px'}}> <DayHoursMins data={this.props.data} /> </p>
                                <div style={{borderTop:'2px dashed gray',width:'100px',margin:'auto'}} className="position-relative">
                                    <FiberManualRecordIcon className="position-absolute gradient2-color" style={{top:'-12px',left:'20px'}}></FiberManualRecordIcon>
                                    <img src={Path2481} width="25px" className="float-left position-absolute" style={{right:0,top:'-13px'}} />
                                </div>
                                <DirectFlight data={{segments:this.props.data.Segments,stop:this.props.data.stops}} />
                            </Grid>
                            <Grid item xs className="py-1 text-center">
                                <h2 className="h3 p-0 m-0" style={{fontSize:'15px'}}>{ariv[1]}</h2>
                                <p><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.DestName.split('(')[0]}</b></p>
                                <p className="text-danger p-0 m-n1" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p>
                            </Grid>
                            
                            <Grid item xs className="px-1 py-0 text-right">
                                <h1 className="h3" style={{fontSize:'18px',fontWeight: 'bolder', fontFamily: 'sans-serif'}}><img src={rupeeIcon} className="rupee-icon" width="20" /> {this.props.data.Fares.FareDetails.Total_Amount}</h1>
                                <p className="mt-2" style={{cursor:'pointer',fontSize:'11px'}} onClick={this.toggleVisibility}>+ Flight Details</p>
                            </Grid>
                            <Grid item xs={12} className="px-3 text-right">
                                {/* <NavLink to="/review-flight">
                                    <Button className="bg-gradient p-2" style={{width:'135px',color:'white',border:'0px solid black'}}>
                                        Book Now
                                    </Button>
                                </NavLink> */}
                                {/* <p className="mt-2" style={{cursor:'pointer',fontSize:'15px'}} onClick={this.toggleVisibility}>+ Flight Details</p> */}
                            </Grid>
                        </Grid>
                        
                    </CardContent>
                    <CardContent className="pt-0 pb-1 xs-device-block xs-pt-0 xs-ps-1 xs-pe-1 xs-pb-0">
                        
                        <Grid container className="mt-0 py-0 pb-0" style={{borderBottom:'0px dashed #adacac69'}}>
                            <Grid item xs={2} className="py-1">
                                <img src={images.default} width="25px" className="float-left" />
                            </Grid>
                            <Grid item xs={5} className="py-1 position-relative text-center">
                                <h2 className="h3 p-0 m-0" style={{fontSize:'12px'}}>{dep[1]}</h2>
                                <p className="m-0 p-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.Origin}</b></p>
                                <p className="pb-0" style={{fontSize: '9px'}}> <DayHoursMins data={this.props.data} /> </p>
                                
                            </Grid>
                            <Grid item xs={5} className="py-1 text-center">
                                <h2 className="h3 p-0 m-0" style={{fontSize:'12px'}}>{ariv[1]}</h2>
                                <p className="m-0 p-0"><b className="gray-color p-0 m-0" style={{fontSize:'10px'}}>{this.props.data.Destination}</b></p>
                                <DirectFlightForMobile data={{segments:this.props.data.Segments,stop:this.props.data.stops}} />
                                
                                {/* <p className="text-danger p-0 m-n1" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p> */}
                            </Grid>
                            
                            <Grid item xs={12} className="p-0 px-1 text-end pb-1">
                                <h1 className="h3" style={{fontSize:'18px',fontWeight: 'bolder', fontFamily: 'sans-serif'}}><img src={rupeeIcon} className="rupee-icon" width="15" /> {this.props.data.Fares.FareDetails.Total_Amount}</h1>
                            </Grid>
                        </Grid>
                        
                    </CardContent>
                    {/* <CardActions className="p-0 position-relative">
                        <FlightDetails hidden={this.state.hide} />
                    </CardActions> */}
                    {this.props.data?.Fares?.Warning && this.props.data?.Fares?.Warning != "" ? (<><div className="p-2 m-0 text-left" style={{borderTop:'2px dashed #adacac69', color: '#2196f3', fontSize: 11}} dangerouslySetInnerHTML={{__html:this.props.data?.Fares?.Warning}} /></>) : (<></>) }
                </Card>
            </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default RoundSearchList;