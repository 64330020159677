// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import tsImg1 from '../../assets/Icons/b777ef87bb1759e70c21d83c2dd9a830.jpg';
import tsImg2 from '../../assets/Icons/download.jpeg';
import tsImg3 from '../../assets/Icons/plgespc_statue-of-unity_625x300_31_October_18.webp';
import tsImg4 from '../../assets/Icons/the-tall-trees-munnar.jpg';

// import library
import React, {Component} from "react";
import Slider from "react-slick";
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button } from 'react-bootstrap';
import path2481 from '../../assets/Icons/Path-2481.png';
import rupeeIcon from '../../assets/Icons/icons8-rupee-96.png';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { render } from "@testing-library/react";
import zIndex from "@material-ui/core/styles/zIndex";
import Services from "../services/services";
import displayMessages from "../displayMessages/displayMessages";
// import { allAirports } from "../../assets/allAirports";
import { AllAirportsContext } from '../../core/provider/AllAirportsProvider';
import { NavLink } from "react-router-dom";
import moment from "moment";

const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 2,
    initialSlide: 0
};

const tabSettings = {
    infinite: true,
    slidesToShow: 2,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0
};

const mobileSettings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0
};

class PopularDomesticFlightRout extends Component {
    static contextType = AllAirportsContext;
    
    state = {
        popularDomesticFlights : null,
        selectedCityCode : '',
        allAirports: [],
        loading: true,
        error: null,
        objKeys : [],
        keysData: []
    }

    componentDidMount(){
        try{
            const { allAirports } = this.context;

            new Services().getPopularDomesticFlights().then(res => res.json()).then((res) =>{
                console.log("==== getPopularDomesticFlights ====",res);
                
                if(res.success && res.success === true){
                    let objKeys = Object.keys(res.message);
                    let selectedCityCode = objKeys[0];

                    let keysData = [];

                    objKeys.map(e => {
                        let allAirportName = allAirports?.find(an => an.ooro_AirportCode == e);
                        keysData.push({code: e, name: allAirportName?.ooro_AirportName, data: allAirportName});
                    });

                    this.setState({keysData: keysData, allAirports: allAirports, loading: false, error: null, popularDomesticFlights : res.message, objKeys: objKeys, selectedCityCode: selectedCityCode});
                }
                else{
                    console.log("== error ==", res.message);
                    this.setState({
                        loading: false,
                        error: res.message
                    });
                    new displayMessages().errorMsg({error:{message: `${res?.message} at getPopularDomesticFlights()`, status: res?.status, type: res?.type}});
                }
                
            }, (error) => {
                console.log("== error ==", error);
                this.setState({
                    loading: false,
                    error: error
                });
                // new displayMessages().errorMsg({error:error});
            });

            setTimeout(() => {
                //$('.pdfr > .pdfr-slider > .slick-list > .slick-track').css('transform','translate3d(-1045px, 0px, 0px)');
            }, 100);
        } catch (error) {
            throw new Error(error);
        }
    };

    selectAirport = (code) =>{
        this.setState({selectedCityCode: code});
    }

    render(){
        try{
            // console.log(allAirports);
            console.log("=== state ====",this.state);
            if(this.state.loading){
                return (<></>);
            }
            else if(this.state.error){
                return (<><p></p></>);
            }
            else{

                let li = [];

                this.state.keysData.map(e =>{
                    if(e.code == this.state.selectedCityCode){
                        li.push(<li className="active">{e.name}</li>); 
                    }
                    else{
                        li.push(<li onClick={()=> this.selectAirport(e.code)}>{e.name}</li>);
                    }
                });

                let cData = [];

                let currentData = this.state.popularDomesticFlights[this.state.selectedCityCode];

                currentData.map(e =>{
                    let fromAllAirportName = this.state?.allAirports?.find(an => an.ooro_AirportCode == e.dis_from);
                    let toAllAirportName = this.state?.allAirports?.find(an => an.ooro_AirportCode == e.dis_to);
                    let date = new Date(e.fdt);
                    let returnDate = new Date();
                    returnDate.setDate(date.getDate() + 1);
                    let dateStr = moment(date).format('yyyy-MM-DD');
                    let dateStr2 = moment(date).format('DD MMM, yyyy');
                    let returnDateStr = moment(returnDate).format('yyyy-MM-DD');
                    cData.push(
                        <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                            <p className="border-gradient"></p>
                            <CardContent className="px-4 py-4 pb-1">
                            <div className="py-2 d-flex">
                                <div>
                                    <p className="h5 mb-0"><b>{fromAllAirportName?.ooro_AirportName}</b></p>
                                </div>
                                <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                    <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                    <p className="sm-p h">{dateStr2}</p>
                                </div>
                                <div style={{}}>
                                    <p className="h5 mb-0"><b>{toAllAirportName?.ooro_AirportName}</b></p>
                                </div>
                            </div>
                            <p className="p h pt-0 px-0 p-0"><b className="h">Starting From</b></p>
                            </CardContent>
                            <CardActions className="p-0">
                            <Grid container>
                                <Grid item xs={6} className="p-0">
                                    <div className="bg-gradient py-2" style={{borderTopRightRadius:'30px'}}>
                                        <p className="h3 h w-color px-4">
                                            <img src={rupeeIcon} className="rupee-icon" width="30" /> {e.price}
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={1} className="p-0"></Grid>
                                <Grid item xs={5} className="p-0 px-2">
                                    <NavLink to={`/search/${e.dis_from}/${e.dis_to}/${dateStr}/${returnDateStr}/1/0/0/0/0/0`} style={{color: 'white', textDecoration: 'unset'}}>
                                        <div className="bg-gradient py-2" style={{cursor: 'pointer'}}>  
                                        <p className="h3 h w-color px-4" style={{fontSize: '14px'}}>
                                            Book Now
                                        </p>
                                        </div>
                                    </NavLink>
                                </Grid>
                            </Grid>
                            </CardActions>
                        </Card>
                    );
                });

                return(
                    <div>
                        <Grid container className="pdfr">
                            <Grid item xs className="px-4 xs-px-2 mx-2" style={{zIndex: 1}}>
                                <Card className="my-card border-radius p-3 pb-5 xs-p-0 xs-pb-5">
                                    <CardContent>
                                    <Grid container>
                                        {/* <Grid item xs={12} sm={2} md={2} lg={1} xl={1} className="xs-device-block mb-3">
                                            <Button variant="contained" type="button" className="shadow my-success-btn color-w bg-gradient border-0 pd-10 float-right next">
                                                <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                            </Button>
                                            <Button variant="contained" type="button" className="shadow my-success-btn disabled color-w bg-gradient border-0 pd-10 prev">
                                                <ArrowBackIosIcon></ArrowBackIosIcon>
                                            </Button>
                                        </Grid> */}
                                        <Grid item xs={9} sm={10} md={10} lg={11} xl={11}>
                                            <h2 className="h">Popular Domestic flight routes</h2>
                                        </Grid>
                                        <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                                            {/* <Button variant="contained" type="button" className="shadow my-success-btn color-w bg-gradient border-0 pd-10 float-right next">
                                                <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                            </Button>
                                            <Button variant="contained" type="button" className="shadow my-success-btn disabled color-w bg-gradient border-0 pd-10 prev">
                                                <ArrowBackIosIcon></ArrowBackIosIcon>
                                            </Button> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs>
                                            <div className="w-100 md-device lg-device xl-device">
                                                <span className="h4 h"><b>From- </b></span> 
                                                <ul className="f-city">
                                                    {li}
                                                </ul>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardContent>
                                    <CardActions></CardActions>
                                </Card>
                            </Grid>
                            {/* <Grid item xs></Grid> */}
                        </Grid>
                        <div className="pdfr px-5 xs-px-3 lg-device-block xl-device-block" style={{height:'200px',overflow:'hidden'}}>
                            <Slider {...settings} className="px-2 mt-3 pdfr-slider" style={{zIndex: 2}}>
                                {cData}
                            </Slider>	
                        </div>
                        <div className="pdfr px-5 xs-px-3 sm-device-block md-device-block" style={{height:'200px',overflow:'hidden'}}>
                            <Slider {...tabSettings} className="px-2 mt-3 pdfr-slider">
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                            </Slider>	
                        </div>
                        <div className="pdfr px-5 xs-px-3 xs-device-block xs-pe-20-px" style={{height:'200px',overflow:'hidden'}}>
                            <Slider {...mobileSettings} className="px-2 mt-3 pdfr-slider">
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                                <Card className="my-card route-card position-relative" style={{marginRight:'40px',float:'left'}}>
                                    <p className="border-gradient"></p>
                                    <CardContent className="px-4 py-4">
                                    <div className="py-2 d-flex">
                                        
                                        <div>
                                            <p className="h5 mb-0"><b>Delhi</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                        <div className="mx-3 mt-3 text-center position-relative" style={{borderTop:'2px dashed #000000',width:'40%'}}>
                                            <img src={path2481} width="30" style={{marginTop:'-15px', marginLeft:'35px'}} />
                                        </div>
                                        <div style={{}}>
                                            <p className="h5 mb-0"><b>Bangaluru</b></p>
                                            <p className="sm-p h">Thu, 1 Jan</p>
                                        </div>
                                    </div>
                                    </CardContent>
                                    <CardActions className="p-0">
                                    <Grid container>
                                        <Grid item xs className="px-2">
                                            <p className="p h pt-3 px-3"><b className="h">Starting From</b></p>
                                        </Grid>
                                        <Grid item xs className="p-0">
                                            <div className="bg-gradient py-2" style={{borderTopLeftRadius:'30px'}}>
                                            <p className="h3 h w-color px-4">
                                                <img src={rupeeIcon} className="rupee-icon" width="30" /> 3,166
                                            </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </CardActions>
                                </Card>
                            </Slider>	
                        </div>
                    </div>
                );
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default PopularDomesticFlightRout;