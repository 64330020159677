import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

import Header from "./src/components/Header/Header";
import Sidebar from "./src/components/Sidebar/Sidebar.js";
import Footer from "./src/components/Footer/Footer.js";
import Breadcrumbs from "./src/components/Breadbrumbs/Breadcrumbs.js";

import Dashboard from "./src/pages/dashboard/Dashboard.js";
import Typography from "./src/pages/typography/Typography.js";
import Notifications from "./src/pages/notifications/Notifications.js";
import Tables from "./src/pages/tables/Tables.js";
import Charts from "./src/pages/uielements/charts/Charts.js";
import Icons from "./src/pages/uielements/icons/IconsPage.js";
import Maps from "./src/pages/uielements/maps/google/GoogleMapPage";
import ErrorPage from "./src/pages/error/ErrorPage";
import Profile from './src/pages/Profile/Profile'

import './src/styles/main.scss'
import s from "./src/components/Layout/Layout.module.scss";
import AccountLog from "./src/pages/AccountLog/AccountLog";
import ChangePassword from "./src/pages/ChangePassword/ChangePassword";

class Main extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarOpened: false,
  };

  state = {
    isOpenSidebar: false
  }

  render() {
    return (
      <div className={s.root}>
        <div className={s.wrap}>
          <Header sidebarOpened={this.state.isOpenSidebar} onHandelToggle={() => this.setState({isOpenSidebar: !this.state.isOpenSidebar})} />
          <Sidebar sidebarOpened={this.state.isOpenSidebar} />
          <main className={s.content}>
            <Breadcrumbs url={this.props.location.pathname} />
            <Switch>
              <Route path="/user" exact render={() => <Redirect to="user/dashboard"/>} />
              <Route path="/user/dashboard" exact component={Dashboard}/>
              <Route path="/user/Account Statement" exact component={AccountLog}/>
              <Route path="/user/typography" exact component={Typography} />
              <Route path="/user/tables" exact component={Tables} />
              <Route path="/user/notifications" exact component={Notifications} />
              <Route path="/user/ui-elements" exact render={() => <Redirect to={"/user/ui-elements/charts"} />} />
              <Route path="/user/ui-elements/charts" exact component={Charts} />
              <Route path="/user/ui-elements/icons" exact component={Icons} />
              <Route path="/user/ui-elements/maps" exact component={Maps} />
              <Route path="/user/profile" exact component={Profile} />
              <Route path="/user/Change Password" exact component={ChangePassword} />
              <Route path="/error" exact component={ErrorPage} />
              <Route component={ErrorPage}/>
              <Route path='*' exact={true} render={() => <Redirect to="/error" />} /> 
            </Switch>
          </main>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default Main;
