// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ShareIcon from '@material-ui/icons/Share';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import multiply from "../../assets/Icons/multiply.svg";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { NavLink } from 'react-router-dom';
import { Filter, FilterList, Link } from "@material-ui/icons";
import Services from "../services/services";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


class VarticalSearchDisplayCard extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:props.data,
            shareModel: false,
            url: null
        }
    }

    onClick = () =>{
        this.props.onHandel(true);
    }

    toggleShareModel = () => this.setState({shareModel: !this.state.shareModel});

    share = () =>{
        try{
            let url = window.location.href;
            new Services().urlShortner(url).then(res => res.json().then(res => {
                console.log(res);
                this.setState({url: res.url});
                this.toggleShareModel();
            }, (error) =>{
                console.log("== error ==", error);
            }));
        } catch (error) {
            throw new Error(error);
        }
    }

    copyToClipboard = () => {
        try{
            const elem = document.createElement('textarea');
            elem.value = this.state.url;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
        } catch (error) {
            throw new Error(error);
        }
    }

    render(){
        try{
            let style = {display:'none'};
            let returnStyle = {display:'none'};
            if(this.props.visible){
                style = {display:''};
            }
            if(this.props.data.trip){
                returnStyle = {display:''};
            }
            let data = this.props.data;
            //  let dd =  new Date(data.departDateStr);
            //  let departDay =  dd.getDay();
            let className = "VarticalSearchDisplayCard " + this.props.className;
            return(
                <div className={className}>
                    <Modal size="lg" dialogClassName="modal-95w modal-500-px xs-m-0" centered show={this.state.shareModel} onHide={this.toggleShareModel} 
                    backdrop="static" keyboard={false}
                    >
                        <img src={multiply} className="position-absolute a" width="20px" style={{top:12, right:5, zIndex: 10}} onClick={this.toggleShareModel} />
                        <Modal.Body className="p-0" 
                        // onClick={this.toggleDepart}
                        >
                            <card className="bg-white">
                                <div className="row p-3">
                                    <h1>Copy Link</h1>
                                    <div className="col-8">
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text><Link /></InputGroup.Text>
                                            <FormControl id="inlineFormInputGroup" value={this.state.url} />
                                        </InputGroup>
                                    </div>
                                    <div className="col-4">
                                        <Button variant="outline-success" onClick={this.copyToClipboard}>Copy</Button>
                                    </div>
                                </div>
                            </card>
                        </Modal.Body>
                    </Modal>
                    <Card className="search-card my-card box-shadow border-radius" style={style}>
                        <CardContent className="m-0 p-1">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={9} lg={10} xl={10} className="px-3 py-0 md-px-1 lg-px-1">
                                <Grid container className="mt-1 xs-mt-3" style={{borderRadius:'10px'}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} className="px-4 py-3 d-flex flex-row xs-px-1 sm-px-0 md-px-0 lg-px-0 border-xs-left border-xs-bottom border-xs-top border-xs-right">
                                        <div className="px-2">
                                            {/* <p className="mb-4 h" style={{lineHeight: '1px'}}>FROM</p> */}
                                            <span className="h3 bold pt-2" style={{lineHeight: '1px', fontSize:'20px'}}>{data.from.ooro_AirportCode}</span>
                                            <br />
                                            <span className="h" style={{fontSize: '15px'}}>{data.from.ooro_AirportName}</span>
                                        </div>
                                        <div className="position-relative px-2">
                                            <ArrowForwardIcon className="color-primary" style={{fontSize:'40px'}}></ArrowForwardIcon>
                                        </div>
                                        <div className="px-2">
                                            {/* <p className="mb-4 h" style={{lineHeight: '1px'}}>FROM</p> */}
                                                
                                            <span className="h3 bold" style={{lineHeight: '1px',fontSize:'20px'}}>{data.to.ooro_AirportCode}</span>
                                            <br />
                                            <span className="h" style={{fontSize: '15px'}}>{data.to.ooro_AirportName}</span>
                                        </div>
                                        
                                    </Grid>
                                    
                                    <Grid item xs={6} sm={2} md={2} lg={2} xl={2} className="border-left px-3 py-3 border-xs-left border-xs-bottom border-xs-top border-xs-right">
                                        <p className="mb-4 h" style={{lineHeight: '1px'}}>DEPART 
                                            {/* <EventNoteIcon className="color-primary"></EventNoteIcon> */}
                                        </p>
                                        
                                        <span className="h3 bold pt-2" style={{lineHeight: '1px',fontSize:'20px'}}>{data.departDate.date} </span>

                                        <span className="h" style={{lineHeight: '20px',fontSize:'13px'}}>{monthNames[(data.departDate.month)]}`{data.departDate.year}, { days[new Date(data.departDateStr).getDay()] }</span>
                                    </Grid>
                                    <Grid item xs={6} sm={2} md={2} lg={2} xl={2} className="border-left px-3 py-3 border-xs-left border-xs-bottom border-xs-top border-xs-right" style={returnStyle}>
                                        <p className="mb-4 h" style={{lineHeight: '1px'}}>RETURN 
                                            {/* <EventNoteIcon className="color-primary"></EventNoteIcon> */}
                                        </p>
                                        
                                        <span className="h3 bold pt-2" style={{lineHeight: '1px',fontSize:'20px'}}>{data.returnDate.date} </span>

                                        <span className="h" style={{lineHeight: '20px',fontSize:'13px'}}>{monthNames[(data.returnDate.month)]}`{data.returnDate.year}, { days[new Date(data.returnDateStr).getDay()] }</span>
                                    </Grid>
                                    <Grid item xs={6} sm={2} md={2} lg={2} xl={2} className="px-3 py-3 border-left border-xs-left border-xs-bottom border-xs-top border-xs-right">
                                        <p className="mb-4 h" style={{lineHeight: '1px'}}>Travellers</p>
                                        <span className="h3 bold pt-2" style={{lineHeight: '1px', fontSize:'20px'}}>{data.travellersData.adult + data.travellersData.children + data.travellersData.infants}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={2} xl={2} className="px-3 py-3 border-left border-xs-left border-xs-bottom border-xs-top border-xs-right">
                                        <p className="mb-4 h" style={{lineHeight: '1px'}}>Travellers Class</p>
                                        <span className="h3 bold pt-2" style={{lineHeight: '1px', fontSize:'20px'}}>{data.travellersData.seatClassesData.name}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={2} xl={2} className="mt-3 pt-1 pe-3 lg-device-block xl-device-block">
                                <Button variant="outline-secondary" className="p-2 float-right" onClick={this.onClick}>MODIFY SEARCH</Button>
                                <Button className="p-2 bg-gradient w-color float-right me-3 border-radius" style={{border:'0px solid black'}} onClick={this.share}>
                                    <ShareIcon></ShareIcon>
                                </Button>
                                <Button className="p-2 bg-gradient w-color me-3 float-end border-radius lg-device" style={{border:'0px solid black'}}>
                                    <FilterList />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={2} xl={2} className="mt-3 border-xs-top border-sm-top border-md-top p-3 text-center xs-device-block sm-device-block md-device-block">
                                
                                <Button className="p-2 bg-gradient w-color me-3 float-start border-radius" style={{border:'0px solid black'}} onClick={this.share}>
                                    <ShareIcon></ShareIcon>
                                </Button>
                                <Button className="p-2 bg-gradient w-color me-3 float-start border-radius" style={{border:'0px solid black'}} onClick={() => this.props.openFilter(true)}>
                                    <FilterList /> Filter
                                </Button>
                                <Button variant="outline-secondary" className="p-2 float-end" onClick={this.onClick}>MODIFY SEARCH</Button>
                                
                            </Grid>
                        </Grid>	
                        </CardContent>
                        <CardActions className="p-0">
                            
                        </CardActions>
                    </Card>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default VarticalSearchDisplayCard;