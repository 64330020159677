// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import tsImg1 from '../../assets/Icons/b777ef87bb1759e70c21d83c2dd9a830.jpg';
import tsImg2 from '../../assets/Icons/download.jpeg';
import tsImg3 from '../../assets/Icons/plgespc_statue-of-unity_625x300_31_October_18.webp';
import tsImg4 from '../../assets/Icons/the-tall-trees-munnar.jpg';

// import library
import React from "react";
import Slider from "react-slick";
import Grid from '@material-ui/core/Grid';
import { Button } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { NavLink } from 'react-router-dom';
import Services from '../services/services';
import CustomNavLink from "./CustonNavLink";
import DisplayMessages from "../displayMessages/displayMessages";

export default function TravelStory(prop) {
    try{
        const [tarvelHistory, setTarvelHistory] = React.useState([]);
        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 4,
            speed: 500,
            slidesToScroll: 3,
            initialSlide: 1
        };

        let className = `travel-story mb-5 ${prop.className}`;

        React.useEffect(() =>{
            try{
                new Services().getTestimonials().then(res => res.json()).then((res) =>{
                    console.log("getTestimonials" ,res);
                    setTarvelHistory(res.tarvelHistory);
                }, (error) =>{
                    console.log("== error getTestimonials ==", error);
                });
            } catch (error) {
                throw new Error(error);
            }
        }, []);
        let th = [];
        tarvelHistory.map(e =>{
            th.push(<div className="position-relative w-h mr" style={{cursor:'pointer'}}>
                <CustomNavLink url={e.url} target={e.target}>
                    <div className="position-absolute w-h border-radius" style={{cursor:'pointer',background: 'rgb(0 0 0 / 30%)'}}></div>
                    <img src={e.img} className="ts-img border-radius" />
                    <span className="position-absolute btn-out-let">{e.category}</span>
                    <span className="position-absolute txt-footer">{e.Title}</span>
                    <span className="position-absolute read-more-footer"><b>Read More</b></span>
                </CustomNavLink>
            </div>);
        });

        return (
            <Grid container className={className}>
                <Grid item xs className="px-4 mx-2">
                    <Card className="my-card p-3 border-radius">
                        <CardContent className="position-relative px-5">
                        {/* <Button variant="contained" type="button" className="position-absolute shadow my-success-btn color-w border-0 pd-10 float-right next" style={{backgroundColor:'rgb(0 0 0 / 80%)'}}>
                            <ArrowForwardIosIcon></ArrowForwardIosIcon>
                        </Button>
                        <Button variant="contained" type="button" className="shadow position-absolute my-success-btn color-w border-0 pd-10 prev" style={{backgroundColor:'rgb(0 0 0 / 80%)'}}>
                            <ArrowBackIosIcon></ArrowBackIosIcon>
                        </Button> */}
                        <Grid container>
                            <Grid item xs={11}>
                                <span className="h2 h">Travel <b> Story </b></span> 
                            </Grid>
                        </Grid>
                        <Slider {...settings} className="mt-3">
                            {th}
                        </Slider>
                        </CardContent>
                        <CardActions></CardActions>
                    </Card>
                </Grid>
                {/* <Grid item xs></Grid> */}
            </Grid>
        );
    } catch (error) {
        throw new Error(error);
    }
}