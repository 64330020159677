import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import Login from "../login/Login.js";

import loginImage from "../../assets/registerImage.svg";
import SofiaLogo from "../../components/Icons/SidebarIcons/SofiaLogo.js";
import GoogleIcon from "../../components/Icons/AuthIcons/GoogleIcon.js";
import TwitterIcon from "../../components/Icons/AuthIcons/TwitterIcon.js";
import FacebookIcon from "../../components/Icons/AuthIcons/FacebookIcon.js";
import GithubIcon from "../../components/Icons/AuthIcons/GithubIcon.js";
import LinkedinIcon from "../../components/Icons/AuthIcons/LinkedinIcon.js";
import { registerUser } from "../../actions/register.js";
import userImg from "../../../../assets/Icons/blank-profile.jpeg";
import s from "./ChangePassword.module.scss";
import Services from "../../../../shared/services/services.js";
import DisplayMessages from "../../../../shared/displayMessages/displayMessages.js";

class ChangePassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    let OoroUserData = localStorage.getItem('OoroUserData');
    console.log("=== userData ===", JSON.parse(OoroUserData));
    let userData = {};
    if(OoroUserData){
      userData = JSON.parse(OoroUserData);
    }
    this.state = {
      op: '',
      np: '',
      cp: '',
      error: false,
      errorMessage: '',
      success: false
    };

    this.changeOp = this.changeOp.bind(this);
    this.changeNp = this.changeNp.bind(this);
    this.changeCp = this.changeCp.bind(this);
    this.doRegister = this.doRegister.bind(this);
    this.doChangePassword = this.doChangePassword.bind(this);
  }

  changeOp(event) {
    this.setState( { op: event.target.value });
  }

  changeNp(event) {
    this.setState( { np: event.target.value });
  }

  changeCp(event) {
    this.setState({ cp: event.target.value });
  }

  doChangePassword = () =>{
        console.log(this.state);
        let token = localStorage.getItem('OoroToken');
        if(token){
            if(this.state.op != '' && this.state.np != '' && this.state.cp != ''){
                if( this.state.np === this.state.cp ){
                    this.setState({error: false, errorMessage: ''});
                    new Services().chnagePassword(token, this.state.op, this.state.np).then(res => res.json()).then(
                        (result) => {
                                console.log(result);
                                if(result.success){
                                    this.setState({
                                        error: false,
                                        error: '',
                                        success: true
                                    });
                                }
                                else{
                                    this.setState({
                                        error: true,
                                        errorMessage: result.message,
                                        success: false
                                    });
                                }
                            
                        },
                        (error) => {
                          console.log("== error ==", error);
                          
                          this.setState({
                              error: true,
                              errorMessage: 'Something went wrong',
                              success: false
                          });
                          // new DisplayMessages().errorMsg({error:error});
                        }
                    );
                }
                else{
                    this.setState({error: true, errorMessage: 'Confirm password is not maching.'});
                }
            }
            else{
                this.setState({error: true, errorMessage: 'Please fill all the inputs.'});
            }
        }
        else{
            window.location.reload();
        }
  }

  doRegister(e) {
    // e.preventDefault();
    // this.props.dispatch(registerUser({
    //   creds: {
    //     email: this.state.email,
    //     password: this.state.password,
    //   },
    //   history: this.props.history
    // }));
  }

  render() {
    // const { from } = this.props.location.state || { from: { pathname: '/app' } };

    // if (Login.isAuthenticated(JSON.parse(localStorage.getItem('authenticated')))) {
    //   return (
    //     <Redirect to={from} />
    //   );
    // }

    let OoroUserData = localStorage.getItem('OoroUserData');
    console.log("=== userData ===", JSON.parse(OoroUserData));
    let userData = {};
    if(OoroUserData){
      userData = JSON.parse(OoroUserData);
    }

    let errorClass = '';

    if( this.state.cp != '' && this.state.np !== this.state.cp ){
        errorClass = 'border-danger';
    }

    return (
      <div className="auth-page">
        <Container className="col-12">
          <Row className="d-flex align-items-center">
            {/* <Col xs={12} lg={6} className="left-column">

            </Col> */}
            <Col xs={12} lg={6} className="right-column">
                {this.state.error ? (
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Oops!</strong> {this.state.errorMessage}
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({error: false})}></button>
                    </div>
                ): (<></>)}

                {this.state.success ? (
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>Successfull!</strong> Password has been changed.
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({success: false})}></button>
                    </div>
                ): (<></>)}

                <Widget className="widget-p-lg p-3">
                    <Row>
                        <Col>
                            <div className="d-flex">
                                <img className={s.image} style={{borderRadius: '50%'}} src={userImg} alt="..." />
                                <div className={s.userInfo}>
                                <p className="headline-3 mb-1">{userData?.Name}</p>
                                <p className="body-3 muted mb-1">{userData?.Email}</p>
                                <p className="body-3 muted mb-1">{userData?.Mobile}</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Button className="rounded-pill my-3 btn btn-outline-success float-end" type="button" color="secondary-red">Wallet: ₹{userData?.wallet ? userData.wallet : 0}</Button>
                        </Col>
                    </Row>
                </Widget>
                <Widget className="widget-auth widget-p-lg p-3">
                    <div className="d-flex align-items-center justify-content-between py-3">
                    <p className="auth-header mb-0">Edit Profile</p>
                    </div>
                    {/* <div className="auth-info my-2">
                    <p>This is a real app with Node.js backend - use <b>"admin@flatlogic.com / password"</b> to login!</p>
                    </div> */}
                    <form onSubmit={this.doChangePassword} method="post">
                        <FormGroup className="my-3">
                        <FormText>Current Password</FormText>
                        <Input id="op" required className="input-transparent pl-3" value={this.state.op} onChange={this.changeOp} type="text" required name="op" placeholder="Enter Current Password" />
                    </FormGroup>
                    <FormGroup className="my-3">
                        <FormText>New Password</FormText>
                        <Input id="np" required className="input-transparent pl-3" value={this.state.np} onChange={this.changeNp} type="text" required name="np" placeholder="Enter New Password" />
                    </FormGroup>
                    <FormGroup  className="my-3">
                        <div className="d-flex justify-content-between">
                        <FormText>Confirm Password</FormText>
                        {/* <Link to="/error">Forgot password?</Link> */}
                        </div>
                        <Input id="cp" required className={'input-transparent pl-3 '+ errorClass} value={this.state.cp} onChange={this.changeCp} type="text" required name="cp" placeholder="Enter Confirm Password"/>
                    </FormGroup>
                    <div className="bg-widget d-flex justify-content-center">
                        <Button className="rounded-pill my-3 btn btn-outline-primary" type="button" onClick={this.doChangePassword} color="secondary-red">Submit</Button>
                    </div>
                    </form>
                </Widget>
            </Col>
          </Row>
        </Container>
        {/* <Footer /> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default ChangePassword;
