import React from 'react';
import { Tab, Menu } from 'semantic-ui-react'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import fastFood from "../../assets/Icons/fast-food.svg";
import suitcase from "../../assets/Icons/suitcase.svg";
import SeatMapVertical from './SeatMapVertical';
import CustomFunction from '../library/CustomFunction';


const SeatMapsView = (prop) =>{
    try{
        let seatsMapData = prop?.data?.seats;
        let flightId = prop?.data?.flightId;
        let td = prop.travelersDetails.filter(r => r.label != 'Infant');
        let tds = [];
        td.map(e =>{
            e.data.map(ee =>{
                tds.push(ee);
            });
        });

        console.log(tds);

        if(seatsMapData && seatsMapData.length > 0){
            return(
                <SeatMapVertical data={seatsMapData} travelersDetails={td} totalSeats={prop.totalSeats} seatMapData={prop.seatMapData} flightId={flightId} onHandle={(data) =>{prop.onHandle(data)}} onClosed={(data) => prop.onClosed(data)} />
            );
        }
        else{
            return(
                <div style={{minHeight:'73vh', maxHeight:'73vh'}}></div>
            );
        }
    } catch (error) {
        throw new Error(error);
    }
}

const TabView = (prop) =>{
    try{
        const images = new CustomFunction().getImage(prop.data.airlineCode+'.gif');
        let orgCode = prop.data.orgName;
        let destCode = prop.data.destName;
        if(prop.data.l > 0){
            if(prop.data.sl > 0){
                return <>
                <Grid container className="p-0">
                    <Grid item xs={3}>
                        <img src={images.default} width="30px" />
                    </Grid>
                    <Grid item xs={9}>
                        <span class="ms-2">{orgCode} - {destCode}</span>
                        <br />
                        <span class="ms-2" style={{fontSize:'10px'}}>{prop.data.sl} of {prop.data.totalSeats}</span>
                    </Grid>
                </Grid>
            </>;
            }
            else{
            return <>
                <Grid container className="p-0">
                    <Grid item xs={3}>
                        <img src={images.default} width="30px" />
                    </Grid>
                    <Grid item xs={9}>
                        <span class="ms-2">{orgCode} - {destCode}</span>
                        <br />
                        <span class="ms-2" style={{color:'red', fontSize:'10px'}}>Selection Pending</span>
                    </Grid>
                </Grid>
            </>;
            }
        }
        else{
            return <>
                <Grid container>
                    <Grid item xs={3} className="p-0">
                        <img src={images.default} width="30px" />
                    </Grid>
                    <Grid item xs={9}>
                        <span class="ms-2">{orgCode} - {destCode}</span>
                        <br />
                        <span class="ms-2" style={{fontSize:'10px'}}>Not Available</span>
                    </Grid>
                </Grid>
            </>;
        }
    } catch (error) {
        throw new Error(error);
    }
}

class SeatMaps extends React.Component{
    render(){
        try{
            let item = [];
            this.props.data.forEach(element => {

                let seatsMapData = element.airSeatMaps;
                let seatMapData = this.props.seatMapData.filter(r => r.flightId == element.Flight.Flight_Id)[0];
                console.log("== seatMapData ==", seatMapData);

                item.push({
                    menuItem: (<Menu.Item key='tabView'>
                                <TabView data={{airlineCode: element.Flight.Airline_Code, orgName: element.Flight.Origin, destName: element.Flight.Destination, l: element.airSeatMaps?.seats?.length? element.airSeatMaps?.seats?.length: 0 , totalSeats: this.props.totalSeats, sl: seatMapData?.seats?.length? seatMapData?.seats?.length: 0}} />
                            </Menu.Item>),
                    render: () => <Tab.Pane attached={false}><SeatMapsView data={seatsMapData} seatMapData={seatMapData} totalSeats ={this.props.totalSeats} travelersDetails={this.props.travelersDetails} onClosed={(data) =>{this.props.onClosed()}} onHandle={(data) =>{ this.props.onHandle(data)}} /></Tab.Pane>,
                });
            });
            return(
                <>
                <Grid container>
                    <Grid item xs={12}>
                        <h3 className="m-0 p-2 color-w bg-gradient"> Add Seats</h3>
                    </Grid>
                </Grid>
                    <Tab menu={{ pointing: true }} className="tab-menu-m-0" panes={item} />
                </>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default SeatMaps;