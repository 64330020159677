// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import boyMobile from '../../assets/Icons/Group-1270.png';
import appleStore from '../../assets/Icons/applestore.jpg';
import playStore from '../../assets/Icons/playstore.jpg';
import girlPlane from '../../assets/Icons/Group-1203.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Button } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Input } from 'semantic-ui-react';

class StayUptoDate extends Component {
    

    componentDidMount(){
        
    };

    render(){
        try{
            return(
                <div className={this.props.className}>
                    <Grid container className="stay-up-to-date mb-5">
                        <Grid item xs className="px-4 mx-2">
                            <Card className="my-card p-3 border-radius">
                                <CardContent className="position-relative px-5">
                                <Grid container>
                                    <Grid item xs={3}>
                                        <img src={girlPlane} alt="logo" className="girl-plane" />
                                    </Grid>
                                    <Grid item xs={9} className="px-4 pt-5">
                                        <span className="h2 h"><span>Stay up</span> <b> to Date </b></span> 
                                        <br />
                                        <p>Subscribe now and recieve the latest travel news.</p>
                                        <Grid container className="mt-4">
                                            <Grid item xs={4} className="pe-4">
                                            <Input placeholder="Your Name" className="w-100 mt-1" />
                                            
                                            </Grid>
                                            <Grid item xs={4} className="px-4">
                                            <Input placeholder="Email" className="w-100 mt-1" />
                                            
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button className="bg-gradient border-0 form-btn btn btn-primary">Subscribe</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <hr />
                                <Grid container>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={6} className="px-5 pt-5">
                                        <span className="h1 h"><span>Download Our</span> <b> Mobile App</b></span> 
                                        <Grid container className="mt-4">
                                            <Grid item xs={12} className="pe-4">
                                                <img src={playStore} alt="logo" className="play-store me-3" />
                                                <img src={appleStore} alt="logo" className="apple-store" />
                                            </Grid>
                                            <Grid item xs={12} className="pe-4 mt-5">
                                                <p className="h3 h"> Get download link on your Mobile</p>
                                            </Grid>
                                            <Grid item xs={8} className="pe-4 mt-4">
                                            <Input placeholder="Email" className="w-100 mt-1" />
                                            
                                            </Grid>
                                            <Grid item xs={4} className="mt-4">
                                                <Button className="bg-gradient border-0 form-btn btn btn-primary">Send Link</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5} className="pt-4">
                                        <img src={boyMobile} alt="logo" className="boy-mobile w-100" />
                                    </Grid>
                                </Grid>
                                </CardContent>
                                <CardActions></CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default StayUptoDate;