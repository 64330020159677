import "./ContactUs.scss";
import "./ContactUs.css";
import Header from '../../../shared/component/Header.js';
import LeaveComment from '../../../shared/component/LeaveComment.js';
import LiBlock from '../../../shared/component/LiBlock.js';
import Forms from '../../../shared/component/Form.js';
import banner from '../../../assets/Icons/contactus.jpg';
import contactImg from '../../../assets/Icons/contact-center-abstract-concept_335657-3032.jpg';
import Grid from '@material-ui/core/Grid';
import CustomCalender from "../../../shared/component/CustomCalender";
import { Call, LocationCityOutlined, LocationOn, MailOutline } from "@material-ui/icons";
//import Calendar from "../../../shared/component/calender/Calendar";
//import Input from '../../../shared/component/Input.js';

const checkLogin = (status) =>{
    //this.setState({loggedIn: status});
}

function ContactUs(prop) {
    console.log(prop);
    try{
        return (
        <div className="TravelStoryDetails mb-5">
            <Header onLoggedIn={checkLogin} openSideBar={(status) =>prop.openSideBar(status)} nav={prop.nav}  />
            <img src={banner} className="w-100 banner" />
            {/* <CustomCalender /> */}
            <Grid container className="p-2 pt-4">
                <Grid item xs={12} className="">
                    
                </Grid>
            </Grid>
            <Grid container className="p-5 pb-0">
                <Grid item xs={5} className="pe-5 ps-3">
                    <Forms></Forms>
                </Grid>
                <Grid item xs={7} className="ps-5 pe-3">
                    <div className="row">
                        <div className="col-4 text-center">
                            <Call style={{color:'#7752EF', fontSize: '50px'}}></Call>
                            <h3 className="p-0 m-0 mt-2">Phone</h3>
                            <p className="a">8239299909</p>
                        </div>
                        <div className="col-4 text-center">
                            <MailOutline style={{color:'#7752EF', fontSize: '50px'}}></MailOutline>
                            <h3 className="p-0 m-0 mt-2">Email</h3>
                            <p className="a">support@kwikapi.com</p>
                        </div>
                        <div className="col-4 text-center">
                            <LocationOn style={{color:'#7752EF', fontSize: '50px'}}></LocationOn>
                            <h3 className="p-0 m-0 mt-2">Location</h3>
                            <p>
                                Yoflic India Private Limited, <br/>
                                Jalalpur Bazar, Near Old Police Station,<br/>
                                Chapra, Bihar 841412
                            </p>
                        </div>
                        <div className="col-12 text-center">
                            <img src={contactImg} style={{width:"80%"}} />
                        </div>
                    </div>
                    {/* <Grid container >
                        <Grid item xs={4} className="text-center position-relative">
                            <div className="text-center">
                                <div className="bg-gradient text-center" style={{width: '80px', padding: '25px', height: '80px', borderRadius: '50%'}}>
                                    <img src={call} className="" width="30" />
                                </div>
                                <h3 className="p-0 m-0 mt-2">Phone</h3>
                                <p className="a">8239299909</p>
                            </div>
                            
                        </Grid>
                        <Grid item xs={4} className="text-center position-relative">
                            <div className="text-center">
                                <div className="bg-gradient text-center" style={{width: '80px', padding: '25px', height: '80px', borderRadius: '50%'}}>
                                    <img src={call} className="" width="30" />
                                </div>
                                <h3 className="p-0 m-0 mt-2">Email</h3>
                                <p className="a">support@kwikapi.com</p>
                            </div>
                            
                        </Grid>
                        <Grid item xs={4} className="text-center position-relative">
                            <div className="text-center">
                                <section className="bg-gradient text-center" style={{width: '80px', padding: '25px', height: '80px', borderRadius: '50%'}}>
                                    <img src={call} className="" width="30" />
                                </section>
                                <h3 className="p-0 m-0 mt-2">Location</h3>
                                <p>
                                    Yoflic India Private Limited, <br/>
                                    Jalalpur Bazar, Near Old Police Station,<br/>
                                    Chapra, Bihar 841412
                                </p>
                            </div>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
            
        </div>
        );
    } catch (error) {
        throw new Error(error);
    }
  }
  
  export default ContactUs;