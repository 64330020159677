import "./Home.scss";
import "./Home.css";
import logo from '../../../assets/Icons/logo.png';
import plane from '../../../assets/Icons/plane2.png';
import blankLogo from '../../../assets/Icons/blank-profile.jpeg';
import bgPng from '../../../assets/Icons/bg-plane.png';
// import bgPng from '../../../assets/Icons/bg-png-c.png';
import Grid from '@material-ui/core/Grid';
import { Image, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Header from '../../../shared/component/Header.js';
import StayUptoDate from '../../../shared/component/StayUptoDate.js';
import SupperOffer from '../../../shared/component/SupperOffer.js';
import SearchCard from '../../../shared/component/SearchCard.js';
import Testimonial from '../../../shared/component/Testimonial.js';
import Footer from '../../../shared/component/Footer.js';
import OurFamily from '../../../shared/component/OurFamily.js';
import TravelStory from '../../../shared/component/TravelStory.js';
import PopularDomesticFlightRout from '../../../shared/component/PopularDomesticFlightRout.js';
import React from "react";
import Services from "../../../shared/services/services";
import displayMessages from "../../../shared/displayMessages/displayMessages";
import { Button, Dropdown, Menu } from 'semantic-ui-react';
import Login from "../Login/Login";
import LoginMobile from "../Login/LoginMobile";
import multiply from "../../../assets/Icons/multiply.svg";
import menu from "../../../assets/Icons/menu.png";
//import Input from '../../../shared/component/Input.js';
import TreeViews from "../../../shared/ui/TreeViews";
import CryptoJS from "crypto-js";
import FooterLink from "../../../shared/component/FooterLink";
import CustomNavLink from "../../../shared/component/CustonNavLink";
import { Base64 } from 'js-base64';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


function Headers(prop) {
	try{
		return(
			<div className="bg-white" style={{fontSize:'16px'}}>
				<span className="float-left">
					<b>{prop.LValue}</b>
				</span>
				<span className="float-right" style={{fontSize:'16px'}}>
					<b>{prop.RValue}</b>
				</span>
			</div>
		);
	} catch (error) {
        throw new Error(error);
    }
}

const Body = (prop) =>{
	try{
		return(
			<div className="h5 c-h p-3" style={{lineHeight: '30px',fontWeight:300}} dangerouslySetInnerHTML={{__html: prop.data}}></div>
		);
	} catch (error) {
        throw new Error(error);
    }
}

class Home extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			allAirlines:[],
			loginModel:false,
			logedin: false,
			additionalIndex: 0,
		}
	}

	componentDidMount(){
		var datas = [{ id: 1, name: 'Anil' }, { id: 2, name: 'Sunil' }]

		// Encrypt
		var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(datas), '123', '6666666666666666').toString();
		//log encrypted data
		console.log('Encrypt Data -')
		console.log(ciphertext);
		//alert(ciphertext);

		// Decrypt
		var bytes = CryptoJS.AES.decrypt(ciphertext, '123', '6666666666666666');
		var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

		//log decrypted Data
		console.log('decrypted Data -')
		console.log(decryptedData);

		let data = [{name:'getAll', value:'1'}]
		console.log("=== getAllAirports ===");
		new Services().getAllAirports(data).then(res => res.json()).then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    allAirlines: result.message
                });
                console.log('--- allAirlines ----',this.state.allAirlines);
            },
            (error) => {
				console.log("== error ==", error);
                this.setState({
                    isLoaded: true,
                    error
                });
                // new displayMessages().errorMsg({error:error});
            }
		);

		let completeUrl = Base64.encode(window.location.href);

		new Services().sendUrlContent({UrlConetent: completeUrl}).then(res => res.json()).then(
            (result) => {
                console.log('--- sendUrlContent data ----',result);
            },
            (error) => {
				console.log("== error ==", error);
                // new displayMessages().errorMsg({error:error});
            }
		);
		
	}

	toggleLoginModel = () => this.setState({loginModel: !this.state.loginModel});

	logout = () =>{
		localStorage.removeItem('OoroToken');
		localStorage.removeItem('OoroUserData');
		this.setState({logedin: false});
	}

	render(){
		try {
			// throw new Error("this is an error");
			console.log("=== bannerData ===", this.props.bannerData);

			let additionalIndex = this.state.additionalIndex;
			let additionalActive = [];
			this.props?.mainPageData?.additional?.map(e =>{
				additionalActive.push('');
			});
			additionalActive[this.state.additionalIndex] = 'active';
			
			let tabsData = [];

			this.props?.mainPageData?.additional?.map((e, i) =>{
				tabsData.push(<Grid item xs={4} xl={4} className={"d-tab text-left " + additionalActive[i]} onClick={() =>{this.setState({additionalIndex: i})}}>
					<span className="mb-0 pb-2">{e.title}</span>
				</Grid>)
			});

			let navLi = [];
			this.props?.nav?.map(e =>{
				let active = '';
				if(e.name == this.props.activeName){
					active = 'actives';
				}
				navLi.push(<li className={active}><CustomNavLink url={e.url} target={e.target} name={e.name}></CustomNavLink></li>);
			});
			let displayLoginBtn = 'unset';
			let displayProfileLogo = 'none';
			let token = localStorage.getItem('OoroToken');
			let userData = localStorage.getItem('OoroUserData');
			console.log("=== token ===", token);
			console.log("=== userData ===", userData);

			
			if(token){
				displayLoginBtn = 'none';
				displayProfileLogo = 'unset';
			}

			let testimonials = [];
			this.props?.mainPageData?.additional?.map((e, i) =>{
				testimonials.push({id: (i + 1) ,label:<Headers LValue={e.title} RValue={<></>} />,body:<Body data={e.content} />});
			
			});
			
			return (
			<div className="Home bg-cream" style={{backgroundColor:'#F8F8F8', position: 'relative', height:'100vh', overflow:'auto'}}>
				<Header onLoggedIn={this.checkLogin} isDisableXsMenu={true} openSideBar={(status) =>this.props.openSideBar(status)} nav={this.props?.nav} />
				<Modal size="lg" dialogClassName="xs-modal-100w modal-95w xs-m-0" centered show={this.state.loginModel} onHide={this.toggleLoginModel} 
				backdrop="static" keyboard={false}
				>
					<img src={multiply} className="position-absolute a" width="20px" style={{top:12, right:5, zIndex: 10}} onClick={this.toggleLoginModel} />
					<Modal.Body className="p-0" 
					// onClick={this.toggleDepart}
					>
						<card className="bg-white">
							<Login onHandle={(data) =>{this.toggleLoginModel(); this.setState({logedin: true});}} />
							<LoginMobile onHandle={(data) =>{this.toggleLoginModel(); this.setState({logedin: true});}} />
						</card>
					</Modal.Body>
				</Modal>
				<Carousel className="sm-device md-device lg-device xl-device" autoPlay={true} infiniteLoop={true} interval="30000" showArrows={false} showThumbs={false} stopOnHover={false} showIndicators={false} showStatus={false}>
					{this.props.bannerData?.map(e => <div>
						<Image src={e.url} style={{height: '570px'}} />
					</div>)}
				</Carousel>
				<Grid container className="h-120vh bg-gradient search-card-section" style={{ position: 'absolute', top: 0, padding: '0rem 3rem'}}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						{/* <img src={logo} alt="logo" className="logo-fixed lg-device xl-device home-logo-fixed" style={{left: 10, top: 20}} /> */}
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4 mobile-nav text-center">
								<nav class="navbar navbar-expand-lg navbar-light bg-none">
									<div class="container-fluid pe-5 xs-pe-0">
										<button class="float-start navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => this.props.openSideBar(true)}>
											<img src={menu} style={{width:'30px'}} />
										</button>
										<img src={logo} alt="logo" className="float-end xs-device sm-device md-device" style={{width:'125px'}} />
										
										<Dropdown style={{display:displayProfileLogo, zIndex: 10}} text={<Image src={blankLogo} roundedCircle style={{width:'50px', height:'50px'}} />} pointing='right' className='link item profile-logo-dropdown-mobile float-end'>
											<Dropdown.Menu>
												<Dropdown.Item>
													<NavLink to="/user" style={{color: '#000000de', textdecoration: 'none'}}>Dashboard</NavLink>
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										<Button size="large" className="home-login-btn-mobile float-end me-4" style={{display: displayLoginBtn, backgroundColor: 'white', width: '100px', fontSize: '11px'}} onClick={this.toggleLoginModel}>Login/Register</Button>
									</div>
								</nav>
							</Grid>
							{/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="desktop-nav">
								<ul className="nav-bar">
									{navLi}
								</ul>
							</Grid> */}
							<Grid item xs={12} sm={12} md={1} lg={2} xl={2}></Grid>
							<Grid item xs={12} sm={12} md={10} lg={8} xl={8} className="search-card-heading" style={{marginTop: '150px'}}>
								<SearchCard data={{allAirlines:this.state.allAirlines}} />
							</Grid>
						</Grid>
					</Grid>
					{/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
						<div className="position-relative">
							<div className="lg-device xl-device">
							<Dropdown style={{display:displayProfileLogo}} text={<Image src={blankLogo} roundedCircle style={{width:'50px', height:'50px'}} />} pointing='left' className='link item profile-logo-dropdown'>
								<Dropdown.Menu>
									<Dropdown.Item>
										<NavLink to="/user" style={{color: '#000000de', textdecoration: 'none'}}>Dashboard</NavLink>
									</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Button size="large" className="home-login-btn" style={{display: displayLoginBtn}} onClick={this.toggleLoginModel}>Login/Register</Button>
							</div>
							<img src={bgPng} alt="logo" className="bg-png-fixed desktop" />
							
							<img src={plane} alt="logo" className="plane-img-fixed lg-device" />
						</div>
					</Grid> */}
				</Grid>

				<div className="main-container">

					<PopularDomesticFlightRout />

					{/* <SupperOffer className="xl-device-block" /> */}

					<TravelStory className="xl-device-block" />
					
					{/* <StayUptoDate className="xl-device-block" /> */}
					<Grid container className="xs-device sm-device md-device lg-device">
						<Grid item xs>
							<card className="bg-white">
								<TreeViews classes="footer-flight-details-treeViews" datas={testimonials} dynamicNode={true} />
							</card>
						</Grid>
					</Grid>
					<div className="xl-device">
						<Grid container className="testimonials mb-5 xs-device-block sm-device-block md-device-block lg-device-block xl-device-block">
							<Grid item xs className="px-4 mx-2">
								<Card className="my-card p-0 border-radius">
									{/* <CardContent className="px-5">
										<Testimonial data={this.props.mainPageData.testmonial} />
									</CardContent> */}
									{/* <div className="px-3">
										<hr />
									</div> */}
									<div className="footer-block" style={{position:"relative"}}></div>
									<CardActions>
										<Grid container>
											<Grid item xs={7} className="p-5">
												<Grid container className="description">
													{/* <Grid item xs={4} xl={3} className={"d-tab text-left "+ additionalActive[0]} onClick={() =>{this.setState({additionalIndex: 0})}}>
														<span className="mb-0 pb-2">{this.props?.mainPageData?.additional[0].title}</span>
													</Grid> */}
													{tabsData}
													
													{/* <Grid item xs={4} xl={3} className={"d-tab text-left "+ additionalActive2} onClick={() =>{this.setState({additionalIndex: 1})}}>
														<span className="mb-0 pb-2">{this.props?.mainPageData?.additional[1].title}</span>
													</Grid>
													<Grid item xs={4} xl={4} className={"d-tab text-left "+ additionalActive3} onClick={() =>{this.setState({additionalIndex: 2})}}>
														<span className="mb-0 pb-2">{this.props?.mainPageData?.additional[2].title}</span>
													</Grid> */}
												</Grid>
												<Grid container className="mt-5">
													<Grid item xs={12} dangerouslySetInnerHTML={{__html: this.props?.mainPageData?.additional[additionalIndex].content}}>
														
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={4}>
												<img src={plane} alt="logo" className="footer-palne" />
											</Grid>
										</Grid>
									</CardActions>
								</Card>
							</Grid>
							{/* <Grid item xs></Grid> */}
						</Grid>
					</div>
				</div>
				{ this.props?.footer?.columns[0]? <FooterLink data={this.props?.footer?.columns[0]} />: null }
				<Grid container className="footer" style={{backgroundColor: 'black'}}>
					<Grid item xs className="p-0 main-container">
						<Card className="my-card p-0" style={{borderRadius:'0px', backgroundColor: 'black'}}>
							{/* <CardContent className="position-relative px-5 lg-device-block xl-device-block">
								<OurFamily data={this.props.footer.our_family} />
							</CardContent>
							<hr className="lg-device-block xl-device-block" /> */}
							<CardActions>
								<Footer data={{footer_links: this.props?.footer?.footer_links, contact_cols: this.props?.footer?.contact_cols}} />
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</div>
			);
		} catch (error) {
			throw new Error(error);
		}
	}
  }
  
  export default Home;