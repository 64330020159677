/* For use in material-ui/src/styles/colors.js */
export const primary = {
    50: '#e0e0e0',
    100: '#b3b3b3',
    200: '#808080',
    300: '#4d4d4d',
    400: '#262626',
    500: '#000000',
    600: '#000000',
    700: '#000000',
    800: '#000000',
    900: '#000000',
    A100: '#a6a6a6',
    A200: '#8c8c8c',
    A400: '#737373',
    A700: '#666666',
    'contrastDefaultColor': 'light',
};

/* For use in material-ui/src/styles/colors.js */
export const secondary = {
    50: '#e1e1e1',
    100: '#b5b5b5',
    200: '#838383',
    300: '#515151',
    400: '#2c2c2c',
    500: '#070707',
    600: '#060606',
    700: '#050505',
    800: '#040404',
    900: '#020202',
    A100: '#a6a6a6',
    A200: '#8c8c8c',
    A400: '#737373',
    A700: '#666666',
    'contrastDefaultColor': 'dark',
};



