// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import logoBlack from '../../assets/Icons/logo-black.png';
import logo from '../../assets/Icons/logo.png';
import blankLogo from '../../assets/Icons/blank-profile.jpeg';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import {Image, Modal} from 'react-bootstrap';
import { Button, Dropdown, Menu } from 'semantic-ui-react';
import Login from "../../web/component/Login/Login";
import multiply from "../../assets/Icons/multiply.svg";
import logout from "../../assets/Icons/logout.png";
import login from "../../assets/Icons/login.png";
import menu from "../../assets/Icons/menu.png";
import { ArrowBack } from "@material-ui/icons";
import { Card } from "@material-ui/core";
import CustomNavLink from "./CustonNavLink";

class Header extends Component {
    
  constructor(props){
    super(props)
    this.state = {
      loginModel:false,
      logedin: false
    }
  }

  componentDidMount(){
      
  };

  toggleLoginModel = () => this.setState({loginModel: !this.state.loginModel});

  logout = () =>{
    localStorage.removeItem('OoroToken');
    localStorage.removeItem('OoroUserData');
    this.setState({logedin: false});
    this.props.onLoggedIn(false);
  }

  back = () => {
    window.history.back();
  }

  render(){
    try{
      let navLi = [];
      this.props?.nav?.map(e =>{
        let active = '';
        if(e.name == this.props?.activeName){
          active = 'actives';
        }
        navLi.push(<li className={active}><CustomNavLink url={e.url} target={e.target} name={e.name} style={{color: 'black'}}></CustomNavLink></li>);
      });

      let displayLoginBtn = 'unset';
      let displayProfileLogo = 'none';
      let token = localStorage.getItem('OoroToken');
      let userData = localStorage.getItem('OoroUserData');
      console.log("=== token ===", token);
      console.log("=== userData ===", userData);

      
      if(token){
        displayLoginBtn = 'none';
        displayProfileLogo = 'unset';
      }

      return(
        <div className={this.props.className}>
          <Modal size="lg" dialogClassName="modal-90w modal-95w xs-modal-100w xs-m-0" centered show={this.state.loginModel} onHide={this.toggleLoginModel} 
        backdrop="static" keyboard={false}
        >
          <img src={multiply} className="position-absolute a" width="25px" style={{top:10, right:10, zIndex: 10}} onClick={this.toggleLoginModel} />
          <Modal.Body className="p-0" 
          // onClick={this.toggleDepart}
          >
            <card className="bg-white">
              <Login onHandle={(data) =>{this.toggleLoginModel(); this.setState({logedin: true}); this.props.onLoggedIn(true);}} />
            </card>
          </Modal.Body>
        </Modal>
          <Grid container className="position-fixed sm-device-block md-device-block lg-device-block xl-device-block p-0" style={{background: 'white',zIndex: '11'}}>
            <Grid item xs={12} className="p-0 desktop-nav">
              <Grid container className="header position-relative" style={{ boxShadow: '0px 2px 13px 0px grey'}}>
                  <Grid item xs={3} className="">
                    <div className="left-logo-section position-absolute">
                      <img src={logoBlack} alt="logo" className="f-img position-absolute" />
                    </div>
                  </Grid>
                  <Grid item xs={9} className="h-right-section">
                    {/* <Image src={blankLogo} roundedCircle className="profile-pic mt-1 me-5" /> */}
                    <Dropdown style={{display:displayProfileLogo}} text={<Image src={blankLogo} roundedCircle style={{width:'50px', height:'50px'}} />} pointing='top' className='link item profile-logo-dropdown me-5'>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink to="/user" style={{color: '#000000de', textdecoration: 'none'}}>Dashboard</NavLink>
                        </Dropdown.Item>
                        {/* <Dropdown.Item>Profile</Dropdown.Item> */}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button size="large" className="login-btn me-5 my-4" style={{display: displayLoginBtn}} onClick={this.toggleLoginModel}>Login/Register</Button>
                    <ul className="nav-bar li-border-bottom-0 me-4">
                      {navLi}
                      {/* <li><NavLink to="/">Home </NavLink></li>
                      <li><NavLink to="/about">About</NavLink> </li>
                      <li><NavLink to="/service">Service</NavLink></li>
                      <li><NavLink to="/contact">Contact</NavLink></li> */}
                    </ul>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="p-0 mobile-nav">
              <Grid container className="header bg-gradient-2 position-relative">
                <Grid item xs={12} sm={9} md={9} lg={9} xl={9} className="pt-4">
                  <nav class="navbar navbar-expand-lg navbar-light bg-none">
                    <div class="container-fluid">
                      <button class="float-start navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => this.props.openSideBar(true)}>
                        {/* <span class="navbar-toggler-icon w-color"></span> */}
                        <img src={menu} style={{width:'30px'}} />
                      </button>
                      <img src={logo} alt="logo" className="xs-device sm-device float-start" style={{width: '110px',  margin: 'auto', marginTop:'-13px'}} />
                      <div className="xs-device sm-device md-device">
                      <Dropdown style={{display:displayProfileLogo}} text={<Image src={blankLogo} roundedCircle style={{width:'50px', height:'50px'}} />} pointing="right" className='link item profile-logo-dropdown-mobile'>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <NavLink to="/user" style={{color: '#000000de', textdecoration: 'none'}}>Dashboard</NavLink>
                          </Dropdown.Item>
                          {/* <Dropdown.Item>Profile</Dropdown.Item> */}
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <Image src={blankLogo} roundedCircle className="profile-pic" style={{display:displayProfileLogo}} /> */}
                      <Button size="large" className="login-btn" style={{display: displayLoginBtn}} onClick={this.toggleLoginModel}>Login/Register</Button>
                      </div>
                      {/* <div class="collapse navbar-collapse pt-3" style={{position: 'absolute', top: '59px', backgroundColor: 'white', width: '92%'}} id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                          <li className="nav-item py-2 px-2"><NavLink to="/" className="w-black w-100" style={{display: 'block'}}>Home </NavLink></li>
                          <li className="nav-item py-2 px-2"><NavLink to="/about" className="w-black w-100" style={{display: 'block'}}>About</NavLink> </li>
                          <li className="nav-item py-2 px-2"><NavLink to="/service" className="w-black w-100" style={{display: 'block'}}>Service</NavLink></li>
                          <li className="nav-item py-2 px-2"><NavLink to="/contact" className="w-black w-100" style={{display: 'block'}}>Contact</NavLink></li>
                        </ul>
                      </div> */}
                    </div>
                  </nav>
                </Grid>
                <Grid item xs={3} className="md-device">
                  <div className="left-logo-section bg-img position-absolute">
                    <img src={logo} alt="logo" className="f-img position-absolute" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.props?.isDisableXsMenu? <></> : <div className="xs-device-block position-fixed w-100" style={{top: 0, zIndex: 10}}>
            <Card className="search-card my-card box-shadow xs-mt-0" style={{borderRadius: '0px'}}>
                <Grid container className="py-3 bg-gradient color-w">
                    <Grid item xs={1} className="px-2 py-1 ps-1">
                        <span onClick={this.back}>
                            <ArrowBack></ArrowBack>
                        </span>
                    </Grid>
                    <Grid item xs={9} className="px-2 py-1 text-center" style={{fontSize: '15px'}}>
                        <span>Review Flights</span>
                    </Grid>
                    <Grid item xs={2} className="px-2 py-1 text-end">
                        <span>
                            <img src={login} style={{display: displayLoginBtn}} onClick={this.toggleLoginModel} className="a me-2" width="20px" />
                            <img src={logout} style={{display: displayProfileLogo}} onClick={this.logout} className="a me-2" width="20px" />
                        </span>
                    </Grid>
                </Grid>
            </Card>
          </div>}
        </div>
      );
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default Header;