import React from 'react';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
const loading = require('../../assets/Icons/loadingNew.gif');
const search = require('../../assets/Icons/search.gif');
console.log(loading.default);
console.log(search.default);

export default class Loading extends React.Component {
    render() {
        try{
            return (
                <>
                    <Grid container style={{height:'80vh'}} >
                        <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <img src={loading.default} style={{width:'300px'}} />
                                <h1>{this.props.text}</h1>
                            </div>
                        </Grid>
                        
                    </Grid>     
                </>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}