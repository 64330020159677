// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import Indigo from '../../assets/Icons/indigo.png';

// import library
import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import FlightMetaData from "./FlightMetaData";

class OverView extends Component {
    

  componentDidMount(){
      
  };

  render(){
    try{
      let item = [];

      this.props.data.Segments.forEach(element => {
        item.push(<FlightMetaData data={element} travelClass={this.props.travelClass} classes="mb-3" />);
      });

      //console.log("*** Overview ***", this.props.data);
      return(
        <div className="overflow">
            {item}
        </div>
      );
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default OverView;