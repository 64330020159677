import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

export default function FareDetailList(prop) {
  try{
    let item = [];
    let total = 0;

    prop.data.map((e, i) =>{
      total = total + e?.Flight?.Fares[0]?.FareDetails[0]?.Total_Amount;
      item.push(
        <TreeItem className="list-group-item" nodeId={`${i+1}`} label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>{e?.Flight?.Origin} - {e?.Flight?.Destination}</b></span><span className="float-right" style={{fontSize:'16px'}}><b>₹ {e?.Flight?.Fares[0]?.FareDetails[0]?.Total_Amount.toFixed(2)}</b></span></div>}>
          <div className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><span className="float-left">Base Fare: </span><span className="float-right">₹ {e?.Flight?.Fares[0]?.FareDetails[0]?.Basic_Amount.toFixed(2)}</span></div>
          <br />
          <div className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><span className="float-left">Total fee & surcharges: </span><span className="float-right">₹ {e?.Flight?.Fares[0]?.FareDetails[0]?.AirportTax_Amount.toFixed(2)}</span></div>
          {/* <br />
          <div className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><span className="float-left">CCF: </span><span className="float-right">₹ 0</span></div> */}
          <br />
          <div className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><span className="float-left">Total: </span><span className="float-right">₹ {e?.Flight?.Fares[0]?.FareDetails[0]?.Total_Amount.toFixed(2)}</span></div>
        </TreeItem>
      );
    });

    if(prop?.travelerDetails){
      prop.travelerDetails.map(e =>{
        if(e?.data){
          e.data.map(ee =>{
            let baggageArr = [];
            let mealsArr = [];
            if(ee?.baggage){
              let baggage = [];
              let baggageTotal = 0;
              ee.baggage.map(eee =>{
                if(eee?.baggage?.Total_Amount){
                  total = total + eee.baggage.Total_Amount;
                  baggageTotal = baggageTotal + eee.baggage.Total_Amount;
                  baggage.push(<div className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><span className="float-left"> {eee.baggage.SSR_TypeDesc}</span><span className="float-right">₹ {eee.baggage.Total_Amount.toFixed(2)}</span></div>);
                }
              });

              if(baggage.length > 0){
                item.push(
                  <TreeItem className="list-group-item" nodeId={`${prop.data.length+2}`} label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>Baggage</b></span><span className="float-right" style={{fontSize:'16px'}}><b>₹ {baggageTotal.toFixed(2)}</b></span></div>}>{baggage}</TreeItem>
                );
              }
            }

            if(ee?.meals){
              let meals = [];
              let mealsTotal = 0;
              ee.meals.map(eee =>{
                if(eee?.meals?.Total_Amount){
                  total = total + eee.meals.Total_Amount;
                  mealsTotal = mealsTotal + eee.meals.Total_Amount;
                  meals.push(<div className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><span className="float-left"> {eee.meals.SSR_TypeDesc}</span><span className="float-right">₹ {eee.meals.Total_Amount.toFixed(2)}</span></div>);
                }
              });
              if(meals.length > 0){
                item.push(
                  <TreeItem className="list-group-item" nodeId={`${prop.data.length+3}`} label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>Meals</b></span><span className="float-right" style={{fontSize:'16px'}}><b>₹ {mealsTotal.toFixed(2)}</b></span></div>}>{meals}</TreeItem>
                );
              }
            }
            
          })
        }
      });
    }

    if(prop?.seatMapData){
      let seats = [];
      
      let seatsTotal = 0;
      prop.seatMapData.map((e, i) =>{
        let seatstr = [];

        if(e?.seats){
          e.seats.map(ee =>{
            total = total + ee.Total_Amount;
            seatsTotal = seatsTotal + ee.Total_Amount;
            seatstr.push(<tr className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><td> {ee.SSR_TypeName}</td><td className="text-end">₹ {ee.Total_Amount.toFixed(2)}</td></tr>);
          });
        }


        if(seats.length > 0 && i < (prop.seatMapData.length)){
          seats.push(<table className="table m-0 p-0">{seatstr}</table>);
        }
        else{
          seats.push(<table className="table m-0 p-0">{seatstr}</table>);
        }
      });

      if(seats.length > 0){
        item.push(
          <TreeItem className="list-group-item" nodeId={`${prop.data.length+4}`} label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>Seats</b></span><span className="float-right" style={{fontSize:'16px'}}><b>₹ {seatsTotal.toFixed(2)}</b></span></div>}>{seats}</TreeItem>
        );
      }

    }

    if(prop?.appliedCoupon){
      total = total - Number(prop.appliedCoupon.amount);
      item.push(
        <TreeItem className="list-group-item" nodeId={`${prop.data.length+1}`} label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>Discounts</b></span><span className="float-right" style={{fontSize:'16px'}}><b style={{color:'blue'}}>- ₹ {Number(prop?.appliedCoupon?.amount).toFixed(2)}</b></span></div>}>
          <div className="bg-white py-2 t-g-color" style={{paddingLeft:'6px'}}><span className="float-left"> {prop?.appliedCoupon?.code}</span><span className="float-right">- ₹ {Number(prop?.appliedCoupon?.amount).toFixed(2)}</span></div>
        </TreeItem>
      );
    }

    let ccf = [];

    if(prop?.ccf){
      total = total + Number(prop.ccf);
      ccf.push(<TreeItem className="list-group-item" style={{paddingLeft: '0px'}} nodeId="3" label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>CCF:</b></span><span className="float-right" style={{fontSize:'16px'}}><b>₹ { prop?.ccf ? Number(prop.ccf).toFixed(2) : 0 }</b></span></div>} />);
    }

    return (
      <TreeView
        className="FareDetailList list-group box-shadow"
        defaultExpanded={['1']}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
      >
        {item}
        {ccf}
        <TreeItem className="list-group-item" style={{paddingLeft: '0px'}} nodeId="4" label={<div className="bg-white" style={{fontSize:'16px'}}><span className="float-left"><b>Total Amount:</b></span><span className="float-right" style={{fontSize:'16px'}}><b>₹ {total.toFixed(2)}</b></span></div>} />
      </TreeView>
    );
  } catch (error) {
    throw new Error(error);
  }
}
