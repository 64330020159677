import { data } from 'jquery';
import React, { Component } from 'react';
import { DatePicker, RangePicker, theme } from 'react-trip-date';
import {ThemeProvider} from 'styled-components';

const Title = ()=>{
    let titleDay = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    return (
        <div class="sc-bxivhb ldpDQc title-weeks">
        {titleDay.map(item=>(<p key={Math.random()}>{item}</p>))}
        </div>
    )
}

const  handleResponsive  =  setNumberOfMonth  =>  {
	let  width  =  document.querySelector('.tp-calendar').clientWidth;
	if  (width  >  900)  {
		setNumberOfMonth(3);
	}  else  if  (width  <  900  &&  width  >  580)  {
		setNumberOfMonth(2);
	}  else  if  (width  <  580)  {
		setNumberOfMonth(1);
	}
};
	
class Calender extends Component {

	constructor(props){
		super(props)
		let selectDate;
		if(this.props.trip){
			selectDate = this.props.selectedDate;
		}else{
			selectDate = [this.props.selectedDate[0]];
		}
		console.log(selectDate);
		// this.state = {
		// 	cDate:this.props.selectedDate,
		// 	price : this.props.price,
		// 	selectDate: selectDate
		// };
	}
  
	onChange = date => {
		console.log(date);
		try{
		
			//console.log(date.length);
			let sDate;
			if(date.length > 0){
				this.props.returnDate(date);
				//   if(this.props.trip){
				// 	if(date.length == 2){
				// 		// this.setState({
				// 		// 	selectDate:date
				// 		// });
						
				// 	}
				//   }else{
				// 	// date.forEach(element => {
				// 	// 	sDate = element
				// 	// 	console.log(element);
				// 	// });
				// 	// this.setState({
				// 	// 	selectDate:date
				// 	// });
				// 	this.props.returnDate(date);
				//   }
			}
			else{
				var today = new Date(),
				date = today.getFullYear() +'-'+ (today.getMonth() +1) +'-'+ today.getDate();
				sDate = date;
				this.setState({
					selectDate:[sDate]
				});
			}

			//console.log(this.state.selectDate);

			

		} catch (error) {
			throw new Error(error);
		}

	};

  	Day = ({  day  }) => {
		try{
			let yy = day.format('YYYY');
			let mm = day.format('MM');
			let dd = day.format('DD');
			console.log(dd);
			console.log(mm);
			console.log(yy);
			let price = this.props.price;
			//console.log(this.state.price);
			let yData = [];
			let mData = [];
			let dData = [];

			yData = price.filter(f => f.year == yy);
			if(yData.length > 0){
				mData = yData[0]?.data.filter(f => f.month == mm);
			}
			if(mData.length > 0){
				dData = mData[0]?.data.filter(f => f.day == dd);
			}
			// console.log(yData);
			// console.log(mData);
			// console.log(dData);
			//   console.log(day.format('YYYY'));
			//   console.log(day.format('MM'));
			//   console.log(day.format('DD'));
			//   this.setState({
			// 	startPrice : this.state.startPrice - 150
			//   });
			if(dData.length > 0){
			return  (
				<div className="element">
					<p  className="date" style={{fontSize:'14px'}}><b>{day.format('DD')}</b></p>
					<p  className="date" style={{fontSize:'9px'}}>₹ {dData[0].price}</p>
				</div>
				);
			}else{
				return(
					<div className="element">
						<p  className="date" style={{fontSize:'14px'}}><b>{day.format('DD')}</b></p>
						<p  className="date" style={{fontSize:'9px'}}></p>
					</div>
				)
			}
		} catch (error) {
			throw new Error(error);
		}
	};
  
	render() {
		console.log(this.props);
		try{
			let selectedDate;
			if(this.props.trip){
				selectedDate = this.props.selectedDate;
			}else{
				selectedDate = [this.props.selectedDate[0]];
			}
			let price =  this.props.price;
			
			let disabledBeforeDate = this.props.disabledBeforeDate;
			console.log("*** disabledBeforeDate ***", disabledBeforeDate);
			let td = new Date();
			let mm = '';
			let dd = '';
			if((td.getMonth()+1) < 10){
				mm = '0'+(td.getMonth()+1);
			}else{
				mm = (td.getMonth()+1);
			}

			if(td.getDate() < 10){
				dd = '0'+td.getDate();
			}else{
				dd = td.getDate()+1;
			}
			let tdStr = td.getFullYear()+'-'+mm+'-'+dd;
			let date1 = new Date(td.getFullYear()+'-'+mm+'-'+dd);
			let date2 = new Date(disabledBeforeDate);
			let diffTime = Math.abs(date2 - date1);
			let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

			console.log("** diffDays **", diffDays);

			let disabledDays = [];

			if(disabledBeforeDate){
				for (let index = 0; index < diffDays; index++) {
					let today = new Date();
					today.setDate(today.getDate() + index);
					let m = '';
					let d = '';
					if((today.getMonth()+1) < 10){
						m = '0'+(today.getMonth()+1);
					}else{
						m = (today.getMonth()+1);
					}

					if(today.getDate() < 10){
						d = '0'+today.getDate();
					}else{
						d = today.getDate()+1;
					}
					let todayStr = today.getFullYear()+'-'+m+'-'+d;
					disabledDays.push(todayStr)
				}
			}

			console.log("**** disabledDays ****", disabledDays);

			return (
				<ThemeProvider theme={theme}>
				<DatePicker
					handleChange={this.onChange}
				//   onChange = {this.onChange}
					selectedDays={selectedDate} //initial selected days
					jalali={false}
					numberOfMonths={2}
					numberOfSelectableDays={2} // number of days you need 
					disabledDays={disabledDays} //disabeld days
					responsive={handleResponsive} // custom responsive, when using it, `numberOfMonths` props not working
					disabledBeforeToday={false} 
					disabledBeforeDate={["2021-05-26"]}
					disabledAfterDate={"2021-05-26"}
					disabled={false} // disable calendar 
					dayComponent={this.Day} //custom day component 
					titleComponent={Title} // custom title of days
				/>
				</ThemeProvider>
			);
		} catch (error) {
			throw new Error(error);
		}
	}
}

export default Calender;