import React, { Component } from 'react'
import { Input } from 'semantic-ui-react'
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button } from 'react-bootstrap';
import { ThreeSixty } from '@material-ui/icons';


class CouterNumber extends Component {
    constructor(props){
        super(props)
        this.state = {
            value:Number(this.props.value)
        }
    }

    sub = () =>{
        try{
            //alert('sub')
            if(Number(this.props.value) > 0){
                let c = 0;
                if(this.state.value > this.props.maxValue){
                    // this.setState({
                    //     value:0
                    // }); 
                    c = 0;
                }else{
                    if(this.props.value == 0){
                        c = 0;
                    }
                    else{
                        c = this.state.value - 1 ;
                    }
                }
                this.setState({value : c});
                this.props.onHandel({value:c, addBtn:this.props.addBtn, subBtn:this.props.subBtn, maxValue:this.props.maxValue});
            }
        } catch (error) {
            throw new Error(error);
        }
        
    }

    add = () =>{
        try{
            //alert('add')
            if(Number(this.props.maxValue) > Number(this.props.value)){
                //console.log(this.props);
                let c = 0;
                if(this.state.value > this.props.maxValue){
                    // this.setState({
                    //     value:0
                    // }); 
                    c = 1;
                }else{
                    if(this.props.value == 0){
                        c = 1;
                    }
                    else{
                        c = this.state.value + 1 ;
                    }
                }
                this.setState({value : c});
                this.props.onHandel({value:c, addBtn:this.props.addBtn, subBtn:this.props.subBtn, maxValue:this.props.maxValue});
            }
            else{
                console.log(this.state.value);
                this.setState({value : Number(this.props.value)});
                this.props.onHandel({value:this.props.value, addBtn:this.props.addBtn, subBtn:this.props.subBtn, maxValue:this.props.maxValue});
            }
        } catch (error) {
            throw new Error(error);
        }
    }
  render(){
    try{
        //console.log(this.props);
        //this.setState({value:Number(this.props.value)})
        let addBtn = false;
        let subBtn = false;
        let sumVal = Number(this.props.value) + Number(this.props.value2) + Number(this.props.value3);
        let val = this.props.value;

        if(sumVal >= 9){
            addBtn = true;
        }else if(this.props.value >= this.props.maxValue){
            addBtn = true;
        }
        
        if(this.props.value <= 0){
            subBtn = true;
        }else if(this.props.value <= this.props.minVal){
            subBtn = true;
        }
        if(this.props.value > this.props.maxValue){
            // this.setState({
            //     value:0
            // }); 
            val = 0;
        }
        
        return(
                <>
                    <Grid container>
                        <Grid item xs={5} className="px-1 ps-3 py-3">
                            <p className="p-0 m-0" style={{lineHeight: '8px'}}><b>{this.props.text}</b></p>
                            <small className="p-0 m-0" style={{fontSize:'8px',lineHeight: '0px'}}>{this.props.subText}</small>
                        </Grid>
                        <Grid item xs={7} className="py-2 px-2">
                            <Grid container>
                                <Grid item xs>
                                    <Button className="bg-gradient" onClick={this.sub} style={{width:'100%',color:'white',border:'0px solid black'}} disabled={subBtn}>
                                        -
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className="text-center">
                                    <Input focus value={this.props.value} className="sm-input" />
                                </Grid>
                                <Grid item xs>
                                    <Button className="bg-gradient" onClick={this.add} style={{width:'100%',color:'white',border:'0px solid black'}} disabled={addBtn}>
                                        +
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="m-0" />
                </>
        );
    } catch (error) {
        throw new Error(error);
    }
  }
}

export default CouterNumber