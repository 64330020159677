// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
// import Modal from '@material-ui/core/Modal';
import { Progress } from 'semantic-ui-react'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { NavLink, Redirect } from 'react-router-dom';
import TravellersDropdown from "../ui/TravellersDropdown";
import CustomDropdown from "../ui/Dropdown";
import Calender from "./Calender";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Services from "../services/services";
import displayMessages from "../displayMessages/displayMessages";

import { allFlightsData } from '../../assets/flights';
import { allAirlinesData } from '../../assets/allAirlines';
import CustomCalender from "./CustomCalender";
import moment from "moment";
import multiply from "../../assets/Icons/multiply.svg";

const HandleSearch = (prop) => {
	try{
		if(prop.status){
			return <CircularProgress color="secondary" className="btn-spinner" />;
		}
		else{
			return <FlightIcon className="spin-animation"></FlightIcon>;
		}
	} catch (error) {
		throw new Error(error);
	}
}

const TextDiv = (prop) =>(
	<Grid container className="w-100">
	  <Grid item xs={10}>
		<div>
		  <p className="p-0 m-0"><b>{prop.heading}</b></p>
		  <p className="sm-p p-0 m-0">{prop.desc}</p>
		</div>
	  </Grid>
	  <Grid item xs={2} className="text-right">
		<p className="p-0 m-0"><b>{prop.shortForm}</b></p>
	  </Grid>
	</Grid>
  )
  
  const Heading = (prop) => (
	<span>{prop.state}, {prop.country}</span>
  )


  const DisplayAirport = (prop) =>{
	  console.log(prop);
	if(prop.if && prop.if != ''){
		return(<>{prop.if}</>)
	}
	else{
		return(<>{prop.else}</>)
	}
  }
  
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];

 const monthIndex = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
 const DisplayReturn = (prop) => {
	try{
		if(prop.trip){
			return(
				<>
				<span className="h3 bold" style={{lineHeight: '1px', fontSize: '18px'}}>{prop.returnDate.date} {monthNames[prop.returnDate.month]}</span>
									
				<p className="sm-p h" style={{lineHeight: '20px'}}>{prop.returnDate.year}</p>
				</>
			)
		}
		else{
			return(
				<>
				<p className="sm-p h mt-n2" style={{lineHeight: '10px'}}> Book a round trip to save more </p>
								
				<p className="sm-p h" style={{lineHeight: '1px'}}>{prop.returnDate.year}</p>
				</>
			)
		}
	} catch (error) {
		throw new Error(error);
	}
}

class SearchCard extends React.Component {
	constructor(props) {
		super(props);
		let getM;
		let getM1;
		let getD;
		let getD1;
		
		var today = new Date();
		var today1 = new Date();
		
		today1.setDate(today1.getDate() + 7);
        
		let date = {month :(today.getMonth()), date :today.getDate(), year :today.getFullYear()};
        let date1 = {month :(today1.getMonth()), date :today1.getDate(), year :today1.getFullYear()};
		
		if((today.getMonth() +1) < 10){
			getM = '0' + (today.getMonth() +1);
		}
		else{
			getM = (today.getMonth() +1);
		}

		if(today.getDate() < 10){
			getD = '0' + today.getDate();
		}
		else{
			getD = today.getDate();
		}

		if((today1.getMonth() +1) < 10){
			getM1 = '0' + (today1.getMonth() +1);
		}
		else{
			getM1 = (today1.getMonth() +1);
		}

		if(today1.getDate() < 10){
			getD1 = '0' + today1.getDate();
		}
		else{
			getD1 = today1.getDate();
		}
        
		let dateStr = today.getFullYear() +'-'+ getM +'-'+ getD;
        let dateStr1 = today1.getFullYear() +'-'+ getM1 +'-'+ getD1;
		
		this.state = {
			error: null,
			isLoaded: false,
			trip:0,
			toSelectedAirport: {id: "411",ooro_AirportCode: "BOM",ooro_AirportName: "Mumbai",ooro_CountryCode: "IND",ooro_CountryName: "India"},
			fromSelectedAirport: {id: "4067",ooro_AirportCode: "DEL",ooro_AirportName: "Delhi",ooro_CountryCode: "IND",ooro_CountryName: "India"},
			allAirportsData: [],
			T10India: [],
			fromPlace: [],
			toPlace: [],
			toDropdown:false,
			fromDropdown:false,
			toDropdownMobile:false,
			fromDropdownMobile:false,
			depart:false,
			departMobile:false,
			departDate:date,
			departDateStr:dateStr,
			return:false,
			returnMobile:false,
			returnDate:date1,
			returnDateStr:dateStr1,
			travellers:false,
			travellersMobile:false,
			travellersData:{adult: 1, children: 0, infants: 0, seatClassesData: {id: 0, name: "Economy"}},
			oneWayClass: "hr",
			roundTripClass: "",
			directFlight:false,
			redirect:false,
			finelData:{},
			isBtnLoader:false,
			allFlights:[],
			returnAllFlights:[],
			allAirlines:this.props.data.allAirlines,
			price: [{year: `${today.getFullYear()}`, data: []}]
		};
		console.log(this.state.departDateStr);
	}

	c = 6017;

	price = [{
		year:'2021',
		data: [
			{
				month:'06',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'07',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					// {day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'08',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'09',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'10',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
			{
				month:'11',
				data:[
					{day:'01', price: this.c--},
					{day:'02', price: this.c--},
					{day:'03', price: this.c--},
					{day:'04', price: this.c--},
					{day:'05', price: this.c--},
					{day:'06', price: this.c--},
					{day:'07', price: this.c--},
					{day:'08', price: this.c--},
					{day:'09', price: this.c--},
					{day:'10', price: this.c--},
					{day:'11', price: this.c--},
					{day:'12', price: this.c--},
					{day:'13', price: this.c--},
					{day:'14', price: this.c--},
					{day:'15', price: this.c--},
					{day:'16', price: this.c--},
					{day:'17', price: this.c--},
					{day:'18', price: this.c--},
					{day:'19', price: this.c--},
					{day:'20', price: this.c--},
					{day:'21', price: this.c--},
					{day:'22', price: this.c--},
					{day:'23', price: this.c--},
					{day:'24', price: this.c--},
					{day:'25', price: this.c--},
					{day:'26', price: this.c--},
					{day:'27', price: this.c--},
					{day:'28', price: this.c--},
					{day:'29', price: this.c--},
					{day:'30', price: this.c--},
					{day:'31', price: this.c--},
				]
			},
		]
	}];

	
	componentDidMount() {
		try{
			let lastSearch = JSON.parse(localStorage.getItem("lastSearch"));
			console.log(lastSearch);
			if(lastSearch?.fromSelectedAirport){
				this.setState({
					trip:lastSearch.trip,
					fromSelectedAirport:lastSearch.fromSelectedAirport,
					toSelectedAirport:lastSearch.toSelectedAirport,
					departDate:lastSearch.departDate,
					departDateStr:lastSearch.departDateStr,
					returnDate:lastSearch.returnDate,
					returnDateStr:lastSearch.returnDateStr,
					travellers:lastSearch.travellers,
					travellersData:lastSearch.travellersData,
					directFlight:lastSearch.directFlight,
					oneWayClass:lastSearch.oneWayClass,
					roundTripClass:lastSearch.roundTripClass
				});
			}
			
			let data = [{name:'getAll', value:'1'}];
			// let 
			// for (let index = 1; index < 7; index++) {
			// 	const element = array[index];
				
			// }

			new Services().getAllAirports(data).then(res => res.json()).then(
				(result) => {
					this.setState({
					isLoaded: false,
					allAirportsData: result.message,
					T10India: result.T10India
					});
					console.log(this.state.allAirportsData);
					console.log(this.state.T10India);
				},
				(error) => {
					console.log("== error ==", error);
					this.setState({
					isLoaded: false,
					error
					});
					// new displayMessages().errorMsg({error:error});
				}
			);

			this.setFareCalenderData(lastSearch?.fromSelectedAirport, lastSearch?.toSelectedAirport);
		} catch (error) {
			throw new Error(error);
		}
	}
	// state = {
	// 	toDropdown:false,
	// 	fromDropdown:false,
	// 	depart:false,
	// 	return:false,
	// 	travellers:false
	// }

	clearDropdown = () =>{
		this.setState({
			depart: false, toDropdown:false, fromDropdown:false, travellers:false, return:false
		})
	}

	toggleDepart = () => setTimeout(() => {
		this.setState({ depart: !this.state.depart, return: false, toDropdown:false, fromDropdown:false, travellers:false })
	}, 50);

	toggleReturn = () => setTimeout(() => {
		this.setState({ return: !this.state.return, depart: false, toDropdown:false, fromDropdown:false, travellers:false })
	}, 50);

	toggleDepartMobile = () => setTimeout(() => {
		this.setState({ departMobile: !this.state.departMobile, return: false, toDropdown:false, fromDropdown:false, travellers:false })
	}, 50);

	toggleReturnMobile = () => setTimeout(() => {
		this.setState({ returnMobile: !this.state.returnMobile, depart: false, toDropdown:false, fromDropdown:false, travellers:false })
	}, 50);
	
	toggleTravellers = () => setTimeout(() => {
		this.setState({ travellers: !this.state.travellers, toDropdown:false, fromDropdown:false, depart:false, return: false, })
	}, 50);
	
	toggleToDropdown = () => setTimeout(() => {
		this.setState({ toDropdown: !this.state.toDropdown, fromDropdown:false, depart:false, travellers:false, return: false, })
	}, 50);

	toggleFromDropdown = () => setTimeout(() => {
		this.setState({ fromDropdown: !this.state.fromDropdown, toDropdown:false, depart:false, travellers:false })
	}, 50);

	toggleTravellersMobile = () => setTimeout(() => {
		this.setState({ travellersMobile: !this.state.travellersMobile, toDropdownMobile:false, fromDropdownMobile:false, depart:false, return: false, })
	}, 50);
	
	toggleToDropdownMobile = () => setTimeout(() => {
		this.setState({ toDropdownMobile: !this.state.toDropdownMobile, fromDropdownMobile:false, depart:false, travellersMobile:false, return: false, })
	}, 50);

	toggleFromDropdownMobile = () => setTimeout(() => {
		this.setState({ fromDropdownMobile: !this.state.fromDropdownMobile, toDropdownMobile:false, depart:false, travellersMobile:false })
	}, 50);

	fromSelectedAirport = (airportData) =>{
		console.log('--- from airport data --- ',airportData);
		this.setState({
			fromSelectedAirport: airportData,
			fromDropdown : false,
			toDropdown : true
		});

		let from = airportData;
		let to = this.state.toSelectedAirport;

		this.setFareCalenderData(from, to);
	}

	fromSelectedAirportMobile = (airportData) =>{
		this.toggleFromDropdownMobile();
		console.log('--- from airport data --- ',airportData);
		this.setState({
			fromSelectedAirport: airportData,
			fromDropdown : false,
			toDropdown : false
		});

		let from = airportData;
		let to = this.state.toSelectedAirport;

		this.setFareCalenderData(from, to);
	}

	handelReturn = () =>{
		if(this.state.trip){
			this.toggleReturn();
		}
		else{
			this.roundTrip();
			this.toggleReturn();
		}
	}

	handelReturnMobile = () =>{
		if(this.state.trip){
			this.toggleReturnMobile();
		}
		else{
			this.roundTrip();
			this.toggleReturnMobile();
		}
	}

	toSelectedAirport = (airportData) =>{
		console.log('--- to airport data --- ',airportData);
		this.setState({
			toSelectedAirport: airportData,
			toDropdown : false,
			depart:true
		});

		let to = airportData;
		let from = this.state.fromSelectedAirport;

		this.setFareCalenderData(from, to);
	}

	toSelectedAirportMobile = (airportData) =>{
		this.toggleToDropdownMobile();
		console.log('--- to airport data --- ',airportData);
		this.setState({
			toSelectedAirport: airportData,
			toDropdown : false,
			depart: false
		});

		let to = airportData;
		let from = this.state.fromSelectedAirport;

		this.setFareCalenderData(from, to);
	}

	switchToFrom = () =>{
		let tempTo = this.state.toSelectedAirport;
		this.setState({
			toSelectedAirport:this.state.fromSelectedAirport,
			fromSelectedAirport:tempTo,
		});
	}

	setDepartDate = (date) =>{
		console.log(date);
		try{
			if(this.state.trip === 0){
				let sDate = date;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				var td2 = new Date(sDate);
				td2.setDate(td2.getDate() + 7);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					depart:false,
					returnDate :date2,
					returnDateStr :dStr2,
					return:false,
					travellers:true
				});
			}
			else{
				let sDate = date.from;
				let sDate2 = date.to;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				var td2 = new Date(sDate2);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					depart:false,
					returnDate :date2,
					returnDateStr :dStr2,
					return:false,
					travellers:true
				});
				
				
				
				
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	setReturnDate = (date) =>{
		console.log(date);
		try{
			let sDate;
			date.forEach(element => {
				sDate = element
				console.log(element);
			});
			var td1 = new Date(sDate);
			var dStr1 = sDate;
			var date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
			// var td2 = new Date(sDate);
			// td2.setDate(td2.getDate() + 7);
			// let getD = td2.getDate();
			// if(td2.getDate() < 10){
			// 	getD = '0'+td2.getDate();
			// }
			// let getM = (td2.getMonth()+1);
			// if((td2.getMonth()+1) < 10){
			// 	getM = '0'+(td2.getMonth()+1);
			// }
			// let dStr2 = td2.getFullYear() +'-'+ getM +'-'+ getD;
			// date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
			this.setState({
				returnDate :date1,
				returnDateStr :dStr1,
				return:false,
				travellers:true
			});
		} catch (error) {
			throw new Error(error);
		}
	}

	setDepartDateMobile = (date) =>{
		console.log(date);
		try{
			if(this.state.trip === 0){
				let sDate = date;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				var td2 = new Date(sDate);
				td2.setDate(td2.getDate() + 7);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					depart:false,
					departMobile:false,
					returnDate :date2,
					returnDateStr :dStr2,
					returnMobile:false,
					travellers:false
				});
				// this.toggleDepartMobile();
				// this.toggleReturnMobile();
			}
			else{
				let sDate = date.from;
				let sDate2 = date.to;
				
				var td1 = new Date(sDate);
				var dStr1 = sDate;
				let date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
				var td2 = new Date(sDate2);
				
				let dStr2 = moment(td2).format("YYYY-MM-DD");
				let date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
				this.setState({
					departDate :date1,
					departDateStr :dStr1,
					departMobile:false,
					returnDate :date2,
					returnDateStr :dStr2,
					returnMobile:false,
					travellers:false
				});
				
				
				// this.toggleDepartMobile();
				// this.toggleReturnMobile();
				
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	setReturnDateMobile = (date) =>{
		console.log(date);
		try{
			let sDate;
			date.forEach(element => {
				sDate = element
				console.log(element);
			});
			var td1 = new Date(sDate);
			var dStr1 = sDate;
			var date1 = {month :(td1.getMonth()), date :td1.getDate(), year :td1.getFullYear()};
			// var td2 = new Date(sDate);
			// td2.setDate(td2.getDate() + 7);
			// let getD = td2.getDate();
			// if(td2.getDate() < 10){
			// 	getD = '0'+td2.getDate();
			// }
			// let getM = (td2.getMonth()+1);
			// if((td2.getMonth()+1) < 10){
			// 	getM = '0'+(td2.getMonth()+1);
			// }
			// let dStr2 = td2.getFullYear() +'-'+ getM +'-'+ getD;
			// date2 = {month :(td2.getMonth()), date :td2.getDate(), year :td2.getFullYear()};
			this.setState({
				returnDate :date1,
				returnDateStr :dStr1,
				return:false,
				travellers:false
			});

			this.toggleDepartMobile();
		} catch (error) {
			throw new Error(error);
		}
	}

	setFareCalenderData = (fromSelectedAirport = this.state.fromSelectedAirport, toSelectedAirport = this.state.toSelectedAirport) =>{
		console.log("fromSelectedAirport", fromSelectedAirport, "toSelectedAirport", toSelectedAirport);
		try{
			let ddd = new Date();
			let today = new Date();
			let price = [{year: `${today.getFullYear()}`, data: []}];
			this.setState({price: price});
			
			for (let index = 1; index < 6; index++) {
				let mmm = monthIndex[ddd.getMonth()];
				// if(mmm < 10){
				// 	mmm = `0${mmm}`;
				// }else{
				// 	mmm = `${mmm}`;
				// }
				let data = {
					"Destination": toSelectedAirport.ooro_AirportCode,
					"Month": mmm,
					"Origin": fromSelectedAirport.ooro_AirportCode,
					"Year": `${ddd.getFullYear()}`
				}
				new Services().fareCalender(data).then(res => res.json()).then(
					(result) => {
						console.log("fareCalender Data ", data, result);
						// let data = [
						// 	{
						// 		month:'06',
						// 		data:[
						// 			{day:'01', price: this.c--},
						// 		]
						// 	}
						// ];

						let priceData = []; 
						if(result.LowFares?.length > 0){
							result.LowFares.map(r =>{
								let d = r.TravelDate.split('/')[1];
								let amount = r.Amount;
								priceData.push({day: d, price: amount});
							});
						}

						let price = this.state.price;
						let priceYear = price.filter(r => r.year == ddd.getFullYear());

						if(priceYear.length > 0){
							price.map(e =>{
								if(e.year == ddd.getFullYear()){
									e.data.push({month: mmm, data: priceData});
								}
							});
						}
						else{
							price.push({year: ddd.getFullYear(), data: [{month: mmm, data: priceData}]});
						}

						console.log("=== price ===", price);
						this.setState({price: price});
					},
					(error) => {
						console.log("== error ==", error);
						// new displayMessages().errorMsg({error:error});
					}
				);
				ddd.setMonth(ddd.getMonth() + 1);
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	oneWay = () => {
		this.setState({
			trip:0,
			oneWayClass:"hr",
			roundTripClass:""
		})
		console.log('oneWay');
	}

	roundTrip = () => {
		this.setState({
			trip:1,
			oneWayClass:"",
			roundTripClass:"hr"
		})
		console.log('roundTrip');
	}


	selectedTravellers = (data) => {
		console.log('--- selectedTravellers ----',data);
		this.setState({
			travellersData: data,
			travellers:false
		});
	}

	selectedTravellersMobile = (data) => {
		this.toggleTravellersMobile();
		console.log('--- selectedTravellers ----',data);
		this.setState({
			travellersData: data,
			travellers:false
		});
	}

	setDirectFlight = (event) => {
		console.log(event.target.checked);
		this.setState({
			directFlight:event.target.checked
		})
	};


	getFlightsData = (data) => {
		try{
			//console.log("=== allFlightsData ===", allFlightsData);
			new Services().searchFlight(data).then(res => res.json()).then(
				(result) => {
					let fData = [];
					let amountRange = [];
					let filterFlights = [];
					console.log("=== results ===",result);
					if(result?.TripDetails?.Flights?.Flights){
						fData = result.TripDetails.Flights.Flights;
						amountRange = result.TripDetails.Flights.Amount_Range;
						filterFlights = result.TripDetails.Flights.Filter_Flights;
					}
					else{
						console.log("=== allFlightsData ===", allFlightsData);
						fData = allFlightsData.TripDetails.Flights.Flights;
						amountRange = allFlightsData.TripDetails.Flights.Amount_Range;
						filterFlights = allFlightsData.TripDetails.Flights.Filter_Flights;
					}
					this.setState({
						isBtnLoader: false,
						redirect:true,
						allFlights: fData[0],
						returnAllFlights: fData[1],
						amountRange:amountRange,
						filterFlights:filterFlights
					});
					console.log('--- All Flights Data ---',this.state.allFlights);
				},
				(error) => {
					console.log("== error ==", error);
					this.setState({
						isBtnLoader: false,
						error
					});
					// new displayMessages().errorMsg({error:error});
				}
			);
		} catch (error) {
			throw new Error(error);
		}
    }

	onSubmit = () => {
		try{
			// this.setState({
			// 	isBtnLoader:true
			// });

			let finelData = {
				trip:this.state.trip,
				fromSelectedAirport:this.state.fromSelectedAirport,
				toSelectedAirport:this.state.toSelectedAirport,
				departDate:this.state.departDate,
				departDateStr:this.state.departDateStr,
				returnDate:this.state.returnDate,
				returnDateStr:this.state.returnDateStr,
				travellersData:this.state.travellersData,
				directFlight:this.state.directFlight,
				oneWayClass:this.state.oneWayClass,
				roundTripClass:this.state.roundTripClass
			};

			localStorage.setItem("lastSearch", JSON.stringify(finelData));
			let lastSearch = localStorage.getItem("lastSearch");
			console.log(JSON.parse(lastSearch));

			// let fromDate = finelData.departDate.month+'/'+finelData.departDate.date+'/'+finelData.departDate.year;

			// let data = [
			// 	{name:'travalType', value:'0'},
			// 	{name:'bookingType', value:finelData.trip},
			// 	{name:'origin', value:finelData.from.ooro_AirportCode},
			// 	{name:'destination', value:finelData.to.ooro_AirportCode},
			// 	{name:'travelDate', value:finelData.departDateStr},
			// 	{name:'returnDate', value:finelData.returnDateStr},
			// 	{name:'tripId', value:finelData.trip},
			// 	{name:'adultsCount', value:finelData.travellersData.adult.toString()},
			// 	{name:'childCount', value:finelData.travellersData.children.toString()},
			// 	{name:'infantCount', value:finelData.travellersData.infants.toString()},
			// 	{name:'classOfTraval', value:finelData.travellersData.seatClassesData.id},
			// 	{name:'inventoryType', value:'0'}
			// ];

			// this.setState({
			// 	finelData:finelData
			// })
			// console.log('--- final search data ---',finelData);
			// //return(<Redirect to={{pathname:'/search',data:finelData}} />);

			// //console.log('--- body ----',data);
			
			// //this.getFlightsData(data);

			// let fData = [];
			// let amountRange = [];
			// let filterFlights = [];

			// 	console.log("=== allFlightsData ===", allFlightsData);
			// 	fData = allFlightsData.TripDetails.Flights.Flights;
			// 	amountRange = allFlightsData.TripDetails.Flights.Amount_Range;
			// 	filterFlights = allFlightsData.TripDetails.Flights.Filter_Flights;
			// 	console.log("== fData ==", fData);
			// this.setState({
			// 	isBtnLoader: false,
			// 	//redirect:true,
			// 	allFlights: fData[0],
			// 	returnAllFlights: fData[1],
			// 	amountRange:amountRange,
			// 	filterFlights:filterFlights
			// });
			// console.log('--- All Flights Data ---',this.state.allFlights);
		} catch (error) {
			throw new Error(error);
		}
	}

	renderRedirect = () => {
		if (this.state.redirect) {
		  return <Redirect to={{pathname:'/search',
		  data:this.state.finelData, 
		  searchFlight:this.state.allFlights, 
		  allAirlines:this.state.allAirlines, 
		  returnAllFlights:this.state.returnAllFlights, 
		  amountRange: this.state.amountRange, 
		  filterFlights: this.state.filterFlights}} />
		}
	}


	

  render(){
		try{
			let fromClass ='mb-4 h s-label ';
			let toClass ='mb-4 h s-label ';
			let departClass ='mb-2 h s-label';
			let travellersClass ='mb-4 h s-label';
			//   let oneWayClass = "tab";
			//   let roundTripClass = "tab";
			//   if(this.state.trip){
			// 	roundTripClass +=" active";
			//   }
			//   else{
			// 	oneWayClass +=" active";
			//   }

			if(this.state.fromDropdown){
				//alert('hai');
				fromClass = 'mb-4 h s-label active-text';
			}

			if(this.state.toDropdown){
				//alert('hai');
				toClass = 'mb-4 h s-label active-text';
			}

			if(this.state.depart){
				//alert('hai');
				departClass = 'mb-2 h s-label active-text';
			}

			if(this.state.travellers){
				//alert('hai');
				travellersClass = 'mb-4 h s-label active-text';
			}
			let searchData = this.state;
			//let url = '/search?';
			let directFlight = 0;
			if(searchData.directFlight == true){
				directFlight = 1;
			}

			let selectedDate = this.state.departDateStr;

			if(this.state.trip === 1){
				selectedDate = {from: this.state.departDateStr, to: this.state.returnDateStr}
			}
			
			let url = '/search/'+searchData.fromSelectedAirport.ooro_AirportCode+'/'+searchData.toSelectedAirport.ooro_AirportCode+'/'+searchData.departDateStr+'/'+searchData.returnDateStr+'/'+searchData.travellersData.adult+'/'+searchData.travellersData.children+'/'+searchData.travellersData.infants+'/'+searchData.trip+'/'+directFlight+'/'+searchData.travellersData.seatClassesData.id;

			//console.log('items',this.state.isBtnLoader);
			return(
				<div className="search-card">
					{this.renderRedirect()}
					<Modal size="lg" dialogClassName="modal-90w modal-dialog-sm" centered show={this.state.depart} onHide={this.toggleDepart} 
					// backdrop="static" keyboard={false}
					>
						<Modal.Body className="p-0" 
						// onClick={this.toggleDepart}
						>
							<card className="bg-white sm-device md-device lg-device xl-device">
									{/* <h1 className="m-0 p-2 text-center color-primary">Departure</h1> */}
									<CustomCalender isMobile={false} price={this.state.price} selectedDate={selectedDate} type={false} returnDate={this.setDepartDate} trip={this.state.trip} />
									{/* <Calender price={this.price} selectedDate={[this.state.departDateStr]} returnDate={this.setDepartDate} trip={0} /> */}
							</card>
							<card className="bg-white xs-device">
									{/* <h1 className="m-0 p-2 text-center color-primary">Departure</h1> */}
									<CustomCalender isMobile={true} price={this.state.price} selectedDate={selectedDate} type={false} returnDate={this.setDepartDate} trip={this.state.trip} />
									{/* <Calender price={this.price} selectedDate={[this.state.departDateStr]} returnDate={this.setDepartDate} trip={0} /> */}
							</card>
						</Modal.Body>
					</Modal>
					<Modal size="lg" dialogClassName="modal-90w modal-dialog-sm" centered show={this.state.return} onHide={this.toggleReturn} 
					// backdrop="static" keyboard={false}
					>
						<Modal.Body className="p-0" 
						// onClick={this.toggleDepart}
						>
							<card className="bg-white sm-device md-device lg-device xl-device">
									{/* <h1 className="m-0 p-2 text-center color-primary">Return</h1> */}
									<CustomCalender isMobile={false} price={this.state.price} selectedDate={selectedDate} type={true} returnDate={this.setDepartDate} trip={this.state.trip} />
							</card>
							<card className="bg-white xs-device">
									{/* <h1 className="m-0 p-2 text-center color-primary">Return</h1> */}
									<CustomCalender isMobile={true} price={this.state.price} selectedDate={selectedDate} type={true} returnDate={this.setDepartDate} trip={this.state.trip} />
							</card>
						</Modal.Body>
					</Modal>
					<Modal size="lg" dialogClassName="modal-90w modal-dialog-sm" centered show={this.state.departMobile} onHide={this.toggleDepartMobile} 
					// backdrop="static" keyboard={false}
					>
						<Modal.Body className="p-0" 
						// onClick={this.toggleDepart}
						>
							<card className="bg-white xs-device">
									{/* <h1 className="m-0 p-2 text-center color-primary">Departure</h1> */}
									<CustomCalender isMobile={true} price={this.state.price} selectedDate={selectedDate} type={false} returnDate={this.setDepartDateMobile} trip={this.state.trip} />
									{/* <Calender price={this.price} selectedDate={[this.state.departDateStr]} returnDate={this.setDepartDate} trip={0} /> */}
							</card>
						</Modal.Body>
					</Modal>
					<Modal size="lg" dialogClassName="modal-90w modal-dialog-sm" centered show={this.state.returnMobile} onHide={this.toggleReturnMobile} 
					// backdrop="static" keyboard={false}
					>
						<Modal.Body className="p-0" 
						// onClick={this.toggleDepart}
						>
							<card className="bg-white xs-device">
									{/* <h1 className="m-0 p-2 text-center color-primary">Return</h1> */}
									<CustomCalender isMobile={true} price={this.state.price} selectedDate={selectedDate} type={true} returnDate={this.setDepartDateMobile} trip={this.state.trip} />
							</card>
						</Modal.Body>
					</Modal>
					<Modal size="lg" dialogClassName="modal-90w modal-dialog-sm d-unset" centered show={this.state.fromDropdownMobile} onHide={this.toggleFromDropdownMobile} 
					// backdrop="static" keyboard={false}
					>
						<Modal.Body className="p-0" 
						// onClick={this.toggleDepart}
						>
							{/* <p>hjdhajdshs dnjd djadjadh</p> */}
							<img src={multiply} className="position-absolute a" width="20px" style={{top:12, right:8, zIndex:10}} onClick={this.toggleFromDropdownMobile} />
							<CustomDropdown visible={true} id="fromDropdown" classes="fromDropdownMobile" selectedAirport={this.fromSelectedAirportMobile} T10India={this.state.T10India} datas={this.state.allAirportsData} cId={this.state.fromSelectedAirport?.id} selectedId={this.state.toSelectedAirport?.id} />
						</Modal.Body>
					</Modal>
					<Modal size="lg" dialogClassName="modal-90w modal-dialog-sm d-unset" centered show={this.state.toDropdownMobile} onHide={this.toggleToDropdownMobile} 
					// backdrop="static" keyboard={false}
					>
						<Modal.Body className="p-0" 
						// onClick={this.toggleDepart}
						>
							<img src={multiply} className="position-absolute a" width="20px" style={{top:12, right:8, zIndex:10}} onClick={this.toggleToDropdownMobile} />
							<CustomDropdown visible={true} id="toDropdown" classes="fromDropdownMobile" selectedAirport={this.toSelectedAirportMobile} T10India={this.state.T10India} datas={this.state.allAirportsData} cId={this.state.toSelectedAirport?.id} selectedId={this.state.fromSelectedAirport?.id} />
						</Modal.Body>
					</Modal>
					<Modal size="lg" dialogClassName="modal-90w modal-dialog-sm d-unset position-relative" centered show={this.state.travellersMobile} onHide={this.toggleTravellersMobile} 
					// backdrop="static" keyboard={false}
					>
						<Modal.Body className="p-0" 
						// onClick={this.toggleDepart}
						>
							<img src={multiply} className="position-absolute a" width="20px" style={{top:12, left:8, zIndex:10}} onClick={this.toggleTravellersMobile} />
							<p className="text-center m-0 bg-gradient py-3 px-2" style={{color: 'white'}}>Travellers</p>
							<TravellersDropdown visible={true} id="travellers" classes="travellersMobile" onHandel={this.selectedTravellersMobile} data={this.state.travellersData} />
						</Modal.Body>
					</Modal>
					{/* onClick={this.clearDropdown} */}
					<Card className="search-card my-card border-radius" style={{overflow: 'unset'}}>
						<CardContent className="position-relative">
							<Grid container>
								<Grid item xs={12} sm={8} md={8} lg={8} xl={8} className="mb-3">
									<Grid container>
										<Grid item xs={5} sm={4} md={3} lg={3} xl={3} className="tab" onClick={this.oneWay}>
											<p className="h">One Way</p>
											<hr className={this.state.oneWayClass} />
										</Grid>
										<Grid item xs={5} sm={4} md={3} lg={4} xl={4} className="tab" onClick={this.roundTrip}>
											<p className="h">Round TRIP</p>
											<hr className={this.state.roundTripClass} />
										</Grid>
										{/* <Grid item xs={4} sm={4} md={3} lg={3} xl={3} className="text-center">
											<p>M</p>
										</Grid> */}
									</Grid>
								</Grid>
								<Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="border-xs-top pt-xs-3">
									<p>
										<img src={Ticket} style={{width:'25px',float:'right' ,marginTop:'3px'}} />
										<span className="l-b h5 h float-xs-start" style={{float:'right',marginRight:'0.75rem'}}>Book Flight Tickets</span>
									</p>
								</Grid>
							</Grid>
							{/* <hr /> */}
							<div className="sm-device md-device lg-device xl-device">
								<Grid container className="mt-2 border py-1" style={{borderRadius:'10px'}}>
									<Grid item xs={6} sm md lg xl className="px-4 py-4 from position-relative">
										<label for="fromDropdown" style={{cursor:'pointer'}} onClick={this.toggleFromDropdown}>
											<p className={fromClass} style={{lineHeight: '1px'}}>FROM</p>
											
											<span className="h3 bold pt-2" style={{lineHeight: '1px', fontSize: '18px'}}>
												<DisplayAirport if={this.state.fromSelectedAirport?.ooro_AirportName} else="Select" />
											</span>
										</label>
										<CustomDropdown visible={this.state.fromDropdown} id="fromDropdown" classes="position-absolute" selectedAirport={this.fromSelectedAirport} T10India={this.state.T10India} datas={this.state.allAirportsData} cId={this.state.fromSelectedAirport?.id} selectedId={this.state.toSelectedAirport?.id} />
									</Grid>
									<Grid item xs={6} sm md lg xl className="border-left px-4 py-4 to position-relative">
										<SwapHorizIcon className="color-primary" style={{position:'absolute',left:'-11px', top:'30px',cursor:'pointer'}} onClick={this.switchToFrom}></SwapHorizIcon>
										<label for="toDropdown" style={{cursor:'pointer'}} onClick={this.toggleToDropdown}>
											<p className={toClass} style={{lineHeight: '1px'}}>TO</p>
											
											<span className="h3 bold" style={{lineHeight: '1px', fontSize: '18px'}}>
												<DisplayAirport if={this.state.toSelectedAirport?.ooro_AirportName} else="Select" />
											</span>
										</label>
										<CustomDropdown visible={this.state.toDropdown} id="toDropdown" classes="position-absolute" selectedAirport={this.toSelectedAirport} T10India={this.state.T10India} datas={this.state.allAirportsData} cId={this.state.toSelectedAirport?.id} selectedId={this.state.fromSelectedAirport?.id} />
									</Grid>
									<Grid item xs={6} sm md lg xl className="border-left departure px-2 py-2 border-xs-top" onClick={this.toggleDepart} style={{cursor:'pointer'}}>
										<label for="" style={{cursor:'pointer'}}>
											<p className={departClass} style={{lineHeight: '1px'}}>DEPARTURE <EventNoteIcon className="color-primary"></EventNoteIcon></p>
											
											<span className="h3 bold" style={{lineHeight: '1px', fontSize: '18px'}}>{this.state.departDate.date} {monthNames[this.state.departDate.month]}</span>
											
											<p className="sm-p h" style={{lineHeight: '20px'}}>{this.state.departDate.year}</p>
										</label>
									</Grid>
									<Grid item xs={6} sm md lg xl className="border-left px-4 py-2 border-xs-top">
										<label for="" style={{cursor:'pointer'}} onClick={this.handelReturn}>
											<p className={departClass} style={{lineHeight: '1px'}}>RETURN <EventNoteIcon className="color-primary"></EventNoteIcon></p>
											<DisplayReturn returnDateStr={this.state.returnDateStr} returnDate={this.state.returnDate} trip={this.state.trip} />
										</label>
									</Grid>
									
									<Grid item xs={12} sm={3} md={3} lg={3} xl={3} className="px-3 py-3 border-left border-xs-top">
										<div className="w-100 position-relative" style={{display: 'inline-block'}}>
											<label for="" onClick={this.toggleTravellers} style={{cursor:'pointer'}}>
												<p className={travellersClass} style={{lineHeight: '1px'}}>TRAVELLERS & CLASS</p>
												<span className="h3 bold pt-2" style={{lineHeight: '1px'}}>{this.state.travellersData.adult + this.state.travellersData.children + this.state.travellersData.infants}</span> <span className="bold h" style={{fontSize: '15px'}}>Traveller</span>
												<p className="sm-p h" style={{lineHeight: '10px'}}>{this.state.travellersData.seatClassesData.name}</p>
											</label>
											<TravellersDropdown visible={this.state.travellers} id="travellers" classes="position-absolute" onHandel={this.selectedTravellers} data={this.state.travellersData} />
										</div>
									</Grid>
								</Grid>
							</div>
							<div className="xs-device">
								<Grid container className="mt-2 border py-1" style={{borderRadius:'10px'}}>
									<Grid item xs={6} sm md lg xl className="px-4 py-4 from">
										<label for="fromDropdown" style={{cursor:'pointer'}} onClick={this.toggleFromDropdownMobile}>
											<p className={fromClass} style={{lineHeight: '1px'}}>FROM</p>
											
											<span className="h3 bold pt-2" style={{lineHeight: '1px', fontSize: '18px'}}>
												<DisplayAirport if={this.state.fromSelectedAirport?.ooro_AirportName} else="Select" />
											</span>
										</label>
									</Grid>
									<Grid item xs={6} sm md lg xl className="border-left px-4 py-4 to position-relative">
										<SwapHorizIcon className="color-primary" style={{position:'absolute',left:'-11px', top:'30px',cursor:'pointer'}} onClick={this.switchToFrom}></SwapHorizIcon>
										<label for="toDropdown" style={{cursor:'pointer'}} onClick={this.toggleToDropdownMobile}>
											<p className={toClass} style={{lineHeight: '1px'}}>TO</p>
											
											<span className="h3 bold" style={{lineHeight: '1px', fontSize: '18px'}}>
												<DisplayAirport if={this.state.toSelectedAirport?.ooro_AirportName} else="Select" />
											</span>
										</label>
									</Grid>
									<Grid item xs={6} sm md lg xl className="border-left departure px-2 py-2 border-xs-top" onClick={this.toggleDepartMobile} style={{cursor:'pointer'}}>
										<label for="" style={{cursor:'pointer'}}>
											<p className={departClass} style={{lineHeight: '1px'}}>DEPARTURE <EventNoteIcon className="color-primary"></EventNoteIcon></p>
											
											<span className="h3 bold" style={{lineHeight: '1px', fontSize: '18px'}}>{this.state.departDate.date} {monthNames[this.state.departDate.month]}</span>
											
											<p className="sm-p h" style={{lineHeight: '20px'}}>{this.state.departDate.year}</p>
										</label>
									</Grid>
									<Grid item xs={6} sm md lg xl className="border-left px-4 py-2 border-xs-top" onClick={this.handelReturnMobile}>
										<label for="" style={{cursor:'pointer'}}>
											<p className={departClass} style={{lineHeight: '1px'}}>RETURN <EventNoteIcon className="color-primary"></EventNoteIcon></p>
											<DisplayReturn returnDateStr={this.state.returnDateStr} returnDate={this.state.returnDate} trip={this.state.trip} />
										</label>
									</Grid>
									
									<Grid item xs={12} sm={3} md={3} lg={3} xl={3} className="px-3 py-3 border-left border-xs-top" onClick={this.toggleTravellersMobile} style={{cursor:'pointer'}}>
										<label for="" style={{cursor:'pointer'}}>
											<p className={travellersClass} style={{lineHeight: '1px'}}>TRAVELLERS & CLASS</p>
											<span className="h3 bold pt-2" style={{lineHeight: '1px'}}>{this.state.travellersData.adult + this.state.travellersData.children + this.state.travellersData.infants}</span> <span className="bold h" style={{fontSize: '15px'}}>Traveller</span>
											<p className="sm-p h" style={{lineHeight: '10px'}}>{this.state.travellersData.seatClassesData.name}</p>
										</label>
									</Grid>
								</Grid>
							</div>
							
							<Grid container className="">
								<Grid item xs>
									{/* <Checkbox checked={this.state.directFlight} onChange={this.setDirectFlight}></Checkbox> <span style={{fontSize:'12px'}} >Direct Flights</span> */}
								</Grid>
							</Grid>
							<Grid container className="mt-4">
								<Grid item xs={12} sm md lg xl></Grid>
								<Grid item xs={12} sm md lg xl>
									<NavLink target={this.props?.isAlone? '_blank': null} to={{pathname:url}}>
										<Button variant="contained" disabled={this.state.isBtnLoader} type="button" className="shadow my-success-btn color-w bg-gradient w-100-per border-0 pd-10 position-relative mt-xs-4" onClick={this.onSubmit}>
											SEARCH FLIGHTS 
											<HandleSearch status={this.state.isBtnLoader} />
											
											{/* <div className="position-absolute w-100 text-center" style={{bottom:0,left:'6%'}}>
												<Progress percent={100} active className="my-progress-primary m-0 p-0" />
											</div> */}
										</Button>
									</NavLink>
								</Grid>
								<Grid item xs={12} sm md lg xl></Grid>
							</Grid>
						</CardContent>
						<CardActions>
							<Grid container>
								<Grid item xs={4} sm={8} md={8} lg={9} xl={9}>
									
								</Grid>
								<Grid item xs={8} sm={4} md={4} lg={3} xl={3}>
									{/* <span style={{float:'right',marginRight:'20px'}}>
										<ReportProblemIcon className="r-color" style={{fontSize:'17px'}}></ReportProblemIcon>	
										<span className="r-color l-s">Covid 19 Updates</span>
									</span> */}
								</Grid>
							</Grid>
						</CardActions>
					</Card>
				</div>
			);
		} catch (error) {
			throw new Error(error);
		}
    }
}

export default SearchCard;