
export default class CustomFunction {

    
    filterUniqueData = (key, array) =>{

        let arrayUniqueByKey = [...new Map(array.map(item =>
        [item[key], item])).values()];
        return arrayUniqueByKey;
    }

    checkKey = (index, array) =>{
        //console.log("array", array, index);
        let arr = [];
        let condition = ['A', 'B', 'C', 'D', 'E', 'F'];
        condition.map(c =>{
            //console.log(e.SSR_TypeName.length);
            let status = 0;
            array.map(e =>{
                if(Object.values(e).indexOf(`${index}${c}`) > -1){
                    // console.log(Object.values(e).indexOf(`${index}${c}`) > -1);
                    // console.log(Object.values(e));
                    // console.log(`${index}${c}`);
                    arr.push({...e, index:0});
                    status = 1;
                }
            });

            if(status == 0){
                //console.log("== status ==", status);
                //arr.push({Currency_Code:"INR"});
                arr.push({Currency_Code:"INR", Leg_Index:0, SSR_Status:0, SSR_TypeDesc: `SEAT-${index}${condition[arr.length]}`, SSR_TypeName: `${index}${condition[arr.length]}`, Total_Amount:0, index:1});
            }
        });
        console.log("=== arr ===", arr);
        return arr;
    }

    scrollTop(){
        let element = document.getElementById('body');
        console.log("=== bodyElement ===", element);
        element.scroll(0, 0);
    }

    getImage = (path) => {
        try{
            let imgData = require('../../assets/AL1/'+path);
            return imgData;
        }
        catch(err){
            // console.log("=== error getImage ===", process.env.PUBLIC_URL);
            return require('../../assets/AL1/Default.gif');
        };
    }

}