import "./SearchCard.scss";
import "./SearchCard.css";
import logo from '../../../assets/Icons/logo.png';
import plane from '../../../assets/Icons/plane2.png';
import blankLogo from '../../../assets/Icons/blank-profile.jpeg';
import bgPng from '../../../assets/Icons/bg-plane.png';
// import bgPng from '../../../assets/Icons/bg-png-c.png';
import Grid from '@material-ui/core/Grid';
import { Image, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import StayUptoDate from '../../../shared/component/StayUptoDate.js';
import SupperOffer from '../../../shared/component/SupperOffer.js';
import SearchCard from '../../../shared/component/SearchCard.js';
import Testimonial from '../../../shared/component/Testimonial.js';
import Footer from '../../../shared/component/Footer.js';
import OurFamily from '../../../shared/component/OurFamily.js';
import TravelStory from '../../../shared/component/TravelStory.js';
import PopularDomesticFlightRout from '../../../shared/component/PopularDomesticFlightRout.js';
import React from "react";
import Services from "../../../shared/services/services";
import displayMessages from "../../../shared/displayMessages/displayMessages";
import { Button, Dropdown, Menu } from 'semantic-ui-react';
import Login from "../Login/Login";
import LoginMobile from "../Login/LoginMobile";
import multiply from "../../../assets/Icons/multiply.svg";
import menu from "../../../assets/Icons/menu.png";
//import Input from '../../../shared/component/Input.js';
import TreeViews from "../../../shared/ui/TreeViews";
import CryptoJS from "crypto-js";
import FooterLink from "../../../shared/component/FooterLink";
import CustomNavLink from "../../../shared/component/CustonNavLink";


function Headers(prop) {
	try{
		return(
			<div className="bg-white" style={{fontSize:'16px'}}>
				<span className="float-left">
					<b>{prop.LValue}</b>
				</span>
				<span className="float-right" style={{fontSize:'16px'}}>
					<b>{prop.RValue}</b>
				</span>
			</div>
		);
	} catch (error) {
		throw new Error(error);
	}
}

const Body = (prop) =>{
	try{
		return(
			<div className="h5 c-h p-3" style={{lineHeight: '30px',fontWeight:300}} dangerouslySetInnerHTML={{__html: prop.data}}>
				
			</div>
		);
	} catch (error) {
		throw new Error(error);
	}
}

class SearchCardAlone extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			allAirlines:[],
			loginModel:false,
			logedin: false,
			additionalIndex: 0,
		}
	}

	componentDidMount(){
		try{
			var datas = [{ id: 1, name: 'Anil' }, { id: 2, name: 'Sunil' }]

			// Encrypt
			var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(datas), '123', '6666666666666666').toString();
			//log encrypted data
			console.log('Encrypt Data -')
			console.log(ciphertext);
			//alert(ciphertext);

			// Decrypt
			var bytes = CryptoJS.AES.decrypt(ciphertext, '123', '6666666666666666');
			var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

			//log decrypted Data
			console.log('decrypted Data -')
			console.log(decryptedData);

			let data = [{name:'getAll', value:'1'}]
			console.log("=== getAllAirports ===");
			new Services().getAllAirports(data).then(res => res.json()).then(
				(result) => {
					this.setState({
						isLoaded: true,
						allAirlines: result.message
					});
					console.log('--- allAirlines ----',this.state.allAirlines);
				},
				(error) => {
					console.log("== error ==", error);
					this.setState({
						isLoaded: true,
						error
					});
					// new displayMessages().errorMsg({error:error});
				}
			);
		} catch (error) {
			throw new Error(error);
		}
	}

	toggleLoginModel = () => this.setState({loginModel: !this.state.loginModel});

	logout = () =>{
		localStorage.removeItem('OoroToken');
		localStorage.removeItem('OoroUserData');
		this.setState({logedin: false});
	}

	render(){
		try{
			let additionalIndex = this.state.additionalIndex;
			let additionalActive = [];
			this.props.mainPageData.additional.map(e =>{
				additionalActive.push('');
			});
			additionalActive[this.state.additionalIndex] = 'active';
			
			let tabsData = [];

			this.props.mainPageData.additional.map((e, i) =>{
				tabsData.push(<Grid item xs={4} xl={4} className={"d-tab text-left " + additionalActive[i]} onClick={() =>{this.setState({additionalIndex: i})}}>
					<span className="mb-0 pb-2">{e.title}</span>
				</Grid>)
			});

			let navLi = [];
			this.props.nav.map(e =>{
				let active = '';
				if(e.name == this.props.activeName){
					active = 'actives';
				}
				navLi.push(<li className={active}><CustomNavLink url={e.url} target={e.target} name={e.name}></CustomNavLink></li>);
			});
			let displayLoginBtn = 'unset';
			let displayProfileLogo = 'none';
			let token = localStorage.getItem('OoroToken');
			let userData = localStorage.getItem('OoroUserData');
			console.log("=== token ===", token);
			console.log("=== userData ===", userData);

			
			if(token){
				displayLoginBtn = 'none';
				displayProfileLogo = 'unset';
			}

			let testimonials = [];
			this.props.mainPageData.additional.map((e, i) =>{
				testimonials.push({id: (i + 1) ,label:<Headers LValue={e.title} RValue={<></>} />,body:<Body data={e.content} />});
			
			});
			
			return (
			<div className="Home">
				<Grid container className="h-120vh" style={{padding: '0rem 3rem'}}>
					<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={10} className="search-card-heading" style={{marginTop: '20px'}}>
								<SearchCard data={{allAirlines:this.state.allAirlines}} isAlone={true} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			);
		} catch (error) {
			throw new Error(error);
		}
	}
  }
  
  export default SearchCardAlone;