import React from 'react';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import { NavLink, Redirect } from 'react-router-dom';
const sww = require('../../assets/Icons/spinner.gif');
//console.log(sww.default);

export default class CustomSpinnner extends React.Component {

    refresh(){
        window.location.reload();        
    }

    render() {
        try{
            let url = ``;
            return (
                <>
                    <Grid container className="py-5">
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} className="text-center">
                            <img src={sww.default} style={{width:"100px"}} />
                            <h1 style={{fontSize:'14px'}}>Please Wait</h1>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        
                    </Grid>     
                </>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}