// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";

// import img
import Ticket from '../../assets/Icons/airplane-ticket.png';
import Path2481 from '../../assets/Icons/Path-2481.png';
import Group1206 from '../../assets/Icons/Group_1206.png';
import Path2482 from '../../assets/Icons/Path2482.png';
import Indigo from '../../assets/Icons/indigo.png';
import uk from "../../assets/AL1/UK.gif";
import rupeeIcon from '../../assets/Icons/icons8-rupee-24.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import { Row, Form, Button } from 'react-bootstrap';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Checkbox from '@material-ui/core/Checkbox';
import FlightIcon from '@material-ui/icons/Flight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { NavLink } from 'react-router-dom';
import FlightDetails from "./FlightDetails";
import CustomFunction from "../library/CustomFunction";


const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const float2int = (value) => {
    return value | 0;
}

const getNoOfDays = (d1,d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    
    // To calculate the time difference of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();
    
    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}

const DirectFlight = (prop) => {
    try{
        //console.log(prop);
        if(prop.data.stop == 0){
            return <p className="pt-3" style={{fontSize: '11px'}}>Non Stop </p>
        }
        else {
            //let via = '';
            let via = prop.data.segments[0].Destination_City.split('(')[0];
            for (let index = 1; index < prop.data.segments.length-1; index++) {
                via = via+', '+ prop.data.segments[index].Destination_City.split('(')[0];
                
            }
            return <p className="pt-3" style={{fontSize: '11px'}}>{prop.data.stop} Stop, via {via} </p>
        }
    } catch (error) {
        throw new Error(error);
    }
}

const DayHoursMins = (prop) =>{
    //console.log(prop);
    if(prop.data.DurD == 0){
        return(<>{prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
    }
    else{
        return(<>{prop.data.DurD}Day {prop.data.DurH}Hrs {prop.data.DurM}Mins </>);
    }
}

class FooterSearchList extends Component {

    // constructor(props){
    //     super(props);
    //     this.state = {isToggleOn: true};
    // }
    
    state = {
        hide: true
    }

    toggleVisibility = () => this.setState({ hide: !this.state.hide })

    
    className = "search-least "+this.props.classes;

    render() {
        try{
            //console.log(this.props.data);
            let dep = this.props.data.Segments[0].Departure_DateTime.split(' ');
            let ariv = this.props.data.Segments[this.props.data.Segments.length - 1].Arrival_DateTime.split(' ');

            let next = getNoOfDays(dep[0], ariv[0]);
            let nextText = '';
            if(next == 1){
                nextText = "NEXT DAY";
            }
            if(next > 1){
                //console.log(ariv[0]);
                let dd = new Date(ariv[0]);
                let dd1 = dd.getDay();
                //console.log(dd);
                //console.log(dd1);
                nextText = "On "+dayName[dd1];
            }

            
            const images = new CustomFunction().getImage(this.props.data.Airline_Code+'.gif');
            //images.default
            //console.log(images.default);
            return(
            <div className="footer-search-list">
                <Grid container className="m-0 py-0 px-3">
                    <Grid item xs={12}>
                        <span className="float-left py-2 m-0"><b className="py-0 px-2 ps-0">{this.props.data.AlName.toUpperCase()}</b>
                            <span className="gray-color f-w-600 py-0 px-2" style={{borderLeft: '2px solid #9e9e9e'}}>{this.props.data.Segments[0].Airline_Code}-{this.props.data.Segments[0].Flight_Number}</span>
                        </span>
                        
                    </Grid>
                </Grid>
                <Grid container className="mt-0 py-0 pb-0 px-3" style={{borderBottom:'0px dashed #adacac69'}}>
                    <Grid item xs={1} className="py-1">
                        <img src={images.default} width="45px" className="float-left air-plane-logo" />
                        
                    </Grid>
                    <Grid item xs className="py-1 position-relative text-center">
                        <h2 className="h3 p-0 m-0" style={{fontSize:'15px'}}>{dep[1]}</h2>
                        <p><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.OrgName.split('(')[0]}</b></p>
                    </Grid>
                    <Grid item xs sm={3} className="px-0 text-center">
                        <p className="pb-2" style={{fontSize: '9px'}}> <DayHoursMins data={this.props.data} /> </p>
                        <div style={{borderTop:'2px dashed gray',width:'100px',margin:'auto'}} className="position-relative way-details">
                            <FiberManualRecordIcon className="position-absolute gradient2-color" style={{top:'-12px',left:'20px'}}></FiberManualRecordIcon>
                            <img src={Path2481} width="25px" className="float-left position-absolute" style={{right:0,top:'-13px'}} />
                        </div>
                        <DirectFlight data={{segments:this.props.data.Segments,stop:this.props.data.stops}} />
                    </Grid>
                    <Grid item xs className="py-1 text-center">
                        <h2 className="h3 p-0 m-0" style={{fontSize:'15px'}}>{ariv[1]}</h2>
                        <p><b className="gray-color p-0 m-0" style={{fontSize:'12px'}}>{this.props.data.DestName.split('(')[0]}</b></p>
                        <p className="text-danger p-0 m-n1" style={{fontSize:'9px'}}><b>{nextText.toUpperCase()}</b></p>
                    </Grid>
                    
                    <Grid item xs className="px-1 py-3">
                        <h1 className="h3" style={{fontSize:'18px',fontWeight: 'bolder', fontFamily: 'sans-serif'}}>₹ {this.props.data.Fares.FareDetails.Total_Amount}</h1>
                    </Grid>
                    <Grid item xs={12} className="px-3 text-right">
                        {/* <NavLink to="/review-flight">
                            <Button className="bg-gradient p-2" style={{width:'135px',color:'white',border:'0px solid black'}}>
                                Book Now
                            </Button>
                        </NavLink> */}
                    </Grid>
                </Grid>
            </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default FooterSearchList;