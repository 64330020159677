import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './dashboard/src/styles/app.scss'
import './App.css';
import './Globle.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Login from './web/component/Login/Login.js';
import Grid from '@material-ui/core/Grid';
import ReviewFlight from './web/component/ReviewFlight/ReviewFlight.js';
import SearchResult from './web/component/SearchResult/SearchResult.js';
// import Signup from './web/component/Signup/Signup.js';
// import HeaderBar from './web/component/Header/Header.js';
import TravelStoryDetails from './web/component/TravelStoryDetails/TravelStoryDetails.js';
// import Payment from './web/component/Payment/Payment.js';
import Home from './web/component/Home/Home.js';
import { Route, Switch, Link, withRouter, Redirect } from 'react-router-dom';
import { Menu, Segment, Sidebar, Button } from 'semantic-ui-react';
import {Image} from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import {connect} from 'react-redux';
import SomeThingWentWrong from './shared/component/SomeThingWentWrong';
import Header from "./shared/component/Header";
//import Service from './shared/services/services';
import Services from "./shared/services/services";
import displayMessages from "./shared/displayMessages/displayMessages";
import blankLogo from './assets/Icons/012218f4d43ade31f4e6146e2178f4be.jpg';
import multiply from "./assets/Icons/multiply.svg";
import Loading from './shared/component/Loading';
import Page from './web/component/Page/Page';
import ContactUs from './web/component/ContactUs/ContactUs';
import Confirmation from './web/component/Confirmation/Confirmation';
import LayoutComponent from './dashboard/main';
import SearchCardAlone from './web/component/SearchCard/SearchCard';
import CustomNavLink from './shared/component/CustonNavLink';
import ErrorBoundary from './core/exceptions/errorBoundary';
import { AllAirportsProvider } from './core/provider/AllAirportsProvider';
// import DashboardApp from './dashboard/src/components/App';

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  if (!localStorage.getItem("OoroToken")) {
    // dispatch(logoutUser());
    return (<Redirect to="/" />)
  } else {
    return (
      <Route { ...rest } render={props => (React.createElement(component, props))} />
    );
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display:"none"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

// let v = false;

// function toggle() {
//   v = !v;
//   //setVisible(v);
// }

const NotFoundUrl = (prop) =>{
  return(
    <>
      <Header openSideBar={(status) =>prop.openSideBar(status)} nav={prop.nav} />
      <Grid container className="px-2 py-4 pb-0" style={{marginTop:'100px'}}>
          <Grid item xs={12} className="pt-2 d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
              <SomeThingWentWrong />
          </Grid>
      </Grid>
    </>
  );
}

// const SearchResultIn = (prop) =>{
//   const { from, to, fromDate, toDate, adult, children, infants, trip, directFlight, seatClass } = prop.match.params;
//   console.log({from:from, to:to, fromDate:fromDate, toDate:toDate, adult:adult, children:children, infants:infants, trip:trip, directFlight:directFlight, seatClass:seatClass});
//   return(
//     <>
//     {/* <SearchResult openSideBar={(data) => prop.openSideBar(true)} /> */}
//     </>
//   )
// }

function App() {

  const [visible, setVisible] = React.useState(false);
  const [pageData, setPageData] = React.useState({});
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loginModel, setLoginModel] = React.useState(true);
  // const [logedin, setLogedin] = React.useState(true);
  const classes = useStyles();

  const sidebar = (status) =>{
    setVisible(status);
    console.log("=== atif ===");
  }

  const toggleLoginModel = () => setLoginModel(!loginModel);

  // const logout = () =>{
  //   localStorage.removeItem('OoroToken');
  //   localStorage.removeItem('OoroUserData');
  //   setLogedin(false);
  //   //this.props.onLoggedIn(false);
  // }
  
    setInterval(() => {
      let token = localStorage.getItem('OoroToken');
      let userData = localStorage.getItem('OoroUserData');
      //console.log("== check token ==", token);
      if(token){
        new Services().profile(token).then(res => res.json()).then((results) =>{
          //console.log("=== profile details ===", results);
          if(results.success === false){
            localStorage.removeItem('OoroToken');
            localStorage.removeItem('OoroUserData');
          }
        }, (error) =>{
          console.log("== error ==", error);
        });
      }
      
    }, 1000);

    React.useEffect(() => {
      // document.title = "Ooro Flights";
      
      new Services().getPageDerails().then(res => res.json()).then(
        (result) => {
            console.log(result);
            setPageData(result)
            setLoading(false);
            setError(false);
            //this.setAllFlightsData(result);
        
        },
        (error) => {
          console.log("== error ==", error);
          setLoading(false);
          setError(true);
          // new displayMessages().errorMsg({error:error});
        }
      );
    }, [])

  if(loading){
    return (
        <div className="app">
          <Loading text="Loading...." />
        </div>
      );
  }
  else if(error){
    return (
      <div className="app pt-5 align-items-center">
        <SomeThingWentWrong />
      </div>
    );
  }
  else{
    let displayLoginBtn = 'unset';
    let displayProfileLogo = 'none';
    let token = localStorage.getItem('OoroToken');
    let userData = localStorage.getItem('OoroUserData');
    console.log("=== token ===", token);
    console.log("=== userData ===", userData);

    
    if(token){
      displayLoginBtn = 'none';
      displayProfileLogo = 'unset';
    }

    let menu = [];
    
    pageData?.header?.nav?.map(e =>{
      menu.push(<Menu.Item as='a' className="text-start">
        <CustomNavLink url={e.url} target={e.target} name={e.name} style={{textDecoration: 'unset'}}></CustomNavLink>
        {/* <Link to={e.url} target={e.target}>
          <Icon name='home' />
          {e.name}
        </Link> */}
    </Menu.Item>)
    });


    console.log("width & height", document.getElementById('body').offsetHeight, window.outerHeight);

    document.documentElement.style.setProperty('--app-width', document.getElementById('body').offsetWidth + 'px');
    document.documentElement.style.setProperty('--app-height', document.getElementById('body').offsetHeight + 'px');

    return (
      <ErrorBoundary>
        <AllAirportsProvider>
          <div className="app" style={{overflow: 'auto'}}>
            <Sidebar.Pushable as={Segment}>
              <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                inverted
                // onHide={() => setVisible(false)}
                vertical
                visible={visible}
                width='thin'
              >
                <Grid container className="mt-5">
                <img src={multiply} className="position-absolute a" width="25px" style={{top:10, right:10, zIndex: 10}} onClick={() =>setVisible(false)} />
                  <Grid xs={12} className="text-center mb-1 p-2">
                    <Image src={blankLogo} roundedCircle style={{display: displayProfileLogo, width:'70px', height:'70px', border:'1px solid white'}} />
                    <Button size="large" className="login-btn me-5 my-4" style={{display: displayLoginBtn, fontSize: '14px', width: '100%'}} onClick={toggleLoginModel}>Login/Register</Button>
                  </Grid>
                  <Grid xs={12} className="mb-3 text-start">
                      {menu}
                      {/* <Menu.Item as='a'>
                        <Link to="/about">
                          <Icon name='info' />
                          About
                        </Link>
                      </Menu.Item>
                      <Menu.Item as='a'>
                        <Link to="/service">
                          <Icon name='servicestack' />
                          Service
                        </Link>
                      </Menu.Item>
                      <Menu.Item as='a'>
                        <Link to="/contact">
                          <Icon name='phone' />
                          Contact
                        </Link>
                      </Menu.Item> */}
                  </Grid>
                </Grid>
              </Sidebar>
      
              <Sidebar.Pusher dimmed={visible}>
                <div className={classes.root}>
                  <AppBar position="static">
                    <Toolbar>
                      <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" value={true} onClick={(e, data) => setVisible(true)}>
                        <MenuIcon />
                      </IconButton>
                      <Typography variant="h6" className={classes.title}>
                        News
                      </Typography>
                      {/* <Button color="inherit">Login</Button> */}
                    </Toolbar>
                  </AppBar>
                </div>
                <Switch>
                    {/* <Route exact path="/login" component={Login} /> */}
                    {/* <Route exact path="/" component={Login} /> */}
                    {/* <Route exact path="/">
                      <Redirect to="" />
                    </Route> */}
                    {/* <Route exact path="/signup" component={Signup} /> */}
                    <Route exact path="/">
                      <Home openSideBar={sidebar} nav={pageData?.header?.nav} bannerData={pageData.header?.background_img} mainPageData={pageData.main_page} footer={pageData.footer} />
                    </Route>
                    <Route exact path="/search-alone">
                      <SearchCardAlone openSideBar={sidebar} nav={pageData?.header?.nav} mainPageData={pageData.main_page} footer={pageData.footer} />
                    </Route>
                    <Route exact path="/search/:from/:to/:fromDate/:toDate/:adult/:children/:infants/:trip/:directFlight/:seatClass" render={(prop) =>(<SearchResult {...prop} openSideBar={sidebar} nav={pageData?.header?.nav} />)} />
                      {/* <SearchResult openSideBar={sidebar} />
                    </Route> */}
                    <Route exact path="/review-flight" render={(prop) =>(<ReviewFlight {...prop} openSideBar={sidebar} nav={pageData?.header?.nav} />)} />
                      {/* <ReviewFlight openSideBar={sidebar} />
                    </Route> */}
                    <Route exact path="/details">
                      <TravelStoryDetails openSideBar={sidebar} nav={pageData?.header?.nav} />
                    </Route>

                    <Route exact path="/page/:parma" render={(prop) =>(<Page {...prop} openSideBar={sidebar} nav={pageData?.header?.nav} pageContent={pageData.page_contents.main_pages} />)} />
                    <Route exact path="/Contact_Us" render={(prop) =>(<ContactUs {...prop} openSideBar={sidebar} nav={pageData?.header?.nav} />)} />
                    <Route exact path="/Confirmation" render={(prop) =>(<Confirmation {...prop} openSideBar={sidebar} nav={pageData?.header?.nav} />)} />
                    <PrivateRoute path="/user" dispatch={false} component={LayoutComponent} />
                    <Route path="*">
                      <NotFoundUrl openSideBar={sidebar} nav={pageData?.header?.nav} />
                    </Route>
                </Switch>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        </AllAirportsProvider>
      </ErrorBoundary>
    );
  }
}

export default withRouter((App));
