// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import logo from '../../assets/Icons/logo.png';
import blankLogo from '../../assets/Icons/012218f4d43ade31f4e6146e2178f4be.jpg';

// import library
import React, {Component} from "react";
import { Input } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

class LeaveComment extends Component {
    

  componentDidMount(){
      
  };

  render(){
    try{
      return(
        <div className="px-5 py-4">
          <Form method="post">
              <h1>Leave a Comment</h1>
              <p className="t-color p m-0 p-0">Your email address will not be published. Required fields are marked *</p>
              <Grid container>
                  <Grid item xs={12}>
                    <Form.Field className="form-group pt-3">
                        <label className="mb-2"><b className="t-color">Your Message</b></label>
                        <textarea type="email" className="w-100 mt-1 box-shadow" rows="5"></textarea>
                    </Form.Field>
                  </Grid>
                  <Grid item xs={4} className="pe-2">
                    <Form.Field className="form-group pt-3">
                        <label className="mb-2"><b className="t-color">Name</b></label>
                        <Input type="text" className="w-100 mt-1 box-shadow" />
                    </Form.Field>
                  </Grid>
                  <Grid item xs={4} className="pe-2">
                    <Form.Field className="form-group pt-3">
                        <label className="mb-2"><b className="t-color">Email</b></label>
                        <Input type="text" className="w-100 mt-1 box-shadow" />
                    </Form.Field>
                  </Grid>
                  <Grid item xs={4} className="pe-2">
                    <Form.Field className="form-group pt-3">
                        <label className="mb-2"><b className="t-color">Website</b></label>
                        <Input type="url" className="w-100 mt-1 box-shadow" />
                    </Form.Field>
                  </Grid>
                  <Grid item xs={5} className="py-3">
                    <Form.Field className="">
                        <Checkbox className="ms-n3 float-left p-0" /> <label className="float-left ps-2" style={{fontSize: '10px'}}>Notify Me Of Follow-Up Comments By Email.</label>
                    </Form.Field>
                    <Form.Field>
                        <Checkbox className="float-left p-0" /> <label className="float-left ps-2" style={{fontSize: '10px'}}>Notify Me Of New Posts By Email.</label>
                    </Form.Field>
                  </Grid>
                  <Grid item xs={7} className="py-3">
                    <div className="pt-2">
                        <button type="button" class="bg-gradient border-0 btn-footer btn btn-primary" style={{width:'150px'}}>Post Comment</button>
                    </div>
                  </Grid>
              </Grid>
              
          </Form>
        </div>
      );
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default LeaveComment;