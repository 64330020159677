import React from 'react';
import { Row, Form, Button, Modal } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import { NavLink, Redirect } from 'react-router-dom';
const sww = require('../../assets/Icons/clear.svg');
console.log(sww.default);

export default class SessionExpired extends React.Component {

    refresh(){
        window.location.reload();        
    }

    render() {
        try{
            let url = `search-results/${this.props.data.from.ooro_AirportCode}/${this.props.data.to.ooro_AirportCode}/${this.props.data.departDateStr}/${this.props.data.returnDateStr}/${this.props.data.travellersData.adult}/${this.props.data.travellersData.children}/${this.props.data.travellersData.infants}/${this.props.data.trip}/${Number(this.props.data.directFlight)}/${this.props.data.travellersData.seatClassesData.id}`;
            return (
                <>
                    <Grid container className="py-5">
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} className="text-center">
                            <img src={sww.default} style={{width:"100px"}} />
                            <h1>Your session has expired</h1>
                            <Grid container className="pt-4">
                                <Grid item xs={12} className="text-center">
                                    <NavLink to={url}>
                                        <Button variant="contained" type="button" className="shadow my-success-btn color-w bg-gradient border-0 pd-10 position-relative" style={{width:200}}>
                                            Research 
                                        </Button>
                                    </NavLink>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        
                    </Grid>     
                </>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}