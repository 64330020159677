// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import artboard1 from '../../assets/Icons/Artboard–1.png';
import iciciLogo from '../../assets/Icons/icici-logo.png';
import TripCancellation from '../../assets/Icons/trip-cancellation.png';
import TripDelay from '../../assets/Icons/trip-delay.png';
import LostBaggage from '../../assets/Icons/lost-baggage.png';
import MedicalExpenses from '../../assets/Icons/medical-expenses.png';
import ViewAll from '../../assets/Icons/view-all.png';

// import library
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { Card } from "react-onsenui";

class TravelInsurance extends Component {
    state = {
        value: true
    }

    hasChangeValue = (event) => this.setState({ value: event.target.value })

    componentDidMount(){
        
    };

    render(){
        try{
            return(
                <div className="box-shadow">
                    <Grid container>
                        <Grid item xs={12} className="bg-white px-3">
                            <Grid container className="travel-insurance position-relative">
                                <Grid item xs={1} className="ti-left-section py-4">
                                    <img src={artboard1} alt="Artboard1" className="airbord1 float-start" width="50px" />
                                </Grid>
                                <Grid item xs={3} className="ti-middle-section py-4">
                                    <p className="p-0 py-2 m-0 float-start">
                                        <b>43%</b> of our flyers secure their trip with ICICI lombard Travel insurence
                                    </p>
                                </Grid>
                                <Grid item xs={8} className="ti-right-section text-right py-4">
                                    <img src={iciciLogo} alt="iciciLogo" width="200px" className="icici-logo" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="px-3">
                        <Grid item xs className="py-3">
                            <img src={TripCancellation} alt="Artboard1" className="airbord1 float-start" width="50px" />
                            <p className="p-0 m-0 py-3 float-start"><b>Trip Cancellation</b></p>
                        </Grid>
                        <Grid item xs className="py-3">
                            <img src={TripDelay} alt="Artboard1" className="airbord1 float-start" width="50px" />
                            <p className="p-0 m-0 py-3 float-start"><b>Trip Delay</b></p>
                        </Grid>
                        <Grid item xs className="py-3">
                            <img src={LostBaggage} alt="Artboard1" className="airbord1 float-start" width="50px" />
                            <p className="p-0 m-0 py-3 float-start"><b>Lost Baggage</b></p>
                        </Grid>
                        <Grid item xs className="py-3">
                            <img src={MedicalExpenses} alt="Artboard1" className="airbord1 float-start" width="50px" />
                            <p className="p-0 m-0 py-3 float-start"><b>Medical Expenses</b></p>
                        </Grid>
                        <Grid item xs className="py-3 text-center">
                            <Grid container className="w-100">
                                <Grid item xs={2}>
                                    <img src={ViewAll} alt="Artboard1" className="airbord1 float-start" width="50px" />
                                </Grid>
                                <Grid item xs={10}>
                                    <p className="p-0 m-0 py-2"><b>View All Benifits</b></p>
                                    <p className="a sm-p w-100">Click Here</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="bg-white px-4">
                        <Grid item xs={8} className="py-3">
                        <RadioGroup aria-label="gender" name="gender1" value={this.state.value} onChange={this.hasChangeValue}>
                            <FormControlLabel value="yes" control={<Radio />} label="Yes, Secure my trip" />
                            <FormControlLabel value="no" control={<Radio />} label="No, I am prepared to take risk" />
                        </RadioGroup>
                        </Grid>
                        <Grid item xs={4} className="py-4 pe-3">
                            <div className="float-end text-center">
                                <p className="p-0 m-0"><b>Premium Amount</b></p>
                                <h1 className="p-0 m-0">₹ 179</h1>
                            </div>
                        </Grid>
                    </Grid>
                    <Alert severity="info"><b>Note: </b> The insurence policy is valid for indian residente between the ages of 2 and 70 years Terms and Condition.</Alert>
                </div>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default TravelInsurance;