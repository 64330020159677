import React from 'react';
import { Tab, Menu } from 'semantic-ui-react'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import baggage from "../../assets/Icons/baggage.svg";
import suitcase from "../../assets/Icons/suitcase.svg";
import { Clear } from '@material-ui/icons';
import { Button } from 'react-bootstrap';
import CustomFunction from '../library/CustomFunction';


const BaggageList = (prop) =>{
    try{
    
        let kg = Number(prop.data.SSR_TypeDesc.toLowerCase().split(' ')[1]);
        // console.log(ssrSplt[(ssrSplt.length -1)]);
        // let kg = Number(ssrSplt[(ssrSplt.length -1)].split('kgs')[0]);
        // console.log(kg);
        // if(isNaN(kg)){
        //     kg = Number(ssrSplt[(ssrSplt.length -1)].split('kg')[0]);
        //     console.log(kg);
        // }
        let img = "";
        if(kg <= 8){
            img = suitcase;
        }else{
            img = baggage;
        }

        const setData = () =>{
            prop.onHandle({flightId: prop.flightId, baggage: prop.data});
        }

        const unSetData = () =>{
            prop.onHandle({flightId: prop.flightId, baggage: {}});
        }

        let selectedData = prop?.selectedData;
        console.log(selectedData);
        if(selectedData?.SSR_TypeDesc == prop.data.SSR_TypeDesc){
            return(
                <Grid container style={{borderBottom:'1px solid black',backgroundColor: '#00000066'}} data-value="true" onClick={unSetData}>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className="p-2">
                        <img src={img} width="50" className="float-start" /> <span className="ms-3 text float-start" style={{fontSize:'15px'}}>{prop.data.SSR_TypeDesc}</span>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} lg={3} xl={3} className="p-2">
                        <IconButton aria-label="delete" className="float-end button" color="danger" data-value="true" onClick={unSetData}>
                            <Clear />
                        </IconButton> 
                        <span className="me-3 py-3 xs-py-0 float-end price" style={{fontSize:'15px'}}><b>₹ {prop.data.Total_Amount}</b></span>
                        
                    </Grid>
                </Grid>          
            );
        }
        else{
            return(
                <Grid container style={{borderBottom:'1px solid black'}} data-value="true" onClick={setData}>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className="p-2">
                        <img src={img} width="50" className="float-start" /> <span className="ms-3 text float-start" style={{fontSize:'15px'}}>{prop.data.SSR_TypeDesc}</span>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} lg={3} xl={3} className="p-2">
                        <IconButton aria-label="delete" className="float-end button" color="primary" data-value="true" onClick={setData}>
                            <AddIcon />
                        </IconButton> 
                        <span className="me-3 py-3 xs-py-0 float-end price" style={{fontSize:'15px'}}><b>₹ {prop.data.Total_Amount}</b></span>
                        
                    </Grid>
                </Grid>          
            );
        }
    } catch (error) {
        throw new Error(error);
    }
}

const BaggageView = (prop) =>{
    try{
        let item = [];

        let baggageData = prop.baggageData;

        if(baggageData?.length > 0){
            baggageData.forEach(e =>{
                item.push(<BaggageList data={e} selectedData={prop.selectedData} flightId={prop.flightId} onHandle={(data) =>{prop.onHandle(data)}} />);
            })
            
            return(
                <Grid container className="p-0 baggage">
                    <Grid item xs={12} className="p-3" style={{borderBottom:'1px solid black'}}>
                        <h3 className="m-0">Select your prefered meal(s) </h3>
                    </Grid>
                    <Grid item xs={12} className="p-3 xs-p-0" style={{maxHeight:'56vh', minHeight:'56vh', overflow:'auto'}}>
                        {item}
                    </Grid>
                    <Grid item xs={12} className="p-3 text-center">
                        <Button className="color-w bg-gradient border-0 pd-10" style={{width:80}} onClick={($event) => prop.onClosed(false)}>Done</Button>
                    </Grid>
                </Grid>
            );
        }
        else{
            return(
                <div style={{minHeight:'73vh', maxHeight:'73vh'}}></div>
            );
        }
    } catch (error) {
        throw new Error(error);
    }
}

const TabView = (prop) =>{
    try{
        console.log("=== prop.data ===", prop.data);

        const images = new CustomFunction().getImage(prop.data.airlineCode+'.gif');

        // let orgCode = prop.data.orgName.split('(')[1].split(')')[0];
        // let destCode = prop.data.destName.split('(')[1].split(')')[0];

        let orgCode = prop.data.orgName;
        let destCode = prop.data.destName;

        if(prop.data.l > 0){
            if(prop.selectedData?.Flight_ID){
                return <>
                <Grid container className="p-0">
                    <Grid item xs={3}>
                        <img src={images.default} width="30px" />
                    </Grid>
                    <Grid item xs={9}>
                        <span class="ms-2">{orgCode} - {destCode}</span>
                        <br />
                        <span class="ms-2" style={{fontSize:'10px'}}>1 of 1</span>
                    </Grid>
                </Grid>
                </>;
            }else{
                return <>
                <Grid container className="p-0">
                    <Grid item xs={3}>
                        <img src={images.default} width="30px" />
                    </Grid>
                    <Grid item xs={9}>
                        <span class="ms-2">{orgCode} - {destCode}</span>
                        <br />
                        <span class="ms-2" style={{color:'red', fontSize:'10px'}}>Selection Pending</span>
                    </Grid>
                </Grid>
                </>;
            }
        }
        else{
            return <>
                <Grid container>
                    <Grid item xs={3} className="p-0">
                        <img src={images.default} width="30px" />
                    </Grid>
                    <Grid item xs={9}>
                        <span class="ms-2">{orgCode} - {destCode}</span>
                        <br />
                        <span class="ms-2" style={{fontSize:'10px'}}>Not Available</span>
                    </Grid>
                </Grid>
            </>;
        }
    } catch (error) {
        throw new Error(error);
    }
}

class Baggage extends React.Component{
    render(){
        try{
            let item = [];
            this.props.data.forEach(element => {

                console.log("=== element ===", element);

                let baggageData = element?.ssrFlightDetails?.filter(e => e.SSR_TypeName == "BAGGAGE");
                let selectedData = this.props?.selectedData?.filter(e => e.flightId == element.Flight_Id)[0]?.baggage;

                item.push({
                    menuItem: (<Menu.Item key='tabView'>
                                <TabView data={{airlineCode: element.Airline_Code, orgName: element.Origin, destName: element.Destination, l:baggageData?.length?baggageData.length: 0}} selectedData={selectedData} />
                            </Menu.Item>),
                    render: () => <Tab.Pane attached={false}><BaggageView selectedData={selectedData} flightId={element.Flight_Id} baggageData={baggageData} onClosed={(data) =>this.props.onClosed(data)} onHandle={(data) =>{this.props.onHandle(data)}} /></Tab.Pane>,
                });
            });
            return(
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <h3 className="m-0 p-2 color-w bg-gradient"> Add Baggage</h3>
                        </Grid>
                    </Grid>
                    <Tab menu={{ pointing: true }} panes={item} />
                </>
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default Baggage;