import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
} from "reactstrap";
import Widget from "../Widget/Widget";
import moreIcon from "../../assets/tables/moreIcon.svg";

import s from "./BookingList.module.scss";  
import { CircularProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Services from "../../../../shared/services/services";
import DisplayMessages from "../../../../shared/displayMessages/displayMessages";
import { Modal } from 'react-bootstrap';
import multiply from "../../../../assets/Icons/multiply.svg";
import { PrintRounded, Visibility } from "@material-ui/icons";
import TicketsDetrails from "../TicketsDetrails/TicketsDetrails";

const BookingList = function () {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [firstTable] = useState(mock.firstTable);
  const [secondTable, setSecondTable] = useState([]);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isDetailsModel, setIsDetailsModel] = useState(false);
  const [userSession, setUserSession] = useState('');

  const pageSize = 10;
  // const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  }

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  }

  const transactionMenuOpen = (id) => {
    setSecondTable(
      secondTable.map( transaction => {
        if (transaction.id === id) {
          transaction.dropdownOpen = !transaction.dropdownOpen;
        }
        return transaction;
      })
    )
  }

  const toggleDetailsModel = (userSession = '') =>{
    setUserSession(userSession);
    setIsDetailsModel(!isDetailsModel);
  }

  React.useEffect(() => {
    // document.title = "Ooro Flights";
    let token = localStorage.getItem('OoroToken');
    let data = {
        "Auth_Header": {
          "UserToken": token,
          "is_user": 0
        },
        "limit": 2
    }
    if(token){
      new Services().getBookingList(data).then(res => res.json()).then(
        (result) => {
          console.log(result);
          if(Array.isArray(result.message)){
            let data = result.message;
            data.map((element, i) => { element.dropdownOpen = false; element.id = i+1; return element; });
            setSecondTable(data);
          }
          else{
            setSecondTable(result.message);
          }
          setLoading(false);
          setError(false);
          //this.setAllFlightsData(result);
            
        },
        (error) => {
          console.log("== error ==", error);
          setLoading(true);
          setError(true);
          // new DisplayMessages().errorMsg({error:error});
        }
      );
    }
    else{
      window.location.reload();
    }
  }, []);

  const printPartOfPage = (elementId, uniqueIframeId) =>{
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', uniqueIframeId)
        iframe.setAttribute('id', uniqueIframeId)
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        document.body.appendChild(iframe)
        pri = iframe.contentWindow
    }
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
}

  return (
  <div>
    <Modal size="lg" dialogClassName="modal-90w" className="p-0" centered show={isDetailsModel}
      backdrop="static" keyboard={false}
      >
        {/* <PrintRounded className="position-absolute a" style={{top:5, right:40, zIndex:10}} onClick={() => printPartOfPage('print-section', 'iframe-print-section')}></PrintRounded> */}
        <img src={multiply} className="position-absolute a" width="25px" style={{top:10, right:10, zIndex:10}} onClick={() =>{ toggleDetailsModel('') }} />
        <Modal.Body className="p-0" 
        // onClick={this.toggleDepart}
        >
            <card className="bg-white" id="print-section">
                
                <TicketsDetrails userSession={userSession} />
            </card>
        </Modal.Body>
    </Modal>
  <Row>
      <Col>
          <Row className="mb-4">
              <Col>
                  <Widget>
                  <div className={s.tableTitle}>
                      <div className="headline-2">Booking List</div>
                      {/* <Dropdown
                      className="d-none d-sm-block"
                      nav
                      isOpen={tableDropdownOpen}
                      toggle={() => tableMenuOpen()}
                      >
                      <DropdownToggle nav>
                          <img className="d-none d-sm-block" src={moreIcon} alt="More..."/>
                      </DropdownToggle>
                      <DropdownMenu >
                          <DropdownItem>
                          <div>Copy</div>
                          </DropdownItem>
                          <DropdownItem>
                          <div>Edit</div>
                          </DropdownItem>
                          <DropdownItem>
                          <div>Delete</div>
                          </DropdownItem>
                      </DropdownMenu>
                      </Dropdown> */}
                  </div>
                  <>{ !loading ? (
                        <>
                            <div className="widget-table-overflow">
                                    <Table className="table-striped table-borderless table-hover" responsive>
                                    <thead>
                                    <tr>
                                        {/* <th>
                                        <div className="checkbox checkbox-primary">
                                            <input
                                            id="checkbox200"
                                            className="styled"
                                            type="checkbox"
                                            />
                                            <label for="checkbox200"/>
                                        </div>
                                        </th> */}
                                        <th className={s.nameCol + ' ps-3'}>Order No.</th>
                                        <th>Passenger</th>
                                        <th>Book Date</th>
                                        <th>Travel Date</th>
                                        <th>PNR/Ref</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                        { Array.isArray(secondTable) ? (
                                            <tbody>
                                            {secondTable
                                                .slice(
                                                secondTableCurrentPage * pageSize,
                                                (secondTableCurrentPage + 1) * pageSize
                                                )
                                                .map((item, i) => (
                                                <tr key={uuidv4()}>
                                                {/* <td>
                                                    <div className="checkbox checkbox-primary">
                                                    <input
                                                        id={item.id}
                                                        className="styled"
                                                        type="checkbox"
                                                    />
                                                    <label for={item.id} />
                                                    </div>
                                                </td> */}
                                                <td className="ps-3"><a style={{cursor: 'pointer', color: '#21ba45'}} onClick={() =>{ toggleDetailsModel(item.userSession) }}>{item.userSession} <Visibility></Visibility> </a></td>
                                                <td>{item.name} <br /> <small>{item.mob} / {item.email}</small> </td>
                                                <td>{item.bookDate}</td>
                                                <td><span className="m-0" dangerouslySetInnerHTML={{ __html: item.Destination }}></span> <br /> <span className="m-0" dangerouslySetInnerHTML={{ __html: item.trvDate }}></span></td>
                                                <td dangerouslySetInnerHTML={{ __html: item.pnr }}></td>
                                                <td>₹{item.amt}</td>
                                                <td><Badge className={item.status + ' badge'}>{item.status}</Badge></td>
                                                <td>
                                                    <Dropdown
                                                        className="d-none d-sm-block custom-menu"
                                                        nav
                                                        isOpen={item.dropdownOpen}
                                                        toggle={() => transactionMenuOpen(item.id)}
                                                    >
                                                        <DropdownToggle nav>
                                                        <img className="d-none d-sm-block" src={moreIcon} alt="More ..."/>
                                                        </DropdownToggle>
                                                        <DropdownMenu >
                                                        <DropdownItem>
                                                            <div>Cancel</div>
                                                        </DropdownItem>
                                                        {/* <DropdownItem>
                                                            <div>Edit</div>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <div>Delete</div>
                                                        </DropdownItem> */}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        ): (<>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={10} className="text-center">
                                                        {secondTable}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>)}
                                    </Table>
                                    {Array.isArray(secondTable) ? (<>
                                        <Pagination className="pagination-with-border">
                                        <PaginationItem disabled={secondTableCurrentPage <= 0}>
                                            <PaginationLink
                                            onClick={e => setSecondTablePage(e, secondTableCurrentPage - 1)}
                                            previous
                                            href="#top"
                                            />
                                        </PaginationItem>
                                        {[...Array(secondTablePagesCount)].map((page, i) =>
                                            <PaginationItem active={i === secondTableCurrentPage} key={i}>
                                            <PaginationLink onClick={e => setSecondTablePage(e, i)} href="#top">
                                                {i + 1}
                                            </PaginationLink>
                                            </PaginationItem>
                                        )}
                                        <PaginationItem disabled={secondTableCurrentPage >= secondTablePagesCount - 1}>
                                            <PaginationLink
                                            onClick={e => setSecondTablePage(e, secondTableCurrentPage + 1)}
                                            next
                                            href="#top"
                                            />
                                        </PaginationItem>
                                        </Pagination>
                                    </>) : (<></>)}
                                </div>
                        </>
                ) : (<>
                    <Grid container>
                        <Grid item xs={12} className="py-5 text-center">
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </>)}</>
                  </Widget>
              </Col>
          </Row>

      </Col>
  </Row>
</div>
  
  )
}

export default BookingList;
