// import css & scss
import "../scss/comman.scss";
import "../scss/comman.css";
import "../../Globle.scss";

// import img
import logo from '../../assets/Icons/logo.png';
import blankLogo from '../../assets/Icons/012218f4d43ade31f4e6146e2178f4be.jpg';

// import library
import React, {Component} from "react";
import { Input } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

class Forms extends Component {
    

  componentDidMount(){
      
  };

  render(){
    try{
      return(
        <div className="card box-shadow px-5 py-4">
          <Form method="post">
              <h1 className="t-color">Get In Touch !</h1>
              <Form.Field className="form-group pt-1">
                <label className="mb-2"><b className="t-color">Your Name (Required)</b></label>
                <Input type="text" className="w-100 mt-1 box-shadow" placeholder="Name" />
              </Form.Field>
              <Form.Field className="form-group pt-1">
                <label className="mb-2"><b className="t-color">Your Email (Required)</b></label>
                <Input type="email" className="w-100 mt-1 box-shadow" placeholder="Email" />
              </Form.Field>
              <Form.Field className="form-group pt-1">
                <label className="mb-2"><b className="t-color">Mobile Number</b></label>
                <Input type="text" className="w-100 mt-1 box-shadow" placeholder="Mobile Number" />
              </Form.Field>
              <Form.Field className="form-group pt-1">
                <label className="mb-2"><b className="t-color">Subject</b></label>
                <Input type="email" className="w-100 mt-1 box-shadow" placeholder="Subject" />
              </Form.Field>
              <Form.Field className="form-group pt-1">
                <label className="mb-2"><b className="t-color">Your Message</b></label>
                <textarea className="w-100 mt-1 box-shadow" rows="5" placeholder="Message"></textarea>
              </Form.Field>
              <div className="pt-3 text-center">
                <button type="button" class="bg-gradient border-0 btn-footer btn btn-primary" style={{width:'100px'}}>Submit</button>
              </div>
          </Form>
        </div>
      );
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default Forms;