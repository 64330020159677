import "./Page.scss";
import "./Page.css";
import Header from '../../../shared/component/Header.js';
import LeaveComment from '../../../shared/component/LeaveComment.js';
import LiBlock from '../../../shared/component/LiBlock.js';
import Forms from '../../../shared/component/Form.js';
import banner from '../../../assets/Icons/june1.jpg';
import Grid from '@material-ui/core/Grid';
import CustomCalender from "../../../shared/component/CustomCalender";
//import Calendar from "../../../shared/component/calender/Calendar";
//import Input from '../../../shared/component/Input.js';

const checkLogin = (status) =>{
    //this.setState({loggedIn: status});
}

function Page(prop) {
    try{
        const { parma } = prop.match.params;
        console.log("parma =====", parma);
        let pageHtml = '';

        let pageContent = prop.pageContent.filter(r => r.parma == parma);
        if(pageContent.length > 0){
            pageHtml = pageContent[0].contents;
            document.title = pageContent[0].title;
        }

        return (
            <div className="TravelStoryDetails">
                <Header onLoggedIn={checkLogin} openSideBar={(status) =>prop.openSideBar(status)} nav={prop.nav}  />
                <Grid container className="px-5 pb-5" style={{paddingTop: '130px'}}>
                    <Grid item xs={12} className="ps-5 pe-3" dangerouslySetInnerHTML={{__html:pageHtml}}>
                    </Grid>
                </Grid>
                
            </div>
        );
    } catch (error) {
        throw new Error(error);
    }
  }
  
  export default Page;