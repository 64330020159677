import React from 'react'
import { Input } from 'semantic-ui-react';


const Inputs = (prop) => {

    const handelChange = (event, input) =>{
        prop.onChange({parentIndex: prop.parentIndex, childIndex: prop.childIndex}, input.value);
    }

    return <Input label={prop.label} type={prop.type} value={prop.value} placeholder={prop.placeHolder} className={prop.classes} name={prop.name} id={prop.id} style={prop.style} onChange={handelChange} />
}

export default Inputs